import React,{useMemo} from "react";
import { useTranslation } from "react-i18next";
import "./TemperatureSide.scss";
import { LoadSetUpCalculations } from "../../../../../services/loadSetUpCalculations";
import { useSelector } from "react-redux";
const TemperatureSide:React.FC<any>  = (props) => {
  const { t } = useTranslation();   
  const { LOAD_DATA_JSON } = useSelector((state: any) => state?.ecoEfficiency?.config || {});
  
  //mtLoadRanges 
  const loadRanges = useMemo(() => {
    return ({ from: LoadSetUpCalculations?.getTotal(props.TempTable?.filter(value => value?.cabinetType === props?.cabinetType)?.map(cabinet => cabinet?.capacityFrom && cabinet?.capacityFrom) || []) || 0, to: LoadSetUpCalculations?.getTotal(props.TempTable?.filter(value => value?.cabinetType === props?.cabinetType)?.map(cabinet => cabinet?.capacityTo && cabinet?.capacityTo) || []) || 0 });
  }, [props?.cabinetType,props.TempTable]);
  return (
        <div className="store-size-location-table">
            <div className="sub-head">{props.temperatureType}{loadRanges.from} kW to {loadRanges.to} kW</div>
            <div className="panel-body">
              
              <div className="table-head">
              {props?.headerType?.map((e: any) => {
              return (
                <div key={`div${e?.fieldKey}`} className="table-items">
                  {t(e?.fieldKey)}{" "}
                </div>
              );
              })}
              </div>
              
              <div>
              <div className="row_entries"> 
              <div className="accordion accordion-flush">
                <div className="accordion_form_main">
                {props.TempTable?.filter(value => value?.cabinetType === props?.cabinetType)?.map((value, key) => {
                    return (
                        <div key={key} className="accordion_div_main">
                        <div className="accordion-item">
                          <div className="accordion-header">
                          <div className="table-data">{value?.cabinetName?.charAt(0)?.toUpperCase() + value?.cabinetName?.slice(1)}</div>
                          <div className="table-data">{LOAD_DATA_JSON?.[`${value?.cabinetId}`]?.tempFrom} to {LOAD_DATA_JSON?.[`${value?.cabinetId}`]?.tempTo} °C</div>
                          <div className="table-data">{value?.capacityFrom} to {value?.capacityTo} kW</div>
                          </div>
                        </div>
                      </div>
                     );
                  })}
                </div>
              </div>         
              </div>
            </div>
          </div>
        </div>
    );
};

export default TemperatureSide;