import React from "react";
import MediumTempSide from "./MediumTempSide";
import LowTempSide from "./LowTempSide";
const StoreSizeTable:React.FC<any> = (props) => { 
    return (
        <div className="row">
           <div className="col-md-6 col-sm-12">
            <MediumTempSide key={`${props.type}MTTable`} storeType={props.type} mediumTempTable={props.mediumTempTable} tableHeader={props.tableHeader} viewSchematic={true}/>
           </div>
           <div className="col-md-6 col-sm-12">
            <LowTempSide key={`${props.type}LTTable`} storeType={props.type} lowTempTable={props.lowTempTable} tableHeader={props.tableHeader} viewSchematic={true}/>
           </div>
        </div>
    );
};

export default StoreSizeTable;