import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import './StackedGraph.scss';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from "chartjs-plugin-datalabels";
ChartJS.register(
  CategoryScale,
  ChartDataLabels,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
const StackedGraph= (props) => {
  let delayed;
const options = {
  animation: {
    onComplete: () => {
      delayed = true;
    },
    delay: (context) => {
      let delay = 0;
      if (context.type === 'data' && context.mode === 'default' && !delayed) {
        delay = context.dataIndex * 300 + context.datasetIndex * 100;
      }
      return delay;
    },
  },
  type: 'line',
  plugins: {
    legend: {
      filter:null,
      onClick: function (e) {
        e.stopPropagation();
    },
    
    position:'right',
    labels: {
      usePointStyle:true,
        font: {
          size: [`${props?.label?.length >= 5 ? 12 : 14}`],
          weight: 900
        }
    }
    },
    datalabels: {
      display: true,
      color: "black",
      offset: 40,
    },
    title: {
      display: true,
      
    },
  },
  scales: {
    x: {
      grid: {
        color: 'transparent',
        borderColor: 'black'
      },
      scaleInstance : 
      {
        width : 50
      },
      ticks: {
        autoSkip: false,
        maxRotation: [`${props?.label?.length >= 5 ? 20 : 0}`],
        minRotation: [`${props?.label?.length >= 5 ? 20 : 0}`],
        // maxRotation:0,
        // minRotation:0,
        font: {
          size: [`${props?.label?.length >= 5 ? 12 : 14}`],
          weight: 900
        },
    },
    },
    A: {
      
      grid: {
        color: 'transparent',
        borderColor: 'black'
      },
      type: 'linear',
      position: 'left',
      ticks: { beginAtZero: true, color: 'black', font: {
        size: [`${props?.label?.length >= 5 ? 12 : 14}`],
        weight: 900
      } },
      title: {
        display: true,
        text: 'CapEx & OpEx (Euro)',
        padding:10,
        color:'#000',
        font: {
          size: [`${props?.label?.length >= 5 ? 12 : 14}`],
          weight: 900
        }
      }
    },
    B: {
      type: 'linear',
      position: 'right',
      ticks: { beginAtZero: true, color: 'black', font: {
        size: [`${props?.label?.length >= 5 ? 12 : 14}`]
      } },
      title: {
        display: true,
        text: 'Emissions (TCO2)',
        padding:10,
        color:'#000',
        font: {
          size: [`${props?.label?.length >= 5 ? 12 : 14}`],
          
          weight: 900
        }
      },
      grid: {
        color: 'transparent',
        borderColor: 'black'
      },
    },
  },
};

const labels = props.label;

const data = {
  labels,
  datasets: [
    
    {
      label: 'CAPEX',
      data: props.capex,
      backgroundColor: '#CE5D55',
      stack: 'Stack 0',
      pointStyle:'rect',
      datalabels: {
        display: true,
        font: {
          size: 10,
          weight: 800
        },
        color: '#000',
        formatter: function(value) {
          value = Math.round(value);
          return  value.toLocaleString();
        },
        offset: 0,
      },
      yAxisID: 'A'
    },
    {
      label: 'OpEx - Electricity',
      data: props.opexElectricity,
      backgroundColor: '#01AFF1',
      stack: 'Stack 0',
      pointStyle:'rect',
      datalabels: {
        display: true,
        font: {
          size: 10,
          weight: 800
        },
        color: '#000',
        formatter: function(value) {
          value = Math.round(value);
          return  value.toLocaleString();
        },
        offset: 0
      },
      yAxisID: 'A',
    },
    {
      label: 'OpEx - Service',
      data: props.opexService,
      backgroundColor: '#FBC400',
      stack: 'Stack 0',
      pointStyle:'rect',
      datalabels: {
        display: true,
        font: {
          size: 12,
          weight: 800
        },
        color: '#FBC400',
        formatter: function(value) {
          value = Math.round(value);
          return  value.toLocaleString();
        },
        offset: -55,
        anchor: "end",
        align: "start",
      },
      yAxisID: 'A'
    },
    {
      label: 'OpEx - Topping Off',
      data: props.opexToppingUp,
      backgroundColor: '#9EC66A',
      stack: 'Stack 0',
      pointStyle:'rect',
       datalabels: {
        display: true,
        font: {
          size: 12,
          weight: 800,
         
        },
        color: '#9EC66A',
        formatter: function(value) {
          value = Math.round(value);
          return  value.toLocaleString();
        },
        offset: -70,
        anchor: "end",
        align: "start",
      },
      yAxisID: 'A',
    },
    {
      label: 'Emissions (TC02)',
      data: props.emission,
      backgroundColor: '#717272',
      stack: 'Stack 1',
      pointStyle:'rect',
      border:'5px',
      borderColor:'white',
      yAxisID: 'B',
      datalabels: {
        display: true,
        color: "white",
        font: {
          size: 10,
          weight: 800
        },
        formatter: function(value) {
          value = Math.round(value);
          return  value.toLocaleString();
        },
        offset: 0,
      },
    }
  ],
};


  return <> 
      <Bar id={props?.source} options={options} data={data} aria-label="Stacked Graph" />
      <Bar style={{display:'none', opacity:'0.5'}} id="stacked-graph-pdf" options={options} data={data} aria-label="Stacked Graph" />
   </>;
};
export default StackedGraph;
