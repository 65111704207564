/****************************************************
ContactForm.tsx - SFDC Conatct form
*****************************************************/
import React, { useEffect } from 'react';
import "./ContactForm.scss";
const ContactForm = () => {
  useEffect(() => {
    setTimeout(function () {
      if(!document.getElementById("contactFormJs"))
      {
      const scriptFile = document.createElement("script");
      scriptFile.id="contactFormJs";
      scriptFile.src = "/js/contactForm.js";
      document.body.appendChild(scriptFile);
      }
    }, 300);
      // eslint-disable-next-line
  }, []);
  return (
    <>
      <div className="contact-info">
        <div className="contact-title">Contact Us</div>
        <p>We will only use your personal information to respond to your inquiry. Our <a href="https://www.honeywell.com/us/en/privacy-statement" target="_blank" role="link">Privacy Policy</a> provides detailed information on how we use and protect your personal information.</p>
        <p>Please provide all information requested so we can respond more quickly to your inquiry:</p>
      </div>
      <div id={"wrapperContactForm"}>
        <form id="mktoForm_140">
        </form>
      </div>
    </>
  );
};
export default ContactForm;
