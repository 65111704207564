//calculations for load setup 
import React from 'react';
export class LoadSetUpCalculations extends React.Component {
    private static numberOr0 = num => isNaN(num) ? 0 : num;
    //Capacity calculations
    public static getCapacity = (cabinetId, temperature, length) => {
        let capacityValue = 0;
        let specCapacity = 0;
        try {
            switch (cabinetId) {
                case "multiOpen":
                    specCapacity = (-0.0031 * Math.pow(temperature, 3) + 0.0436 * Math.pow(temperature, 2) - 0.2025 * temperature + 1.408) * 1.2;
                    capacityValue = LoadSetUpCalculations.getRoundValue(length * specCapacity, 1);
                    break;
                case "multiClosed":
                    specCapacity = (-0.0012 * Math.pow(temperature, 3) + 0.0013 * Math.pow(temperature, 2) - 0.0575 * temperature + 1.06) * 1.2;
                    capacityValue = LoadSetUpCalculations.getRoundValue(length * specCapacity, 1);
                    break;
                case "semiVerticalClosed":
                    specCapacity = (-0.0133 * temperature + 0.6267) * 1.2;
                    capacityValue = LoadSetUpCalculations.getRoundValue(length * specCapacity, 1);
                    break;
                case "rollIn":
                    specCapacity = (-0.025 * temperature + 1.73) * 1.2;
                    capacityValue = LoadSetUpCalculations.getRoundValue(length * specCapacity, 1);
                    break;
                case "ultraFresh":
                    specCapacity = (-0.04 * temperature + 1.95) * 1.2;
                    capacityValue = LoadSetUpCalculations.getRoundValue(length * specCapacity, 1);
                    break;
                case "counter":
                    specCapacity = (-0.0083 * temperature + 0.34) * 1.2;
                    capacityValue = LoadSetUpCalculations.getRoundValue(length * specCapacity, 1);
                    break;
                case "glassDoorMt":
                    specCapacity = (-0.005 * temperature + 0.29) * 1.2;
                    capacityValue = LoadSetUpCalculations.getRoundValue(length * specCapacity, 1);
                    break;
                case "storageRoomX3":
                    capacityValue = 0.2616240497 * Math.pow(length, 0.688677507);
                    capacityValue = LoadSetUpCalculations.getRoundValue(capacityValue, 1);
                    break;
                case "storageRoomX4":
                    capacityValue = 0.2378814091 * Math.pow(length, 0.6886417413);
                    capacityValue = LoadSetUpCalculations.getRoundValue(capacityValue, 1);
                    break;
                case "storageRoomX8":
                    capacityValue = 0.2219994127 * Math.pow(length, 0.6894364563);
                    capacityValue = LoadSetUpCalculations.getRoundValue(capacityValue, 1);
                    break;
                case "laboratoryDockX4":
                    capacityValue = 0.3353497705 * Math.pow(length, 0.6527073409);
                    capacityValue = LoadSetUpCalculations.getRoundValue(capacityValue, 1);
                    break;
                case "islandSingleRowWithLids":
                    specCapacity = (-0.005 * temperature + 0.11) * 1.1;
                    capacityValue = LoadSetUpCalculations.getRoundValue(length * specCapacity, 1);
                    break;
                case "islandDoubleRowWithLids":
                    specCapacity = (-0.0083 * temperature + 0.26) * 1.1;
                    capacityValue = LoadSetUpCalculations.getRoundValue(length * specCapacity, 1);
                    break;
                case "combo":
                    specCapacity = (-0.0067 * temperature + 0.29) * 1.1;
                    capacityValue = LoadSetUpCalculations.getRoundValue(length * specCapacity, 1);
                    break;
                case "glassDoorLt":
                    specCapacity = (-0.01 * temperature + 0.12) * 1.1;
                    capacityValue = LoadSetUpCalculations.getRoundValue(length * specCapacity, 1);
                    break;
                case "storageRoomX2":
                    capacityValue = 0.34411271 * Math.pow(length, 0.6373384864);
                    capacityValue = LoadSetUpCalculations.getRoundValue(capacityValue, 1);
                    break;
                default:
                    capacityValue = 0;
                    break;
            }

        } catch {
            capacityValue = 0;
        }
        return capacityValue;
    };
    public static getTotal = (inputColl: any[]) => {
        let sumValue = 0;
        try {
            sumValue = inputColl.reduce((a, b) =>
                LoadSetUpCalculations.numberOr0(a) + LoadSetUpCalculations.numberOr0(b));

        } catch {
            sumValue = 0;

        }
        return Math.round(sumValue);
    };
    public static getTotalWithPrecision = (inputColl: any[]) => {
        let sumValue = 0;
        try {
            sumValue = inputColl.reduce((a, b) =>
                LoadSetUpCalculations.numberOr0(a) + LoadSetUpCalculations.numberOr0(b));

        } catch {
            sumValue = 0;

        }
        return LoadSetUpCalculations.getRoundValue(sumValue,1);
    };
    private static getRoundValue = (value, precision) => {
        const multiplier = Math.pow(10, precision || 0);
        return Math.round(value * multiplier) / multiplier;
    };
}