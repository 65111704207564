import React, { useCallback, useState, useMemo, useEffect } from "react";
import { skipToken } from "@reduxjs/toolkit/query";
import "./DistributedSystemAirCooled.scss";
import { LABELKEYS_COMPRESSOR_SETUP, LABELKEYS_SYSTEM_LOAD, REGEX_ITEMS } from "../../../../../../helper/constants";
import { useTranslation } from "react-i18next";
import TabStepper from "../../../../../common/TabContainer/TabStepper";
import ProgressStepper from '../../../../../common/ProgressBar/ProgressStepper';
import Headline from '../../../../../common/Headline/Headline';
import { useSelector } from "react-redux";
import DistributedSystemPanel from "../distributedsystempanel/DistributedSystemPanel";
import { useGetDataQuery, dataApi } from "../../../../../../services/data";
import { setTables, setCompressorSetup, setCalculator } from "../../../../../../store/actions";
import { CompressorSetUpCalculations } from "../../../../../../services/compressorSetUpCalculations";
import Loader from "../../../../../common/Loader/Loader";
import { store } from '../../../../.../../../../store/ecoEfficiencyStore';
const DistributedSystemAirCooled = () => {
  const { t } = useTranslation();
  const [isDefaultLTLoading, setIsDefaultLTLoading] = useState(false);
  const { SYSTEM_ARCHS_PRE_CALCULATION_REACT_MS_MAPPING, SERVICE_REQUEST_FETCH_DATA_TYPES, SYSTEM_ARCHS_KEYS, COMPRESSOR_ARCHS_CONFIG, TYPE_OF_RACK, DISTRIBUTED_SYSTEM_TABLE_HEADER, PROGRESSBAR_STEPS_VALUE_COLL, PAGE_NAVIGATION, SYSTEM_ARCHS_REACT_MS_MAPPING, RESTORE_SESSION_PAGE_IDS } = useSelector((state: any) => state?.ecoEfficiency?.config || {});
  const [typeOfRack, setTypeOfRack] = useState(TYPE_OF_RACK.MT);
  const [defaultResetKey, setDefaultResetKey] = useState(Date.now());
  const { userInputs, tables, updatedOn, restoreTransactionId, restoredSessions, restoreSessionData } = useSelector((state: any) => state?.ecoEfficiency?.calculator || {});
  const { locationAndSize, chooseSystems, compressorSetup, loadSetup } = userInputs;
  const [climate] = useState(tables?.countryMaster?.[locationAndSize?.country]?.[locationAndSize?.city]);
  const { ARC_3 } = compressorSetup;
  const type = ARC_3?.[typeOfRack]?.type;
  const company = ARC_3?.[typeOfRack]?.company;
  //type restoreSessionData
  const restoreSessionArcData = useMemo(() => {
    if (!restoredSessions?.includes(RESTORE_SESSION_PAGE_IDS?.ARC_3) && restoreSessionData?.[restoreTransactionId]?.[RESTORE_SESSION_PAGE_IDS?.ARC_3]) {
      return restoreSessionData?.[restoreTransactionId]?.[RESTORE_SESSION_PAGE_IDS?.ARC_3] || undefined;
    }
    else {
      return undefined;
    }
  }, [RESTORE_SESSION_PAGE_IDS, restoreTransactionId, restoredSessions, restoreSessionData]);
  //On rack type size
  const setRackType = useCallback((rackType) => {
    setTypeOfRack(rackType);
  }, [setTypeOfRack]);

  // for active stage
  const activeStageLocationSetup = useMemo(() => {
    const selectedSystemArr = tables?.systemArchMaster?.filter(value => chooseSystems?.selectedSystems?.indexOf(value?.architectureId) > -1)?.map((item: any) => item.architectureId);
    const nextCompressorStage = tables?.systemArchMaster?.filter(value => selectedSystemArr?.indexOf(value.architectureId) > selectedSystemArr?.indexOf(Object.keys(SYSTEM_ARCHS_REACT_MS_MAPPING)[2]))[0]?.architectureId || "";
    return nextCompressorStage;
  }, [chooseSystems, SYSTEM_ARCHS_REACT_MS_MAPPING, tables?.systemArchMaster]);
  // for next page link
  const continueNextPageLink = useMemo(() => {
    let nextPAgeLink = PAGE_NAVIGATION.SYSTEM_DETAILS.COMPRESSOR_SETUP.DISTRIBUTED_SYSTEM_WATER;
    if (!activeStageLocationSetup) {
      nextPAgeLink = PAGE_NAVIGATION.SYSTEM_DETAILS.PARAMETER_SETUP;
    }
    return nextPAgeLink;
  }, [activeStageLocationSetup, PAGE_NAVIGATION]);

  //refrigerant
  const refrigerant = useMemo(() => {
    if (typeOfRack == TYPE_OF_RACK.MT) {
      return chooseSystems?.system?.[SYSTEM_ARCHS_KEYS.ARC_3]?.mtSide;
    }
    else if (typeOfRack == TYPE_OF_RACK.LT) {
      return chooseSystems?.system?.[SYSTEM_ARCHS_KEYS.ARC_3]?.ltSide;
    }
    else {
      return null;
    }
  }, [chooseSystems, typeOfRack, SYSTEM_ARCHS_KEYS, TYPE_OF_RACK]);

  //tableKey
  const tableKey = useMemo(() => {
    return `${locationAndSize?.storeSize}-${SYSTEM_ARCHS_KEYS.ARC_3}-${typeOfRack}-${refrigerant}-${type}-${company}-${climate}`;
  }, [typeOfRack, locationAndSize?.storeSize, refrigerant, SYSTEM_ARCHS_KEYS, type, company, climate]);

  //tableKey
  const isTableReloadForRack = (rackType) => {
    const selctedType = ARC_3?.[rackType]?.type;
    const selectectCompany = ARC_3?.[rackType]?.company;
    let selctedRefrigerant = "";
    if (rackType == TYPE_OF_RACK.MT) {
      selctedRefrigerant = chooseSystems?.system?.[SYSTEM_ARCHS_KEYS.ARC_3]?.mtSide;
    }
    else if (typeOfRack == TYPE_OF_RACK.LT) {
      selctedRefrigerant = chooseSystems?.system?.[SYSTEM_ARCHS_KEYS.ARC_3]?.ltSide;
    }
    if (selctedType?.length > 0 && selectectCompany?.length > 0) {
      const key = `${locationAndSize?.storeSize}-${SYSTEM_ARCHS_KEYS.ARC_3}-${rackType}-${selctedRefrigerant}-${selctedType}-${selectectCompany}-${climate}`;
      if (!tables?.compressorMaster[key]) {
        return true;
      }
      else {
        return false;
      }
    }
    return false;
  };
  /*------Data Query -----*/
  //country master
  const { data: compressorData, isLoading: isCompressorDataLoading } = useGetDataQuery(!type || !company || !refrigerant || tables?.compressorMaster?.[tableKey] ? skipToken : {
    tableKey: tableKey,
    dataType: SERVICE_REQUEST_FETCH_DATA_TYPES.COMPRESSOR,
    storeSize: locationAndSize?.storeSize,
    architectureId: SYSTEM_ARCHS_KEYS.ARC_3,
    cabinetType: typeOfRack,
    type: type,
    company: company,
    refrigerant: refrigerant,
    climate: climate
  });
  useEffect(() => {
    if (compressorData?.result?.data && !tables?.compressorMaster[tableKey] && tableKey) {
      setTables({ compressorMaster: { ...tables?.compressorMaster, [tableKey]: compressorData?.result?.data } });
    }
    else {
      if (tableKey && !tables?.compressorMaster[tableKey]) {
        const systemData = { [SYSTEM_ARCHS_KEYS.ARC_3]: { ...compressorSetup[SYSTEM_ARCHS_KEYS.ARC_3], [typeOfRack]: { ...compressorSetup[SYSTEM_ARCHS_KEYS.ARC_3]?.[typeOfRack], compressorInputs: [] } } };
        setCompressorSetup({ ...compressorSetup, ...systemData });
      }
    }
    // eslint-disable-next-line 
  }, [compressorData?.result?.data]);
  useEffect(() => {
    if (!type && !company) {
      const defaultType = COMPRESSOR_ARCHS_CONFIG?.[SYSTEM_ARCHS_KEYS.ARC_3]?.[typeOfRack]?.DEFAULT_TYPE;
      const defaultCompany = COMPRESSOR_ARCHS_CONFIG?.[SYSTEM_ARCHS_KEYS.ARC_3]?.[typeOfRack]?.DEFAULT_COMPANY;
      const defaultLTType = COMPRESSOR_ARCHS_CONFIG?.[SYSTEM_ARCHS_KEYS.ARC_3]?.[TYPE_OF_RACK.LT]?.DEFAULT_TYPE;
      const defaulLTCompany = COMPRESSOR_ARCHS_CONFIG?.[SYSTEM_ARCHS_KEYS.ARC_3]?.[TYPE_OF_RACK.LT]?.DEFAULT_COMPANY;
      const systemData = { [SYSTEM_ARCHS_KEYS.ARC_3]: { ...compressorSetup[SYSTEM_ARCHS_KEYS.ARC_3], [TYPE_OF_RACK.MT]: { ...compressorSetup?.[SYSTEM_ARCHS_KEYS.ARC_3][TYPE_OF_RACK.MT], type: defaultType, company: defaultCompany }, [TYPE_OF_RACK.LT]: { ...compressorSetup?.[SYSTEM_ARCHS_KEYS.ARC_3][TYPE_OF_RACK.LT], type: defaultLTType, company: defaulLTCompany } } };
      setCompressorSetup({ ...compressorSetup, ...systemData });
    }
    // eslint-disable-next-line 
  }, [typeOfRack]);
  useEffect(() => {
    if (tableKey && tables?.compressorMaster?.[tableKey]) {
      if (!(ARC_3?.[typeOfRack]?.compressorInputs?.length > 0)) {
        const inputTable: any = [];
        const cabinets = tables?.cabinetBySizeMaster?.[`${locationAndSize?.storeSize}`]?.filter(value => value.cabinetType === typeOfRack);
        const compressorData = tables?.compressorMaster?.[tableKey]?.compressorData?.map(compressor => { return { modelId: compressor?.modelId, cop: compressor?.cop, enRating: compressor?.enRating, count: 0 }; });
        if (compressorData?.length < 5) {
          while (compressorData?.length < 5) {
            compressorData.push({ modelId: undefined, cop: 0, enRating: 0, count: 0 });
          }
        }
        cabinets?.forEach((cabinetItem) => {
          const defaultValueItem = tables?.compressorMaster?.[tableKey]?.additionalDefaultData?.[cabinetItem?.cabinetId];
          const defaultSessionCompressors = restoreSessionArcData?.[typeOfRack]?.[cabinetItem?.cabinetId]?.compressors || undefined;
          const compressorDataWithCount = compressorData?.map((item) => ({
            ...item,
            count: restoreSessionArcData ? defaultSessionCompressors?.[item?.modelId]?.count || 0 : (defaultValueItem?.[item?.modelId] || 0)
          }));
          const countColl = compressorDataWithCount?.map(comp => comp?.count || 0) || [];
          const enRatingColl = compressorDataWithCount?.map(comp => comp?.enRating || 0) || [];
          const capacity = CompressorSetUpCalculations.getCapacityCabinetWise(countColl, enRatingColl, cabinetItem?.noOfUnits);

          const inputItem = { cabinetId: cabinetItem?.cabinetId, cabinetName: cabinetItem?.cabinetName, capPerUnit: CompressorSetUpCalculations.getRoundValue(cabinetItem?.capPerUnit || 0, 1), noOfUnits: cabinetItem?.noOfUnits, capacity: capacity, compressorData: compressorDataWithCount };
          inputTable.push(inputItem);
        });
        if (inputTable?.length > 0) {
          const totalCapacity = CompressorSetUpCalculations?.getTotal(inputTable?.map(input => input?.capacity || 0)) || 0;
          const systemData = { [SYSTEM_ARCHS_KEYS.ARC_3]: { ...compressorSetup[SYSTEM_ARCHS_KEYS.ARC_3], [typeOfRack]: { ...compressorSetup[SYSTEM_ARCHS_KEYS.ARC_3]?.[typeOfRack], selectedCompressorLoad: totalCapacity, compressorInputs: inputTable } } };
          setCompressorSetup({ ...compressorSetup, ...systemData });
        }
      }
    }
    // eslint-disable-next-line 
  }, [tableKey, tables?.compressorMaster?.[tableKey], defaultResetKey]);

  // Set table selection 
  const setCountValue = useCallback((cabinetId: string, modelIndex: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const isValid = REGEX_ITEMS?.NUMBER?.test(e?.target?.value);

    if (e?.target?.value?.length === 0 || isValid) {
      const inputTable = ARC_3?.[typeOfRack]?.compressorInputs || [];
      if (inputTable?.length > 0) {
        const cabinetIndex = inputTable?.map(input => input?.cabinetId)?.indexOf(cabinetId);
        if (cabinetIndex > -1 && inputTable[cabinetIndex]?.cabinetId === cabinetId) {
          const configItem = inputTable[cabinetIndex];
          if (configItem?.compressorData?.[modelIndex]) {
            configItem.compressorData[modelIndex].count = e?.target?.value;
            const countColl = configItem?.compressorData?.map(comp => comp?.count || 0) || [];
            const enRatingColl = configItem?.compressorData?.map(comp => comp?.enRating || 0) || [];
            const capacity = CompressorSetUpCalculations?.getCapacityCabinetWise(countColl, enRatingColl, configItem?.noOfUnits);
            configItem.capacity = capacity;
            inputTable[cabinetIndex] = configItem;
            const totalCapacity = CompressorSetUpCalculations?.getTotal(inputTable?.map(input => input?.capacity || 0)) || 0;
            const systemData = { [SYSTEM_ARCHS_KEYS.ARC_3]: { ...compressorSetup[SYSTEM_ARCHS_KEYS.ARC_3], [typeOfRack]: { ...compressorSetup[SYSTEM_ARCHS_KEYS.ARC_3]?.[typeOfRack], selectedCompressorLoad: totalCapacity, compressorInputs: inputTable } } };
            setCompressorSetup({ ...compressorSetup, ...systemData });
          }

        }
      }
    }
  }, [compressorSetup, ARC_3, typeOfRack, SYSTEM_ARCHS_KEYS]);

  const setInputTable = useCallback((key, table, rack) => {
    if (key && table) {

      if (!(ARC_3?.[rack]?.compressorInputs?.length > 0)) {
        const inputTable: any = [];
        const cabinets = tables?.cabinetBySizeMaster?.[`${locationAndSize?.storeSize}`]?.filter(value => value.cabinetType === rack);
        const compressorData = table?.compressorData?.map(compressor => { return { modelId: compressor?.modelId, cop: compressor?.cop, enRating: compressor?.enRating, count: 0 }; });
        if (compressorData?.length < 5) {
          while (compressorData?.length < 5) {
            compressorData.push({ modelId: undefined, cop: 0, enRating: 0, count: 0 });
          }
        }
        cabinets?.forEach((cabinetItem) => {
          const defaultValueItem = table?.additionalDefaultData?.[cabinetItem?.cabinetId];
          const defaultSessionCompressors = restoreSessionArcData?.[rack]?.[cabinetItem?.cabinetId]?.compressors || undefined;
          const compressorDataWithCount = compressorData?.map((item) => ({
            ...item,
            count: restoreSessionArcData ? defaultSessionCompressors?.[item?.modelId]?.count || 0 : (defaultValueItem?.[item?.modelId] || 0)
          }));
          const countColl = compressorDataWithCount?.map(comp => comp?.count || 0) || [];
          const enRatingColl = compressorDataWithCount?.map(comp => comp?.enRating || 0) || [];
          const capacity = CompressorSetUpCalculations.getCapacityCabinetWise(countColl, enRatingColl, cabinetItem?.noOfUnits);

          const inputItem = { cabinetId: cabinetItem?.cabinetId, cabinetName: cabinetItem?.cabinetName, capPerUnit: CompressorSetUpCalculations.getRoundValue(cabinetItem?.capPerUnit || 0, 1), noOfUnits: cabinetItem?.noOfUnits, capacity: capacity, compressorData: compressorDataWithCount };
          inputTable.push(inputItem);
        });
        if (inputTable?.length > 0) {
          const totalCapacity = CompressorSetUpCalculations?.getTotal(inputTable?.map(input => input?.capacity || 0)) || 0;
          const systemData = { [SYSTEM_ARCHS_KEYS.ARC_3]: { ...compressorSetup[SYSTEM_ARCHS_KEYS.ARC_3], [rack]: { ...compressorSetup[SYSTEM_ARCHS_KEYS.ARC_3]?.[rack], selectedCompressorLoad: totalCapacity, compressorInputs: inputTable } } };
          setCompressorSetup({ ...compressorSetup, ...systemData });
        }
        if (restoredSessions && restoreTransactionId) {
          setCalculator({ restoredSessions: [...restoredSessions, RESTORE_SESSION_PAGE_IDS?.ARC_3] });
        }
      }
    }

  }, [compressorSetup, SYSTEM_ARCHS_KEYS, ARC_3, locationAndSize?.storeSize, tables?.cabinetBySizeMaster, RESTORE_SESSION_PAGE_IDS, restoredSessions, restoreTransactionId, restoreSessionArcData]);
  //loadDeafultLT
  const loadDefaultLT = async () => {
    const defaultType = await ARC_3?.[TYPE_OF_RACK.LT]?.type || COMPRESSOR_ARCHS_CONFIG?.[SYSTEM_ARCHS_KEYS.ARC_3]?.[TYPE_OF_RACK.LT]?.DEFAULT_TYPE;
    const defaultCompany = await ARC_3?.[TYPE_OF_RACK.LT]?.company || COMPRESSOR_ARCHS_CONFIG?.[SYSTEM_ARCHS_KEYS.ARC_3]?.[TYPE_OF_RACK.LT]?.DEFAULT_COMPANY;
    const ltRefrigerant = chooseSystems?.system?.[SYSTEM_ARCHS_KEYS.ARC_3]?.ltSide;
    const ltTablekey = `${locationAndSize?.storeSize}-${SYSTEM_ARCHS_KEYS.ARC_3}-${TYPE_OF_RACK.LT}-${ltRefrigerant}-${defaultType}-${defaultCompany}-${climate}`;
    setIsDefaultLTLoading(true);
    const ltTableData = await store.dispatch(dataApi.endpoints.getData.initiate({
      tableKey: ltTablekey,
      dataType: SERVICE_REQUEST_FETCH_DATA_TYPES.COMPRESSOR,
      storeSize: locationAndSize?.storeSize,
      architectureId: SYSTEM_ARCHS_KEYS.ARC_3,
      cabinetType: TYPE_OF_RACK.LT,
      type: defaultType,
      company: defaultCompany,
      refrigerant: ltRefrigerant,
      climate: climate
    }));
    if (ltTableData?.data?.isSuccess && ltTableData?.data?.result?.data) {
      setTables({ compressorMaster: { ...tables?.compressorMaster, [ltTableData?.originalArgs?.tableKey || ltTablekey]: ltTableData?.data.result.data } });
      setInputTable(ltTableData?.originalArgs?.tableKey || ltTablekey, ltTableData?.data?.result?.data, TYPE_OF_RACK.LT);
    }
    await setIsDefaultLTLoading(false);
  };
  useEffect(() => {
    if (typeOfRack == TYPE_OF_RACK.MT && !isCompressorDataLoading && !isDefaultLTLoading && (Object.keys(ARC_3?.[TYPE_OF_RACK.MT]?.compressorInputs || [])?.length > 0 && Object.keys(ARC_3?.[TYPE_OF_RACK.LT] || [])?.length < 1 || isTableReloadForRack(TYPE_OF_RACK.LT))) {
      loadDefaultLT();
    }
    // eslint-disable-next-line 
  }, [ARC_3]);

  //reset  load setup values
  const setDefault = useCallback(() => {   
    const defaultType = COMPRESSOR_ARCHS_CONFIG?.[SYSTEM_ARCHS_KEYS.ARC_3]?.[TYPE_OF_RACK.MT]?.DEFAULT_TYPE;
    const defaultCompany = COMPRESSOR_ARCHS_CONFIG?.[SYSTEM_ARCHS_KEYS.ARC_3]?.[TYPE_OF_RACK.MT]?.DEFAULT_COMPANY;
    const defaultLTType = COMPRESSOR_ARCHS_CONFIG?.[SYSTEM_ARCHS_KEYS.ARC_3]?.[TYPE_OF_RACK.LT]?.DEFAULT_TYPE;
    const defaulLTCompany = COMPRESSOR_ARCHS_CONFIG?.[SYSTEM_ARCHS_KEYS.ARC_3]?.[TYPE_OF_RACK.LT]?.DEFAULT_COMPANY;
    const systemData = { [SYSTEM_ARCHS_KEYS.ARC_3]: { ...compressorSetup[SYSTEM_ARCHS_KEYS.ARC_3], [TYPE_OF_RACK.MT]: { ...compressorSetup?.[SYSTEM_ARCHS_KEYS.ARC_3]?.[TYPE_OF_RACK.MT], type: defaultType, company: defaultCompany, compressorInputs: [] }, [TYPE_OF_RACK.LT]: { ...compressorSetup?.[SYSTEM_ARCHS_KEYS.ARC_3]?.[TYPE_OF_RACK.LT], type: defaultLTType, company: defaulLTCompany, compressorInputs: [] } } };
    setCompressorSetup({ ...compressorSetup, ...systemData });
    setTypeOfRack(TYPE_OF_RACK.MT);
    setDefaultResetKey(Date.now());
  }, [compressorSetup,SYSTEM_ARCHS_KEYS,TYPE_OF_RACK,COMPRESSOR_ARCHS_CONFIG]);
  //ltLoadRanges warning
  const isLTLoadWarning = useMemo(() => {
    const isDeviated = CompressorSetUpCalculations.isDeviated(COMPRESSOR_ARCHS_CONFIG.LOAD_DEVIATION, compressorSetup?.[SYSTEM_ARCHS_KEYS.ARC_3]?.[TYPE_OF_RACK.LT]?.selectedCompressorLoad, loadSetup?.selectedLTLoad);
    return isDeviated;
  }, [loadSetup?.selectedLTLoad, COMPRESSOR_ARCHS_CONFIG, TYPE_OF_RACK, compressorSetup, SYSTEM_ARCHS_KEYS]);
  //mtLoadRanges warning
  const isMTLoadRangeWarning = useMemo(() => {
    const isDeviated = CompressorSetUpCalculations.isDeviated(COMPRESSOR_ARCHS_CONFIG.LOAD_DEVIATION, compressorSetup?.[SYSTEM_ARCHS_KEYS.ARC_3]?.[TYPE_OF_RACK.MT]?.selectedCompressorLoad, loadSetup?.selectedMTLoad);
    return isDeviated;
  }, [loadSetup?.selectedMTLoad, SYSTEM_ARCHS_KEYS, compressorSetup, TYPE_OF_RACK, COMPRESSOR_ARCHS_CONFIG]);
  //is continue button disabled
  const isContinueButtonDisabled = useMemo(() => {
    return isMTLoadRangeWarning || isLTLoadWarning || isCompressorDataLoading || isDefaultLTLoading;
  }, [isLTLoadWarning, isMTLoadRangeWarning, isCompressorDataLoading, isDefaultLTLoading]);


  return (
    <div>
      <ProgressStepper step={''} completed={PROGRESSBAR_STEPS_VALUE_COLL.EcoEfficiency[2]} />
      <Headline key={`hl-${SYSTEM_ARCHS_KEYS.ARC_3}_${updatedOn}`} calculationId={SYSTEM_ARCHS_PRE_CALCULATION_REACT_MS_MAPPING?.[SYSTEM_ARCHS_KEYS.ARC_3]} headline={t(LABELKEYS_COMPRESSOR_SETUP.HEADING)} activeStageLocationSetup={activeStageLocationSetup} backButton={PAGE_NAVIGATION.SYSTEM_DETAILS.COMPRESSOR_SETUP.SEMI_DISTRIBUTED_SYSTEM} continueButton={continueNextPageLink} isDisable={isContinueButtonDisabled} />
      <TabStepper step={''} labelArray={PROGRESSBAR_STEPS_VALUE_COLL.EcoEfficiencyTabContainer} completed={PROGRESSBAR_STEPS_VALUE_COLL.EcoEfficiencyTabContainer[2]} childStep={PROGRESSBAR_STEPS_VALUE_COLL.EcoEfficiencyTabChildContainer[2]} calltoAction={t(LABELKEYS_SYSTEM_LOAD.RESET)} currentStep={true} onResetClick={setDefault} />
      <div className="row mt-3">
        <div className="col-sm-12">
          <DistributedSystemPanel refrigerant={refrigerant} key={`sp-${SYSTEM_ARCHS_KEYS.ARC_3}_${updatedOn}`} isLTLoading={(typeOfRack == TYPE_OF_RACK.LT && isCompressorDataLoading) || isDefaultLTLoading} isMTLoading={typeOfRack == TYPE_OF_RACK.MT && isCompressorDataLoading} showLTLoadWarning={isLTLoadWarning} showMTLoadWarning={isMTLoadRangeWarning} typeOfRack={typeOfRack} setRackType={setRackType} architecture={SYSTEM_ARCHS_KEYS.ARC_3} compressorData={tables?.compressorMaster?.[tableKey]?.compressorData} />
          {isCompressorDataLoading ? <Loader /> : <div className="distributed-table">
            <div className="panel-body">
              <div className="table-head">
                {DISTRIBUTED_SYSTEM_TABLE_HEADER?.map((e: any) => {
                  return (
                    (e?.fieldCount === 5) ?
                      <div key={`div${e?.fieldKey}`} className="table-items col-md-5">
                        <div className={e?.fieldCSS}>{t(e?.fieldKey)}{" "}</div>
                      </div>
                      :
                      ((e?.fieldCount === 2) ?
                        <div key={`div${e?.fieldKey}`} className="table-items col-md-2">
                          <div className={e?.fieldCSS}>{t(e?.fieldKey)}{" "}</div>
                        </div>
                        :
                        <div key={`div${e?.fieldKey}`} className="table-items col-md-1">
                          <div className={e?.fieldCSS}>{t(e?.fieldKey)}{" "}</div>
                        </div>
                      ));
                })}
              </div>
              {(ARC_3?.[typeOfRack]?.compressorInputs?.map((cabinetItem, key) => {
                const loadTableItem = loadSetup?.loadInputs?.filter(ld => ld?.cabinetId === cabinetItem?.cabinetId && ld?.cabinetType === typeOfRack)?.[0];
                return (
                  <div key={key}>
                    <div className="table-data col-md-2">{cabinetItem?.cabinetName?.charAt(0)?.toUpperCase() + cabinetItem?.cabinetName?.slice(1)}</div>
                    <div className="table-data col-md-2">{cabinetItem?.capPerUnit || 0}</div>
                    <div className="table-data col-md-1">{cabinetItem?.noOfUnits || 0}</div>
                    {(cabinetItem?.compressorData || [{ isEmpty: true }, { isEmpty: true }, { isEmpty: true }, { isEmpty: true }, { isEmpty: true }])?.map((compItem, index) => {
                      return (<div key={`column-${key}-${index}`} className="table-data col-md-1"><input readOnly={compItem?.isEmpty} onChange={setCountValue(cabinetItem?.cabinetId, index)} value={compItem?.count} type="text" aria-label="Count" /></div>);
                    })}
                    <div className="table-data col-md-1">{cabinetItem?.capacity || 0}</div>
                    <div className="table-data col-md-1">{loadTableItem?.capacity || 0}</div>
                  </div>
                );
              }))
              }
            </div>
          </div>}
        </div>
      </div>
    </div>
  );
};

export default DistributedSystemAirCooled;