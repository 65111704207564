//calculations for load setup 
import React from 'react';
import { CompressorSetUpCalculations } from "../services/compressorSetUpCalculations";
export class PreCalculationInputs extends React.Component {
    //Capacity calculations
    public static getInputJSON = async (calculationId, userInputs, config) => {
        const { SYSTEM_ARCHS_PRE_CALCULATION_REACT_MS_MAPPING, SYSTEM_ARCHS_KEYS, TYPE_OF_RACK, CONFIG_SIMULATION_SETUP_SELECT_FIELD,CASCADE_MTSIDE_VALUES } = config;
        const { loadSetup, locationAndSize, compressorSetup, chooseSystems, parameterSetup, simulationSetup } = userInputs;
        const { ARC_2, ARC_1 } = compressorSetup;
        let calculationJSONOutput = {};
        try {
            switch (calculationId) {
                case SYSTEM_ARCHS_PRE_CALCULATION_REACT_MS_MAPPING.LOAD_SETUP:
                    const refrigerantMap = {};
                    chooseSystems?.selectedSystems?.map((val) => {
                        refrigerantMap[val] = { "LT": chooseSystems?.system?.[val]?.ltSide, "MT": chooseSystems?.system?.[val]?.mtSide, "High": chooseSystems?.system?.[val]?.highStage };
                    });
                    const calculationJSONLoadSetup = { "data": { "MT": {}, "LT": {}, "refrigerantMap": refrigerantMap, "climate": locationAndSize?.climate, "storeSize": locationAndSize?.storeSize, "storeSizeValue": parseFloat(locationAndSize?.storeSizeValue), "selectedLT": parseFloat(loadSetup?.selectedLTLoad), "selectedMT": parseFloat(loadSetup?.selectedMTLoad) }, "calculationId": "load_setup" };
                    const inputTable = loadSetup?.loadInputs;
                    if (inputTable?.length > 0) {
                        inputTable?.map((val) => {
                            if (val.cabinetType == "MT") {
                                calculationJSONLoadSetup.data.MT[val?.cabinetId] = { "length": parseFloat(val?.length), "capacity": parseFloat(val?.capacity), "temperature": parseFloat(val?.temperature) };
                            }
                            if (val.cabinetType == "LT") {
                                calculationJSONLoadSetup.data.LT[val?.cabinetId] = { "length": parseFloat(val?.length), "capacity": parseFloat(val?.capacity), "temperature": parseFloat(val?.temperature) };
                            }
                        });
                    }
                    calculationJSONOutput = calculationJSONLoadSetup;
                    break;
                case SYSTEM_ARCHS_PRE_CALCULATION_REACT_MS_MAPPING.PARAM_SETUP:
                    const calculationJSONParmsSetup = { "data": { "country": locationAndSize?.country, "city": locationAndSize?.city, "day_load": parseFloat(parameterSetup?.percentageLoadInDayAndNight?.dayLoad), "night_load": parseFloat(parameterSetup?.percentageLoadInDayAndNight?.nightLoad),"selectedArchitecture": chooseSystems?.selectedSystems}, "calculationId": "parameter_setup" };

                    await chooseSystems?.selectedSystems?.map((val) => {
                        let archKey = val;
                        if (val === SYSTEM_ARCHS_KEYS.ARC_9) {
                            archKey = SYSTEM_ARCHS_KEYS.ARC_8;
                        }
                        calculationJSONParmsSetup.data[val] = { "env_type": "", "min_cond": "", "MT": {}, "LT": {} };
                        calculationJSONParmsSetup.data[val]["env_type"] = parameterSetup?.minimumCondensingTemperature?.expValue?.[archKey];
                        calculationJSONParmsSetup.data[val]["min_cond"] = parseFloat(parameterSetup?.minimumCondensingTemperature?.minimumCondensingTemperature?.[archKey]);
                        calculationJSONParmsSetup.data[val].MT = { "td_evap": parseFloat(parameterSetup?.saturationTemperatureSuction?.tdEvap?.[archKey]), "td_cond": parseFloat(parameterSetup?.saturationTemperatureSuction?.tdCond?.[archKey]), "delp_suc": parseFloat(parameterSetup?.saturationTemperatureSuction?.delpSuc?.[archKey]), "delp_liq": parseFloat(parameterSetup?.saturationTemperatureSuction?.delpLiq?.[archKey]) };
                        calculationJSONParmsSetup.data[val].LT = { "td_evap": parseFloat(parameterSetup?.saturationTemperatureLiquid?.tdEvap?.[archKey]), "td_cond": parseFloat(parameterSetup?.saturationTemperatureLiquid?.tdCond?.[archKey]), "delp_suc": parseFloat(parameterSetup?.saturationTemperatureLiquid?.delpSuc?.[archKey]), "delp_liq": parseFloat(parameterSetup?.saturationTemperatureLiquid?.delpLiq?.[archKey]) };

                    });
                    calculationJSONOutput = calculationJSONParmsSetup;
                    break;
                case SYSTEM_ARCHS_PRE_CALCULATION_REACT_MS_MAPPING.SIMULATION_SETUP:
                    const leakageRates = {};
                    const refrigerantPrices = {};
                    const fieldConfig = await CONFIG_SIMULATION_SETUP_SELECT_FIELD?.filter(config => config?.fieldKey == "electricityIntencity")[0];
                    const electricityIntencityLabel = await fieldConfig?.fieldvalueoptions?.filter(f => f.value == simulationSetup?.electricityIntencity?.value)[0]?.label || "";
                    const refrigentsSelected: any = [];
                    await chooseSystems?.selectedSystems?.map((val) => {
                        let archKey = val;
                        if (val === SYSTEM_ARCHS_KEYS.ARC_9) {
                            archKey = SYSTEM_ARCHS_KEYS.ARC_8;
                        }
                        leakageRates[val] = parseFloat(simulationSetup?.leakageRate[archKey] || '0');
                        if (chooseSystems?.system?.[val]) {
                            ["ltSide", "mtSide", "highStage"].map((key) => {
                                const refrigenValue: any = chooseSystems?.system?.[val]?.[key] || undefined;
                                if (!refrigentsSelected.includes(refrigenValue) && !CASCADE_MTSIDE_VALUES?.includes(refrigenValue)) {
                                    refrigentsSelected.push(refrigenValue);
                                }
                            });
                        }
                    });
                    await refrigentsSelected?.map((val) => {
                        if (simulationSetup?.refrigerantPrice?.[val]?.toString()?.length > 0) {
                            refrigerantPrices[val] = parseFloat(simulationSetup?.refrigerantPrice?.[val]);
                        }
                    });
                    const calculationJSONSimulationSetup = { "data": { "country": locationAndSize?.country, "city": locationAndSize?.city, "storeSize": locationAndSize?.storeSize, "selectedArchitecture": chooseSystems?.selectedSystems, "electricityPrice": parseFloat(simulationSetup?.electricityPrice?.value), "lifeSpan": parseFloat(simulationSetup?.lifeSpanOfSystem?.value), "electricityIntencity": { "category": electricityIntencityLabel, "value": parseFloat(simulationSetup?.electricityIntencity?.value) }, "refrigerantPrice": refrigerantPrices, "leakageRate": leakageRates }, "calculationId": SYSTEM_ARCHS_PRE_CALCULATION_REACT_MS_MAPPING.SIMULATION_SETUP };

                    calculationJSONOutput = calculationJSONSimulationSetup;
                    break;
                case SYSTEM_ARCHS_PRE_CALCULATION_REACT_MS_MAPPING.ARC_1:
                    let targetLoadLT: any = 0;
                    let targetLoadMT: any = 0;
                    const slectedMTLoadArc1 = loadSetup?.selectedMTLoad || 0;
                    const slectedLTLoadArc1 = loadSetup?.selectedLTLoad || 0;
                    targetLoadLT = CompressorSetUpCalculations?.getRoundValue(slectedLTLoadArc1 - (slectedLTLoadArc1 * (parseInt(ARC_1?.subCooler) / 100)), 1);
                    targetLoadMT = CompressorSetUpCalculations?.getRoundValue(slectedMTLoadArc1 + (slectedLTLoadArc1 - targetLoadLT), 1);
                    const calculationJSONArc1 = { "data": { "MT": { "type": (compressorSetup?.[SYSTEM_ARCHS_KEYS.ARC_1]?.[TYPE_OF_RACK.MT]?.type) ? compressorSetup?.[SYSTEM_ARCHS_KEYS.ARC_1]?.[TYPE_OF_RACK.MT]?.type : "", "company": (compressorSetup?.[SYSTEM_ARCHS_KEYS.ARC_1]?.[TYPE_OF_RACK.MT]?.company) ? compressorSetup?.[SYSTEM_ARCHS_KEYS.ARC_1]?.[TYPE_OF_RACK.MT]?.company : "", "compressors": {} }, "LT": { "type": (compressorSetup?.[SYSTEM_ARCHS_KEYS.ARC_1]?.[TYPE_OF_RACK.LT]?.type) ? compressorSetup?.[SYSTEM_ARCHS_KEYS.ARC_1]?.[TYPE_OF_RACK.LT]?.type : "", "company": (compressorSetup?.[SYSTEM_ARCHS_KEYS.ARC_1]?.[TYPE_OF_RACK.LT]?.company) ? compressorSetup?.[SYSTEM_ARCHS_KEYS.ARC_1]?.[TYPE_OF_RACK.LT]?.company : "", "compressors": {} }, "refrigerant": chooseSystems?.system?.[SYSTEM_ARCHS_KEYS.ARC_1]?.mtSide, "climate": locationAndSize?.climate, "storeSize": locationAndSize?.storeSize, "targetLT": parseFloat(targetLoadLT), "selectedLT": parseFloat(compressorSetup?.[SYSTEM_ARCHS_KEYS.ARC_1]?.[TYPE_OF_RACK.LT]?.selectedCompressorLoad), "targetMT": parseFloat(targetLoadMT), "selectedMT": parseFloat(compressorSetup?.[SYSTEM_ARCHS_KEYS.ARC_1]?.[TYPE_OF_RACK.MT]?.selectedCompressorLoad), "loadShift": CompressorSetUpCalculations?.getLoadShiftValue(compressorSetup?.[SYSTEM_ARCHS_KEYS.ARC_1]?.subCooler), "architectureId": SYSTEM_ARCHS_KEYS.ARC_1 }, "calculationId": SYSTEM_ARCHS_PRE_CALCULATION_REACT_MS_MAPPING?.[SYSTEM_ARCHS_KEYS.ARC_1] };
                    const mtCompressorTable1 = compressorSetup?.[SYSTEM_ARCHS_KEYS.ARC_1]?.[TYPE_OF_RACK.MT]?.compressorInputs;
                    const ltCompressorTable1 = compressorSetup?.[SYSTEM_ARCHS_KEYS.ARC_1]?.[TYPE_OF_RACK.LT]?.compressorInputs;
                    if (mtCompressorTable1?.length > 0) {
                        mtCompressorTable1?.map((val) => {
                            calculationJSONArc1.data.MT.compressors[val?.modelId] = { "count": parseFloat(val?.count), "capacity": parseFloat(val?.capacity) };
                        });
                    }
                    if (ltCompressorTable1?.length > 0) {
                        ltCompressorTable1?.map((val) => {
                            calculationJSONArc1.data.LT.compressors[val?.modelId] = { "count": parseFloat(val?.count), "capacity": parseFloat(val?.capacity) };
                        });
                    }
                    calculationJSONOutput = calculationJSONArc1;
                    break;
                case SYSTEM_ARCHS_PRE_CALCULATION_REACT_MS_MAPPING.ARC_2:
                    const calculationJSONArc2 = { "data": { "MT": {}, "LT": {}, "refrigerant": chooseSystems?.system?.[SYSTEM_ARCHS_KEYS.ARC_2]?.mtSide, "climate": locationAndSize?.climate, "storeSize": locationAndSize?.storeSize, "totalTargetLT": 0, "selectedLT": parseFloat(compressorSetup?.[SYSTEM_ARCHS_KEYS.ARC_2]?.[TYPE_OF_RACK.LT]?.selectedCompressorLoad), "totalTargetMT": 0, "selectedMT": parseFloat(compressorSetup?.[SYSTEM_ARCHS_KEYS.ARC_2]?.[TYPE_OF_RACK.MT]?.selectedCompressorLoad),"configuration":compressorSetup?.[SYSTEM_ARCHS_KEYS.ARC_2]?.configuration, "loadShift": CompressorSetUpCalculations?.getLoadShiftValue(compressorSetup?.[SYSTEM_ARCHS_KEYS.ARC_2]?.subCooler), "architectureId": SYSTEM_ARCHS_KEYS.ARC_2 }, "calculationId": SYSTEM_ARCHS_PRE_CALCULATION_REACT_MS_MAPPING?.[SYSTEM_ARCHS_KEYS.ARC_2] };
                    const mtCompressorTable2 = compressorSetup?.[SYSTEM_ARCHS_KEYS.ARC_2]?.[TYPE_OF_RACK.MT]?.compressorInputs;
                    const ltCompressorTable2 = compressorSetup?.[SYSTEM_ARCHS_KEYS.ARC_2]?.[TYPE_OF_RACK.LT]?.compressorInputs;
                    const targetLoadLTColl: any = [];
                    const targetLoadMTColl: any = [];
                    const loadInputTable = loadSetup?.loadInputs;
                    const slectedMTLoad = loadSetup?.selectedMTLoad || 0;
                    const slectedLTLoad = loadSetup?.selectedLTLoad || 0;

                    const mtCompressorTableRacks: any = [], ltCompressorTableRacks: any = [];
                    await ltCompressorTable2?.map(comp => comp.rackType)?.map((rackName) => {
                        if (!ltCompressorTableRacks.includes(rackName)) {
                            ltCompressorTableRacks.push(rackName);
                            const rackLTLoad = CompressorSetUpCalculations.getArc2LTLoadWithSubCooler(rackName, loadInputTable, ARC_2, config);
                            targetLoadLTColl.push(rackLTLoad);
                            calculationJSONArc2.data.LT[rackName] = { "compressors": {}, "type": (compressorSetup?.[SYSTEM_ARCHS_KEYS.ARC_2]?.[TYPE_OF_RACK.LT]?.type) ? compressorSetup?.[SYSTEM_ARCHS_KEYS.ARC_2]?.[TYPE_OF_RACK.LT]?.type : "", "company": (compressorSetup?.[SYSTEM_ARCHS_KEYS.ARC_2]?.[TYPE_OF_RACK.LT]?.company) ? compressorSetup?.[SYSTEM_ARCHS_KEYS.ARC_2]?.[TYPE_OF_RACK.LT]?.company : "" };
                            calculationJSONArc2.data.LT[rackName].compressors.targetLT = rackLTLoad;
                        }
                    });
                    const totalLTLoad = CompressorSetUpCalculations?.getTotal(targetLoadLTColl);
                    calculationJSONArc2.data.totalTargetLT = totalLTLoad;
                    await mtCompressorTable2?.map(comp => comp.rackType)?.map((rackName) => {

                        if (!mtCompressorTableRacks.includes(rackName)) {
                            mtCompressorTableRacks.push(rackName);
                            const currentRackLoad = CompressorSetUpCalculations.getArc2MTRackLoad(rackName, loadInputTable, config);
                            const targetRackMTLoad = ((slectedMTLoad + (slectedLTLoad - totalLTLoad)) * (currentRackLoad / slectedMTLoad));
                            targetLoadMTColl.push(targetRackMTLoad);
                            calculationJSONArc2.data.MT[rackName] = { "compressors": {}, "type": (compressorSetup?.[SYSTEM_ARCHS_KEYS.ARC_2]?.[TYPE_OF_RACK.MT]?.type) ? compressorSetup?.[SYSTEM_ARCHS_KEYS.ARC_2]?.[TYPE_OF_RACK.MT]?.type : "", "company": (compressorSetup?.[SYSTEM_ARCHS_KEYS.ARC_2]?.[TYPE_OF_RACK.MT]?.company) ? compressorSetup?.[SYSTEM_ARCHS_KEYS.ARC_2]?.[TYPE_OF_RACK.MT]?.company : "" };
                            calculationJSONArc2.data.MT[rackName].compressors.targetMT = targetRackMTLoad;
                        }
                    });
                    const totalMTLoad = CompressorSetUpCalculations?.getTotal(targetLoadMTColl);
                    calculationJSONArc2.data.totalTargetMT = totalMTLoad;
                    if (mtCompressorTableRacks?.length > 0) {
                        await mtCompressorTableRacks?.map((rackName) => {
                            const rackItems = mtCompressorTable2?.filter(comp => comp.rackType == rackName);
                            rackItems?.map((val) => {
                                calculationJSONArc2.data.MT[rackName].compressors[val?.modelId] = { "count": parseFloat(val?.count), "capacity": parseFloat(val?.capacity) };
                            });
                            const rackMTCapacity = CompressorSetUpCalculations?.getTotal(rackItems?.map(input => input?.capacity || 0)) || 0;
                            calculationJSONArc2.data.MT[rackName].compressors.selectedMT = rackMTCapacity;
                        });
                    }
                    if (ltCompressorTableRacks?.length > 0) {
                        await ltCompressorTableRacks?.map((rackName) => {
                            const rackItems = ltCompressorTable2?.filter(comp => comp.rackType == rackName);
                            rackItems?.map((val) => {
                                calculationJSONArc2.data.LT[rackName].compressors[val?.modelId] = { "count": parseFloat(val?.count), "capacity": parseFloat(val?.capacity) };
                            });
                            const rackLTCapacity = CompressorSetUpCalculations?.getTotal(rackItems?.map(input => input?.capacity || 0)) || 0;
                            calculationJSONArc2.data.LT[rackName].compressors.selectedLT = rackLTCapacity;
                        });
                    }
                    calculationJSONOutput = calculationJSONArc2;
                    break;
                case SYSTEM_ARCHS_PRE_CALCULATION_REACT_MS_MAPPING.ARC_3:
                    const calculationJSONArc3 = { "data": { "MT": { "type": (compressorSetup?.[SYSTEM_ARCHS_KEYS.ARC_3]?.[TYPE_OF_RACK.MT]?.type) ? compressorSetup?.[SYSTEM_ARCHS_KEYS.ARC_3]?.[TYPE_OF_RACK.MT]?.type : "", "company": (compressorSetup?.[SYSTEM_ARCHS_KEYS.ARC_3]?.[TYPE_OF_RACK.MT]?.company) ? compressorSetup?.[SYSTEM_ARCHS_KEYS.ARC_3]?.[TYPE_OF_RACK.MT]?.company : "" }, "LT": { "type": (compressorSetup?.[SYSTEM_ARCHS_KEYS.ARC_3]?.[TYPE_OF_RACK.LT]?.type) ? compressorSetup?.[SYSTEM_ARCHS_KEYS.ARC_3]?.[TYPE_OF_RACK.LT]?.type : "", "company": (compressorSetup?.[SYSTEM_ARCHS_KEYS.ARC_3]?.[TYPE_OF_RACK.LT]?.company) ? compressorSetup?.[SYSTEM_ARCHS_KEYS.ARC_3]?.[TYPE_OF_RACK.LT]?.company : "" }, "refrigerant": chooseSystems?.system?.[SYSTEM_ARCHS_KEYS.ARC_3]?.mtSide, "climate": locationAndSize?.climate, "storeSize": locationAndSize?.storeSize, "targetLT": parseFloat(loadSetup?.selectedLTLoad), "selectedLT": parseFloat(compressorSetup?.[SYSTEM_ARCHS_KEYS.ARC_3]?.[TYPE_OF_RACK.LT]?.selectedCompressorLoad), "targetMT": parseFloat(loadSetup?.selectedMTLoad), "selectedMT": parseFloat(compressorSetup?.[SYSTEM_ARCHS_KEYS.ARC_3]?.[TYPE_OF_RACK.MT]?.selectedCompressorLoad), "architectureId": SYSTEM_ARCHS_KEYS.ARC_3 }, "calculationId": SYSTEM_ARCHS_PRE_CALCULATION_REACT_MS_MAPPING?.[SYSTEM_ARCHS_KEYS.ARC_3] };
                    const mtCompressorTable3 = compressorSetup?.[SYSTEM_ARCHS_KEYS.ARC_3]?.[TYPE_OF_RACK.MT]?.compressorInputs;
                    const ltCompressorTable3 = compressorSetup?.[SYSTEM_ARCHS_KEYS.ARC_3]?.[TYPE_OF_RACK.LT]?.compressorInputs;

                    if (mtCompressorTable3?.length > 0) {
                        mtCompressorTable3?.map((val) => {
                            const mtCompressor = {};
                            val?.compressorData?.map((cd) => {
                                mtCompressor[cd?.modelId] = { "count": parseFloat(cd?.count) };
                            });
                            calculationJSONArc3.data.MT[val?.cabinetId] = { "cap_unit": parseFloat(val?.capPerUnit), "no_of_units": parseFloat(val?.noOfUnits), "selected_capacity": parseFloat(val?.capacity || "0"), "required_capacity": parseFloat(loadSetup?.loadInputs?.filter(ld => ld?.cabinetId === val?.cabinetId && ld?.cabinetType === TYPE_OF_RACK.MT)?.[0]?.capacity || 0), "compressors": mtCompressor };
                        });
                    }
                    if (ltCompressorTable3?.length > 0) {
                        ltCompressorTable3?.map((val) => {
                            const ltCompressor = {};
                            val?.compressorData?.map((cd) => {
                                ltCompressor[cd?.modelId] = { "count": parseFloat(cd?.count) };
                            });
                            calculationJSONArc3.data.LT[val?.cabinetId] = { "cap_unit": parseFloat(val?.capPerUnit), "no_of_units": parseFloat(val?.noOfUnits), "selected_capacity": parseFloat(val?.capacity || "0"), "required_capacity": parseFloat(loadSetup?.loadInputs?.filter(ld => ld?.cabinetId === val?.cabinetId && ld?.cabinetType === TYPE_OF_RACK.LT)?.[0]?.capacity || "0"), "compressors": ltCompressor };
                        });
                    }
                    calculationJSONOutput = calculationJSONArc3;
                    break;
                case SYSTEM_ARCHS_PRE_CALCULATION_REACT_MS_MAPPING.ARC_4:
                    const calculationJSONArc4 = { "data": { "MT": { "type": (compressorSetup?.[SYSTEM_ARCHS_KEYS.ARC_4]?.[TYPE_OF_RACK.MT]?.type) ? compressorSetup?.[SYSTEM_ARCHS_KEYS.ARC_4]?.[TYPE_OF_RACK.MT]?.type : "", "company": (compressorSetup?.[SYSTEM_ARCHS_KEYS.ARC_4]?.[TYPE_OF_RACK.MT]?.company) ? compressorSetup?.[SYSTEM_ARCHS_KEYS.ARC_4]?.[TYPE_OF_RACK.MT]?.company : "" }, "LT": { "type": (compressorSetup?.[SYSTEM_ARCHS_KEYS.ARC_4]?.[TYPE_OF_RACK.LT]?.type) ? compressorSetup?.[SYSTEM_ARCHS_KEYS.ARC_4]?.[TYPE_OF_RACK.LT]?.type : "", "company": (compressorSetup?.[SYSTEM_ARCHS_KEYS.ARC_4]?.[TYPE_OF_RACK.LT]?.company) ? compressorSetup?.[SYSTEM_ARCHS_KEYS.ARC_4]?.[TYPE_OF_RACK.LT]?.company : "" }, "refrigerant": chooseSystems?.system?.[SYSTEM_ARCHS_KEYS.ARC_4]?.mtSide, "climate": locationAndSize?.climate, "storeSize": locationAndSize?.storeSize, "targetLT": parseFloat(loadSetup?.selectedLTLoad), "selectedLT": parseFloat(compressorSetup?.[SYSTEM_ARCHS_KEYS.ARC_4]?.[TYPE_OF_RACK.LT]?.selectedCompressorLoad), "targetMT": parseFloat(loadSetup?.selectedMTLoad), "selectedMT": parseFloat(compressorSetup?.[SYSTEM_ARCHS_KEYS.ARC_4]?.[TYPE_OF_RACK.MT]?.selectedCompressorLoad), "architectureId": SYSTEM_ARCHS_KEYS.ARC_4 }, "calculationId": SYSTEM_ARCHS_PRE_CALCULATION_REACT_MS_MAPPING?.[SYSTEM_ARCHS_KEYS.ARC_4] };
                    const mtCompressorTable4 = compressorSetup?.[SYSTEM_ARCHS_KEYS.ARC_4]?.[TYPE_OF_RACK.MT]?.compressorInputs;
                    const ltCompressorTable4 = compressorSetup?.[SYSTEM_ARCHS_KEYS.ARC_4]?.[TYPE_OF_RACK.LT]?.compressorInputs;
                    if (mtCompressorTable4?.length > 0) {
                        mtCompressorTable4?.map((val) => {
                            const mtCompressor = {};
                            val?.compressorData?.map((cd) => {
                                mtCompressor[cd?.modelId] = { "count": parseFloat(cd?.count) };
                            });
                            calculationJSONArc4.data.MT[val?.cabinetId] = { "cap_unit": parseFloat(val?.capPerUnit), "no_of_units": parseFloat(val?.noOfUnits), "selected_capacity": parseFloat(val?.capacity || 0), "required_capacity": parseFloat(loadSetup?.loadInputs?.filter(ld => ld?.cabinetId === val?.cabinetId && ld?.cabinetType === TYPE_OF_RACK.MT)?.[0]?.capacity || 0), "compressors": mtCompressor };
                        });
                    }
                    if (ltCompressorTable4?.length > 0) {
                        ltCompressorTable4?.map((val) => {
                            const ltCompressor = {};
                            val?.compressorData?.map((cd) => {
                                ltCompressor[cd?.modelId] = { "count": parseFloat(cd?.count) };
                            });
                            calculationJSONArc4.data.LT[val?.cabinetId] = { "cap_unit": parseFloat(val?.capPerUnit), "no_of_units": parseFloat(val?.noOfUnits), "selected_capacity": parseFloat(val?.capacity || 0), "required_capacity": parseFloat(loadSetup?.loadInputs?.filter(ld => ld?.cabinetId === val?.cabinetId && ld?.cabinetType === TYPE_OF_RACK.LT)?.[0]?.capacity || 0), "compressors": ltCompressor };
                        });
                    }
                    calculationJSONOutput = calculationJSONArc4;

                    break;
                case SYSTEM_ARCHS_PRE_CALCULATION_REACT_MS_MAPPING.ARC_5:
                    const calculationJSONArc5 = { "data": { "MT": { "type": (compressorSetup?.[SYSTEM_ARCHS_KEYS.ARC_5]?.[TYPE_OF_RACK.MT]?.type) ? compressorSetup?.[SYSTEM_ARCHS_KEYS.ARC_5]?.[TYPE_OF_RACK.MT]?.type : "", "company": (compressorSetup?.[SYSTEM_ARCHS_KEYS.ARC_5]?.[TYPE_OF_RACK.MT]?.company) ? compressorSetup?.[SYSTEM_ARCHS_KEYS.ARC_5]?.[TYPE_OF_RACK.MT]?.company : "", "compressors": {}, "refrigerant": chooseSystems?.system?.[SYSTEM_ARCHS_KEYS.ARC_5]?.highStage }, "LT": { "type": (compressorSetup?.[SYSTEM_ARCHS_KEYS.ARC_5]?.[TYPE_OF_RACK.LT]?.type) ? compressorSetup?.[SYSTEM_ARCHS_KEYS.ARC_5]?.[TYPE_OF_RACK.LT]?.type : "", "company": (compressorSetup?.[SYSTEM_ARCHS_KEYS.ARC_5]?.[TYPE_OF_RACK.LT]?.company) ? compressorSetup?.[SYSTEM_ARCHS_KEYS.ARC_5]?.[TYPE_OF_RACK.LT]?.company : "", "compressors": {}, "refrigerant": chooseSystems?.system?.[SYSTEM_ARCHS_KEYS.ARC_5]?.ltSide }, "climate": locationAndSize?.climate, "storeSize": locationAndSize?.storeSize, "targetLT": parseFloat(loadSetup?.selectedLTLoad), "selectedLT": parseFloat(compressorSetup?.[SYSTEM_ARCHS_KEYS.ARC_5]?.[TYPE_OF_RACK.LT]?.selectedCompressorLoad), "targetMT": CompressorSetUpCalculations?.getType2TargetMTLoad(loadSetup?.selectedMTLoad, loadSetup?.selectedLTLoad, 2), "selectedMT": parseFloat(compressorSetup?.[SYSTEM_ARCHS_KEYS.ARC_5]?.[TYPE_OF_RACK.MT]?.selectedCompressorLoad), "architectureId": SYSTEM_ARCHS_KEYS.ARC_5 }, "calculationId": SYSTEM_ARCHS_PRE_CALCULATION_REACT_MS_MAPPING?.[SYSTEM_ARCHS_KEYS.ARC_5] };
                    const mtCompressorTable5 = compressorSetup?.[SYSTEM_ARCHS_KEYS.ARC_5]?.[TYPE_OF_RACK.MT]?.compressorInputs;
                    const ltCompressorTable5 = compressorSetup?.[SYSTEM_ARCHS_KEYS.ARC_5]?.[TYPE_OF_RACK.LT]?.compressorInputs;
                    if (mtCompressorTable5?.length > 0) {
                        mtCompressorTable5?.map((val) => {
                            calculationJSONArc5.data.MT.compressors[val?.modelId] = { "count": parseFloat(val?.count), "capacity": parseFloat(val?.capacity) };
                        });
                    }
                    if (ltCompressorTable5?.length > 0) {
                        ltCompressorTable5?.map((val) => {
                            calculationJSONArc5.data.LT.compressors[val?.modelId] = { "count": parseFloat(val?.count), "capacity": parseFloat(val?.capacity) };
                        });
                    }
                    calculationJSONOutput = calculationJSONArc5;
                    break;
                case SYSTEM_ARCHS_PRE_CALCULATION_REACT_MS_MAPPING.ARC_6:
                    const calculationJSONArc6 = { "data": { "MT": { "type": (compressorSetup?.[SYSTEM_ARCHS_KEYS.ARC_6]?.[TYPE_OF_RACK.MT]?.type) ? compressorSetup?.[SYSTEM_ARCHS_KEYS.ARC_6]?.[TYPE_OF_RACK.MT]?.type : "", "company": (compressorSetup?.[SYSTEM_ARCHS_KEYS.ARC_6]?.[TYPE_OF_RACK.MT]?.company) ? compressorSetup?.[SYSTEM_ARCHS_KEYS.ARC_6]?.[TYPE_OF_RACK.MT]?.company : "", "compressors": {}, "refrigerant": chooseSystems?.system?.[SYSTEM_ARCHS_KEYS.ARC_6]?.mtSide, }, "LT": { "type": (compressorSetup?.[SYSTEM_ARCHS_KEYS.ARC_6]?.[TYPE_OF_RACK.LT]?.type) ? compressorSetup?.[SYSTEM_ARCHS_KEYS.ARC_6]?.[TYPE_OF_RACK.LT]?.type : "", "company": (compressorSetup?.[SYSTEM_ARCHS_KEYS.ARC_6]?.[TYPE_OF_RACK.LT]?.company) ? compressorSetup?.[SYSTEM_ARCHS_KEYS.ARC_6]?.[TYPE_OF_RACK.LT]?.company : "", "refrigerant": chooseSystems?.system?.[SYSTEM_ARCHS_KEYS.ARC_6]?.ltSide }, "climate": locationAndSize?.climate, "storeSize": locationAndSize?.storeSize, "targetLT": parseFloat(loadSetup?.selectedLTLoad), "selectedLT": parseFloat(compressorSetup?.[SYSTEM_ARCHS_KEYS.ARC_6]?.[TYPE_OF_RACK.LT]?.selectedCompressorLoad), "targetMT": CompressorSetUpCalculations?.getType2TargetMTLoad(loadSetup?.selectedMTLoad, loadSetup?.selectedLTLoad, 3.5), "selectedMT": parseFloat(compressorSetup?.[SYSTEM_ARCHS_KEYS.ARC_6]?.[TYPE_OF_RACK.MT]?.selectedCompressorLoad), "architectureId": SYSTEM_ARCHS_KEYS.ARC_6 }, "calculationId": SYSTEM_ARCHS_PRE_CALCULATION_REACT_MS_MAPPING?.[SYSTEM_ARCHS_KEYS.ARC_6] };
                    const mtCompressorTable6 = compressorSetup?.[SYSTEM_ARCHS_KEYS.ARC_6]?.[TYPE_OF_RACK.MT]?.compressorInputs;
                    const ltCompressorTable6 = compressorSetup?.[SYSTEM_ARCHS_KEYS.ARC_6]?.[TYPE_OF_RACK.LT]?.compressorInputs;
                    if (mtCompressorTable6?.length > 0) {
                        mtCompressorTable6?.map((val) => {
                            calculationJSONArc6.data.MT.compressors[val?.modelId] = { "count": parseFloat(val?.count), "capacity": parseFloat(val?.capacity) };
                        });
                    }
                    if (ltCompressorTable6?.length > 0) {
                        ltCompressorTable6?.map((val) => {
                            const ltCompressor = {};
                            val?.compressorData?.map((cd) => {
                                ltCompressor[cd?.modelId] = { "count": parseFloat(cd?.count) };
                            });
                            calculationJSONArc6.data.LT[val?.cabinetId] = { "cap_unit": parseFloat(val?.capPerUnit), "no_of_units": parseFloat(val?.noOfUnits), "selected_capacity": parseFloat(val?.capacity || 0), "required_capacity": parseFloat(loadSetup?.loadInputs?.filter(ld => ld?.cabinetId === val?.cabinetId && ld?.cabinetType === TYPE_OF_RACK.LT)?.[0]?.capacity || 0), "compressors": ltCompressor };
                        });
                    }
                    calculationJSONOutput = calculationJSONArc6;
                    break;
                case SYSTEM_ARCHS_PRE_CALCULATION_REACT_MS_MAPPING.ARC_8:
                    const calculationJSONArc8 = { "data": { "MT": { "type": (compressorSetup?.[SYSTEM_ARCHS_KEYS.ARC_8]?.[TYPE_OF_RACK.MT]?.type) ? compressorSetup?.[SYSTEM_ARCHS_KEYS.ARC_8]?.[TYPE_OF_RACK.MT]?.type : "", "company": (compressorSetup?.[SYSTEM_ARCHS_KEYS.ARC_8]?.[TYPE_OF_RACK.MT]?.company) ? compressorSetup?.[SYSTEM_ARCHS_KEYS.ARC_8]?.[TYPE_OF_RACK.MT]?.company : "", "compressors": {} }, "LT": { "type": (compressorSetup?.[SYSTEM_ARCHS_KEYS.ARC_8]?.[TYPE_OF_RACK.LT]?.type) ? compressorSetup?.[SYSTEM_ARCHS_KEYS.ARC_8]?.[TYPE_OF_RACK.LT]?.type : "", "company": (compressorSetup?.[SYSTEM_ARCHS_KEYS.ARC_8]?.[TYPE_OF_RACK.LT]?.company) ? compressorSetup?.[SYSTEM_ARCHS_KEYS.ARC_8]?.[TYPE_OF_RACK.LT]?.company : "", "compressors": {} }, "refrigerant": chooseSystems?.system?.[SYSTEM_ARCHS_KEYS.ARC_8]?.mtSide, "climate": locationAndSize?.climate, "storeSize": locationAndSize?.storeSize, "targetLT": parseFloat(loadSetup?.selectedLTLoad), "selectedLT": parseFloat(compressorSetup?.[SYSTEM_ARCHS_KEYS.ARC_8]?.[TYPE_OF_RACK.LT]?.selectedCompressorLoad), "targetMT": CompressorSetUpCalculations?.getType2TargetMTLoad(loadSetup?.selectedMTLoad, loadSetup?.selectedLTLoad, 0), "selectedMT": parseFloat(compressorSetup?.[SYSTEM_ARCHS_KEYS.ARC_8]?.[TYPE_OF_RACK.MT]?.selectedCompressorLoad), "architectureId": SYSTEM_ARCHS_KEYS.ARC_8 }, "calculationId": SYSTEM_ARCHS_PRE_CALCULATION_REACT_MS_MAPPING?.[SYSTEM_ARCHS_KEYS.ARC_8] };
                    const mtCompressorTable8 = compressorSetup?.[SYSTEM_ARCHS_KEYS.ARC_8]?.[TYPE_OF_RACK.MT]?.compressorInputs;
                    const ltCompressorTable8 = compressorSetup?.[SYSTEM_ARCHS_KEYS.ARC_8]?.[TYPE_OF_RACK.LT]?.compressorInputs;
                    if (mtCompressorTable8?.length > 0) {
                        mtCompressorTable8?.map((val) => {
                            calculationJSONArc8.data.MT.compressors[val?.modelId] = { "count": parseFloat(val?.count), "capacity": parseFloat(val?.capacity) };
                        });
                    }
                    if (ltCompressorTable8?.length > 0) {
                        ltCompressorTable8?.map((val) => {
                            calculationJSONArc8.data.LT.compressors[val?.modelId] = { "count": parseFloat(val?.count), "capacity": parseFloat(val?.capacity) };
                        });
                    }
                    calculationJSONOutput = calculationJSONArc8;
                    break;
                case SYSTEM_ARCHS_PRE_CALCULATION_REACT_MS_MAPPING.ARC_9:
                    const calculationJSONArc9 = { "data": { "MT": { "type": (compressorSetup?.[SYSTEM_ARCHS_KEYS.ARC_8]?.[TYPE_OF_RACK.MT]?.type) ? compressorSetup?.[SYSTEM_ARCHS_KEYS.ARC_8]?.[TYPE_OF_RACK.MT]?.type : "", "company": (compressorSetup?.[SYSTEM_ARCHS_KEYS.ARC_8]?.[TYPE_OF_RACK.MT]?.company) ? compressorSetup?.[SYSTEM_ARCHS_KEYS.ARC_8]?.[TYPE_OF_RACK.MT]?.company : "", "compressors": {} }, "LT": { "type": (compressorSetup?.[SYSTEM_ARCHS_KEYS.ARC_8]?.[TYPE_OF_RACK.LT]?.type) ? compressorSetup?.[SYSTEM_ARCHS_KEYS.ARC_8]?.[TYPE_OF_RACK.LT]?.type : "", "company": (compressorSetup?.[SYSTEM_ARCHS_KEYS.ARC_8]?.[TYPE_OF_RACK.LT]?.company) ? compressorSetup?.[SYSTEM_ARCHS_KEYS.ARC_8]?.[TYPE_OF_RACK.LT]?.company : "", "compressors": {} }, "refrigerant": chooseSystems?.system?.[SYSTEM_ARCHS_KEYS.ARC_8]?.mtSide, "climate": locationAndSize?.climate, "storeSize": locationAndSize?.storeSize, "targetLT": parseFloat(loadSetup?.selectedLTLoad), "selectedLT": parseFloat(compressorSetup?.[SYSTEM_ARCHS_KEYS.ARC_8]?.[TYPE_OF_RACK.LT]?.selectedCompressorLoad), "targetMT": CompressorSetUpCalculations?.getType2TargetMTLoad(loadSetup?.selectedMTLoad, loadSetup?.selectedLTLoad, 0), "selectedMT": parseFloat(compressorSetup?.[SYSTEM_ARCHS_KEYS.ARC_8]?.[TYPE_OF_RACK.MT]?.selectedCompressorLoad), "architectureId": SYSTEM_ARCHS_KEYS.ARC_8 }, "calculationId": SYSTEM_ARCHS_PRE_CALCULATION_REACT_MS_MAPPING?.[SYSTEM_ARCHS_KEYS.ARC_9] };
                    const mtCompressorTable9 = compressorSetup?.[SYSTEM_ARCHS_KEYS.ARC_8]?.[TYPE_OF_RACK.MT]?.compressorInputs;
                    const ltCompressorTable9 = compressorSetup?.[SYSTEM_ARCHS_KEYS.ARC_8]?.[TYPE_OF_RACK.LT]?.compressorInputs;
                    if (mtCompressorTable9?.length > 0) {
                        mtCompressorTable9?.map((val) => {
                            calculationJSONArc9.data.MT.compressors[val?.modelId] = { "count": parseFloat(val?.count), "capacity": parseFloat(val?.capacity) };
                        });
                    }
                    if (ltCompressorTable9?.length > 0) {
                        ltCompressorTable9?.map((val) => {
                            calculationJSONArc9.data.LT.compressors[val?.modelId] = { "count": parseFloat(val?.count), "capacity": parseFloat(val?.capacity) };
                        });
                    }
                    calculationJSONOutput = calculationJSONArc9;
                    break;
                default:
                    return undefined;
                    break;
            }

        } catch (err) {
            return undefined;
        }
        return await calculationJSONOutput;
    };



}