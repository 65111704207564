/**********************************************************************************************
ProgressStepper.tsx - Progrss bar componenet to show the progress
***********************************************************************************************/
import React from "react";
import TabStepNavigation from "./TabParentContainer/TabStepNavigation";
import TabStepChildNavigation from "./TabChildContainer/TabStepChildNavigation";
import "./TabContainer.scss";
import { useSelector } from "react-redux";
const TabStepper: React.FC<any> = (props) => {
  const { userInputs,tables } = useSelector((state: any) => state?.ecoEfficiency?.calculator || {});
  const { chooseSystems } = userInputs; 
  const { SYSTEM_ARCHS_REACT_MS_MAPPING, EXCLUDE_ARC_TYPE, SYSTEM_ARCHS_KEYS } = useSelector((state: any) => state?.ecoEfficiency?.config || {});
  const activeStage = props?.completed || "";
  const activeIndex = props?.labelArray?.indexOf(activeStage) || 0;
  const activeChildStage = props?.childStep || "";
  const childLabelArray = tables?.systemArchMaster?.filter(value => chooseSystems?.selectedSystems?.indexOf(value?.architectureId) > -1)?.map((item: any) => SYSTEM_ARCHS_REACT_MS_MAPPING[item.architectureId]);
  const activeChildIndex = childLabelArray?.indexOf(activeChildStage);
  const containsAll = chooseSystems?.filteredSystems?.filter(value => EXCLUDE_ARC_TYPE.includes(value));
   return (
    <>
      <div className="system-steps">
        <div className="nav-list">
          {props?.currentStep ?
            <TabStepNavigation labelArray={props?.labelArray} activeIndex={activeIndex} currentStep={true} results={props?.results || false}/>
            :
            <TabStepNavigation labelArray={props?.labelArray} activeIndex={activeIndex} currentStep={false} results={props?.results || false}/>
          }
        </div>
        {props.childStep &&
          <div className="nav-list-child">
            <TabStepChildNavigation activeChildIndex={activeChildIndex} childLabelArray={tables?.systemArchMaster?.filter(value => chooseSystems?.filteredSystems?.filter(val => (containsAll.length == 2) ? val != SYSTEM_ARCHS_KEYS.ARC_9 : val)?.indexOf(value?.architectureId) > -1)?.map((item: any) => item)} />
           
          </div>
        }
      </div>
      {props.calltoAction && <> <div className="clearfix"></div>
        <div className="set-default-link" onClick={props?.onResetClick}>{props.calltoAction}</div></>}
    </>
  );
};
export default TabStepper;
