/****************************************************
TopMenu.tsx - Hader of the eco efficiency calculator application
*****************************************************/
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import DOMPurify from 'dompurify';
import "bootstrap/dist/css/bootstrap.css";
import { BRANDING_JS_PATHS } from "../../helper/constants";

function TopMenu() {
  const [header, setHeader] = useState("");
  const { HEADER_FOOTER_MARKUP, REACT_APP_ECO_EFF_APP_ID, REACT_APP_ANALYTICS_LAUNCH_URL, ANALYTICS_LAUNCH_DEFAULT_URL } = useSelector((state: any) => state?.ecoEfficiency?.config || {});
  useEffect(() => {
    if (HEADER_FOOTER_MARKUP) {
      //Setting CSS using Map Function Ends
      HEADER_FOOTER_MARKUP?.cssResourcePaths?.map((cssUrlValue: string) => {
        const cssFile = document.createElement("link");
        cssFile.href = cssUrlValue;
        cssFile.rel = "stylesheet";
        document.head.appendChild(cssFile);
      });
      //Custom stleys
      const cssCustomFile:any = document.createElement("link");
      if(cssCustomFile){       
      cssCustomFile.href = "/css/customStyles.css?v=1.0";
      cssCustomFile.rel = "stylesheet";
      document.head.appendChild(cssCustomFile);
      }
      //Setting JS using Map Function Ends         
      /*  HEADER_FOOTER_MARKUP.jsResourcePaths?.map((jsUrlValue: string) => {
          const scriptFile = document.createElement("script");
         scriptFile.src = jsUrlValue;
          document.body.appendChild(scriptFile);
       }); */
      setTimeout(function () {
        //your code to be executed after 5 second
        setHeader(HEADER_FOOTER_MARKUP.headerMarkup);
      }, 200);
      setTimeout(function () {
        if (!document.getElementById("headerFooterJs")) {
          const scriptFile = document.createElement("script");
          scriptFile.id = "headerFooterJs";
          scriptFile.src = BRANDING_JS_PATHS(`${REACT_APP_ECO_EFF_APP_ID}`);
          document.body.appendChild(scriptFile);
        }
      }, 400);
      setTimeout(function () {
        if (!document.getElementById("analyticsLaunchJs")) {
          const scriptFile = document.createElement("script");
          scriptFile.id = "analyticsLaunchJs";
          scriptFile.src = REACT_APP_ANALYTICS_LAUNCH_URL || ANALYTICS_LAUNCH_DEFAULT_URL || "";
          document?.head?.appendChild(scriptFile);
        }
      }, 400);

    }
    // eslint-disable-next-line
  }, [HEADER_FOOTER_MARKUP]);
  return (<div>
    <div className='global-header' dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(header) }} /></div>
  );
}

export default TopMenu;