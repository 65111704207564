/**********************************************************************************************
CalculatorHome.tsx - Home page of the eco efficiency calculator application & managing redirections
***********************************************************************************************/
import React from 'react';
import { useSelector } from 'react-redux';
import { Routes, Route } from "react-router-dom";
import TermsConditions from "./pages/home/TermsConditions";
import Home from "./pages/home/Home";
import { useTranslation } from "react-i18next";
import { LABELKEYS_DASHBOARD } from "../../helper/constants";//dev test
import ContactForm from "./pages/contactform/ContactForm";
import LocationSize from './pages/locationsize/LocationSize';
import LoadSetup from './pages/systemdetails/loadsetup/LoadSetup';
import SystemChoice from './pages/systemdetails/systemchoice/SystemChoice';
import CentralizedSystem from './pages/systemdetails/compressorsetup/centralizedsystem/CentralizedSystem';
import SemiDistributedSystem from './pages/systemdetails/compressorsetup/semidistributedsystem/SemiDistributedSystem';
import DistributedSystemAirCooled from './pages/systemdetails/compressorsetup/distributedsystemaircooled/DistributedSystemAirCooled';
import DistributedSystemWaterCooled from './pages/systemdetails/compressorsetup/distributedsystemwatercooled/DistributedSystemWaterCooled';
import Cascade from './pages/systemdetails/compressorsetup/cascade/Cascade';
import MicroCascade from './pages/systemdetails/compressorsetup/microcascade/MicroCascade';
import R744Transcritical from './pages/systemdetails/compressorsetup/R744transcritical/R744Transcritical';
import ParameterSetup from './pages/systemdetails/parametersetup/ParameterSetup';
import SimulationSetup from './pages/systemdetails/simulationsetup/SimulationSetup';
import Results from './pages/results/Results';

const EcoEfficiencyHome = () => {
    const { t } = useTranslation();
    const { PAGE_NAVIGATION } = useSelector((state: any) => state?.ecoEfficiency?.config || {});
    return (<>
        <div className="page-title">{t(LABELKEYS_DASHBOARD.HEADING)}</div>
        <Routes>
            <Route path={PAGE_NAVIGATION?.TERMS_CONDITION} element={<TermsConditions />} />
            <Route path={PAGE_NAVIGATION?.OVERVIEW} element={<Home />} />
            <Route path="/contact-form" element={<ContactForm />} />
            <Route path={PAGE_NAVIGATION?.LOCATION_SIZE} element={<LocationSize />} />
            <Route path={PAGE_NAVIGATION?.SYSTEM_DETAILS?.CHOOSE_SYSTEM} element={<SystemChoice />} />
            <Route path={PAGE_NAVIGATION?.SYSTEM_DETAILS?.LOAD_SETUP} element={<LoadSetup />} />
            <Route path={PAGE_NAVIGATION?.SYSTEM_DETAILS?.COMPRESSOR_SETUP?.CENTRALIZED_SYSTEM} element={<CentralizedSystem />} />
            <Route path={PAGE_NAVIGATION?.SYSTEM_DETAILS?.COMPRESSOR_SETUP?.SEMI_DISTRIBUTED_SYSTEM} element={<SemiDistributedSystem />} />
            <Route path={PAGE_NAVIGATION?.SYSTEM_DETAILS?.COMPRESSOR_SETUP?.DISTRIBUTED_SYSTEM_AIR} element={<DistributedSystemAirCooled />} />
            <Route path={PAGE_NAVIGATION?.SYSTEM_DETAILS?.COMPRESSOR_SETUP?.DISTRIBUTED_SYSTEM_WATER} element={<DistributedSystemWaterCooled />} />
            <Route path={PAGE_NAVIGATION?.SYSTEM_DETAILS?.COMPRESSOR_SETUP?.CASCADE} element={<Cascade />} />
            <Route path={PAGE_NAVIGATION?.SYSTEM_DETAILS?.COMPRESSOR_SETUP?.MICRO_CASCADE} element={<MicroCascade />} />
            <Route path={PAGE_NAVIGATION?.SYSTEM_DETAILS?.COMPRESSOR_SETUP?.R744_TRANSCRITICAL} element={<R744Transcritical />} />
            <Route path={PAGE_NAVIGATION?.SYSTEM_DETAILS?.PARAMETER_SETUP} element={<ParameterSetup />} />
            <Route path={PAGE_NAVIGATION?.SYSTEM_DETAILS?.SIMULATION_SETUP} element={<SimulationSetup />} />
            <Route path={PAGE_NAVIGATION?.RESULTS_PAGE?.RESULTS} element={<Results />} />
        </Routes></>

    );
};
export default EcoEfficiencyHome;
