import React from 'react';
import {
  Chart as ChartJS,
  LinearScale,
  PointElement,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bubble } from 'react-chartjs-2';
ChartJS.register(LinearScale, PointElement, Tooltip, Legend);

import './BubbleGraph.scss';
const BubbleGraph= (props) => {
  const quadrants = {
    id: 'quadrants',
    beforeDraw(chart, args, options) {
      const {ctx, chartArea: {left, top, right, bottom}, scales: {x, y}} = chart;
      const midX = x.getPixelForValue(0);
      const midY = y.getPixelForValue(0);
      ctx.save();
      ctx.fillStyle = options.topLeft;
      ctx.fillRect(left, top, midX - left, midY - top);
      ctx.fillStyle = options.topRight;
      ctx.fillRect(midX, top, right - midX, midY - top);
      ctx.fillStyle = options.bottomRight;
      ctx.fillRect(midX, midY, right - midX, bottom - midY);
      ctx.fillStyle = options.bottomLeft;
      ctx.fillRect(left, midY, midX - left, bottom - midY);
      ctx.restore();
    }
}; 
  
  const options = {
    plugins: {
        quadrants: {
          topLeft: '#FFFFFF',
          topRight: '#9EC66A',
          bottomRight: '#FFFFFF',
          bottomLeft: ' #CE5D55',
        },
        legend: {
          filter:null,
            onClick: function (e) {
              e.stopPropagation();
          },
          position:'right',
            labels: {
              usePointStyle:true,
              font: {
                size: [`${props?.label?.length >= 5 ? 12 : 14}`]
              }
            }
        },
      tooltip: {
        enabled: true,
        usePointStyle : true,
        callbacks: {
          label: function(context) {
              let label = context.dataset.label || '';

              if (label) {
                  label += ': ';
              }
              if (context.parsed.y !== null) {
                  label += context.parsed.x + " , " + context.parsed.y;
              }
              return label;
          }
      }
      },
    },
    scales: {
        x: {
            grid: {
                tickColor: '#000',
                color:'#212121'
              },
              grace:'10%',
              ticks: {
                color: '#000',
                callback: value => parseFloat(parseFloat(value)?.toFixed(1) || 0) + '%',
                font: {
                  size: [`${props?.label?.length >= 5 ? 12 : 14}`]
                }
              },
          beginAtZero: true,
          title: {
            display: true,
            text: 'CO2 Footprint Reduction',
            padding:10,
            color:'#000',
            font: {
              size: [`${props?.label?.length >= 5 ? 12 : 14}`]
            }
          },
          align:'left'
          },
      y: {
        grid: {
        tickColor: '#000',
        color:'#212121',
      },
      
      grace:'10%',
      ticks: {
        color: '#000',
        callback: value => parseFloat(parseFloat(value)?.toFixed(1) || 0) + '%',
        font: {
          size: [`${props?.label?.length >= 5 ? 12 : 14}`]
        }
      },
        beginAtZero: true,
        title: {
          display: true,
          text: 'Cost Reduction',
          padding:10,
          color:'#000',
          font: {
            size: [`${props?.label?.length >= 5 ? 12 : 14}`]
          }
        }
      },
    }
  };  
  const data = {
      datasets: [
        {
          label: props?.labelBubble?.includes('Centralized') ? 'Centralized' + ((props?.refrigerantType) ? " [" + props?.refrigerantType[props?.labelBubble?.indexOf('Centralized')] + "]" : "") : "",
          data: [{
            x: props?.ARC_1?.x,
            y: props?.ARC_1?.y,
            r: 10
          }],
          backgroundColor: props?.labelBubble?.includes('Centralized') ? '#000000' : '#ffffff',
          borderColor: 'transparent',
          borderWidth:2,
          pointStyle:'circle',
          datalabels: {
            display: false,
            color: "black",
            anchor: "horizontal",
            align: "horizontal",
            rotation:0
          },
        },
        {
          label: props?.labelBubble?.includes('Semi-Centralized') ? 'Semi-Centralized' + ((props?.refrigerantType) ? " [" + props?.refrigerantType[props?.labelBubble?.indexOf('Semi-Centralized')] + "]" : "") : "",
          data: [{
            x: props?.ARC_2?.x,
            y: props?.ARC_2?.y,
            r: 10
          }],
          backgroundColor: props?.labelBubble?.includes('Semi-Centralized') ? '#000000' : 'transparent',
          borderColor: 'transparent',
          borderWidth:2,
          pointStyle:'rect',
          datalabels: {
            display: false,
            color: "black",
            anchor: "horizontal",
            align: "horizontal",
            rotation:0
          }
        },
        {
          label: props?.labelBubble?.includes('Distributed-Air-Cooled') ? 'Distributed-Air Cooled' + ((props?.refrigerantType) ? " [" + props?.refrigerantType[props?.labelBubble?.indexOf('Distributed-Air-Cooled')] + "]" : "") : "",
          data: [{
            x: props?.ARC_3?.x,
            y: props?.ARC_3?.y,
            r: 10
          }],
          backgroundColor: props?.labelBubble?.includes('Distributed-Air-Cooled') ? '#000000' : 'transparent',
          borderColor: 'transparent',
          borderWidth:2,
          pointStyle:'triangle',
          datalabels: {
            display: false,
            color: "black",
            anchor: "horizontal",
            align: "horizontal",
            rotation:0
          }
        },
        {
          label: props?.labelBubble?.includes('Distributed-Water-Cooled') ? 'Distributed-Water Cooled' + ((props?.refrigerantType) ? " [" + props?.refrigerantType[props?.labelBubble?.indexOf('Distributed-Water-Cooled')] + "]" : "") : "",
          data: [{
            x: props?.ARC_4?.x,
            y: props?.ARC_4?.y,
            r: 10
          }],
          backgroundColor: props?.labelBubble?.includes('Distributed-Water-Cooled') ? '#000' : 'transparent',
          borderColor: props?.labelBubble?.includes('Distributed-Water-Cooled') ? '#000' : 'transparent',
          borderWidth:2,
          datalabels: {
            display: false,
            color: "black",
            anchor: "horizontal",
            align: "horizontal",
            rotation:0
          },
          pointStyle:  'star',
          color: '#000'
        },
        {
          label: props?.labelBubble?.includes('Cascade') ? 'Cascade' + ((props?.refrigerantType) ? " [" + props?.refrigerantType[props?.labelBubble?.indexOf('Cascade')] + "]" : "") : "",
          data: [{
              x: props?.ARC_5?.x,
              y: props?.ARC_5?.y,
              r: 10
            }],
            backgroundColor: props?.labelBubble?.includes('Cascade') ? '#000' : 'transparent',
            borderColor: props?.labelBubble?.includes('Cascade') ? '#000' : 'transparent',
            borderWidth:2,
            datalabels: {
            display: false,
            color: "black",
            anchor: "horizontal",
            align: "horizontal",
            rotation:0
          },
          pointStyle:  'crossRot',
          color: '#000'
        },
        {
          label: props?.labelBubble?.includes('Micro-Cascade') ? 'Micro-Cascade' + ((props?.refrigerantType) ? " [" + props?.refrigerantType[props?.labelBubble?.indexOf('Micro-Cascade')] + "]" : "") : "",
          data: [{
              x: props?.ARC_6?.x,
              y: props?.ARC_6?.y,
              r: 10
            }],
            backgroundColor: props?.labelBubble?.includes('Micro-Cascade') ? '#000000' : '#ffffff',
            borderColor : "transparent",
            borderWidth:2,
          datalabels: {
            display: false,
          },
          pointStyle:  'rectRot'
        },
        {
          label: props?.labelBubble?.includes('R744-Transcritical:-Standard') ? 'R744 Transcritical: Standard' + ((props?.refrigerantType) ? " [" + props?.refrigerantType[props?.labelBubble?.indexOf('R744-Transcritical:-Standard')] + "]" : "") : "",
          data: [{
              x: props?.ARC_8?.x,
              y: props?.ARC_8?.y,
              r: 10
            }],
            backgroundColor: props?.labelBubble?.includes('R744-Transcritical:-Standard') ? '#fff' : '#fff',
            borderColor: props?.labelBubble?.includes('R744-Transcritical:-Standard') ? '#000' : 'transparent',
            
          datalabels: {
            display: false,
          },
          pointStyle:  'rectRounded'
        },
        {
          label: props?.labelBubble?.includes('R744-Transcritical:-Parallel') ? 'R744 Transcritical: Parallel' + ((props?.refrigerantType) ? " [" + props?.refrigerantType[props?.labelBubble?.indexOf('R744-Transcritical:-Parallel')] + "]" : "") : "",
          data: [{
              x: props?.ARC_9?.x,
              y: props?.ARC_9?.y,
              r: 10
            }],
            backgroundColor: props?.labelBubble?.includes('R744-Transcritical:-Parallel') ? '#000000' : 'transparent',
            borderColor: props?.labelBubble?.includes('R744-Transcritical:-Parallel') ? '#000000' : 'transparent',
           
          datalabels: {
            display: false,
          },
          pointStyle:  'cross'
        }
      ],
    };
    const updateDataSet = data?.datasets?.filter(key => key.label != "") || [];
    if(data?.datasets?.length > 0)
    {
      data.datasets = updateDataSet;
    }
  return <>
  <Bubble id={props?.source} options={options} data={data} plugins={[quadrants]} aria-label="Bubble Graph" />
  </>;
};
export default BubbleGraph;
