import React, { useCallback, useMemo } from "react";
import "./DistributedSystemPanel.scss";
import { BiChevronDown } from "react-icons/bi";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { LABELKEYS_COMPRESSOR_SETUP, LABELKEYS_SYSTEM_ANALYSIS_PANEL } from "../../../../../../helper/constants";
import { setCompressorSetup } from "../../../../../../store/actions";
import { ImgWarning } from "../../../../../../assets/images/archSelection";
import Loader from "../../../../../common/Loader/Loader";
const DistributedSystemPanel = (props) => {
  const { t } = useTranslation();
  const { TYPE_OF_RACK, COMPRESSOR_ARCHS_CONFIG, DISTRIBUTED_SYSTEM_PANEL_DATA_JSON, DISTRIBUTED_SYSTEM_PANEL_TABLE_HEADER } = useSelector((state: any) => state?.ecoEfficiency?.config || {});
  const { userInputs } = useSelector((state: any) => state?.ecoEfficiency?.calculator || {});
  const { compressorSetup, loadSetup, chooseSystems } = userInputs;
  const onRackClick = useCallback((name) => () => {
    props?.setRackType(name);
  }, [props]);

  //type options
  const optionsMTTypeValues = useMemo(() => {
    if (props?.micorcascade) {
      return COMPRESSOR_ARCHS_CONFIG?.[props?.architecture]?.[TYPE_OF_RACK.MT]?.TYPES || [];
    }
    else {
      return COMPRESSOR_ARCHS_CONFIG?.[props?.architecture]?.[TYPE_OF_RACK.MT]?.[props?.refrigerant]?.TYPES || [];
    }
  }, [COMPRESSOR_ARCHS_CONFIG, TYPE_OF_RACK, props?.architecture,props?.refrigerant,props?.micorcascade]);
  const optionsLTTypeValues = useMemo(() => {   
      return COMPRESSOR_ARCHS_CONFIG?.[props?.architecture]?.[TYPE_OF_RACK.LT]?.[props?.refrigerant]?.TYPES || [];
    
  }, [COMPRESSOR_ARCHS_CONFIG, TYPE_OF_RACK, props?.architecture,props?.refrigerant]);
  //company options
  const optionsMTCompanyValues = useMemo(() => {
    if (props?.micorcascade) {
      return COMPRESSOR_ARCHS_CONFIG?.[props?.architecture]?.[TYPE_OF_RACK.MT]?.COMPANIES?.filter(c => c?.type == (props?.type || COMPRESSOR_ARCHS_CONFIG?.[props?.architecture]?.[TYPE_OF_RACK.MT]?.DEFAULT_TYPE || ""))?.map(c => c?.company) || [];
    }
    else {
      return COMPRESSOR_ARCHS_CONFIG?.[props?.architecture]?.[TYPE_OF_RACK.MT]?.[props?.refrigerant]?.COMPANIES?.filter(c => c?.type == (props?.type || COMPRESSOR_ARCHS_CONFIG?.[props?.architecture]?.[TYPE_OF_RACK.MT]?.DEFAULT_TYPE || ""))?.map(c => c?.company) || [];
    }
  }, [COMPRESSOR_ARCHS_CONFIG, TYPE_OF_RACK, props?.type, props?.architecture,props?.refrigerant,props?.micorcascade]);
  const optionsLTCompanyValues = useMemo(() => {   
      return COMPRESSOR_ARCHS_CONFIG?.[props?.architecture]?.[TYPE_OF_RACK.LT]?.[props?.refrigerant]?.COMPANIES?.filter(c => c?.type == (props?.type || COMPRESSOR_ARCHS_CONFIG?.[props?.architecture]?.[TYPE_OF_RACK.LT]?.DEFAULT_TYPE || ""))?.map(c => c?.company) || [];
    
  }, [COMPRESSOR_ARCHS_CONFIG, props?.type, TYPE_OF_RACK, props?.architecture,props?.refrigerant]);

  //On type change
  const onTypeChange = useCallback((typeOfRack) => (e) => {
    const defaultCompany = COMPRESSOR_ARCHS_CONFIG?.[props?.architecture]?.[typeOfRack]?.COMPANIES?.filter(c => c?.type == (e?.target?.value))?.map(c => c?.company)?.[0] || "";
    const systemData = { [props?.architecture]: { ...compressorSetup[props?.architecture], [typeOfRack]: { ...compressorSetup?.[props?.architecture]?.[typeOfRack], type: e?.target?.value, company: defaultCompany, compressorInputs: [] } } };
    setCompressorSetup({ ...compressorSetup, ...systemData });
  }, [compressorSetup, props?.architecture, COMPRESSOR_ARCHS_CONFIG]);
  //On Comapny chnage
  const onCompanyChange = useCallback((typeOfRack) => (e) => {
    const systemData = { [props?.architecture]: { ...compressorSetup[props?.architecture], [typeOfRack]: { ...compressorSetup?.[props?.architecture]?.[typeOfRack], company: e?.target?.value, compressorInputs: [] } } };
    setCompressorSetup({ ...compressorSetup, ...systemData });
  }, [compressorSetup, props?.architecture]);
  return (
    <>
      <div className="temperature-side">
        <div className="select-items row mx-0">
          <div className="col-md-6 col-xs-12 border-right">
            <div className="row mx-0">
              <div className="title text-center">{t(LABELKEYS_COMPRESSOR_SETUP.MEDIUM_TEMPERATURE_HEADING)}</div>
              <div className="sub-title text-center">{t(LABELKEYS_COMPRESSOR_SETUP.REFRIGERANT)} <span>{chooseSystems?.system?.[props?.architecture]?.mtSide}</span></div>
              <div className="sub-title text-center">
                {props?.showMTLoadWarning && <div className="warning-message">
                  <div className="mesg">
                    <div className="warning-icon"><img src={ImgWarning} alt="Warning" width="18px" /></div>
                    <span>{t(LABELKEYS_SYSTEM_ANALYSIS_PANEL.WARNING)}</span>
                  </div>
                  <span> {t(LABELKEYS_SYSTEM_ANALYSIS_PANEL.WARNING_MSG_COMP_MT_LOAD)}</span>
                </div>}
              </div>
              <div className="sub-title text-center">
                {t(LABELKEYS_COMPRESSOR_SETUP.SELECTED_MT_COMPRESSOR)} <span className="xbold">{props.isMTLoading ? <Loader /> : compressorSetup?.[props?.architecture]?.[TYPE_OF_RACK.MT]?.selectedCompressorLoad || "-"}</span> <span className="sub-title1">{t(LABELKEYS_COMPRESSOR_SETUP.TARGET_MT_COMPRESSOR)} <span className="xbold">{props?.targetLoads ? props?.targetLoads?.targetMTLoad : loadSetup?.selectedMTLoad || "-"}</span> </span>
              </div>
              {props?.micorcascade !== true && <><div className="col-md-6 col-xs-12">
                <div className="form-group">
                  <>
                    <label htmlFor="Type">{t(LABELKEYS_COMPRESSOR_SETUP.COMPRESSOR_TYPE)} <span className="mandatory"></span></label>
                    <select value={compressorSetup?.[props?.architecture][TYPE_OF_RACK.MT]?.type} onChange={onTypeChange(TYPE_OF_RACK.MT)} className="form-control" placeholder="" aria-label="Compressor Type">
                      {optionsMTTypeValues?.map((key, index) => {
                        return (
                          <option key={index} value={key}>{key}</option>
                        );
                      })}
                    </select>
                    <i className="angle-down-arrow"><BiChevronDown className="angle-down-icon" /></i>
                  </>
                </div>
              </div>
                <div className="col-md-6 col-xs-12">
                  <div className="form-group">
                    <>
                      <label htmlFor="Type">{t(LABELKEYS_COMPRESSOR_SETUP.COMPRESSOR_MANUFACTURE)} <span className="mandatory"></span></label>
                      <select value={compressorSetup?.[props?.architecture][TYPE_OF_RACK.MT]?.company} onChange={onCompanyChange(TYPE_OF_RACK.MT)} className="form-control" placeholder="" aria-label="Compressor Manufacture">
                        {optionsMTCompanyValues?.map((key, index) => {
                          return (
                            <option key={index} value={key}>{key}</option>
                          );
                        })}
                      </select>
                      <i className="angle-down-arrow"><BiChevronDown className="angle-down-icon" /></i>
                    </>
                  </div>
                </div></>}
              <div className="col-md-12 pt-2">
                <div className={props?.typeOfRack === TYPE_OF_RACK.MT ? "btn btn-black" : "btn btn-white"} onClick={onRackClick(TYPE_OF_RACK.MT)}>{props.micorcascade ? t(LABELKEYS_COMPRESSOR_SETUP.MEDIUM_TEMPERATURE_MICRO_CAS) : t(LABELKEYS_COMPRESSOR_SETUP.MEDIUM_TEMPERATURE)}</div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-xs-12">
            <div className="row mx-0">
              <div className="title text-center">{t(LABELKEYS_COMPRESSOR_SETUP.LOW_TEMPERATURE_HEADING)}</div>
              <div className="sub-title text-center">{t(LABELKEYS_COMPRESSOR_SETUP.REFRIGERANT)}  <span>{chooseSystems?.system?.[props?.architecture]?.ltSide}</span></div>
              <div className="sub-title text-center">
                {props?.showLTLoadWarning && <div className="warning-message">
                  <div className="mesg">
                    <div className="warning-icon"><img src={ImgWarning} alt="Warning" width="18px" /></div>
                    <span>{t(LABELKEYS_SYSTEM_ANALYSIS_PANEL.WARNING)}</span>
                  </div>
                  <span> {t(LABELKEYS_SYSTEM_ANALYSIS_PANEL.WARNING_MSG_COMP_LT_LOAD)}</span>
                </div>}
              </div>
              <div className="sub-title text-center">
                {t(LABELKEYS_COMPRESSOR_SETUP.SELECTED_LT_COMPRESSOR)} <span className="xbold">{props.isLTLoading ? <Loader /> : compressorSetup?.[props?.architecture]?.[TYPE_OF_RACK.LT]?.selectedCompressorLoad || "-"}</span> <span className="sub-title1">{t(LABELKEYS_COMPRESSOR_SETUP.TARGET_LT_COMPRESSOR)} <span className="xbold"> {props?.targetLoads ? props?.targetLoads?.targetLTLoad : loadSetup?.selectedLTLoad || "-"}</span> </span>
              </div>
              {props?.micorcascade !== true && <><div className="col-md-6 col-xs-12">
                <div className="form-group">
                  <>
                    <label htmlFor="Type">{t(LABELKEYS_COMPRESSOR_SETUP.COMPRESSOR_TYPE)} <span className="mandatory"></span></label>
                    <select onChange={onTypeChange(TYPE_OF_RACK.LT)} value={compressorSetup?.[props?.architecture]?.[TYPE_OF_RACK.LT]?.type} className="form-control" placeholder="" aria-label="Compressor Type">
                      {optionsLTTypeValues?.map((key, index) => {
                        return (
                          <option key={index} value={key}>{key}</option>
                        );
                      })}
                    </select>
                    <i className="angle-down-arrow"><BiChevronDown className="angle-down-icon" /></i>
                  </>
                </div>
              </div>
                <div className="col-md-6 col-xs-12">
                  <div className="form-group">
                    <>
                      <label htmlFor="Type">{t(LABELKEYS_COMPRESSOR_SETUP.COMPRESSOR_MANUFACTURE)} <span className="mandatory"></span></label>
                      <select value={compressorSetup?.[props?.architecture]?.[TYPE_OF_RACK.LT]?.company} onChange={onCompanyChange(TYPE_OF_RACK.LT)} className="form-control" placeholder="" aria-label="Compressor Manufacture">
                        {optionsLTCompanyValues?.map((key, index) => {
                          return (
                            <option key={index} value={key}>{key}</option>
                          );
                        })}
                      </select>
                      <i className="angle-down-arrow"><BiChevronDown className="angle-down-icon" /></i>
                    </>
                  </div>
                </div></>}
              <div className="col-md-12 pt-2">
                <div className={props?.typeOfRack === TYPE_OF_RACK.LT ? "btn btn-black" : "btn btn-white"} onClick={onRackClick(TYPE_OF_RACK.LT)}>{props.micorcascade ? t(LABELKEYS_COMPRESSOR_SETUP.LOW_TEMPERATURE_MICRO_CAS) : t(LABELKEYS_COMPRESSOR_SETUP.LOW_TEMPERATURE)}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="temperature-unit">

        {props?.typeOfRack === TYPE_OF_RACK.MT ?
          <div className="title">{props.micorcascade ? t(LABELKEYS_COMPRESSOR_SETUP.MEDIUM_TEMPERATURE_MICRO_CAS) : t(LABELKEYS_COMPRESSOR_SETUP.MEDIUM_TEMPERATURE)}</div>
          :
          <div className="title">{props.micorcascade ? t(LABELKEYS_COMPRESSOR_SETUP.LOW_TEMPERATURE_MICRO_CAS) : t(LABELKEYS_COMPRESSOR_SETUP.LOW_TEMPERATURE)}</div>
        }
        {props?.micorcascade === true ?
          (
            props?.typeOfRack === TYPE_OF_RACK.MT ?
              <div className="select-temperature select-items row mx-0">
                <div className="col-md-6 col-xs-12">
                  <div className="form-group">
                    <>
                      <label htmlFor="Type">{t(LABELKEYS_COMPRESSOR_SETUP.COMPRESSOR_TYPE)} <span className="mandatory"></span></label>
                      <select onChange={onTypeChange(TYPE_OF_RACK.MT)} value={compressorSetup?.[props?.architecture][TYPE_OF_RACK.MT]?.type} className="form-control" placeholder="" aria-label="Compressor Type">
                        {optionsMTTypeValues?.map((key, index) => {
                          return (
                            <option key={index} value={key}>{key}</option>
                          );
                        })}
                      </select>
                      <i className="angle-down-arrow"><BiChevronDown className="angle-down-icon" /></i>
                    </>
                  </div>
                </div>
                <div className="col-md-6 col-xs-12">
                  <div className="form-group">
                    <>
                      <label htmlFor="Company">{t(LABELKEYS_COMPRESSOR_SETUP.COMPRESSOR_MANUFACTURE)} <span className="mandatory"></span></label>
                      <select onChange={onCompanyChange(TYPE_OF_RACK.MT)} value={compressorSetup?.[props?.architecture][TYPE_OF_RACK.MT]?.company} className="form-control" placeholder="" aria-label="Compressor Manufacture">
                        {optionsMTCompanyValues?.map((key, index) => {
                          return (
                            <option key={index} value={key}>{key}</option>
                          );
                        })}
                      </select>
                      <i className="angle-down-arrow"><BiChevronDown className="angle-down-icon" /></i>
                    </>
                  </div>
                </div>
              </div>
              :
              <>
                <div className="select-temperature select-items row mx-0">
                  <div className="col-md-6 col-xs-12">
                    <div className="form-group">
                      <>
                        <label htmlFor="Company">{t(LABELKEYS_COMPRESSOR_SETUP.COMPRESSOR_TYPE)} <span className="mandatory"></span></label>
                        <select onChange={onTypeChange(TYPE_OF_RACK.LT)} value={compressorSetup?.[props?.architecture]?.[TYPE_OF_RACK.LT]?.type} className="form-control" placeholder="" aria-label="Compressor Type">
                          {optionsLTTypeValues?.map((key, index) => {
                            return (
                              <option key={index} value={key}>{key}</option>
                            );
                          })}
                        </select>
                        <i className="angle-down-arrow"><BiChevronDown className="angle-down-icon" /></i>
                      </>
                    </div>
                  </div>
                  <div className="col-md-6 col-xs-12">
                    <div className="form-group">
                      <>
                        <label htmlFor="Company">{t(LABELKEYS_COMPRESSOR_SETUP.COMPRESSOR_MANUFACTURE)} <span className="mandatory"></span></label>
                        <select onChange={onCompanyChange(TYPE_OF_RACK.LT)} value={compressorSetup?.[props?.architecture]?.[TYPE_OF_RACK.LT]?.company} className="form-control" placeholder="" aria-label="Compressor Manufacture">
                          {optionsLTCompanyValues?.map((key, index) => {
                            return (
                              <option key={index} value={key}>{key}</option>
                            );
                          })}
                        </select>
                        <i className="angle-down-arrow"><BiChevronDown className="angle-down-icon" /></i>
                      </>
                    </div>
                  </div>
                </div>
                <div className="border">
                  <div className="dist-table-panel">
                    <div className="panel-body">
                      <div className="table-head">
                        {DISTRIBUTED_SYSTEM_PANEL_TABLE_HEADER?.map((e: any) => {
                          return (
                            (e?.fieldCount === 3) ?
                              <div key={`div${e?.fieldKey}`} className="table-items">
                                {t(e?.fieldKey)}{" "}
                              </div>
                              :
                              <div key={`div${e?.fieldKey}`} className="table-items">
                                {t(e?.fieldKey)}{" "}
                              </div>
                          );
                        })}
                      </div>
                      {DISTRIBUTED_SYSTEM_PANEL_DATA_JSON?.map((val, key) => {
                        let valueCollection: any = [];
                        if (val?.id === "01") {
                          valueCollection = props?.compressorData?.map(comp => comp?.cop || 0) || ["0", "0", "0", "0", "0"];
                        }
                        else if (val?.id === "02") {
                          valueCollection = props?.compressorData?.map(comp => comp?.enRating || 0) || ["0", "0", "0", "0", "0"];
                        }
                        if (valueCollection?.length < 5) {
                          while (valueCollection?.length < 5) {
                            valueCollection.push("0");
                          }
                        }
                        return (
                          <div key={key} className="row-elements">
                            <div className="table-data">{val.CompressorName}</div>
                            {valueCollection?.map((name, index) => {
                              return (<div key={`column-${key}-${index}`} className="table-data">{name}</div>);
                            })}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </>
          )
          :
          (
            <div className="border">
              <div className="dist-table-panel">
                <div className="panel-body">
                  <div className="table-head">
                    {DISTRIBUTED_SYSTEM_PANEL_TABLE_HEADER?.map((e: any) => {
                      return (
                        (e?.fieldCount === 3) ?
                          <div key={`div${e?.fieldKey}`} className="table-items">
                            {t(e?.fieldKey)}{" "}
                          </div>
                          :
                          <div key={`div${e?.fieldKey}`} className="table-items">
                            {t(e?.fieldKey)}{" "}
                          </div>
                      );
                    })}
                  </div>
                  {DISTRIBUTED_SYSTEM_PANEL_DATA_JSON?.map((val, key) => {
                    let valueCollection: any = [];
                    if (val?.id === "01") {
                      valueCollection = props?.compressorData?.map(comp => comp?.cop || 0) || ["0", "0", "0", "0", "0"];
                    }
                    else if (val?.id === "02") {
                      valueCollection = props?.compressorData?.map(comp => comp?.enRating || 0) || ["0", "0", "0", "0", "0"];
                    }
                    if (valueCollection?.length < 5) {
                      while (valueCollection?.length < 5) {
                        valueCollection.push("0");
                      }
                    }
                    return (
                      <div key={key} className="row-elements">
                        <div className="table-data">{val.CompressorName}</div>
                        {valueCollection?.map((name, index) => {
                          return (<div key={`column-${key}-${index}`} className="table-data">{name}</div>);
                        })}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>)}
      </div>
    </>
  );
};

export default DistributedSystemPanel;