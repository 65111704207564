import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { LABELKEYS_STORE_SIZE, RESULTS_LABEL } from "../../../../../helper/constants";
import GroupBarGraph from "../../../../common/Graph/GroupBarGraph/GroupBarGraph";
import StackedGraph from "../../../../common/Graph/StackedGraph/StackedGraph";
import BubbleGraph from "../../../../common/Graph/BubbleGraph/BubbleGraph";
import DOMPurify from 'dompurify';
import { useSelector } from "react-redux";
import { GraphInputs } from "../../../../../services/graphInputs";
import { ImgArrowX, ImgArrowY } from "../../../../../assets/images/archSelection";
const ResultsGraph = () => {
    const { t } = useTranslation();
    const { userInputs, tables, transactionId } = useSelector((state: any) => state?.ecoEfficiency?.calculator || {});
    const { locationAndSize, chooseSystems } = userInputs;
    const resultTable = tables?.resultsMaster?.[transactionId] || [];
    const { SYSTEM_ARCHS_KEYS } = useSelector((state: any) => state?.ecoEfficiency?.config || {});
    // Graph JSON
    const graphInput = useMemo(() => {
        const graphJSON = GraphInputs.getGraphJSON(userInputs, tables?.resultsMaster, transactionId, tables?.systemArchMaster);
        return graphJSON;
        // eslint-disable-next-line
    }, [tables?.resultsMaster]);
    return (
        <div>
            <div className="results-graph results-group-graph">
                <div className="graph-info">
                    {t(LABELKEYS_STORE_SIZE.HEADING)}:
                    <span> {locationAndSize?.storeSize} </span>
                    {t(RESULTS_LABEL.STORE_LOCATION)}:
                    <span> {locationAndSize?.country + " , " + locationAndSize?.city} </span>
                    {Object?.keys(resultTable)?.filter(id => chooseSystems?.selectedSystems?.indexOf(id) > -1)?.map((key, index) => {
                        const mtSideValue = key === SYSTEM_ARCHS_KEYS.ARC_5 ? chooseSystems?.system[key]?.highStage : chooseSystems?.system[key]?.mtSide;
                        const arcName: string = tables?.systemArchMaster?.filter(value => value?.architectureId == key)?.[0]?.architectureName || "";
                        if (!(arcName?.length > 0)) {
                            return;
                        }
                        return (<>{" " + arcName}:<span key={`${key}_${index}`} className="me-1"> <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize((chooseSystems?.system[key]?.ltSide != mtSideValue) ? mtSideValue + " &#47; " + chooseSystems?.system[key]?.ltSide : chooseSystems?.system[key]?.ltSide || "") }} /></span></>);
                    })}

                </div>
                <div className="graph-title">{t(RESULTS_LABEL.ABSOLUTE_GRAPH)}</div>
                <div className="graph-group">

                    <GroupBarGraph source={'group-graph-results'} capex={graphInput?.capex} opexElectricity={graphInput?.electricity} opexService={graphInput?.opexService} opexToppingUp={graphInput?.opexToppingUp} emission={graphInput?.emissionGroup} label={graphInput?.label} />
                </div>
            </div>
            <div className="results-graph results-stacked-graph">
                <div className="graph-info">
                    {t(LABELKEYS_STORE_SIZE.HEADING)}:
                    <span> {locationAndSize?.storeSize} </span>
                    {t(RESULTS_LABEL.STORE_LOCATION)}:
                    <span> {locationAndSize?.country + " , " + locationAndSize?.city} </span>
                    {Object?.keys(resultTable)?.filter(id => chooseSystems?.selectedSystems?.indexOf(id) > -1)?.map((key, index) => {
                        const mtSideValue = key === SYSTEM_ARCHS_KEYS.ARC_5 ? chooseSystems?.system[key]?.highStage : chooseSystems?.system[key]?.mtSide;
                        const arcName: string = tables?.systemArchMaster?.filter(value => value?.architectureId == key)?.[0]?.architectureName || "";
                        if (!(arcName?.length > 0)) {
                            return;
                        }
                        return (<>{" " + arcName}:<span key={`${key}_${index}`} className="me-1"> <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize((chooseSystems?.system[key]?.ltSide != mtSideValue) ? mtSideValue + " &#47; " + chooseSystems?.system[key]?.ltSide : chooseSystems?.system[key]?.ltSide || "") }} /></span></>);
                    })}

                </div>
                <div className="graph-title">{t(RESULTS_LABEL.ABSOLUTE_GRAPH)}</div>
                <div className="graph-stacked"><StackedGraph source={'stacked-graph-results'} capex={graphInput?.capex} opexElectricity={graphInput?.electricity} opexService={graphInput?.opexService} opexToppingUp={graphInput?.opexToppingUp} emission={graphInput?.emissionStack} label={graphInput?.label} /></div>
            </div>
            <div className="results-graph results-bubble-graph">
                <div className="graph-info">
                    {t(LABELKEYS_STORE_SIZE.HEADING)}:
                    <span> {locationAndSize?.storeSize} </span>
                    {t(RESULTS_LABEL.STORE_LOCATION)}:
                    <span> {locationAndSize?.country + " , " + locationAndSize?.city} </span>
                    {Object?.keys(resultTable)?.filter(id => chooseSystems?.selectedSystems?.indexOf(id) > -1)?.map((key, index) => {
                        const mtSideValue = key === SYSTEM_ARCHS_KEYS.ARC_5 ? chooseSystems?.system[key]?.highStage : chooseSystems?.system[key]?.mtSide;
                        const arcName: string = tables?.systemArchMaster?.filter(value => value?.architectureId == key)?.[0]?.architectureName || "";
                        if (!(arcName?.length > 0)) {
                            return;
                        }
                        return (<>{" " + arcName}:<span key={`${key}_${index}`} className="me-1"> <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize((chooseSystems?.system[key]?.ltSide != mtSideValue) ? mtSideValue + " &#47; " + chooseSystems?.system[key]?.ltSide : chooseSystems?.system[key]?.ltSide || "") }} /></span></>);
                    })}

                </div>
                <div className="graph-title">{t(RESULTS_LABEL.RELATIVE_GRAPH)}</div>
                <div className="graph-bubble">
                    <div className="x-axis-arrow"><img src={ImgArrowY} alt="X axis" /></div>
                    <BubbleGraph
                        source={'bubble-graph-results'}
                        label={graphInput?.label}
                        ARC_1={graphInput?.ARC_1}
                        ARC_2={graphInput?.ARC_2}
                        ARC_3={graphInput?.ARC_3}
                        ARC_4={graphInput?.ARC_4}
                        ARC_5={graphInput?.ARC_5}
                        ARC_6={graphInput?.ARC_6}
                        ARC_8={graphInput?.ARC_8}
                        ARC_9={graphInput?.ARC_9}
                        refrigerantType={graphInput?.refrigerantType}
                        labelBubble={graphInput?.labelBubble}
                    />
                    <div className="y-axis-arrow"><img src={ImgArrowX} alt="Y axis" /></div>
                </div>
            </div>

        </div>
    );
};
export default ResultsGraph;
