/**********************************************************************************************
MainContent.tsx - Wrapper of the eco efficiency calculator application
***********************************************************************************************/
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';
import { useGetConfigurationsQuery } from '../../services/configurations';
import Loader from "../common/Loader/Loader";
import TopMenu from "./TopMenu";
import Footer from "./Footer";
import EcoEfficiencyHome from "../dashboard/EcoEfficiencyHome";
import { LANG_API_PATHS, ERROR_MESSAGES } from "../../helper/constants";
const MainContent = () => {
  const { i18n } = useTranslation();
  const language = useSelector((state: any) => state?.ecoEfficiency?.language || "en-us");
  const config = useSelector((state: any) => state?.ecoEfficiency?.config || undefined);
  const { HEADER_FOOTER_MARKUP } = config || {};
  const { data, error, isLoading } = useGetConfigurationsQuery(LANG_API_PATHS(language));
  const [isHeaderLoading, setHeaderLoading] = useState(true);
  //setting lanaguage traslation files from node service for lables
  useEffect(() => {
    if (data?.isSuccess) {
      const resources = data?.result;
      i18n.init({
        resources,
        fallbackLng: "enUS",
        debug: true,
        interpolation: {
          escapeValue: false, // not needed for react as it escapes by default
        },
      });
    }
    else if (!data?.isSuccess && error) {
      throw (ERROR_MESSAGES.LANG_FILE_NOT_FOUND);
    }
  }, [data, error, i18n, language]);
  //set loader
  useEffect(() => {
    if (HEADER_FOOTER_MARKUP) {
      setTimeout(function () {
        setHeaderLoading(false);
      }, 3000);
    }
  }, [HEADER_FOOTER_MARKUP]);
  return (
    <div className="mainContent" role="main">
      {isLoading || !config ? <Loader /> :
        <>
          <TopMenu />
          {isHeaderLoading ? <Loader /> : <div className="eco-container"><EcoEfficiencyHome /></div>}
          <Footer />
        </>
      }
    </div >
  );
};
export default MainContent;
