import React, { useCallback, useState } from "react";
import "./StoreSizeDefaultConfig.scss";
import { LABELKEYS_STORE_SIZE, STORE_SIZE_TEMP_SIDE } from "../../../../../helper/constants";
import { useTranslation } from "react-i18next";
import StoreSizeTable from "./storesizeTable/StoreSizeTable";
import { useSelector } from "react-redux";
import DOMPurify from 'dompurify';
const StoreSizeDefaultConfig = () => {
    const { t } = useTranslation();
    const [flagStoreSizeTable, setFlagStoreSize] = useState(false); 
    const [storeSize, setStoreSize] = useState("");
    const [typeOfStore, setTypeofStore] = useState("");
    const { STORE_SIZE_DEFAULT_CONFIG } = useSelector((state: any) => state?.ecoEfficiency?.config || {});
    const [mediumTempTable, setmediumTempTable] = useState<any[]>([]);
    const [lowTempTable, setlowTempTable] = useState<any[]>([]);
    
    //store size table clcik
    const StoreSizeTableClick = useCallback((name: string) => () => {
        const mtarr: any[] = [];
        const ltarr: any[] = [];
        {
            STORE_SIZE_TEMP_SIDE?.map((val) => {
                Object.keys(val).forEach(function (key) {
                    {
                        val[key]?.slice(0, val[key].length).map((data) => {
                            if (key == "mt") {
                                {
                                    data.capacity?.slice(0, data.capacity.length).map((capacity) => {
                                        Object.keys(capacity).forEach(function (capKey) {
                                            if (capKey == name) {
                                                if (data.capacity[0][capKey] != "") {
                                                    data.relatedCapacity = data.capacity[0][capKey];
                                                    mtarr.push(data);
                                                }
                                            }
                                        });
                                    });
                                }
                            }
                            if (key == "lt") {
                                {
                                    data.capacity?.slice(0, data.capacity.length).map((capacity) => {
                                        Object.keys(capacity).forEach(function (capKey) {
                                            if (capKey == name) {
                                                if (data.capacity[0][capKey] != "") {
                                                    data.relatedCapacity = data.capacity[0][capKey];
                                                    ltarr.push(data);
                                                }
                                            }
                                        });
                                    });
                                }
                            }
                        });
                    }
                });
            });
        }
        setmediumTempTable(mtarr);
        setlowTempTable(ltarr);
        setFlagStoreSize(true);
        setTypeofStore(name);
        setStoreSize(name);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <div className="eco-store-sizes">
            <div className="sub-head">{t(LABELKEYS_STORE_SIZE.HEADING)}</div>
            <div className="selection-wrapper pb-5">
                <div className="store-list">
                    <ul>
                        {STORE_SIZE_DEFAULT_CONFIG?.map((e: any) => {
                            return (
                                <li key={`div${e?.fieldKey}`} onClick={StoreSizeTableClick(e?.fieldKey)} className={typeOfStore === e?.fieldKey ? "active" : ""}>
                                    <div className="img-size">
                                        <img src={storeSize === e.fieldKey ? `${e.fieldHoverImgUrl}` : `${e.fieldImgSize}`} alt={e.fieldKey} />
                                    </div>
                                    <p className="sub-title" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(t(e?.fieldLabelkey) || "") }} />
                                </li>
                            );
                        })}
                    </ul>
                </div>
            </div>
            {flagStoreSizeTable === true && <StoreSizeTable type={typeOfStore} mediumTempTable={mediumTempTable} lowTempTable={lowTempTable} tableHeader={true} />}
        </div>
    );
};

export default StoreSizeDefaultConfig;