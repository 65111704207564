import React, { useState } from "react";
import "./ResultsTable.scss";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import DOMPurify from 'dompurify';
import { ResultsCalculations } from "../../../../../services/resultsCalculations";
const ResultsTable = () => {
  const { t } = useTranslation();
  const { RESULT_TABLE, SYSTEM_ARCHS_KEYS } = useSelector((state: any) => state?.ecoEfficiency?.config || {});
  const { userInputs, tables, transactionId } = useSelector((state: any) => state?.ecoEfficiency?.calculator || {});
  const { locationAndSize, chooseSystems } = userInputs;
  const [showTable, setShowTable] = useState(true);
  const resultTable = tables?.resultsMaster?.[transactionId] || [];
  return (
    <div className="col-md-12 col-sm-12">
      <div className="toggle-table" onClick={() => setShowTable(!showTable)}>{showTable ? "Hide Table" : "View Table"}</div>
      {showTable &&
        <div className="results-table">
          <div className="panel-body">
            <div className="table-head">
              {RESULT_TABLE?.map((e: any) => {
                return (
                  e?.fieldCSSCount === 3 ?
                    <div key={`div${e?.fieldKey}`} className="table-items type col-md-3">
                      <div className="pt-2">{t(e?.fieldKey)} {" "} <span className="text-capitalize">{locationAndSize?.storeSize + " / " + locationAndSize?.country + " / " + locationAndSize?.city}</span></div>
                    </div>
                    :
                    <div key={`div${e?.fieldKey}`} className="table-items col-md-1">
                      {t(e?.fieldKey)} {" "}
                    </div>
                );
              })}
            </div>
            {Object?.keys(resultTable)?.filter(id => chooseSystems?.selectedSystems?.indexOf(id) > -1)?.map((key, index) => {
              const resultTableItem = resultTable[key];
              const mtSideValue = key === SYSTEM_ARCHS_KEYS.ARC_5 ? chooseSystems?.system[key]?.highStage : chooseSystems?.system[key]?.mtSide;
              const arcName: string = tables?.systemArchMaster?.filter(value => value?.architectureId == key)?.[0]?.architectureName || "";
              if (!(arcName?.length > 0)) {
                return;
              }
              return (
                <div className="row mx-0" key={`${key}_${index}`}>
                  <div className="table-data col-md-3"><span className="me-1">{arcName}</span><span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize((chooseSystems?.system[key]?.ltSide != mtSideValue) ? " ( " + mtSideValue + " &#47; " + chooseSystems?.system[key]?.ltSide + " ) " : " ( " + chooseSystems?.system[key]?.ltSide + " ) " || "") }} /></div>
                  <div className="table-data col-md-1">{(parseInt(Math.round(ResultsCalculations.getRoundValue(resultTableItem?.capexAbsolute, 1))?.toString())?.toLocaleString())}</div>
                  <div className="table-data col-md-1">{(parseInt(Math.round(ResultsCalculations.getRoundValue(resultTableItem?.electricityAbsolute, 1))?.toString())?.toLocaleString())}</div>
                  <div className="table-data col-md-1">{(parseInt(Math.round(ResultsCalculations.getRoundValue(resultTableItem?.opexService, 1))?.toString())?.toLocaleString())}</div>
                  <div className="table-data col-md-1">{(parseInt(Math.round(ResultsCalculations.getRoundValue(resultTableItem?.opexToppingOff, 1))?.toString())?.toLocaleString())}</div>
                  <div className="table-data col-md-1">{(parseInt(Math.round(ResultsCalculations.getRoundValue(resultTableItem?.totalOpEx, 1))?.toString())?.toLocaleString())}</div>
                  <div className="table-data col-md-1">{(parseInt(Math.round(ResultsCalculations.getRoundValue(resultTableItem?.capExPlusOpEx, 1))?.toString())?.toLocaleString())}</div>
                  <div className="table-data col-md-1">{(parseInt(Math.round(ResultsCalculations.getRoundValue(resultTableItem?.co2EmmissionElectricity, 1))?.toString())?.toLocaleString())}</div>
                  <div className="table-data col-md-1">{(parseInt(Math.round(ResultsCalculations.getRoundValue(resultTableItem?.co2EmmissionLeaks, 1)).toString()).toLocaleString())}</div>
                  <div className="table-data col-md-1">{(Math.round(resultTableItem?.totalCO2Emission))?.toLocaleString()}</div>
                </div>
              );
            })}
            {(!resultTable || Object?.keys(resultTable)?.length === 0) && <div className="table-data col-md-1">No Results</div>}
            <div>
            </div>
          </div>
        </div>
      }
    </div>
  );
};
export default ResultsTable;
