//analytics.ts - Services to push anaylytic data 
import { addAnalytics } from "../assets/js/utils";
import { store } from "../store/ecoEfficiencyStore";
export default class Analytics {
  public static sendAnalyticsData(path, eventName?: any, customData?: any) {
    // grab current state
    const state = store.getState();
    const calculator: any = state?.ecoEfficiency?.calculator || {};
    const { ANLYTICS_FIELD_KEYS } = state?.ecoEfficiency?.config || {};
    let data: any = null;
    if (path && ANLYTICS_FIELD_KEYS?.[path]?.fields?.length > 0) {
      data = {
        "event": ANLYTICS_FIELD_KEYS?.[path]?.eventName,
        "data": {
        }
      };
      ANLYTICS_FIELD_KEYS?.[path]?.fields?.map(key => {
        if (key?.storeParentKey?.length > 0 && key?.storeKey?.length > 0 && calculator?.userInputs?.[key?.storeParentKey]?.[key?.storeKey]) {
          data["data"][key?.name] = calculator?.userInputs?.[key?.storeParentKey]?.[key?.storeKey];
        }
        else if (key?.storeKey?.length > 0 && !(key?.storeParentKey?.length > 0) && calculator?.[key?.storeKey]) {
          data["data"][key?.name] = calculator[key?.storeKey];
        }
      });

    }
    if (eventName && customData) {
    
        data = {
          "event": eventName,
          "data": {           
          }
        };
        if (data["data"] && customData) {
          data["data"] = { ...data["data"], ...customData };
        }
      
    }
    if (data && addAnalytics) {
      addAnalytics(data);
    }
  }
  public static sendAnalyticsDataForError(state, errorMessage, errorMetaInfo, isErrorAction, errorAction) {
    // grab current state    
    const errorType = errorMetaInfo?.endpointName || undefined;
    const apiRequestUrl = errorMetaInfo?.url || "";
    const apiRequestInputs = errorMetaInfo?.originalArgs || "";
    const calculator = state?.calculator || {};
    const { ANLYTICS_EVENT_TYPES } = state?.config || {};
    let data: any = null;
    if (errorType && errorMessage) {
      data = {
        "event": ANLYTICS_EVENT_TYPES?.ERROR || "error",
        "data": {
          "flow": calculator?.transactionId ? "existing-session" : "new-session",
          "step": window?.location?.pathname,
          "errorType": errorType || "",
          "errorMessage": errorMessage || "",
          "apiRequestUrl": apiRequestUrl || "",
          "apiRequestInputs": apiRequestInputs || ""

        }
      };
      if (data && isErrorAction && errorAction?.length > 0) {
        data["event"] = ANLYTICS_EVENT_TYPES?.ERROR_ACTION || "error-action";
        data["data"]["errorAction"] = errorAction || "";
      }
      if (data && addAnalytics) {
        addAnalytics(data);
      }
    }
  }

}