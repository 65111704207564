import React, { useState } from "react";
import "./Results.scss";
import { useTranslation } from "react-i18next";
import { RESULTS_LABEL } from "../../../../helper/constants";
import { useSelector } from "react-redux";
import ProgressStepper from "../../../common/ProgressBar/ProgressStepper";
import ResultsTable from "./resultstable/ResultsTable";
import ResultsHeadline from "./resultsheadline/Headline";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import ResultsGraph from "./resultsgraph/ResultsGraph";
import SensitivityAnalysis from "./sensitivityanalysis/SensitivityAnalysis";
import { setCalculator } from "../../../../store/actions";
const Results = () => {
    const { t } = useTranslation();
    const { PROGRESSBAR_STEPS_VALUE_COLL, PAGE_NAVIGATION } = useSelector((state: any) => state?.ecoEfficiency?.config || {});
    const { resultsTab } = useSelector((state: any) => state?.ecoEfficiency?.calculator || {});
    const [selectedParameterTab, setSelectedParameterTab] = useState(undefined);
    const [resultHeadline, setResultHeadline] = useState(t(RESULTS_LABEL.HEADLINE));
    const handleSelect = (key) => {
        if (key === 'results') {
            setCalculator({resultsTab : key});
            setResultHeadline(t(RESULTS_LABEL.HEADLINE));
        }
        else {
            setCalculator({resultsTab : key});
            setResultHeadline(t(RESULTS_LABEL.SENSITIVITY_HEADLINE));
        }
    };
    return (
        <>
         <ProgressStepper step={''} completed={PROGRESSBAR_STEPS_VALUE_COLL.EcoEfficiency[3]} />
         <ResultsHeadline headline={resultHeadline} selectedParameterTab={selectedParameterTab} backButton={PAGE_NAVIGATION.SYSTEM_DETAILS.SIMULATION_SETUP} saveButton={PAGE_NAVIGATION.RESULTS_PAGE.SENSITIVE_ANALYSIS} />
            <Tabs defaultActiveKey="results" className="results-nav" onSelect={handleSelect}>
                <Tab eventKey="results" title={t(RESULTS_LABEL.RESULTS)}>
                    <ResultsTable />
                    <ResultsGraph/>
                </Tab>
                <Tab eventKey="sensitivity" title={t(RESULTS_LABEL.SENSITIVITY_ANALYSIS)}>
                    <SensitivityAnalysis setSelectedParameterTab={setSelectedParameterTab} currentTab={resultsTab}/>
                </Tab>
            </Tabs>
        </>
    );
};
export default Results;
