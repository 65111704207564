import React from "react";
import { useSelector } from "react-redux";
import "./ResultsPdf.scss";
//import { ImgArrowX, ImgArrowY } from "../../../../../assets/images/archSelection";
const ResultsPdf = () => {
  const { userInputs, transactionName, currentUserID,updatedOn } = useSelector((state: any) => state?.ecoEfficiency?.calculator || {});
  const { locationAndSize } = userInputs;
  const today = new Date();
  const month = today.toLocaleString('default', { month: 'short' });
  return (
    <div key={updatedOn} className="eco-results-wrapper">
      <div id="eco-resultsPdf-page1" className="eco-resultsPdf-page1">
        <div className="results-User-summary" id="resultsUserSummary">
          <div className="user-details">
            <div className="info">User ID: <span>{currentUserID || ""}</span></div>
            <div className="info">Date: <span>{month + '-' + today.getDate() + '-' + today.getFullYear()}</span> </div>
          </div>
          <div className="location-details">
            <div className="info">Session ID: <span>{transactionName || (locationAndSize?.country + " (" + locationAndSize?.city + "), " + `${locationAndSize?.storeSize.charAt(0).toUpperCase() + locationAndSize?.storeSize.slice(1).toLowerCase()} (${locationAndSize?.storeSizeValue} m2)`)}</span></div>
            <div className="info">COUNTRY: <span>{locationAndSize?.country}</span></div>
            <div className="info">CITY: <span>{locationAndSize?.city}</span> </div>
            <div className="info">STORE SIZE: <span>{locationAndSize?.storeSize}</span> </div>
          </div>
          <div className="title">RESULTS</div>
          <div className="resultsTable" id="results-tab"></div>
          <div id="group-chart-graph">
            <div className="groupChartTable"></div>
          </div>
          <div className="stackedChartTable" id="stacked-chart-graph"></div>
          <div className="bubbleChartTable" id="bubble-chart-graph"></div>
        </div>
      </div>

      <div id="eco-resultsPdf-page2" className="eco-resultsPdf-page2">
        <div className="results-User-summary" id="resultsUserSummary">
          <div className="title">SENSITIVITY ANALYSIS</div>
          <div className="sub-title">Sensitivity Parameter</div>
          <div className="sensitivityTable" id="parameter-tab"></div>
          <div className="groupChartTable1" id="group-chart-graph-1"></div>
          <div className="groupChartTable2" id="group-chart-graph-2"></div>
          <div className="stackedChartTable1" id="stacked-chart-graph-1"></div>
          <div className="stackedChartTable2" id="stacked-chart-graph-2"></div>
          <div className="bubbleChartTable1" id="bubble-chart-graph-1"></div>
          <div className="bubbleChartTable2" id="bubble-chart-graph-2"></div>
        </div>
      </div>
    </div>
  );
};

export default ResultsPdf;