import React from "react";
import { useTranslation } from "react-i18next";
import { MEDIUM_TEMP_STORE_SIZE_TABLE } from "../../../../../../helper/constants";
import "./StoreSizeTable.scss";
import { Accordion, Form } from 'react-bootstrap';
import { useSelector } from "react-redux";
const MediumTempSide:React.FC<any>  = (props) => {
  const { t } = useTranslation();   
  const { TEMPERATURE_HEADING_CONFIG_LOCATION,TEMPERATURE_HEADING_CONFIG_MT } = useSelector((state: any) => state?.ecoEfficiency?.config || {});
    return (
        <div className="store-size-table">
          {props.viewSchematic === true ? <div className="sub-head">{t(MEDIUM_TEMP_STORE_SIZE_TABLE.MT_HEADING)}{" "}</div> : <div className="sub-head">{t(MEDIUM_TEMP_STORE_SIZE_TABLE.MT_HEADING_HOME)}{" "}</div>}
            <div className="panel-body">
              {props.tableHeader ?
              <div className="table-head">
              {TEMPERATURE_HEADING_CONFIG_MT?.map((e: any) => {
              return (
                <div key={`div${e?.fieldKey}`} className="table-items">
                  {t(e?.fieldKey)}{" "}
                </div>
              );
              })}
              </div>
              :
              <div className="table-head">
              {TEMPERATURE_HEADING_CONFIG_LOCATION?.map((e: any) => {
              return (
                <div key={`div${e?.fieldKey}`} className="table-items">
                  {t(e?.fieldKey)}{" "}
                </div>
              );
              })}
              </div>
              }
              <div>
              <div className="row_entries"> 
              <Accordion flush>
                <Form className="accordion_form_main">
                {props.mediumTempTable?.map((val, key) => {
                    return (
                      <div key={key} className="accordion_div_main">
                        <Accordion.Item eventKey={String(key)} >
                          <Accordion.Header>
                          <div className="table-data">{val.case}</div>
                          <div className="table-data">{val.temprature}</div>
                          {props.tableHeader ? <div className="table-data">{val.relatedCapacity} </div> : <div className="table-data">{val.relatedCapacity} kw</div>}
                          </Accordion.Header>
                          {(props.viewSchematic === true && val.schematicGraph) && <Accordion.Body>
                            <div key={`div_synonym}`} className="arch-details">
                              <div className="table-data"><img src={val.schematicGraph} /></div>
                            </div>
                          </Accordion.Body>}
                        </Accordion.Item>
                      </div>
                    );
                  })}
                </Form>
              </Accordion>         
              </div>
            </div>
          </div>
        </div>
    );
};

export default MediumTempSide;