/****************************************************
ErrorBoundary.tsx - To handle dom level errors
*****************************************************/
import React from "react";
 /* eslint-disable */
const ErrorFallback: React.FC<any> = ({ error }) => {
  return (
    <div role="alert">
      <p>Something went wrong.Can't access the application now.</p>      
    </div>
  );
};
export default ErrorFallback;
