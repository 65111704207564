//Graph JSON
import React from 'react';
import { ResultsCalculations } from "../services/resultsCalculations";
export class GraphInputs extends React.Component {
    // Get Input JSON Group and Stack Graph
    public static getGraphJSON = (userInputs, tables, transactionId, labels) => {
        const { chooseSystems } = userInputs;
        const resultTable = tables?.[transactionId] || [];
        const systemSelected: any = [];
        const refrigerant: any = [];
        const systemBubble: any = [];
        const capexAbsolute: any = [];
        const electricityAbsolute: any = [];
        const opexService: any = [];
        const opexToppingUp: any = [];
        const emissionGroupBar: any = [];
        const emissionStack: any = [];
        const Arc_1 : any = [];
        const JSONOutput = {"label" : "", "refrigerantType" : "", "labelBubble" : "", "tempX" : "", "tempY" : "", "capex" : "", "electricity" : "", "opexService" : "", "opexToppingUp" : "", "emissionGroup" : "", "emissionStack" : "",  "ARC_1" : { "x" : null,"y" : null},  "ARC_2" : { "x" : null,"y" : null},  "ARC_3" : { "x" : null,"y" : null},  "ARC_4" : { "x" : null,"y" : null},  "ARC_5" : { "x" : null,"y" : null},  "ARC_6" : { "x" : null,"y" : null}, "ARC_8" : { "x" : null,"y" : null},  "ARC_9" : { "x" : null,"y" : null}};
  
        try {
            Object?.keys(resultTable)?.filter(id => chooseSystems?.selectedSystems?.indexOf(id) > -1)?.map((key) => {
                const omissionObj = {x: 0,y: Number(),r: 10};
                const resultTableItem = resultTable[key];
                // For Graph Label
                const arcName: string = labels?.filter(value => value?.architectureId == key)?.[0]?.architectureName || "";
                const mtSideValue= key === "ARC_5"? chooseSystems?.system[key]?.highStage:chooseSystems?.system[key]?.mtSide;
                const refrigerentType: string = (chooseSystems?.system[key]?.ltSide != mtSideValue) ? mtSideValue + " / " + chooseSystems?.system[key]?.ltSide  :  chooseSystems?.system[key]?.ltSide || "";
                if (arcName.includes('R744 Transcritical: Standard'))
                {
                    systemSelected.push("R744 Transcritical: Standard" + " [" +refrigerentType+ "]");
                    systemBubble.push("R744-Transcritical:-Standard");
                }
                else if (arcName.includes('R744 Transcritical: Booster'))
                {
                    systemSelected.push("R744 Transcritical: Parallel" + " [" +refrigerentType+ "]");
                    systemBubble.push("R744-Transcritical:-Parallel");
                }
                else
                {
                    systemSelected.push(arcName + " [" +refrigerentType+ "]");
                    const bubbleArcName = arcName.replace(/\s+/g, '-');
                    systemBubble.push(bubbleArcName);
                }
                refrigerant.push(refrigerentType);
                JSONOutput.label = systemSelected;
                JSONOutput.refrigerantType = refrigerant;
                JSONOutput.labelBubble = systemBubble;

                // For Arc_1 Heat Map
                const ARC_X_Value = (!Number.isNaN(Number(resultTableItem?.co2EmmissionBaseline))) ? ResultsCalculations.getRoundValue(parseFloat(resultTableItem?.co2EmmissionBaseline),1) : 0;
                const ARC_Y_Value = (!Number.isNaN(Number(resultTableItem?.capExPlusOpExBaseline))) ? ResultsCalculations.getRoundValue(parseFloat(resultTableItem?.capExPlusOpExBaseline),1) : 0;
                Arc_1.push(ARC_X_Value);
                Arc_1.push(ARC_Y_Value);
                JSONOutput[`${key}`].x = Arc_1[0];
                JSONOutput[`${key}`].y = Arc_1[1];
                Arc_1.pop(0);
                Arc_1.pop(1);

                // For CapEx
                const capex = (!Number.isNaN(Number(resultTableItem?.capexAbsolute))) ? Math.round(ResultsCalculations.getRoundValue(resultTableItem?.capexAbsolute, 1)) : 0;
                capexAbsolute.push(capex);
                JSONOutput.capex = capexAbsolute;

                // For OpEx - Electricity
                const electricity = (!Number.isNaN(Number(resultTableItem?.electricityAbsolute))) ? Math.round(ResultsCalculations.getRoundValue(resultTableItem?.electricityAbsolute,1)) : 0;
                electricityAbsolute.push(electricity);
                JSONOutput.electricity = electricityAbsolute;

                // For OpEx - Service
                const service = (!Number.isNaN(Number(resultTableItem?.opexService))) ? Math.round(ResultsCalculations.getRoundValue(resultTableItem?.opexService,1)) : 0;
                opexService.push(service);
                JSONOutput.opexService = opexService;

                // For OpEx - Topping Up
                const opextoppingup = (!Number.isNaN(Number(resultTableItem?.opexToppingOff))) ? Math.round(ResultsCalculations.getRoundValue(resultTableItem?.opexToppingOff,1)) : 0;
                opexToppingUp.push(opextoppingup);
                JSONOutput.opexToppingUp = opexToppingUp;

                // For Emissions
                const totalCo2Omissions = (!Number.isNaN(Number(resultTableItem?.totalCO2Emission))) ? Math.round(ResultsCalculations.getRoundValue(resultTableItem?.totalCO2Emission,1)) : 0;
                omissionObj.y = totalCo2Omissions;
                // For Emission - Group Bar
                emissionGroupBar.push(omissionObj);
                // For Emission - Stack
                emissionStack.push(totalCo2Omissions);
                JSONOutput.emissionGroup = emissionGroupBar;
                JSONOutput.emissionStack = emissionStack;
            });
        } catch (err) {
            return undefined;
        }
        
        return JSONOutput;
    };



}