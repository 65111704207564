const analyticsDataLayer = (window.dataLayer = window.dataLayer || []);
// Global addAnalytics function for adding analytic data
/* eslint-disable */
export function addAnalytics (data) {
  if (analyticsDataLayer) {
    analyticsDataLayer.push(data);
  } else {
    analyticsDataLayer = window.dataLayer = window.dataLayer || [];
    analyticsDataLayer.push(data);
  }
}
