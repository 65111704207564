//calculations for compressor setup 
import React from 'react';
export class CompressorSetUpCalculations extends React.Component {
    private static numberOr0 = num => isNaN(num) ? 0 : num;
    //Capacity calculations
    public static getCapacity = (count, rating) => {
        const capacityValue = CompressorSetUpCalculations.getRoundValue(count * rating, 1);
        return capacityValue;
    };
    //Capacity calculations - Cabinetwise
    public static getCapacityCabinetWise = (countArr, multiplierArr, noOfUnits) => {
        let capacityValue = 0;
        countArr.forEach((element, index) => {
            capacityValue += element * multiplierArr[index];
        });
        capacityValue = CompressorSetUpCalculations.getRoundValue(capacityValue * noOfUnits, 1);
        return capacityValue;
    };
    public static getTotal = (inputColl: any[]) => {
        let sumValue = 0;
        try {
            sumValue = inputColl.reduce((a, b) =>
                CompressorSetUpCalculations.numberOr0(a) + CompressorSetUpCalculations.numberOr0(b));

        } catch {
            sumValue = 0;

        }
        return CompressorSetUpCalculations.getRoundValue(sumValue, 1);
    };
    public static getRoundValue = (value, precision) => {
        const multiplier = Math.pow(10, precision || 0);
        return Math.round(value * multiplier) / multiplier;
    };
    // for cascade,micro cascade
    public static getType2TargetMTLoad = (slectedMTLoad, targetLTLoad, devideBy) => {
        if (devideBy === 0) {
            return CompressorSetUpCalculations.getRoundValue((slectedMTLoad + targetLTLoad),1);
        }
        else {
            const sumValue = slectedMTLoad + ((targetLTLoad / devideBy) + targetLTLoad);
            return CompressorSetUpCalculations.getRoundValue(sumValue, 1);
        }
    };
    //targetLTLoadWithSubCooler
    public static getArc2LTLoadWithSubCooler = (rackName, inputTable, arc, config) => {
        const { RACKS_CABINET_GROUPING, TYPE_OF_RACK } = config;
        let targetLoad = 0;
        const rackCabinetsNames = RACKS_CABINET_GROUPING?.ARC_2?.[TYPE_OF_RACK.LT]?.[rackName];
        const cabinetCapcityValues = inputTable?.filter(input => rackCabinetsNames?.indexOf(input?.cabinetId) > -1)?.map(input => input?.capacity || 0) || [];
        if (cabinetCapcityValues?.length > 0) {
            const totalCapacity = CompressorSetUpCalculations?.getTotal(cabinetCapcityValues) || 0;
            targetLoad = totalCapacity - (totalCapacity * (parseInt(arc?.subCooler) / 100));
        }
        return CompressorSetUpCalculations.getRoundValue?.(targetLoad, 1) || 0;
    };
    public static getArc2MTRackLoad = (rackName, inputTable, config) => {
        const { RACKS_CABINET_GROUPING, TYPE_OF_RACK } = config;
        let targetLoad = 0;
        const rackCabinetsNames = RACKS_CABINET_GROUPING?.ARC_2?.[TYPE_OF_RACK.MT]?.[rackName];
        const cabinetCapcityValues = inputTable?.filter(input => rackCabinetsNames?.indexOf(input?.cabinetId) > -1)?.map(input => input?.capacity || 0) || [];
        if (cabinetCapcityValues?.length > 0) {
            targetLoad = CompressorSetUpCalculations?.getTotal(cabinetCapcityValues) || 0;
        }
        return CompressorSetUpCalculations.getRoundValue?.(targetLoad, 1) || 0;
    };
    public static targetARC_2MTLoadWithShift = (selectedRack, loadSetup, locationAndSize, ARC_2, config) => {
        const { TYPE_OF_RACK, COMPRESSOR_ARCHS_CONFIG } = config;
        let targetLoad = 0;
        const targetLoadLT: any = [];
        const inputTable = loadSetup?.loadInputs;
        const slectedMTLoad = loadSetup?.selectedMTLoad || 0;
        const slectedLTLoad = loadSetup?.selectedLTLoad || 0;
        const currentRackLoad = CompressorSetUpCalculations.getArc2MTRackLoad(selectedRack, inputTable, config);
        const allLTRackNames = COMPRESSOR_ARCHS_CONFIG?.["ARC_2"]?.[locationAndSize?.storeSize]?.[TYPE_OF_RACK.LT]?.Racks || [];
        allLTRackNames?.forEach(rack => {
            targetLoadLT.push(CompressorSetUpCalculations.getArc2LTLoadWithSubCooler(rack, inputTable, ARC_2, config));
        });
        targetLoad = ((slectedMTLoad + (slectedLTLoad - CompressorSetUpCalculations?.getTotal(targetLoadLT))) * (currentRackLoad / slectedMTLoad));
        return CompressorSetUpCalculations.getRoundValue?.(targetLoad, 1) || 0;
    };
    public static isDeviated = (deviationPoint, value, targetValue) => {
        const isHigh = ((parseFloat(value) > ((parseFloat(targetValue) + (parseFloat(targetValue) * (deviationPoint / 100))))));
        const isLow = ((parseFloat(value) < ((parseFloat(targetValue) - (parseFloat(targetValue) * (deviationPoint / 100))))));
        return isHigh || isLow || !value || !targetValue;
    };
    public static getLoadShiftValue = (loadShift) => {
        let shiftValue = 0;
        shiftValue = parseFloat(loadShift) / 100;
        return shiftValue;
    };
}