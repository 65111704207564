//data api calls 
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
export const sessionsApi = createApi({
    reducerPath: 'sessionsApi',
    baseQuery: fetchBaseQuery({
        baseUrl: '/api/sessions',
    }),
    endpoints: (builder) => ({
        getSessions: builder.query<{ response?: any, isSuccess: any, result: any }, any>({
            query: params => ({
                url: '/get-sessions',
                method: 'POST',
                body: params
            }),
            transformResponse: (response: { message: any }) => {
                return response.message;
            }
            ,
        }),
        saveSession: builder.query<{ response?: any, isSuccess: any, result: any }, any>({
            query: params => ({
                url: '/save-session',
                method: 'POST',
                body: params
            }),
            transformResponse: (response: { message: any }) => {
                return response.message;
            }
            ,
        })
    }),
});
export const { useGetSessionsQuery,useSaveSessionQuery } = sessionsApi;