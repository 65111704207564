/**********************************************************************************************
ProgressStepper.tsx - Progrss bar componenet to show the progress
***********************************************************************************************/
import React from "react";
import StepNavigation from "./StepNavigation";
import ProgressBar from "react-bootstrap/ProgressBar";
import "./ProgressBar.scss";
import { useTranslation } from "react-i18next";
import { PROGRESS_BAR } from "../../../helper/constants";
import { useSelector } from "react-redux";
const ProgressStepper:React.FC<any> = (props) => {
  const { t } = useTranslation();
  const { PROGRESSBAR_STEPS_VALUE_COLL } = useSelector((state: any) => state?.ecoEfficiency?.config || {});
  const activeStage = props?.completed || "";
  const activeIndex = PROGRESSBAR_STEPS_VALUE_COLL.EcoEfficiency?.indexOf(activeStage) || 0;
  return (
    <div>
      <div className="mob-progress-bar">
        <div className="content">{t(PROGRESS_BAR.SHOW)} {activeIndex + 1} {t(PROGRESS_BAR.OF)} {PROGRESSBAR_STEPS_VALUE_COLL.EcoEfficiency?.length}</div>
        <div className="bar"><ProgressBar now={((activeIndex+1)/PROGRESSBAR_STEPS_VALUE_COLL.EcoEfficiency?.length)*100} /></div>
      </div>
      <StepNavigation labelArray={PROGRESSBAR_STEPS_VALUE_COLL.EcoEfficiency} activeIndex={activeIndex} />
    </div>
  );
};
export default ProgressStepper;
