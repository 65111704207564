import React, { useEffect, useCallback, useMemo } from "react";
import "./ParameterSetup.scss";
import { skipToken } from "@reduxjs/toolkit/query";
import { LABELKEYS_SYSTEM_LOAD, LABELKEYS_PARAMETER_SETUP, REGEX_ITEMS, LABELKEYS_COMPRESSOR_SETUP, LABEL_KEYS_LOADER } from "../../../../../helper/constants";
import { useTranslation } from "react-i18next";
import ProgressStepper from "../../../../common/ProgressBar/ProgressStepper";
import Headline from "../../../../common/Headline/Headline";
import TabStepper from "../../../../common/TabContainer/TabStepper";
import { useSelector } from "react-redux";
import { BiChevronDown } from "react-icons/bi";
import { useGetDataQuery } from "../../../../../services/data";
import { setParameterSetup, setTables } from "../../../../../store/actions";
import Loader from "../../../../common/Loader/Loader";
import { ImgWarning } from "../../../../../assets/images/archSelection";
const ParameterSetup = () => {
    const { t } = useTranslation();
    const { SYSTEM_ARCHS_PRE_CALCULATION_REACT_MS_MAPPING,SERVICE_REQUEST_FETCH_DATA_TYPES, APPROACH_TEMPERATURE_DATA_JSON, SATURATION_TEMPERATURE_MT_DATA_JSON, SATURATION_TEMPERATURE_LT_DATA_JSON, LOAD_IN_DATA_JSON, PROGRESSBAR_STEPS_VALUE_COLL, PAGE_NAVIGATION, SET_EXPANSION_VALVE, PARAMETER_DEFAULT_CONFIG, SYSTEM_ARCHS_KEYS, EXCLUDE_ARC_TYPE, RESTORE_SESSION_PAGE_IDS, RESTORE_SESSION_PARAMETER_TYPE, TYPE_OF_RACK } = useSelector((state: any) => state?.ecoEfficiency?.config || {});
    const { userInputs, tables, updatedOn,restoreTransactionId, restoredSessions, restoreSessionData } = useSelector((state: any) => state?.ecoEfficiency?.calculator || {});
    const { chooseSystems, parameterSetup } = userInputs;
    const containsAll = chooseSystems?.filteredSystems?.filter(value => EXCLUDE_ARC_TYPE.includes(value));

    /*------Data Query -----*/
    //Parameter Setup data
    const { data: parameterData, isLoading: isParameterDataLoading } = useGetDataQuery(tables?.parameterSetupMaster ? skipToken : {
        dataType: SERVICE_REQUEST_FETCH_DATA_TYPES.DEFAULT_VALUES,
        path: "parameter setup",
    });

    useEffect(() => {
        if (parameterData?.result?.data && !tables?.parameterSetupMaster) {
            setTables({ parameterSetupMaster: parameterData?.result?.data });
        }
        // eslint-disable-next-line 
    }, [parameterData?.result?.data]);

    //restore session
    useEffect(() => {
        if(restoreTransactionId && !restoredSessions?.includes(RESTORE_SESSION_PAGE_IDS?.PARAM_SETUP))
        {
            const paramTable = restoreSessionData?.[restoreTransactionId]?.[RESTORE_SESSION_PAGE_IDS?.PARAM_SETUP] ? restoreSessionData?.[restoreTransactionId]?.[RESTORE_SESSION_PAGE_IDS?.PARAM_SETUP] : "";
            setParameterSetupValues(paramTable, RESTORE_SESSION_PARAMETER_TYPE.restore);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const setParameterSetupValues = (paramTable, flag) => {
        if(paramTable) {

            const parameterSetupCondensingTemperature = {...parameterSetup?.minimumCondensingTemperature?.minimumCondensingTemperature};
            let minimumCondensingTemperature: any = {...parameterSetup?.minimumCondensingTemperature?.minimumCondensingTemperature};
            let expValue: any = {...parameterSetup?.minimumCondensingTemperature?.expValue};
            let mtArr: any = {...parameterSetup?.saturationTemperatureSuction};
            let ltArr: any = {...parameterSetup?.saturationTemperatureLiquid};
            let checkExist: any = true;
            Object.keys(paramTable)?.filter(value => chooseSystems?.selectedSystems?.filter(val => (containsAll.length == 2) ? val != SYSTEM_ARCHS_KEYS.ARC_9 : SYSTEM_ARCHS_KEYS.ARC_8)?.indexOf(value) > -1)?.map((sysval: any) => {
                const restoreSysVal = (sysval == SYSTEM_ARCHS_KEYS.ARC_9) ? SYSTEM_ARCHS_KEYS.ARC_8 : sysval;
                sysval = (flag == RESTORE_SESSION_PARAMETER_TYPE.restore) ? sysval : ((sysval == SYSTEM_ARCHS_KEYS.ARC_9) ? SYSTEM_ARCHS_KEYS.ARC_8 : sysval);
             
                if(!parameterSetupCondensingTemperature[sysval]) {
                    const minCond = (flag == RESTORE_SESSION_PARAMETER_TYPE.restore) ? paramTable[sysval].min_cond : paramTable[sysval].minCond;
                    minimumCondensingTemperature = { ...minimumCondensingTemperature, [restoreSysVal]: minCond };
                    const defaultExpValue = (flag == RESTORE_SESSION_PARAMETER_TYPE.restore) ? ((paramTable[sysval].env_type == SET_EXPANSION_VALVE[0]["value"]) ? SET_EXPANSION_VALVE[0]["value"] : SET_EXPANSION_VALVE[1]["value"]) : (minCond == paramTable[sysval].txv) ? SET_EXPANSION_VALVE[0]["value"] : SET_EXPANSION_VALVE[1]["value"];
                    expValue = { ...expValue, [restoreSysVal]: defaultExpValue };
                    const mtParam = (flag == RESTORE_SESSION_PARAMETER_TYPE.restore) ? TYPE_OF_RACK.MT : TYPE_OF_RACK.MT.toLowerCase();
                    const ltParam = (flag == RESTORE_SESSION_PARAMETER_TYPE.restore) ? TYPE_OF_RACK.LT : TYPE_OF_RACK.LT.toLowerCase();
                    if(paramTable[sysval] && paramTable[sysval]?.[mtParam]) {
                        Object.keys(paramTable[sysval]?.[mtParam])?.map((val: any) => {
                            const mtVal = (flag == RESTORE_SESSION_PARAMETER_TYPE.restore) ? RESTORE_SESSION_PARAMETER_TYPE[val] : val;
                            mtArr = { ...mtArr, [mtVal]: { ...mtArr?.[mtVal], [restoreSysVal]: paramTable[sysval]?.[mtParam]?.[val] } };
                        });
                    }
                    if(paramTable[sysval] && paramTable[sysval]?.[ltParam]) {
                        Object.keys(paramTable[sysval]?.[ltParam])?.map((val: any) => {
                            const ltVal = (flag == RESTORE_SESSION_PARAMETER_TYPE.restore) ? RESTORE_SESSION_PARAMETER_TYPE[val] : val;
                            ltArr = { ...ltArr, [ltVal]: { ...ltArr?.[ltVal], [restoreSysVal]: paramTable[sysval]?.[ltParam]?.[val] } };
                        });
                    }
                    checkExist = false;
                }
            });
            if(!checkExist) {
                if(flag == RESTORE_SESSION_PARAMETER_TYPE.restore) {
                    setParameterSetup({ ...parameterSetup, minimumCondensingTemperature: { ...parameterSetup.minimumCondensingTemperature, minimumCondensingTemperature, expValue: expValue }, saturationTemperatureSuction: mtArr, saturationTemperatureLiquid: ltArr, percentageLoadInDayAndNight: { "dayLoad": paramTable.day_load, "nightLoad": paramTable.night_load } });
                } else {
                    setParameterSetup({ ...parameterSetup, minimumCondensingTemperature: { ...parameterSetup.minimumCondensingTemperature, minimumCondensingTemperature, expValue: expValue }, saturationTemperatureSuction: mtArr, saturationTemperatureLiquid: ltArr });
                }
            }
            (flag == RESTORE_SESSION_PARAMETER_TYPE.restore) ? restoredSessions.push(RESTORE_SESSION_PAGE_IDS?.PARAM_SETUP) : "";
        }
    };
    useEffect(() => {
        if (tables?.parameterSetupMaster && ((restoredSessions.length == 0) || (!restoreTransactionId) || (restoreTransactionId && restoredSessions.length > 0 && restoredSessions?.includes(RESTORE_SESSION_PAGE_IDS?.PARAM_SETUP)))) {
            setParameterSetupValues(tables?.parameterSetupMaster, RESTORE_SESSION_PARAMETER_TYPE.new);            
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tables?.parameterSetupMaster, chooseSystems]);
    const setInputValue = useCallback((fieldId: string, fieldKey: string, archId: any) => (e: React.ChangeEvent<HTMLInputElement>) => {
        const isValid = REGEX_ITEMS?.NUMBER?.test(e?.target?.value);
        if (e?.target?.value?.length === 0 || isValid || e?.target?.value?.match(REGEX_ITEMS?.FLOAT_NUMBER)) {
            let tmpParameterSetupType;
            if (archId) {
                tmpParameterSetupType = { ...parameterSetup?.[fieldKey] };
                tmpParameterSetupType = { ...tmpParameterSetupType, [fieldId]: { ...parameterSetup?.[fieldKey][fieldId], [archId]: e?.target?.value } };
            } else {
                tmpParameterSetupType = { ...parameterSetup?.[fieldKey] };
                tmpParameterSetupType = { ...tmpParameterSetupType, [fieldId]: e?.target?.value };
            }
            setParameterSetup({ ...parameterSetup, [fieldKey]: tmpParameterSetupType });
        }
    }, [parameterSetup]);

    const setSelectValue = useCallback((fieldId: string, fieldKey: string, archId: any) => (e: React.ChangeEvent<HTMLSelectElement>) => {
        let tmpParameterSetupType = { ...parameterSetup?.[fieldKey] };
        tmpParameterSetupType = { ...tmpParameterSetupType, [fieldId]: { ...parameterSetup?.[fieldKey][fieldId], [archId]: e?.target?.value }, ["minimumCondensingTemperature"]: { ...parameterSetup?.[fieldKey]["minimumCondensingTemperature"], [archId]: tables?.parameterSetupMaster[archId][e?.target?.value.toLowerCase()] } };
        setParameterSetup({ ...parameterSetup, [fieldKey]: tmpParameterSetupType });
    }, [parameterSetup, tables?.parameterSetupMaster]);

    //reset parameter setup values
    const setDefault = () => {
        if (tables?.parameterSetupMaster) {
            let minimumCondensingTemperature: any = {};
            let expValue: any = {};
            let mtArr: any = {};
            let ltArr: any = {};
            Object.keys(tables?.parameterSetupMaster)?.filter(value => chooseSystems?.selectedSystems?.filter(val => (containsAll.length == 2) ? val != SYSTEM_ARCHS_KEYS.ARC_9 : SYSTEM_ARCHS_KEYS.ARC_8)?.indexOf(value) > -1)?.map((sysval: any) => {
                sysval = (sysval == SYSTEM_ARCHS_KEYS.ARC_9) ? SYSTEM_ARCHS_KEYS.ARC_8 : sysval;
                minimumCondensingTemperature = { ...minimumCondensingTemperature, [sysval]: tables?.parameterSetupMaster[sysval].minCond };
                const defaultExpValue = (tables?.parameterSetupMaster[sysval].minCond == tables?.parameterSetupMaster[sysval].txv) ? SET_EXPANSION_VALVE[0]["value"] : SET_EXPANSION_VALVE[1]["value"];
                expValue = { ...expValue, [sysval]: defaultExpValue };
                Object.keys(tables?.parameterSetupMaster[sysval]?.mt)?.map((val: any) => {
                    mtArr = { ...mtArr, [val]: { ...mtArr?.[val], [sysval]: tables?.parameterSetupMaster[sysval]?.mt?.[val] } };
                });
                Object.keys(tables?.parameterSetupMaster[sysval]?.lt)?.map((val: any) => {
                    ltArr = { ...ltArr, [val]: { ...ltArr?.[val], [sysval]: tables?.parameterSetupMaster[sysval]?.lt?.[val] } };
                });
            });
            setParameterSetup({ ...parameterSetup, minimumCondensingTemperature: { ...parameterSetup.minimumCondensingTemperature, minimumCondensingTemperature, expValue: expValue }, saturationTemperatureSuction: mtArr, saturationTemperatureLiquid: ltArr, percentageLoadInDayAndNight: { "dayLoad": PARAMETER_DEFAULT_CONFIG.DAY_LOAD, "nightLoad": PARAMETER_DEFAULT_CONFIG.NIGHT_LOAD } });
        }
    };

    const inParameterRange = useCallback((num, a, b) => {
        return (Math.min(a, b) <= num && num <= Math.max(a, b));
    }, []);

    const isButtonDisabled = useMemo(() => {
        let isValid = true;
        APPROACH_TEMPERATURE_DATA_JSON?.map((val) => {
            tables?.systemArchMaster?.filter(value => chooseSystems?.selectedSystems?.filter(val => (containsAll.length == 2) ? val != SYSTEM_ARCHS_KEYS.ARC_9 : SYSTEM_ARCHS_KEYS.ARC_8)?.indexOf(value?.architectureId) > -1)?.map((sysval: any) => {
                const arcID = (sysval?.architectureId == SYSTEM_ARCHS_KEYS.ARC_9) ? SYSTEM_ARCHS_KEYS.ARC_8 : sysval?.architectureId;
                if (val.key == "minimumCondensingTemperature" && parameterSetup?.minimumCondensingTemperature?.[val.key] && !inParameterRange(parseInt(parameterSetup?.minimumCondensingTemperature?.[val.key][arcID]), parseInt(val?.TempFrom), parseInt(val?.TempTo))) {
                    isValid = false;
                }
            });
        });
        SATURATION_TEMPERATURE_MT_DATA_JSON?.map((val) => {
            tables?.systemArchMaster?.filter(value => chooseSystems?.selectedSystems?.filter(val => (containsAll.length == 2) ? val != SYSTEM_ARCHS_KEYS.ARC_9 : SYSTEM_ARCHS_KEYS.ARC_8)?.indexOf(value?.architectureId) > -1)?.map((sysval: any) => {
                const arcID = (sysval?.architectureId == SYSTEM_ARCHS_KEYS.ARC_9) ? SYSTEM_ARCHS_KEYS.ARC_8 : sysval?.architectureId;
                if (parameterSetup?.saturationTemperatureSuction?.[val.key] && !inParameterRange(parseFloat(parameterSetup?.saturationTemperatureSuction?.[val.key][arcID]), parseFloat(val?.SaturationFrom), parseFloat(val?.SaturationTo))) {
                    isValid = false;
                }
            });
        });
        SATURATION_TEMPERATURE_LT_DATA_JSON?.map((val) => {
            tables?.systemArchMaster?.filter(value => chooseSystems?.selectedSystems?.filter(val => (containsAll.length == 2) ? val != SYSTEM_ARCHS_KEYS.ARC_9 : SYSTEM_ARCHS_KEYS.ARC_8)?.indexOf(value?.architectureId) > -1)?.map((sysval: any) => {
                const arcID = (sysval?.architectureId == SYSTEM_ARCHS_KEYS.ARC_9) ? SYSTEM_ARCHS_KEYS.ARC_8 : sysval?.architectureId;
                if (parameterSetup?.saturationTemperatureLiquid?.[val.key] && !inParameterRange(parseFloat(parameterSetup?.saturationTemperatureLiquid?.[val.key][arcID]), parseFloat(val?.SaturationFrom), parseFloat(val?.SaturationTo))) {
                    isValid = false;
                }
            });
        });
        LOAD_IN_DATA_JSON?.map((val) => {
            if (parameterSetup?.percentageLoadInDayAndNight?.[val.key] == "" || parameterSetup?.percentageLoadInDayAndNight?.[val.key] && !inParameterRange(parseInt(parameterSetup?.percentageLoadInDayAndNight?.[val.key]), parseInt(val?.LoadFrom), parseInt(val?.LoadTo))) {
                isValid = false;
            }
        });
        return isValid;

    }, [inParameterRange, tables?.systemArchMaster, chooseSystems?.selectedSystems, parameterSetup?.minimumCondensingTemperature, parameterSetup?.saturationTemperatureLiquid, parameterSetup?.saturationTemperatureSuction, parameterSetup?.percentageLoadInDayAndNight, containsAll, APPROACH_TEMPERATURE_DATA_JSON, SATURATION_TEMPERATURE_MT_DATA_JSON, SATURATION_TEMPERATURE_LT_DATA_JSON, LOAD_IN_DATA_JSON, SYSTEM_ARCHS_KEYS]);
    return (
        <div>
            <ProgressStepper step={''} completed={PROGRESSBAR_STEPS_VALUE_COLL.EcoEfficiency[2]} />
            <Headline key={`ps_${updatedOn}`} calculationId={SYSTEM_ARCHS_PRE_CALCULATION_REACT_MS_MAPPING?.PARAM_SETUP} headline={t(LABELKEYS_PARAMETER_SETUP.HEADING)} backButton={PAGE_NAVIGATION.SYSTEM_DETAILS.COMPRESSOR_SETUP.R744_TRANSCRITICAL} continueButton={PAGE_NAVIGATION.SYSTEM_DETAILS.SIMULATION_SETUP} isDisable={!isButtonDisabled} />
            <TabStepper step={''} completed={PROGRESSBAR_STEPS_VALUE_COLL.EcoEfficiencyTabContainer[3]} calltoAction={t(LABELKEYS_SYSTEM_LOAD.RESET)} labelArray={PROGRESSBAR_STEPS_VALUE_COLL.EcoEfficiencyTabContainer} onResetClick={setDefault} />
            {!isButtonDisabled && <div className="system-warning"><img src={ImgWarning} alt="Warning" /> <span>{t(LABELKEYS_PARAMETER_SETUP.WARNING)}</span></div>}
            {isParameterDataLoading ? <Loader message={t(LABEL_KEYS_LOADER.LOADING)} /> :
                <div className="row mt-3">
                    <div className="col-md-12 col-xs-12">
                        <div className="parameter-setup-table">
                            <div className="panel-body">
                                <div className="table-head">
                                    <div className="table-items expansion-type col-md-3">
                                    </div>
                                    {tables?.systemArchMaster?.filter(value => chooseSystems?.selectedSystems?.filter(val => (containsAll.length == 2) ? val != SYSTEM_ARCHS_KEYS.ARC_9 : SYSTEM_ARCHS_KEYS.ARC_8)?.indexOf(value?.architectureId) > -1)?.map((val: any, key) => {
                                        return (
                                            <div key={key} className="table-items col-md-1">
                                                {val.architectureName?.includes("R744 Transcritical")           
                                                ?  
                                                "R744 Transcritical" : val.architectureName
                                                }
                                            </div>
                                        );
                                    })}
                                </div>
                                {APPROACH_TEMPERATURE_DATA_JSON?.map((val, key) => {
                                    return (
                                        <div key={key} className="d-flex">
                                            <div className="table-data expansion-type col-md-3">{val.ApproachTemperatureName} <span className="small-text">{val.SpanName}</span></div>
                                            {tables?.systemArchMaster?.filter(value => chooseSystems?.selectedSystems?.filter(val => (containsAll.length == 2) ? val != SYSTEM_ARCHS_KEYS.ARC_9 : SYSTEM_ARCHS_KEYS.ARC_8)?.indexOf(value?.architectureId) > -1)?.map((sysval: any, syskey) => {
                                                const arcID = (sysval?.architectureId == SYSTEM_ARCHS_KEYS.ARC_9) ? SYSTEM_ARCHS_KEYS.ARC_8 : sysval?.architectureId;
                                                return (
                                                    <>
                                                        {val.type === "select" ?
                                                            <div key={syskey} className={arcID != SYSTEM_ARCHS_KEYS.ARC_8 && arcID != SYSTEM_ARCHS_KEYS.ARC_9 ? "table-data col-md-1" : "table-data col-md-1"}>
                                                                {(arcID != SYSTEM_ARCHS_KEYS.ARC_8 && arcID != SYSTEM_ARCHS_KEYS.ARC_9) ?
                                                                    <div className="form-group">
                                                                        <>
                                                                            <select className="form-control" placeholder="" aria-label="APPROACH TEMPERATURE" onChange={setSelectValue(val.key, "minimumCondensingTemperature", arcID)} value={parameterSetup?.minimumCondensingTemperature?.[val.key] && parameterSetup?.minimumCondensingTemperature?.[val.key][arcID] || ""}>
                                                                                {SET_EXPANSION_VALVE?.map((item, key) =>
                                                                                    <option key={key} value={item.value}>{item.value}</option>
                                                                                )}
                                                                            </select>
                                                                            <i className="angle-down-arrow"><BiChevronDown className="angle-down-icon" /></i>
                                                                        </>
                                                                    </div> : <input type="text" aria-label="APPROACH TEMPERATURE" value={SET_EXPANSION_VALVE[1]["value"]} readOnly />}
                                                            </div>
                                                            :
                                                            <div key={syskey} className="table-data col-md-1">
                                                                <input type="text" aria-label="APPROACH TEMPERATURE" className={(parameterSetup?.minimumCondensingTemperature?.[val.key] && inParameterRange(parseInt(parameterSetup?.minimumCondensingTemperature?.[val.key][arcID]), parseInt(val?.TempFrom), parseInt(val?.TempTo))) ? "" : "error"} onChange={setInputValue(val.key, "minimumCondensingTemperature", arcID)} value={parameterSetup?.minimumCondensingTemperature?.[val.key] && parameterSetup?.minimumCondensingTemperature?.[val.key][arcID] || ""} />
                                                            </div>
                                                        }
                                                    </>
                                                );
                                            }
                                            )}
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>

                    <div className="parameter-title">{t(LABELKEYS_COMPRESSOR_SETUP.MEDIUM_TEMPERATURE_HEADING)}</div>
                    <div className="col-md-12 col-xs-12">
                        <div className="parameter-setup-table">
                            <div className="panel-body">
                                {SATURATION_TEMPERATURE_MT_DATA_JSON?.map((val, key) => {
                                    return (
                                        <div key={key} className="d-flex">
                                            <div className="table-data col-md-4">{val.SaturationTemperatureName}<span className="small-text">{val.SpanName}</span></div>
                                            {tables?.systemArchMaster?.filter(value => chooseSystems?.selectedSystems?.filter(val => (containsAll.length == 2) ? val != SYSTEM_ARCHS_KEYS.ARC_9 : SYSTEM_ARCHS_KEYS.ARC_8)?.indexOf(value?.architectureId) > -1)?.map((sysval: any, syskey) => {
                                                const arcID = (sysval?.architectureId == SYSTEM_ARCHS_KEYS.ARC_9) ? SYSTEM_ARCHS_KEYS.ARC_8 : sysval?.architectureId;
                                                return (
                                                    <>
                                                        <div key={syskey} className="table-data col-md-1"><input type="text" aria-label="SATURATION TEMPERATURE" className={(parameterSetup?.saturationTemperatureSuction?.[val.key] && inParameterRange(parseFloat(parameterSetup?.saturationTemperatureSuction?.[val.key][arcID]), parseFloat(val?.SaturationFrom), parseFloat(val?.SaturationTo))) ? "" : "error"} onChange={setInputValue(val.key, "saturationTemperatureSuction", arcID)} value={parameterSetup?.saturationTemperatureSuction?.[val.key] && parameterSetup?.saturationTemperatureSuction?.[val.key][arcID] || ""} /></div>
                                                    </>
                                                    );
                                                }
                                            )}
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>

                    <div className="parameter-title">{t(LABELKEYS_COMPRESSOR_SETUP.LOW_TEMPERATURE_HEADING)}</div>
                    <div className="col-md-12 col-xs-12">
                        <div className="parameter-setup-table">
                            <div className="panel-body">
                                <div className="table-head no-border">
                                </div>
                                {SATURATION_TEMPERATURE_LT_DATA_JSON?.map((val, key) => {
                                    return (
                                        <div key={key} className="d-flex">
                                            <div className="table-data col-md-4">{val.SaturationTemperatureName}<span className="small-text">{val.SpanName}</span></div>
                                            {tables?.systemArchMaster?.filter(value => chooseSystems?.selectedSystems?.filter(val => (containsAll.length == 2) ? val != SYSTEM_ARCHS_KEYS.ARC_9 : SYSTEM_ARCHS_KEYS.ARC_8)?.indexOf(value?.architectureId) > -1)?.map((sysval: any, syskey) => {
                                                const arcID = (sysval?.architectureId == SYSTEM_ARCHS_KEYS.ARC_9) ? SYSTEM_ARCHS_KEYS.ARC_8 : sysval?.architectureId;
                                                return (
                                                    <>
                                                        <div key={syskey} className="table-data col-md-1">
                                                            {arcID === "ARC_8" || arcID === "ARC_9" ? val.onHide === "ARC_8" ? "" : 
                                                            <input type="text" aria-label="SATURATION TEMPERATURE" className={(parameterSetup?.saturationTemperatureLiquid?.[val.key] && inParameterRange(parseFloat(parameterSetup?.saturationTemperatureLiquid?.[val.key][arcID]), parseFloat(val?.SaturationFrom), parseFloat(val?.SaturationTo))) ? "" : "error"} onChange={setInputValue(val.key, "saturationTemperatureLiquid", arcID)} value={parameterSetup?.saturationTemperatureLiquid?.[val.key] && parameterSetup?.saturationTemperatureLiquid?.[val.key][arcID] || ""} />
                                                            :
                                                            <input type="text" aria-label="SATURATION TEMPERATURE" className={(parameterSetup?.saturationTemperatureLiquid?.[val.key] && inParameterRange(parseFloat(parameterSetup?.saturationTemperatureLiquid?.[val.key][arcID]), parseFloat(val?.SaturationFrom), parseFloat(val?.SaturationTo))) ? "" : "error"} onChange={setInputValue(val.key, "saturationTemperatureLiquid", arcID)} value={parameterSetup?.saturationTemperatureLiquid?.[val.key] && parameterSetup?.saturationTemperatureLiquid?.[val.key][arcID] || ""} />
                                                            }
                                                        </div>
                                                        
                                                        </>
                                                    );
                                                }
                                            )}
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>

                    <div className="parameter-title">{t(LABELKEYS_PARAMETER_SETUP.DAYLOAD)}</div>
                    <div className="col-md-12 col-xs-12">
                        <div className="parameter-setup-table">
                            <div className="panel-body">
                                <div className="table-head no-border">
                                </div>
                                {LOAD_IN_DATA_JSON?.map((val, key) => {
                                    return (
                                        <div key={key} className="d-flex">
                                            <div className="table-data col-md-2">{val.LoadInName}<span className="small-text">{val.SpanName}</span></div>
                                            <div className="table-data col-md-2"><input type="text" aria-label="LOAD IN DAY AND NIGHT" className={(parameterSetup?.percentageLoadInDayAndNight?.[val.key] && inParameterRange(parseInt(parameterSetup?.percentageLoadInDayAndNight?.[val.key]), parseInt(val?.LoadFrom), parseInt(val?.LoadTo))) ? "" : "error"} onChange={setInputValue(val.key, "percentageLoadInDayAndNight", "")} value={parameterSetup.percentageLoadInDayAndNight[val.key] || ""} /></div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>}
        </div>
    );
};

export default ParameterSetup;