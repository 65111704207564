import React, { useState, useMemo, useEffect, useCallback } from "react";
import { BiChevronDown } from "react-icons/bi";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { RESULTS_LABEL, LABELKEYS_SYSTEM_LOAD, LABELKEYS_STORE_SIZE, SENSITIVITY_PARAMETER_LABEL, REGEX_VALIDATION } from "../../../../../helper/constants";
import { sensitivityAnalysisApi } from "../../../../../services/sensitivityAnalysis";
import { calculateApi } from "../../../../../services/calculate";
import GroupBarGraph from "../../../../common/Graph/GroupBarGraph/GroupBarGraph";
import StackedGraph from "../../../../common/Graph/StackedGraph/StackedGraph";
import BubbleGraph from "../../../../common/Graph/BubbleGraph/BubbleGraph";
import DOMPurify from 'dompurify';
import { setSensitivityAnalysis, setTables, setCalculator } from "../../../../../store/actions";
import { ResultsCalculations } from "../../../../../services/resultsCalculations";
import { store } from '../../../../../store/ecoEfficiencyStore';
import Loader from "../../../../common/Loader/Loader";
import { SensitivityAnalysisInputs } from "../../../../../services/sensitivityAnalysisInputs";
import { GraphInputs } from "../../../../../services/graphInputs";
import { ImgArrowX, ImgArrowY } from "../../../../../assets/images/archSelection";
import "../Results.scss";
const SensitivityAnalysis = (props) => {
    const { t } = useTranslation();
    const { config } = useSelector((state: any) => state?.ecoEfficiency || {});
    const [sensitivityAnalysisLoading, setSensitivityAnalysisLoading] = useState(false);
    const { userInputs, tables, transactionId } = useSelector((state: any) => state?.ecoEfficiency?.calculator || {});
    const { locationAndSize, chooseSystems, simulationSetup, sensitivityAnalysis } = userInputs;
    const resultTable = tables?.resultsMaster?.[transactionId] || [];
    const [showParameter, setShowParameter] = useState("");
    const [parameterName, setParameterName] = useState("");
    const [showSensitivityGraph, setSensitivityGraph] = useState(false);
    const handleChanged = useCallback((e) => {
        const getParameter = e.target.value;
        setShowParameter(getParameter);
    }, [setShowParameter]);
    const { SENSITIVITY_REFRIGERANT_TABLE_HEADER, SENSITIVITY_ELECT_INTENSITY_TABLE_HEADER, SENSITIVITY_ELECT_PRICE_TABLE_HEADER, SENSITIVITY_LIFESPAN_TABLE_HEADER, SENSITIVITY_LEAKAGE_TABLE_HEADER, SENSITIVITY_PARAMETER, CONFIG_SIMULATION_SETUP_SELECT_FIELD, REFRIGERANT_PRICE_OPTIONS, ELECTRICITY_PRICE_RANGES, SENSITIVITY_LEAKAGE_RATE_OPTIONS, SYSTEM_ARCHS_KEYS, CASCADE_MTSIDE_VALUES } = useSelector((state: any) => state?.ecoEfficiency?.config || {});

    useEffect(() => {
        let refrigerantChangePricePercentage: any = {};
        let leakageRatePercentage: any = {};
        Object.keys(sensitivityAnalysis?.refrigerantChangePricePercentage)?.map((sysval: any) => {
            refrigerantChangePricePercentage = { ...refrigerantChangePricePercentage, [sysval]: "0" };
        });
        Object.keys(sensitivityAnalysis?.leakageRatePercentage)?.map((sysval: any) => {
            leakageRatePercentage = { ...leakageRatePercentage, [sysval]: "0" };
        });
        setSensitivityAnalysis({ ...simulationSetup, refrigerantChangePricePercentage: refrigerantChangePricePercentage, leakageRatePercentage: leakageRatePercentage });
        // eslint-disable-next-line 
    }, [simulationSetup]);

    // selected refrigents
    const selectedRefrigents = useMemo(() => {
        const refrigentsSelected: any = [];
        chooseSystems?.selectedSystems?.map((val) => {
            if (chooseSystems?.system?.[val]) {
                ["ltSide", "mtSide", "highStage"].map((key) => {
                    const refrigenValue: any = chooseSystems?.system?.[val]?.[key] || undefined;
                    if (refrigenValue && !CASCADE_MTSIDE_VALUES?.includes(refrigenValue) && refrigenValue?.length > 0 && !refrigentsSelected.includes(refrigenValue)) {
                        refrigentsSelected.push(refrigenValue);
                    }
                });
            }
        });
        return refrigentsSelected;
        // eslint-disable-next-line
    }, [chooseSystems?.selectedSystems, chooseSystems?.system]);

    const updateRefPriceValue = useCallback((priceKey: any, price: any) => (e: React.ChangeEvent<HTMLSelectElement>) => {
        const updatedPrice = ResultsCalculations.getUpdatedPrice(parseFloat(price), parseFloat(e?.target?.value));
        setSensitivityAnalysis({
            ...sensitivityAnalysis, refrigerantPrice: { ...sensitivityAnalysis?.refrigerantPrice, [priceKey]: updatedPrice }, refrigerantChangePricePercentage: { ...sensitivityAnalysis?.refrigerantChangePricePercentage, [priceKey]: e?.target?.value }
        });
    }, [sensitivityAnalysis]);

    const updateLeakageValue = useCallback((leakageKey: any, value: any) => (e: React.ChangeEvent<HTMLSelectElement>) => {
        const updatedPrice = (value) ? ResultsCalculations.getUpdatedPrice(parseFloat(value), parseFloat(e?.target?.value)) : 0;
        setSensitivityAnalysis({
            ...sensitivityAnalysis, leakageRate: { ...sensitivityAnalysis?.leakageRate, [leakageKey]: Math.ceil(updatedPrice) }, leakageRatePercentage: { ...sensitivityAnalysis?.leakageRatePercentage, [leakageKey]: e?.target?.value }
        });
    }, [sensitivityAnalysis]);

    const setValue = useCallback((storeKey: any) => (e: React.ChangeEvent<HTMLSelectElement>) => {
        setSensitivityAnalysis({
            ...sensitivityAnalysis, [storeKey]: { value: e.target.value }
        });
    }, [sensitivityAnalysis]);

    const setInputValue = useCallback((storeKey: any) => (e: React.ChangeEvent<HTMLInputElement>) => {
                setSensitivityAnalysis({
            ...sensitivityAnalysis, [storeKey]: {
                value: e.target.value.replace(
                    REGEX_VALIDATION.ELECTRICITY_PRICE_INPUT_FIELD_VALIDATION,
                    ""
                )
            }
        });
    }, [sensitivityAnalysis]);

    // check range 
    const inRange = useCallback((num, a, b, threshold = 0) => {
        return (Math.min(a, b) - threshold <= num && num <= Math.max(a, b) + threshold);
    }, []);
    // is elec price valid
    const isElectricityPriceValid = useMemo(() => {
        let isValid = true;
        const elecPrice = sensitivityAnalysis?.electricityPrice?.value;
        if (elecPrice == "" || isNaN(elecPrice) || (!(inRange(parseFloat(elecPrice), ELECTRICITY_PRICE_RANGES?.MIN, ELECTRICITY_PRICE_RANGES?.MAX)))) {
            isValid = false;
        }
        return isValid;
    }, [sensitivityAnalysis?.electricityPrice, ELECTRICITY_PRICE_RANGES, inRange]);

    //Enable run button
    const isButtonDisabled = useMemo(() => {
        let isValid: any = Boolean;
        isValid = (showParameter) ? ((isElectricityPriceValid) ? false : true) : true;
        return isValid;
    }, [showParameter, isElectricityPriceValid]);

    //reset sensitivity parameter values
    const setDefault = () => {
        let refrigerantChangePricePercentage: any = {};
        let leakageRatePercentage: any = {};
        Object.keys(sensitivityAnalysis?.refrigerantChangePricePercentage)?.map((sysval: any) => {
            refrigerantChangePricePercentage = { ...refrigerantChangePricePercentage, [sysval]: "0" };
        });
        Object.keys(sensitivityAnalysis?.leakageRatePercentage)?.map((sysval: any) => {
            leakageRatePercentage = { ...leakageRatePercentage, [sysval]: "0" };
        });
        setSensitivityAnalysis({ ...simulationSetup, refrigerantChangePricePercentage: refrigerantChangePricePercentage, leakageRatePercentage: leakageRatePercentage });
    };
    //remove All ChildNodes
    const removeAllChildNodes = (parent) => {
        while (parent.firstChild) {
            parent.removeChild(parent.firstChild);
        }
    };
    // call for sensitivity analysis
    const runSensitivityData = useCallback(async () => {
        const sensitivityJSON: any = await SensitivityAnalysisInputs.getInputJSON(showParameter, userInputs, config);
        setParameterName(SENSITIVITY_PARAMETER[showParameter]);
        await setSensitivityAnalysisLoading(true);
        const pdfSensitivityPage: any = await window.document.getElementsByClassName("parameter-panel "+showParameter?.toString())[0]?.cloneNode(true);
            if (props?.setSelectedParameterTab) {
           await props.setSelectedParameterTab(pdfSensitivityPage);
        }
        await setShowParameter("");
        const tmpRefershKey = Date.now();
        const sensitivityAnalysisData = await store.dispatch(sensitivityAnalysisApi.endpoints.getSensitivityAnalysis.initiate({
            refreshKey: tmpRefershKey,
            transactionId: transactionId,
            pageData: sensitivityJSON,
            pageName: "sensitivityAnalysis"
        }));

        if (sensitivityAnalysisData?.data?.isSuccess) {
            const requestBodyForcalculation = { pageData: { architectures: chooseSystems?.selectedSystems, baseline: chooseSystems?.baseline, calculationId: sensitivityJSON?.calculationId }, refreshKey: tmpRefershKey, transactionId: transactionId };
            const calculationResultData = await store.dispatch(calculateApi.endpoints.getCalculate.initiate(requestBodyForcalculation));
            if (calculationResultData?.data?.result) {
                await setTables({ sensityvityResultsMaster: { ...tables?.sensityvityResultsMaster, [transactionId]: calculationResultData?.data?.result } });
            }
            const pdfSensitivityWrapper = window.document.getElementById("parameter-tab");
            if (pdfSensitivityWrapper) {
                await removeAllChildNodes(pdfSensitivityWrapper);
            }
        }

        await setSensitivityGraph(true);
        await setSensitivityAnalysisLoading(false);
        await setCalculator({ updatedOn: Date.now() });

    }, [showParameter, userInputs, config, SENSITIVITY_PARAMETER, transactionId, chooseSystems?.selectedSystems, chooseSystems?.baseline, tables?.sensityvityResultsMaster, props]);

    // Graph JSON
    const graphInput = useMemo(() => {
        const graphJSON = GraphInputs.getGraphJSON(userInputs, tables?.resultsMaster, transactionId, tables?.systemArchMaster);
        return graphJSON;
        // eslint-disable-next-line
    }, [tables?.resultsMaster, props.currentTab]);

    const graphInputSensitivity = useMemo(() => {
        if (showSensitivityGraph) {
            const graphJSON = GraphInputs.getGraphJSON(userInputs, tables?.sensityvityResultsMaster, transactionId, tables?.systemArchMaster);

            return graphJSON;
        }
        else {
            const graphJSON = GraphInputs.getGraphJSON(userInputs, tables?.resultsMaster, transactionId, tables?.systemArchMaster);

            return graphJSON;
        }
        // eslint-disable-next-line
    }, [tables?.sensityvityResultsMaster, showSensitivityGraph]);

    return (
        <>
            <div className="sensitivity-selection">
                <div className="row">
                    <div className="set-default-link" onClick={setDefault}>{t(LABELKEYS_SYSTEM_LOAD.RESET)}</div>
                </div>
                <div className="row">
                    <div className="col-md-3 col-xs-12 pt-2">
                        <div className="form-group">
                            <>
                                <label htmlFor="Type">{t(RESULTS_LABEL.SENSITIVITY_PARAMETER)} <span className="mandatory"></span></label>
                                <select className="form-control" value={showParameter || ""} onChange={handleChanged} placeholder="" aria-label={t(RESULTS_LABEL.SENSITIVITY_PARAMETER)}>
                                    <option value="">{t(RESULTS_LABEL.SELECT_PARAMETER)}</option>
                                    {SENSITIVITY_PARAMETER?.map((item, key) =>
                                        <option key={key} value={key}>{item}</option>
                                    )}
                                </select>
                                <i className="angle-down-arrow"><BiChevronDown className="angle-down-icon" /></i>
                            </>
                        </div>
                    </div>
                    <div className="col-md-1 col-xs-12 d-flex pt-2">
                        <div className={isButtonDisabled ? "btn btn-run btn-secondary disabled" : "btn btn-run btn-secondary"} id={"btn-run-sensitivity"} onClick={runSensitivityData}>{t(RESULTS_LABEL.RUN)}</div>
                    </div>
                </div>
                <div className="row mx-0" key={"parameter-panel-wrapper"+showParameter}>
                    {showParameter === "0" && (
                        <div className={"parameter-panel "+showParameter?.toString()} key={showParameter}>
                            <div className="panel-title">{SENSITIVITY_PARAMETER[showParameter]}</div>
                            <div className="sensitivity-table">
                                <div className="panel-body">
                                    <div className="table-head">
                                        {SENSITIVITY_REFRIGERANT_TABLE_HEADER?.map((e: any) => {
                                            return (
                                                <div key={`div${e?.fieldKey}`} className="table-items col-md-3">
                                                    {t(e?.fieldKey)}{" "}
                                                </div>
                                            );
                                        })}
                                    </div>
                                    {selectedRefrigents && selectedRefrigents?.map((refrigent: any, i) => {
                                        const refrigerantPricevalue = simulationSetup?.[`${'refrigerantPrice'}`]?.[`${refrigent}`];
                                        return (
                                            <div key={`div${refrigent}${i}`}>
                                                {refrigent && refrigent != "" ?
                                                    <div className="d-flex">
                                                        <div className="table-data col-md-3">{refrigent}</div>
                                                        <div className="table-data col-md-3">{refrigerantPricevalue || ""}</div>
                                                        <div className="table-data col-md-3 px-0">
                                                            <div className="form-group">
                                                                <>
                                                                    <select className="form-control hidden-item-pdf" placeholder="" aria-label="Price" value={sensitivityAnalysis?.[`${'refrigerantChangePricePercentage'}`]?.[`${refrigent}`] || ""} onChange={updateRefPriceValue(refrigent, refrigerantPricevalue)}>
                                                                        {REFRIGERANT_PRICE_OPTIONS?.map((item, key) =>
                                                                            <option key={key} value={item.value}>{item.label}</option>
                                                                        )}
                                                                    </select>
                                                                    <i className="angle-down-arrow"><BiChevronDown className="angle-down-icon" /></i>
                                                                </>
                                                                <select className="form-control item-pdf-only" placeholder="" aria-label="Price">
                                                                    <option>{REFRIGERANT_PRICE_OPTIONS?.filter(rfp => rfp.value == sensitivityAnalysis?.[`${'refrigerantChangePricePercentage'}`]?.[`${refrigent}`])?.[0]?.label || ""}</option>
                                                                </select>

                                                            </div>
                                                        </div>
                                                        <div className="table-data col-md-3">{sensitivityAnalysis?.[`${'refrigerantPrice'}`]?.[`${refrigent}`] || ""}</div>
                                                    </div>
                                                    :
                                                    ""}
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    )}
                    {showParameter === "1" && (
                        <div className={"parameter-panel "+showParameter?.toString()} key={showParameter}>
                            <div className="panel-title">{SENSITIVITY_PARAMETER[showParameter]}</div>
                            <div className="sensitivity-table">
                                <div className="panel-body">
                                    <div className="table-head">
                                        {SENSITIVITY_ELECT_INTENSITY_TABLE_HEADER?.map((e: any) => {
                                            return (
                                                <div key={`div${e?.fieldKey}`} className="table-items col-md-6">
                                                    {t(e?.fieldKey)}{" "}
                                                </div>
                                            );
                                        }
                                        )}
                                    </div>
                                    <div key={`div${'electricityIntencity'}`} className="d-flex">
                                        <div className="table-data col-md-6">{CONFIG_SIMULATION_SETUP_SELECT_FIELD[0]?.fieldvalueoptions?.filter(value => value?.value == simulationSetup?.[`${'electricityIntencity'}`]?.value)[0].label}</div>
                                        <div className="table-data col-md-6 px-0">
                                            <div className="form-group w-100">
                                                <>
                                                    <select className="form-control hidden-item-pdf" placeholder="" aria-label="Electricity Intensity" value={sensitivityAnalysis?.[`${'electricityIntencity'}`]?.value || ""} onChange={setValue(`${'electricityIntencity'}`)}>
                                                        <option>Select</option>
                                                        {
                                                            CONFIG_SIMULATION_SETUP_SELECT_FIELD[0]?.fieldvalueoptions?.map((option: any, key: number) => {
                                                                return (
                                                                    <option key={key} value={option.value}>{option.label}</option>
                                                                );
                                                            })}
                                                    </select>
                                                    <i className="angle-down-arrow"><BiChevronDown className="angle-down-icon" /></i>
                                                </>
                                                <select className="form-control item-pdf-only" placeholder="" aria-label="Price">
                                                    <option>{CONFIG_SIMULATION_SETUP_SELECT_FIELD[0]?.fieldvalueoptions?.filter(flo => flo.value == sensitivityAnalysis?.[`${'electricityIntencity'}`]?.value)?.[0]?.label || ""}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {showParameter === "2" && (
                        <div className={"parameter-panel "+showParameter?.toString()} key={showParameter}>
                            <div className="panel-title">{SENSITIVITY_PARAMETER[showParameter]}</div>
                            <div className="sensitivity-table">
                                <div className="panel-body">
                                    <div className="table-head">
                                        {SENSITIVITY_ELECT_PRICE_TABLE_HEADER?.map((e: any) => {
                                            return (
                                                <div key={`div${e?.fieldKey}`} className="table-items col-md-4">
                                                    {t(e?.fieldKey)}{" "}
                                                </div>
                                            );
                                        }
                                        )}
                                    </div>
                                    <div key={`div${'electricityPrice'}`} className="d-flex">
                                        <div className="table-data col-md-4">{simulationSetup?.[`${'electricityPrice'}`]?.value} {t(SENSITIVITY_PARAMETER_LABEL.KWH)}</div>
                                        <div className="table-data col-md-4"><span>{isNaN(sensitivityAnalysis?.[`${'electricityPrice'}`]?.value) || sensitivityAnalysis?.[`${'electricityPrice'}`]?.value?.length < 1 ? "" : (((sensitivityAnalysis?.[`${'electricityPrice'}`]?.value - simulationSetup?.[`${'electricityPrice'}`]?.value)/simulationSetup?.[`${'electricityPrice'}`]?.value) * 100).toFixed(1) + "%"}</span></div>
                                        <div className="table-data col-md-4">
                                            <input type="text" aria-label="New Value" className={!isElectricityPriceValid ? "form-control error-message" : ""} value={sensitivityAnalysis?.[`${'electricityPrice'}`]?.value || ""} onChange={setInputValue(`${'electricityPrice'}`)} />
                                            <span className="small-text hidden-item-pdf">{t(SENSITIVITY_PARAMETER_LABEL.SPAN)}</span>
                                            </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {showParameter === "3" && (
                        <div className={"parameter-panel "+showParameter?.toString()} key={showParameter}>
                            <div className="panel-title">{SENSITIVITY_PARAMETER[showParameter]}</div>
                            <div className="sensitivity-table">
                                <div className="panel-body">
                                    <div className="table-head">
                                        {SENSITIVITY_LIFESPAN_TABLE_HEADER?.map((e: any) => {
                                            return (
                                                <div key={`div${e?.fieldKey}`} className="table-items col-md-6">
                                                    {t(e?.fieldKey)}{" "}
                                                </div>
                                            );
                                        }
                                        )}
                                    </div>
                                    <div key={`div${'lifeSpanOfSystem'}`} className="d-flex">
                                        <div className="table-data col-md-6 d-flex align-items-center">{simulationSetup?.[`${'lifeSpanOfSystem'}`]?.value} {t(SENSITIVITY_PARAMETER_LABEL.YEARS)}</div>
                                        <div className="table-data col-md-6">
                                            <div className="form-group w-100">
                                                <>
                                                    <select className="form-control hidden-item-pdf" placeholder="" aria-label="New Value" value={sensitivityAnalysis?.[`${'lifeSpanOfSystem'}`]?.value || ""} onChange={setValue(`${'lifeSpanOfSystem'}`)}>
                                                        {
                                                            CONFIG_SIMULATION_SETUP_SELECT_FIELD[2]?.fieldvalueoptions?.map((option: any, key: number) => {
                                                                return (
                                                                    <option key={key} value={option.value}>{option.label}</option>
                                                                );
                                                            })}
                                                    </select>
                                                    <i className="angle-down-arrow"><BiChevronDown className="angle-down-icon" /></i>
                                                </>
                                                <select className="form-control item-pdf-only" placeholder="" aria-label="Price">
                                                    <option>{CONFIG_SIMULATION_SETUP_SELECT_FIELD[2]?.fieldvalueoptions?.filter(flo => flo.value == sensitivityAnalysis?.[`${'lifeSpanOfSystem'}`]?.value)?.[0]?.label || ""}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {showParameter === "4" && (
                        <div className={"parameter-panel "+showParameter?.toString()} key={showParameter}>
                            <div className="panel-title">{SENSITIVITY_PARAMETER[showParameter]}</div>
                            <div className="sensitivity-table">
                                <div className="panel-body">
                                    <div className="table-head">
                                        {SENSITIVITY_LEAKAGE_TABLE_HEADER?.map((e: any) => {
                                            return (
                                                <div key={`div${e?.fieldKey}`} className="table-items col-md-4">
                                                    {t(e?.fieldKey)}{" "}
                                                </div>
                                            );
                                        })}
                                    </div>
                                    {tables?.systemArchMaster?.filter(value => chooseSystems?.selectedSystems?.indexOf(value?.architectureId) > -1)?.map((val: any, key) => {
                                        const arcID = (val?.architectureId == SYSTEM_ARCHS_KEYS.ARC_9) ? SYSTEM_ARCHS_KEYS.ARC_8 : val?.architectureId;
                                        if (val?.architectureId == SYSTEM_ARCHS_KEYS.ARC_9 && chooseSystems?.selectedSystems?.includes(SYSTEM_ARCHS_KEYS.ARC_8)) {
                                            return;
                                        }
                                        return (
                                            <div key={key} className="d-flex">
                                                <div className="table-data col-md-4">
                                                    {val.architectureName?.includes("R744 Transcritical")
                                                        ?
                                                        "R744 Transcritical" : val.architectureName
                                                    }
                                                </div>
                                                <div className="table-data col-md-4">{simulationSetup?.[`${'leakageRate'}`]?.[`${arcID}`] || 0}</div>
                                                <div className="table-data col-md-4">
                                                    <div className="form-group">
                                                        <>
                                                            <select className="form-control hidden-item-pdf" placeholder="" aria-label="Value" value={sensitivityAnalysis?.[`${'leakageRatePercentage'}`]?.[`${arcID}`] || ""} onChange={updateLeakageValue(arcID, simulationSetup?.[`${'leakageRate'}`]?.[`${arcID}`])} >
                                                                {SENSITIVITY_LEAKAGE_RATE_OPTIONS?.map((item, key) =>
                                                                    <option key={key} value={item.value}>{item.label}</option>
                                                                )}
                                                            </select>
                                                            <i className="angle-down-arrow"><BiChevronDown className="angle-down-icon" /></i>
                                                        </>
                                                        <select className="form-control item-pdf-only" placeholder="" aria-label="Price">
                                                            <option>{SENSITIVITY_LEAKAGE_RATE_OPTIONS?.filter(flo => flo.value == sensitivityAnalysis?.[`${'leakageRatePercentage'}`]?.[`${arcID}`])?.[0]?.label || ""}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            {sensitivityAnalysisLoading ? <Loader /> :
                <>
                    <div className="results-graph results-group-graph-1">
                        <div className="graph-info">
                            {t(LABELKEYS_STORE_SIZE.HEADING)}:
                            <span> {locationAndSize?.storeSize} </span>
                            {t(RESULTS_LABEL.STORE_LOCATION)}:
                            <span> {locationAndSize?.country + " , " + locationAndSize?.city} </span>
                            {Object?.keys(resultTable)?.filter(id => chooseSystems?.selectedSystems?.indexOf(id) > -1)?.map((key, index) => {
                                const mtSideValue = key === SYSTEM_ARCHS_KEYS.ARC_5 ? chooseSystems?.system[key]?.highStage : chooseSystems?.system[key]?.mtSide;
                                const arcName: string = tables?.systemArchMaster?.filter(value => value?.architectureId == key)?.[0]?.architectureName || "";
                                if (!(arcName?.length > 0)) {
                                    return;
                                }
                                return (<>{" " + arcName}:<span key={`${key}_${index}`} className="me-1"> <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize((chooseSystems?.system[key]?.ltSide != mtSideValue) ? mtSideValue + " &#47; " + chooseSystems?.system[key]?.ltSide : chooseSystems?.system[key]?.ltSide || "") }} /></span></>);
                            })}

                        </div>
                        <div className="graph-title">{t(RESULTS_LABEL.ABSOLUTE_GRAPH)} <span>{t(RESULTS_LABEL.BASE_PARAMETER)} {parameterName ? "(" + parameterName + ")" : ""}</span></div>
                        <div className="graph-group-1"><GroupBarGraph source={'group-graph-sensitivity-absolute'} capex={graphInput?.capex} opexElectricity={graphInput?.electricity} opexService={graphInput?.opexService} opexToppingUp={graphInput?.opexToppingUp} emission={graphInput?.emissionGroup} label={graphInput?.label} /></div>
                    </div>
                    <div className="results-graph results-group-graph-2">
                        <div className="graph-info">
                            {t(LABELKEYS_STORE_SIZE.HEADING)}:
                            <span> {locationAndSize?.storeSize} </span>
                            {t(RESULTS_LABEL.STORE_LOCATION)}:
                            <span> {locationAndSize?.country + " , " + locationAndSize?.city} </span>
                            {Object?.keys(resultTable)?.filter(id => chooseSystems?.selectedSystems?.indexOf(id) > -1)?.map((key, index) => {
                                const mtSideValue = key === SYSTEM_ARCHS_KEYS.ARC_5 ? chooseSystems?.system[key]?.highStage : chooseSystems?.system[key]?.mtSide;
                                const arcName: string = tables?.systemArchMaster?.filter(value => value?.architectureId == key)?.[0]?.architectureName || "";
                                if (!(arcName?.length > 0)) {
                                    return;
                                }
                                return (<>{" " + arcName}:<span key={`${key}_${index}`} className="me-1"> <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize((chooseSystems?.system[key]?.ltSide != mtSideValue) ? mtSideValue + " &#47; " + chooseSystems?.system[key]?.ltSide : chooseSystems?.system[key]?.ltSide || "") }} /></span></>);
                            })}

                        </div>
                        <div className="graph-title">{t(RESULTS_LABEL.ABSOLUTE_GRAPH)} <span>{t(RESULTS_LABEL.NEW_PARAMETER)} {parameterName ? "(" + parameterName + ")" : ""}</span></div>
                        <div className="graph-group-2"><GroupBarGraph source={'group-graph-sensitivity-relative'} capex={graphInputSensitivity?.capex} opexElectricity={graphInputSensitivity?.electricity} opexService={graphInputSensitivity?.opexService} opexToppingUp={graphInputSensitivity?.opexToppingUp} emission={graphInputSensitivity?.emissionGroup} label={graphInputSensitivity?.label} /></div>
                    </div>

                    <div className="results-graph results-stacked-graph-1">
                        <div className="graph-info">
                            {t(LABELKEYS_STORE_SIZE.HEADING)}:
                            <span> {locationAndSize?.storeSize} </span>
                            {t(RESULTS_LABEL.STORE_LOCATION)}:
                            <span> {locationAndSize?.country + " , " + locationAndSize?.city} </span>
                            {Object?.keys(resultTable)?.filter(id => chooseSystems?.selectedSystems?.indexOf(id) > -1)?.map((key, index) => {
                                const mtSideValue = key === SYSTEM_ARCHS_KEYS.ARC_5 ? chooseSystems?.system[key]?.highStage : chooseSystems?.system[key]?.mtSide;
                                const arcName: string = tables?.systemArchMaster?.filter(value => value?.architectureId == key)?.[0]?.architectureName || "";
                                if (!(arcName?.length > 0)) {
                                    return;
                                }
                                return (<>{" " + arcName}:<span key={`${key}_${index}`} className="me-1"> <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize((chooseSystems?.system[key]?.ltSide != mtSideValue) ? mtSideValue + " &#47; " + chooseSystems?.system[key]?.ltSide : chooseSystems?.system[key]?.ltSide || "") }} /></span></>);
                            })}

                        </div>
                        <div className="graph-title">{t(RESULTS_LABEL.ABSOLUTE_GRAPH)} <span>{t(RESULTS_LABEL.BASE_PARAMETER)} {parameterName ? "(" + parameterName + ")" : ""}</span></div>
                        <div className="graph-stacked-1"><StackedGraph source={'stacked-graph-sensitivity-absolute'} capex={graphInput?.capex} opexElectricity={graphInput?.electricity} opexService={graphInput?.opexService} opexToppingUp={graphInput?.opexToppingUp} emission={graphInput?.emissionStack} label={graphInput?.label} /></div>
                    </div>
                    <div className="results-graph results-stacked-graph-2">
                        <div className="graph-info">
                            {t(LABELKEYS_STORE_SIZE.HEADING)}:
                            <span> {locationAndSize?.storeSize} </span>
                            {t(RESULTS_LABEL.STORE_LOCATION)}:
                            <span> {locationAndSize?.country + " , " + locationAndSize?.city} </span>
                            {Object?.keys(resultTable)?.filter(id => chooseSystems?.selectedSystems?.indexOf(id) > -1)?.map((key, index) => {
                                const mtSideValue = key === SYSTEM_ARCHS_KEYS.ARC_5 ? chooseSystems?.system[key]?.highStage : chooseSystems?.system[key]?.mtSide;
                                const arcName: string = tables?.systemArchMaster?.filter(value => value?.architectureId == key)?.[0]?.architectureName || "";
                                if (!(arcName?.length > 0)) {
                                    return;
                                }
                                return (<>{" " + arcName}:<span key={`${key}_${index}`} className="me-1"> <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize((chooseSystems?.system[key]?.ltSide != mtSideValue) ? mtSideValue + " &#47; " + chooseSystems?.system[key]?.ltSide : chooseSystems?.system[key]?.ltSide || "") }} /></span></>);
                            })}

                        </div>
                        <div className="graph-title">{t(RESULTS_LABEL.ABSOLUTE_GRAPH)} <span>{t(RESULTS_LABEL.NEW_PARAMETER)} {parameterName ? "(" + parameterName + ")" : ""}</span></div>
                        <div className="graph-stacked-2"><StackedGraph source={'stacked-graph-sensitivity-relative'} capex={graphInputSensitivity?.capex} opexElectricity={graphInputSensitivity?.electricity} opexService={graphInputSensitivity?.opexService} opexToppingUp={graphInputSensitivity?.opexToppingUp} emission={graphInputSensitivity?.emissionStack} label={graphInputSensitivity?.label} /></div>
                    </div>
                    <div className="results-graph results-bubble-graph-1">
                        <div className="graph-info">
                            {t(LABELKEYS_STORE_SIZE.HEADING)}:
                            <span> {locationAndSize?.storeSize} </span>
                            {t(RESULTS_LABEL.STORE_LOCATION)}:
                            <span> {locationAndSize?.country + " , " + locationAndSize?.city} </span>
                            {Object?.keys(resultTable)?.filter(id => chooseSystems?.selectedSystems?.indexOf(id) > -1)?.map((key, index) => {
                                const mtSideValue = key === SYSTEM_ARCHS_KEYS.ARC_5 ? chooseSystems?.system[key]?.highStage : chooseSystems?.system[key]?.mtSide;
                                const arcName: string = tables?.systemArchMaster?.filter(value => value?.architectureId == key)?.[0]?.architectureName || "";
                                if (!(arcName?.length > 0)) {
                                    return;
                                }
                                return (<>{" " + arcName}:<span key={`${key}_${index}`} className="me-1"> <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize((chooseSystems?.system[key]?.ltSide != mtSideValue) ? mtSideValue + " &#47; " + chooseSystems?.system[key]?.ltSide : chooseSystems?.system[key]?.ltSide || "") }} /></span></>);
                            })}

                        </div>
                        <div className="graph-title">{t(RESULTS_LABEL.RELATIVE_GRAPH)} <span>{t(RESULTS_LABEL.BASE_PARAMETER)} {parameterName ? "(" + parameterName + ")" : ""}</span></div>
                        <div className="graph-bubble-1">
                            <div className="x-axis-arrow"><img src={ImgArrowY} alt="X axis" /></div>
                            <BubbleGraph
                                source={'bubble-graph-sensitivity-absolute'}
                                label={graphInput?.label}
                                ARC_1={graphInput?.ARC_1}
                                ARC_2={graphInput?.ARC_2}
                                ARC_3={graphInput?.ARC_3}
                                ARC_4={graphInput?.ARC_4}
                                ARC_5={graphInput?.ARC_5}
                                ARC_6={graphInput?.ARC_6}
                                ARC_9={graphInput?.ARC_9}
                                ARC_8={graphInput?.ARC_8}
                                refrigerantType={graphInput?.refrigerantType}
                                labelBubble={graphInput?.labelBubble} />
                            <div className="y-axis-arrow"><img src={ImgArrowX} alt="Y axis" /></div>
                        </div>
                    </div>
                    <div className="results-graph results-bubble-graph-2">
                        <div className="graph-info">
                            {t(LABELKEYS_STORE_SIZE.HEADING)}:
                            <span> {locationAndSize?.storeSize} </span>
                            {t(RESULTS_LABEL.STORE_LOCATION)}:
                            <span> {locationAndSize?.country + " , " + locationAndSize?.city} </span>
                            {Object?.keys(resultTable)?.filter(id => chooseSystems?.selectedSystems?.indexOf(id) > -1)?.map((key, index) => {
                                const mtSideValue = key === SYSTEM_ARCHS_KEYS.ARC_5 ? chooseSystems?.system[key]?.highStage : chooseSystems?.system[key]?.mtSide;
                                const arcName: string = tables?.systemArchMaster?.filter(value => value?.architectureId == key)?.[0]?.architectureName || "";
                                if (!(arcName?.length > 0)) {
                                    return;
                                }
                                return (<>{" " + arcName}:<span key={`${key}_${index}`} className="me-1"> <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize((chooseSystems?.system[key]?.ltSide != mtSideValue) ? mtSideValue + " &#47; " + chooseSystems?.system[key]?.ltSide : chooseSystems?.system[key]?.ltSide || "") }} /></span></>);
                            })}

                        </div>
                        <div className="graph-title">{t(RESULTS_LABEL.RELATIVE_GRAPH)} <span>{t(RESULTS_LABEL.NEW_PARAMETER)} {parameterName ? "(" + parameterName + ")" : ""}</span></div>
                        <div className="graph-bubble-2">
                            <div className="x-axis-arrow"><img src={ImgArrowY} alt="X axis" /></div>
                            <BubbleGraph
                                source={'bubble-graph-sensitivity-relative'}

                                label={graphInputSensitivity?.label}
                                ARC_1={graphInputSensitivity?.ARC_1}
                                ARC_2={graphInputSensitivity?.ARC_2}
                                ARC_3={graphInputSensitivity?.ARC_3}
                                ARC_4={graphInputSensitivity?.ARC_4}
                                ARC_5={graphInputSensitivity?.ARC_5}
                                ARC_6={graphInputSensitivity?.ARC_6}
                                ARC_8={graphInputSensitivity?.ARC_8}
                                ARC_9={graphInputSensitivity?.ARC_9}
                                refrigerantType={graphInput?.refrigerantType}
                                labelBubble={graphInput?.labelBubble}
                            />
                            <div className="y-axis-arrow"><img src={ImgArrowX} alt="Y axis" /></div>
                        </div>
                    </div>
                </>
            }
        </>
    );
};
export default SensitivityAnalysis;
