import React, { useCallback, useMemo, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { skipToken } from "@reduxjs/toolkit/query";
import "./SystemChoice.scss";
import { SYSTEM_CHOICE_TABLE, LABELKEYS_SYSTEM_CHOICE, LABELKEYS_SYSTEM_LOAD, LABEL_KEYS_LOADER } from "../../../../../helper/constants";
import { useTranslation } from "react-i18next";
import Form from 'react-bootstrap/Form';
import { BiChevronDown } from "react-icons/bi";
import TabStepper from "../../../../common/TabContainer/TabStepper";
import ProgressStepper from '../../../../common/ProgressBar/ProgressStepper';
import Headline from '../../../../common/Headline/Headline';
import { useGetDataQuery } from "../../../../../services/data";
import { setChooseSystemValues, setChooseSystem, setTables } from "../../../../../store/actions";
import Loader from "../../../../common/Loader/Loader";
import { ImgWarning } from "../../../../../assets/images/archSelection";

const SystemChoice = () => {
  const { t } = useTranslation();
  const { userInputs, tables } = useSelector((state: any) => state?.ecoEfficiency?.calculator || {});
  const { chooseSystems, locationAndSize } = userInputs;
  const [isCheckAll, setIsCheckAll] = useState(false);
  const { SERVICE_REQUEST_FETCH_DATA_TYPES, SYSTEM_CHOICE_TABLE_HEADER, PROGRESSBAR_STEPS_VALUE_COLL, PAGE_NAVIGATION, SYSTEM_ARCHS_CONFIG } = useSelector((state: any) => state?.ecoEfficiency?.config || {});
  //Enable continue button 
  const isButtonDisabled = useMemo(() => {
    return (!(chooseSystems?.selectedSystems?.length > 1 && chooseSystems?.baseline?.length > 0));
  }, [chooseSystems]);
  /*------Data Query -----*/
  //system master
  const { data: systemArchData, isLoading: isSystemArchDataLoading } = useGetDataQuery(tables?.systemArchMaster ? skipToken : {
    dataType: SERVICE_REQUEST_FETCH_DATA_TYPES.ARCHITECTURES
  });
  useEffect(() => {
    if (systemArchData?.result?.data && !tables?.systemArchMaster) {
      const sysArchData = systemArchData?.result?.data?.filter(d => !SYSTEM_ARCHS_CONFIG?.HIDDEN_ARCS?.includes(d?.architectureId));
      setTables({ systemArchMaster: sysArchData });
    }
    // eslint-disable-next-line 
  }, [systemArchData?.result?.data]);
  useEffect(() => {
    if (chooseSystems?.selectedSystems?.length === tables?.systemArchMaster?.length && tables?.systemArchMaster?.length > 0 && chooseSystems?.selectedSystems?.length > 0) {
      setIsCheckAll(true);
    }
    else {
      setIsCheckAll(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //
  //on handle Select ALL
  const validateDefaultValue =useCallback((arcId, storeType, value) => {
    let defualtValue = value;
    const storeSize = locationAndSize?.storeSize;
    let options: any = [];
    switch (storeType) {
      case "lt":
        options = SYSTEM_ARCHS_CONFIG?.[arcId]?.[storeSize]?.ltSideOptions || SYSTEM_ARCHS_CONFIG?.[arcId]?.ltSideOptions;
        break;
      case "mt":       
        options = SYSTEM_ARCHS_CONFIG?.[arcId]?.[storeSize]?.mtSideOptions || SYSTEM_ARCHS_CONFIG?.[arcId]?.mtSideOptions;
        break;
      case "high":
        options = SYSTEM_ARCHS_CONFIG?.[arcId]?.[storeSize]?.highStageOptions || SYSTEM_ARCHS_CONFIG?.[arcId]?.highStageOptions;
        break;
    }
    if (value?.length > 0 && options?.length > 0 && !options?.includes(value)) {
      defualtValue = options[0];
    }
    return defualtValue;
  },[SYSTEM_ARCHS_CONFIG,locationAndSize]);
  //on handle Select ALL
  const handleSelectAll = useCallback(() => {
    setIsCheckAll(!isCheckAll);
    let selectedSystems = [...chooseSystems?.selectedSystems] || [];
    let system = { ...chooseSystems?.system };
    let baseline = chooseSystems?.baseline || "";
    let filteredSystems = [...chooseSystems?.filteredSystems] || [];
    if (isCheckAll) {
      selectedSystems = [];
      filteredSystems = [];
      baseline = "";
      if (tables?.systemArchMaster) {
        tables?.systemArchMaster.forEach(function (data) {
          system = { ...system, [data?.architectureId]: { ltSide: validateDefaultValue(data?.architectureId,"lt", data?.refrigerantLt || ''), mtSide: validateDefaultValue(data?.architectureId,"mt", data?.refrigerantMt || ''), highStage: validateDefaultValue(data?.architectureId,"high",data?.refrigerantHigh || '')  } };
        });
      }
    }
    else {
      if (tables?.systemArchMaster) {
        tables?.systemArchMaster.forEach(function (data) {
          if (!selectedSystems?.includes(data?.architectureId)) {
            selectedSystems?.push(data?.architectureId);
            system = { ...system, [data?.architectureId]: { ltSide: validateDefaultValue(data?.architectureId,"lt", data?.refrigerantLt || ''), mtSide: validateDefaultValue(data?.architectureId,"mt", data?.refrigerantMt || ''), highStage: validateDefaultValue(data?.architectureId,"high",data?.refrigerantHigh || '')  } };
          }
          if (!filteredSystems?.includes(data?.architectureId)) {
            const archId = data?.architectureId;
            filteredSystems?.push(archId);
          }
        });
      }
    }
    const uniqueSelectedSystems = selectedSystems.filter((element, index) => {
      return selectedSystems.indexOf(element) === index;
    });
    const uniqueUpdatedFilteredSystems = filteredSystems.filter((element, index) => {
      return filteredSystems.indexOf(element) === index;
    });
    setChooseSystem({
      selectedSystems: uniqueSelectedSystems, baseline: baseline, system: system, filteredSystems: uniqueUpdatedFilteredSystems
    });

  }, [isCheckAll, chooseSystems, tables,validateDefaultValue]);

  //on handle Click
  const handleClick = useCallback((e) => {
    const { id } = e.target;
    const selectedSystems = [...chooseSystems?.selectedSystems] || [];
    const filteredSystems = [...chooseSystems?.filteredSystems] || [];
    let updatedSelectedSystems: any = [];
    let updatedFilteredSystems: any = [];
    let fetchLT = '';
    let fetchMT = '';
    let fetchHighStage = '';
    const baseline = chooseSystems?.baseline === id ? "" : chooseSystems?.baseline;
    if (selectedSystems?.includes(id)) {
      updatedSelectedSystems = selectedSystems?.filter(system => system !== id);
      updatedFilteredSystems = filteredSystems?.filter(system => system !== id);
    }
    else {
      updatedSelectedSystems = [...selectedSystems, id];
      updatedFilteredSystems = [...filteredSystems, id];
      if (tables?.systemArchMaster) {
        const defaultValues = tables?.systemArchMaster?.filter((data) => data?.architectureId === id) || [];
        fetchLT = validateDefaultValue(defaultValues?.[0]?.architectureId,"lt", defaultValues?.[0]["refrigerantLt"] || "");
        fetchMT = validateDefaultValue(defaultValues?.[0]?.architectureId,"mt", defaultValues?.[0]["refrigerantMt"] || "");
        fetchHighStage = validateDefaultValue(defaultValues?.[0]?.architectureId,"high",defaultValues?.[0]["refrigerantHigh"] || "");
      }
    }
    const uniqueSelectedSystems = updatedSelectedSystems.filter((element, index) => {
      return updatedSelectedSystems.indexOf(element) === index;
    });
    const uniqueUpdatedFilteredSystems = updatedFilteredSystems.filter((element, index) => {
      return updatedFilteredSystems.indexOf(element) === index;
    });
    setChooseSystem({
      selectedSystems: uniqueSelectedSystems, baseline: baseline, system: { ...chooseSystems?.system, [id]: { ltSide: fetchLT, mtSide: fetchMT, highStage: fetchHighStage } }, filteredSystems: uniqueUpdatedFilteredSystems
    });
    if (uniqueSelectedSystems?.length === tables?.systemArchMaster?.length && tables?.systemArchMaster?.length > 0 && uniqueSelectedSystems?.length > 0) {
      setIsCheckAll(true);
    }
    else {
      setIsCheckAll(false);
    }

  }, [chooseSystems, tables,validateDefaultValue]);
  //on BaseLine Click
  const checkBaseLine = useCallback((baseLineValue) => {
    setChooseSystem({
      baseline: chooseSystems?.baseline?.length > 0 && chooseSystems?.baseline == baseLineValue ? "" : baseLineValue
    });
  }, [chooseSystems]);

  // Set table selection 
  const setValue = useCallback((key: string, sideKey: string) => (e: React.ChangeEvent<HTMLSelectElement>) => {
    e.preventDefault();
    if (SYSTEM_ARCHS_CONFIG?.[key].isMTReadOnly && sideKey == "ltSide") {
      setChooseSystemValues({
        [key]: { ...chooseSystems?.system?.[key], [sideKey]: e?.target?.selectedOptions?.[0]?.value, mtSide: e?.target?.selectedOptions?.[0]?.value }
      });
    }
    else {
      setChooseSystemValues({
        [key]: { ...chooseSystems?.system?.[key], [sideKey]: e?.target?.selectedOptions?.[0]?.value }
      });
    }
  }, [chooseSystems, SYSTEM_ARCHS_CONFIG]);

  //set default
  const setDefaultValues = useCallback(() => {
    const selectedSystems = [...chooseSystems?.selectedSystems] || [];
    const filteredSystems = [...chooseSystems?.filteredSystems] || [];
    let system = { ...chooseSystems?.system };
    let baseline = chooseSystems?.baseline || "";
    if (!(selectedSystems?.length > 0)) {
      baseline = ";";
    }
    if (tables?.systemArchMaster) {
      tables?.systemArchMaster.forEach(function (data) {
        if (selectedSystems?.includes(data?.architectureId)) {
          selectedSystems?.push(data?.architectureId);
          filteredSystems?.push(data?.architectureId);
          system = { ...system, [data?.architectureId]: { ltSide: validateDefaultValue(data?.architectureId,"lt", data?.refrigerantLt || ''), mtSide: validateDefaultValue(data?.architectureId,"mt", data?.refrigerantMt || ''), highStage: validateDefaultValue(data?.architectureId,"high",data?.refrigerantHigh || '') } };
        }
      });
    }
    const uniqueSelectedSystems = selectedSystems.filter((element, index) => {
      return selectedSystems.indexOf(element) === index;
    });
    const uniqueUpdatedFilteredSystems = filteredSystems.filter((element, index) => {
      return filteredSystems.indexOf(element) === index;
    });
    setChooseSystem({
      selectedSystems: uniqueSelectedSystems, baseline: baseline, system: system, filteredSystems: uniqueUpdatedFilteredSystems
    });
  }, [chooseSystems, tables,validateDefaultValue]);
  return (
    <div>
      <ProgressStepper step={''} completed={PROGRESSBAR_STEPS_VALUE_COLL.EcoEfficiency[2]} />
      <Headline headline={t(LABELKEYS_SYSTEM_CHOICE.HEADING)} backButton={PAGE_NAVIGATION.LOCATION_SIZE} continueButton={PAGE_NAVIGATION.SYSTEM_DETAILS.LOAD_SETUP} isDisable={isButtonDisabled} />
      <TabStepper step={''} completed={PROGRESSBAR_STEPS_VALUE_COLL.EcoEfficiencyTabContainer[0]} labelArray={PROGRESSBAR_STEPS_VALUE_COLL.EcoEfficiencyTabContainer} />
      <div className="clearfix"></div>
      <div className="set-default-link" onClick={setDefaultValues}>{t(LABELKEYS_SYSTEM_LOAD.RESET)}</div>
      {isButtonDisabled && !isSystemArchDataLoading && <div className="system-warning"><img src={ImgWarning} alt="Warning" /> <span>{t(LABELKEYS_SYSTEM_LOAD.WARNING)}</span></div>}
      {isSystemArchDataLoading ? <Loader message={t(LABEL_KEYS_LOADER.LOADING)} /> : <div className="system-choice-table">
        <div className="panel-body">
          <div className="table-head">
            <div className="table-items">
              <Form>
                <div key={`inline-checkbox`} className="mb-3">
                  <Form.Check inline name="group1" type="checkbox" id={`inline-checkbox-1`} aria-label="Systems" onChange={handleSelectAll} checked={isCheckAll} />
                </div>
              </Form>
              <span>{t(SYSTEM_CHOICE_TABLE.SYSTEMS)}{" "}</span>
            </div>
            {SYSTEM_CHOICE_TABLE_HEADER?.map((e: any) => {
              return (
                <div key={`div${e?.fieldKey}`} className="table-items">
                  {t(e?.fieldKey)}{" "}
                </div>
              );
            })}
          </div>
          <div>
            <div className="row_entries">
              {tables?.systemArchMaster?.map((val, key) => {
                return (
                  <div key={key}>
                    <div className="table-data">
                      <Form>
                        <div key={`inline-checkbox-${key}`} className="mb-3">
                          <Form.Check inline name="group1" aria-label="Systems" type="checkbox" id={val?.architectureId} onChange={handleClick} checked={chooseSystems?.selectedSystems?.includes(val?.architectureId)} />
                        </div>
                      </Form>
                      {val?.architectureName || ""}
                    </div>
                    <div className={chooseSystems?.selectedSystems?.includes(val?.architectureId) ? "table-data" : "table-data disabled"}>
                      <label className="switch" aria-label="Switch"  >
                        <input id={`chBaseSelection${val?.architectureId}`}
                          disabled={(!chooseSystems?.selectedSystems?.includes(val?.architectureId))}
                          checked={chooseSystems?.baseline == val?.architectureId}
                          type={chooseSystems?.baseline == val?.architectureId ? "checkbox" : "checkbox disabled"}
                          aria-label="Table Check"
                          onClick={() => checkBaseLine(val?.architectureId)}
                        />
                        <span key={`spanBaseSelection${val?.architectureId}`} className="slider round" ></span>
                      </label>
                    </div>
                    <div className={chooseSystems?.selectedSystems?.includes(val?.architectureId) ? "table-data" : "table-data select-checkbox-disable"} >
                      {SYSTEM_ARCHS_CONFIG?.[val?.architectureId]?.fieldType === "select" && SYSTEM_ARCHS_CONFIG?.[val?.architectureId]?.ltSideOptions &&
                        <>
                          <select
                            className={chooseSystems?.selectedSystems?.includes(val?.architectureId) ? "form-control form-control:checked" : "form-control checkbox-disable"}
                            placeholder=""
                            aria-label="Choose System"
                            value={chooseSystems?.system?.[`${val?.architectureId}`]?.ltSide || validateDefaultValue(val?.architectureId,"lt", val?.refrigerantLt || "")}
                            onChange={setValue(val?.architectureId, "ltSide")}
                          >
                            {(SYSTEM_ARCHS_CONFIG?.[val?.architectureId]?.[locationAndSize?.storeSize]?.ltSideOptions || SYSTEM_ARCHS_CONFIG?.[val?.architectureId]?.ltSideOptions)?.map((LTVal: any, key: any) => {
                              return (
                                <option key={key} value={LTVal}>{LTVal}</option>
                              );
                            })}
                          </select>
                          <i className="angle-down-arrow"><BiChevronDown className="angle-down-icon" /></i>
                        </>}
                      {SYSTEM_ARCHS_CONFIG?.[val?.architectureId]?.fieldType === "readOnly" &&
                        <>
                          <input type={"text"} aria-label="Choose System" className={chooseSystems?.selectedSystems?.includes(val?.architectureId) ? "form-control form-control:checked" : "form-control checkbox-disable"} value={chooseSystems?.system?.[`${val?.architectureId}`]?.ltSide?.length > 0 ? chooseSystems?.system?.[`${val?.architectureId}`]?.ltSide : validateDefaultValue(val?.architectureId,"lt", val?.refrigerantLt || "")} readOnly />
                        </>}
                    </div>
                    <div className={chooseSystems?.selectedSystems?.includes(val?.architectureId) ? "table-data" : "table-data select-checkbox-disable"}>
                      {!SYSTEM_ARCHS_CONFIG?.[val?.architectureId]?.isMTReadOnly && SYSTEM_ARCHS_CONFIG?.[val?.architectureId]?.fieldType === "select" && SYSTEM_ARCHS_CONFIG?.[val?.architectureId]?.mtSideOptions &&
                        <>
                          <select
                            className={chooseSystems?.selectedSystems?.includes(val?.architectureId) ? "form-control form-control:checked" : "form-control checkbox-disable"}
                            placeholder=""
                            aria-label="Choose System"
                            value={chooseSystems?.system?.[`${val?.architectureId}`]?.mtSide || validateDefaultValue(val?.architectureId,"mt", val?.refrigerantMt || '')}
                            onChange={setValue(val?.architectureId, "mtSide")}
                          >
                            {(SYSTEM_ARCHS_CONFIG?.[val?.architectureId]?.[locationAndSize?.storeSize]?.mtSideOptions || SYSTEM_ARCHS_CONFIG?.[val?.architectureId]?.mtSideOptions)?.map((MTVal: any, key: any) => {
                              return (
                                <option key={key} value={MTVal}>{MTVal}</option>
                              );
                            })}
                          </select>
                          <i className="angle-down-arrow"><BiChevronDown className="angle-down-icon" /></i>
                        </>
                      }
                      {SYSTEM_ARCHS_CONFIG?.[val?.architectureId]?.isMTReadOnly &&
                        <>
                          <input type={"text"} aria-label="Choose System" className={chooseSystems?.selectedSystems?.includes(val?.architectureId) ? "form-control form-control:checked" : "form-control checkbox-disable"} value={chooseSystems?.system?.[`${val?.architectureId}`]?.mtSide?.length > 0 ? chooseSystems?.system?.[`${val?.architectureId}`]?.mtSide : validateDefaultValue(val?.architectureId,"mt", val?.refrigerantMt || '')} readOnly />
                        </>}
                      {SYSTEM_ARCHS_CONFIG?.[val?.architectureId]?.fieldType === "readOnly" &&
                        <>
                          <input type={"text"} aria-label="Choose System" className={chooseSystems?.selectedSystems?.includes(val?.architectureId) ? "form-control form-control:checked" : "form-control checkbox-disable"} value={chooseSystems?.system?.[`${val?.architectureId}`]?.mtSide?.length > 0 ? chooseSystems?.system?.[`${val?.architectureId}`]?.mtSide : validateDefaultValue(val?.architectureId,"mt", val?.refrigerantMt || '')} readOnly />
                        </>}
                    </div>
                    <div className={chooseSystems?.selectedSystems?.includes(val?.architectureId) ? "table-data" : "table-data select-checkbox-disable"}>
                      {SYSTEM_ARCHS_CONFIG?.[val?.architectureId]?.fieldType === "select" && SYSTEM_ARCHS_CONFIG?.[val?.architectureId]?.highStageOptions &&
                        <>
                          <select
                            className={chooseSystems?.selectedSystems?.includes(val?.architectureId) ? "form-control form-control:checked" : "form-control checkbox-disable"}
                            placeholder=""
                            aria-label="Choose System"
                            value={chooseSystems?.system?.[`${val?.architectureId}`]?.highStage || validateDefaultValue(val?.architectureId,"high",val?.refrigerantHigh || '')}
                            onChange={setValue(val?.architectureId, "highStage")}
                          >
                            {SYSTEM_ARCHS_CONFIG?.[val?.architectureId]?.highStageOptions?.map((HSVal: any, key: any) => {
                              return (
                                <option key={key} value={HSVal}>{HSVal}</option>
                              );
                            })}
                          </select>
                          <i className="angle-down-arrow"><BiChevronDown className="angle-down-icon" /></i>
                        </>
                      }
                      {SYSTEM_ARCHS_CONFIG?.[val?.architectureId]?.fieldType === "readOnly" && val?.refrigerantHigh &&
                        <>
                          <input type={"text"} aria-label="Choose System" className={chooseSystems?.selectedSystems?.includes(val?.architectureId) ? "form-control form-control:checked" : "form-control checkbox-disable"} value={chooseSystems?.system?.[`${val?.architectureId}`]?.highStage || validateDefaultValue(val?.architectureId,"high",val?.refrigerantHigh || '')} readOnly />
                        </>}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>}

    </div>
  );
};

export default SystemChoice;