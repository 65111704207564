import React from "react";
import "./WarningModal.scss";
import { MdClose } from 'react-icons/md';
import { ImgWarning } from "../../../assets/images/archSelection";
import Loader from "../Loader/Loader";

const WarningModal = (props) => {
    return (
        <div className="warning-overlay">
         <div className="warning-container">
         {!props.isLoading && <div className={props?.isWarning?"modal-heading-icon":"modal-heading"}>
                    <div className={props?.isWarning?"warning-title-icon":"warning-title"}>{props?.isWarning && <div className="warning-icon"><img src={ImgWarning} alt="Warning" /></div>}{props?.title || ""}</div>
                    {props?.isCloseVisible && <div className="close-icon" onClick={props?.onClickClose}><MdClose /></div>}
                </div>}
                {!props.isLoading && <p>{props?.messgage || ""}</p>}
                {props.isLoading? <div className="btn-container mx-0">  <Loader message={props.loaderMessage} /></div>:  <div className="btn-container mx-0">
                    <div id="btn-start-new-session" className="btn btn-primary" onClick={props?.onClickButton1}>{props?.button1Text || ""}</div>
                    <div id="btn-start-existing-session" className={props?.continueButtonDisable > 0 || props?.isErrorPopup ? "btn btn-secondary" : "btn btn btn-secondary disabled"} onClick={props?.onClickButton2}>{props?.button2Text || ""}</div>
                </div>}
            </div>
        </div>
    );
};
export default WarningModal;
