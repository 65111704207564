import React, { useCallback, useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { skipToken } from "@reduxjs/toolkit/query";
import "./Cascade.scss";
import { LABELKEYS_COMPRESSOR_SETUP, LABELKEYS_SYSTEM_LOAD, REGEX_ITEMS } from "../../../../../../helper/constants";
import { useTranslation } from "react-i18next";
import TabStepper from "../../../../../common/TabContainer/TabStepper";
import ProgressStepper from '../../../../../common/ProgressBar/ProgressStepper';
import Headline from '../../../../../common/Headline/Headline';
import SystemAnalysisPanel from "../systemanalysispanel/SystemAnalysisPanel";
import { BiChevronDown } from "react-icons/bi";
import { useGetDataQuery, dataApi } from "../../../../../../services/data";
import { setTables, setCompressorSetup, setCalculator } from "../../../../../../store/actions";
import { CompressorSetUpCalculations } from "../../../../../../services/compressorSetUpCalculations";
import Loader from "../../../../../common/Loader/Loader";
import { store } from '../../../../.../../../../store/ecoEfficiencyStore';

const Cascade = () => {
  const { t } = useTranslation();
  const { userInputs, tables, updatedOn, restoreTransactionId, restoredSessions, restoreSessionData } = useSelector((state: any) => state?.ecoEfficiency?.calculator || {});
  const { locationAndSize, chooseSystems, compressorSetup, loadSetup } = userInputs;
  const [climate] = useState(tables?.countryMaster?.[locationAndSize?.country]?.[locationAndSize?.city]);
  const { ARC_5 } = compressorSetup;
  const { TYPE_OF_RACK, COMPRESSOR_ARCHS_CONFIG, SYSTEM_ARCHS_KEYS, SERVICE_REQUEST_FETCH_DATA_TYPES, COMPRESSOR_SETUP_CENTRALIZED_TABLE_HEADER, PROGRESSBAR_STEPS_VALUE_COLL, PAGE_NAVIGATION, SYSTEM_ARCHS_REACT_MS_MAPPING, SYSTEM_ARCHS_PRE_CALCULATION_REACT_MS_MAPPING, RESTORE_SESSION_PAGE_IDS } = useSelector((state: any) => state?.ecoEfficiency?.config || {});
  const [typeOfRack, setTypeOfRack] = useState(TYPE_OF_RACK.MT);
  const [defaultResetKey, setDefaultResetKey] = useState(Date.now());
  const [isDefaultLTLoading, setIsDefaultLTLoading] = useState(false);
  const type = ARC_5?.[typeOfRack]?.type;
  const company = ARC_5?.[typeOfRack]?.company;
  //type restoreSessionData
  const restoreSessionArcData = useMemo(() => {
    if (!restoredSessions?.includes(RESTORE_SESSION_PAGE_IDS?.ARC_5) && restoreSessionData?.[restoreTransactionId]?.[RESTORE_SESSION_PAGE_IDS?.ARC_5]) {
      return restoreSessionData?.[restoreTransactionId]?.[RESTORE_SESSION_PAGE_IDS?.ARC_5] || undefined;
    }
    else {
      return undefined;
    }
  }, [RESTORE_SESSION_PAGE_IDS, restoreTransactionId, restoredSessions, restoreSessionData]);
  //On rack type size
  const setRackType = useCallback((rackType) => {
    setTypeOfRack(rackType);
  }, [setTypeOfRack]);

  //type options
  const optionsTypeValues = useMemo(() => {
    return COMPRESSOR_ARCHS_CONFIG?.[SYSTEM_ARCHS_KEYS.ARC_5]?.[typeOfRack]?.TYPES || [];
  }, [COMPRESSOR_ARCHS_CONFIG, SYSTEM_ARCHS_KEYS, typeOfRack]);
  //refrigerant
  const refrigerant = useMemo(() => {
    if (typeOfRack == TYPE_OF_RACK.MT) {
      return chooseSystems?.system?.[SYSTEM_ARCHS_KEYS.ARC_5]?.highStage;
    }
    else if (typeOfRack == TYPE_OF_RACK.LT) {
      return chooseSystems?.system?.[SYSTEM_ARCHS_KEYS.ARC_5]?.ltSide;
    }
    else {
      return null;
    }
  }, [chooseSystems, typeOfRack, SYSTEM_ARCHS_KEYS, TYPE_OF_RACK]);

  //tableKey
  const tableKey = useMemo(() => {
    return `${locationAndSize?.storeSize}-${SYSTEM_ARCHS_KEYS.ARC_5}-${typeOfRack}-${refrigerant}-${type}-${company}-${climate}`;
  }, [typeOfRack, locationAndSize?.storeSize, refrigerant, SYSTEM_ARCHS_KEYS, type, company, climate]);

  /*------Data Query -----*/
  //country master
  const { data: compressorData, isLoading: isCompressorDataLoading } = useGetDataQuery(!type || !company || !refrigerant || tables?.compressorMaster?.[tableKey] ? skipToken : {
    tableKey: tableKey,
    dataType: SERVICE_REQUEST_FETCH_DATA_TYPES.COMPRESSOR,
    storeSize: locationAndSize?.storeSize,
    architectureId: SYSTEM_ARCHS_KEYS.ARC_5,
    cabinetType: typeOfRack,
    type: type,
    company: company,
    refrigerant: refrigerant,
    climate: climate
  });
  useEffect(() => {
    if (compressorData?.result?.data?.compressorData && !tables?.compressorMaster?.[tableKey] && tableKey) {
      setTables({ compressorMaster: { ...tables?.compressorMaster, [tableKey]: compressorData?.result?.data?.compressorData } });
    }
    else {
      if (tableKey && !tables?.compressorMaster?.[tableKey]) {
        const systemData = { [SYSTEM_ARCHS_KEYS.ARC_5]: { ...compressorSetup[SYSTEM_ARCHS_KEYS.ARC_5], [typeOfRack]: { ...compressorSetup[SYSTEM_ARCHS_KEYS.ARC_5]?.[typeOfRack], selectedCompressorLoad: undefined, compressorInputs: [] } } };
        setCompressorSetup({ ...compressorSetup, ...systemData });
      }
    }
    // eslint-disable-next-line 
  }, [compressorData?.result?.data]);
  //Reset values if table empty
  useEffect(() => {
    if (tableKey && !tables?.compressorMaster?.[tableKey]) {
      const systemData = { [SYSTEM_ARCHS_KEYS.ARC_5]: { ...compressorSetup[SYSTEM_ARCHS_KEYS.ARC_5], [typeOfRack]: { ...compressorSetup[SYSTEM_ARCHS_KEYS.ARC_5]?.[typeOfRack], selectedCompressorLoad: undefined, compressorInputs: [] } } };
      setCompressorSetup({ ...compressorSetup, ...systemData });
    }
    // eslint-disable-next-line 
  }, [tables?.compressorMaster?.[tableKey]]);
  //Set deafult type & company
  useEffect(() => {
    if (!type && !company) {
      const defaultType = COMPRESSOR_ARCHS_CONFIG?.[SYSTEM_ARCHS_KEYS.ARC_5]?.[typeOfRack]?.DEFAULT_TYPE;
      const defaultCompany = COMPRESSOR_ARCHS_CONFIG?.[SYSTEM_ARCHS_KEYS.ARC_5]?.[typeOfRack]?.DEFAULT_COMPANY;
      const defaultLTType = COMPRESSOR_ARCHS_CONFIG?.[SYSTEM_ARCHS_KEYS.ARC_5]?.[TYPE_OF_RACK.LT]?.DEFAULT_TYPE;
      const defaulLTCompany = COMPRESSOR_ARCHS_CONFIG?.[SYSTEM_ARCHS_KEYS.ARC_5]?.[TYPE_OF_RACK.LT]?.DEFAULT_COMPANY;
      const systemData = { [SYSTEM_ARCHS_KEYS.ARC_5]: { ...compressorSetup[SYSTEM_ARCHS_KEYS.ARC_5], [TYPE_OF_RACK.MT]: { ...compressorSetup?.[SYSTEM_ARCHS_KEYS.ARC_5][TYPE_OF_RACK.MT], type: defaultType, company: defaultCompany }, [TYPE_OF_RACK.LT]: { ...compressorSetup?.[SYSTEM_ARCHS_KEYS.ARC_5][TYPE_OF_RACK.LT], type: defaultLTType, company: defaulLTCompany } } };
      setCompressorSetup({ ...compressorSetup, ...systemData });
    }
    // eslint-disable-next-line 
  }, [typeOfRack]);

  useEffect(() => {
    if (tableKey && tables?.compressorMaster?.[tableKey]) {
      if (!(ARC_5?.[typeOfRack]?.compressorInputs?.length > 0)) {
        const inputTable: any = [];
        tables?.compressorMaster?.[tableKey]?.forEach((configItem, i) => {
          if (configItem) {
            let defaultCount = configItem?.defaultCount || 0;
            if (restoreSessionArcData) {
              defaultCount = restoreSessionArcData?.[typeOfRack]?.compressors?.[configItem?.modelId]?.count || 0;
            }
            const capacity = CompressorSetUpCalculations?.getCapacity(defaultCount, configItem?.enRating) || 0;
            inputTable.push({ modelId: configItem?.modelId, compressor: `Compressor #` + (i + 1), cop: configItem?.cop, enRating: configItem?.enRating, count: defaultCount, capacity: capacity });
          }
        });
        if (inputTable?.length > 0) {
          const totalCapacity = CompressorSetUpCalculations?.getTotal(inputTable?.map(input => input?.capacity || 0)) || 0;
          const systemData = { [SYSTEM_ARCHS_KEYS.ARC_5]: { ...compressorSetup[SYSTEM_ARCHS_KEYS.ARC_5], [typeOfRack]: { ...compressorSetup[SYSTEM_ARCHS_KEYS.ARC_5]?.[typeOfRack], selectedCompressorLoad: totalCapacity, compressorInputs: inputTable } } };
          setCompressorSetup({ ...compressorSetup, ...systemData });
        }
      }

    }
    // eslint-disable-next-line 
  }, [tableKey, tables?.compressorMaster?.[tableKey], defaultResetKey]);

  const setInputTable = useCallback((key, table, rack) => {
    if (key && table) {
      if (!(ARC_5?.[rack]?.compressorInputs?.length > 0)) {
        const inputTable: any = [];
        table?.forEach((configItem, i) => {
          if (configItem) {
            let defaultCount = configItem?.defaultCount || 0;
            if (restoreSessionArcData) {
              defaultCount = restoreSessionArcData?.[rack]?.compressors?.[configItem?.modelId]?.count || 0;
            }
            const capacity = CompressorSetUpCalculations?.getCapacity(defaultCount, configItem?.enRating) || 0;
            inputTable.push({ modelId: configItem?.modelId, compressor: `Compressor #` + (i + 1), cop: configItem?.cop, enRating: configItem?.enRating, count: defaultCount, capacity: capacity });
          }
        });
        if (inputTable?.length > 0) {
          const totalCapacity = CompressorSetUpCalculations?.getTotal(inputTable?.map(input => input?.capacity || 0)) || 0;
          const systemData = { [SYSTEM_ARCHS_KEYS.ARC_5]: { ...compressorSetup[SYSTEM_ARCHS_KEYS.ARC_5], [rack]: { ...compressorSetup[SYSTEM_ARCHS_KEYS.ARC_5]?.[rack], selectedCompressorLoad: totalCapacity, compressorInputs: inputTable } } };
          setCompressorSetup({ ...compressorSetup, ...systemData });
        }
        if (restoredSessions && restoreTransactionId) {
          setCalculator({ restoredSessions: [...restoredSessions, RESTORE_SESSION_PAGE_IDS?.ARC_5] });
        }
      }
    }

  }, [compressorSetup, SYSTEM_ARCHS_KEYS, ARC_5, restoreSessionArcData, restoredSessions, restoreTransactionId, RESTORE_SESSION_PAGE_IDS]);

  //loadDeafultLT
  const loadDefaultLT = async () => {
    const defaultType = await ARC_5?.[TYPE_OF_RACK.LT]?.type || COMPRESSOR_ARCHS_CONFIG?.[SYSTEM_ARCHS_KEYS.ARC_5]?.[TYPE_OF_RACK.LT]?.DEFAULT_TYPE;
    const defaultCompany = await ARC_5?.[TYPE_OF_RACK.LT]?.company || COMPRESSOR_ARCHS_CONFIG?.[SYSTEM_ARCHS_KEYS.ARC_5]?.[TYPE_OF_RACK.LT]?.DEFAULT_COMPANY;
    const ltRefrigerant = chooseSystems?.system?.[SYSTEM_ARCHS_KEYS.ARC_5]?.ltSide;
    const ltTablekey = `${locationAndSize?.storeSize}-${SYSTEM_ARCHS_KEYS.ARC_5}-${TYPE_OF_RACK.LT}-${ltRefrigerant}-${defaultType}-${defaultCompany}-${climate}`;

    await setIsDefaultLTLoading(true);
    const ltTableData = await store.dispatch(dataApi.endpoints.getData.initiate({
      tableKey: ltTablekey,
      dataType: SERVICE_REQUEST_FETCH_DATA_TYPES.COMPRESSOR,
      storeSize: locationAndSize?.storeSize,
      architectureId: SYSTEM_ARCHS_KEYS.ARC_5,
      cabinetType: TYPE_OF_RACK.LT,
      type: defaultType,
      company: defaultCompany,
      refrigerant: ltRefrigerant,
      climate: climate
    }));
    if (ltTableData?.data?.isSuccess && ltTableData?.data?.result?.data?.compressorData) {
      setTables({ compressorMaster: { ...tables?.compressorMaster, [ltTableData?.originalArgs?.tableKey || ltTablekey]: ltTableData?.data?.result?.data?.compressorData } });
      setInputTable(ltTableData?.originalArgs?.tableKey || ltTablekey, ltTableData?.data?.result?.data?.compressorData, TYPE_OF_RACK.LT);
    }
    await setIsDefaultLTLoading(false);
  };
  const isTableReloadForRack = (rackType) => {
    const selctedType = ARC_5?.[rackType]?.type;
    const selectectCompany = ARC_5?.[rackType]?.company;
    let selctedRefrigerant = "";
    if (rackType == TYPE_OF_RACK.MT) {
      selctedRefrigerant = chooseSystems?.system?.[SYSTEM_ARCHS_KEYS.ARC_5]?.highStage;
    }
    else if (typeOfRack == TYPE_OF_RACK.LT) {
      selctedRefrigerant = chooseSystems?.system?.[SYSTEM_ARCHS_KEYS.ARC_5]?.ltSide;
    }
    if (selctedType?.length > 0 && selectectCompany?.length > 0) {
      const key = `${locationAndSize?.storeSize}-${SYSTEM_ARCHS_KEYS.ARC_5}-${rackType}-${selctedRefrigerant}-${selctedType}-${selectectCompany}-${climate}`;
      if (!tables?.compressorMaster[key]) {
        return true;
      }
      else {
        return false;
      }
    }
    return false;
  };
  useEffect(() => {
    if (typeOfRack == TYPE_OF_RACK.MT && !isCompressorDataLoading && !isDefaultLTLoading && (Object.keys(ARC_5?.[TYPE_OF_RACK.MT]?.compressorInputs || [])?.length > 0 && Object.keys(ARC_5?.[TYPE_OF_RACK.LT] || [])?.length < 1 || isTableReloadForRack(TYPE_OF_RACK.LT))) {
      loadDefaultLT();
    }
    // eslint-disable-next-line 
  }, [ARC_5]);
  //On type change
  const onTypeChange = useCallback((e) => {
    const defaultCompany = COMPRESSOR_ARCHS_CONFIG?.[SYSTEM_ARCHS_KEYS.ARC_5]?.[typeOfRack]?.COMPANIES?.filter(c => c?.type == (e?.target?.value))?.map(c => c?.company)?.[0] || "";
    const systemData = { [SYSTEM_ARCHS_KEYS.ARC_5]: { ...compressorSetup[SYSTEM_ARCHS_KEYS.ARC_5], [typeOfRack]: { ...compressorSetup?.[SYSTEM_ARCHS_KEYS.ARC_5]?.[typeOfRack], type: e?.target?.value, company: defaultCompany, compressorInputs: [] } } };
    setCompressorSetup({ ...compressorSetup, ...systemData });
  }, [compressorSetup, SYSTEM_ARCHS_KEYS, typeOfRack, COMPRESSOR_ARCHS_CONFIG]);

  //On Comapny chnage
  const onCompanyChange = useCallback((e) => {
    const systemData = { [SYSTEM_ARCHS_KEYS.ARC_5]: { ...compressorSetup[SYSTEM_ARCHS_KEYS.ARC_5], [typeOfRack]: { ...compressorSetup?.[SYSTEM_ARCHS_KEYS.ARC_5]?.[typeOfRack], company: e?.target?.value, compressorInputs: [] } } };
    setCompressorSetup({ ...compressorSetup, ...systemData });
  }, [compressorSetup, SYSTEM_ARCHS_KEYS, typeOfRack]);
  //company options
  const optionsCompanyValues = useMemo(() => {
    return COMPRESSOR_ARCHS_CONFIG?.[SYSTEM_ARCHS_KEYS.ARC_5]?.[typeOfRack]?.COMPANIES?.filter(c => c?.type == (type || COMPRESSOR_ARCHS_CONFIG?.[SYSTEM_ARCHS_KEYS.ARC_5]?.[typeOfRack]?.DEFAULT_TYPE || ""))?.map(c => c?.company) || [];
  }, [COMPRESSOR_ARCHS_CONFIG, SYSTEM_ARCHS_KEYS, typeOfRack, type]);

  // for active stage
  const activeStageLocationSetup = useMemo(() => {
    const selectedSystemArr = tables?.systemArchMaster?.filter(value => chooseSystems?.selectedSystems?.indexOf(value?.architectureId) > -1)?.map((item: any) => item.architectureId);
    const nextCompressorStage = tables?.systemArchMaster?.filter(value => selectedSystemArr?.indexOf(value.architectureId) > selectedSystemArr?.indexOf(Object.keys(SYSTEM_ARCHS_REACT_MS_MAPPING)[4]))[0]?.architectureId || "";
    return nextCompressorStage;
  }, [chooseSystems, SYSTEM_ARCHS_REACT_MS_MAPPING, tables?.systemArchMaster]);

  // for next page link
  const continueNextPageLink = useMemo(() => {
    let nextPAgeLink = PAGE_NAVIGATION.SYSTEM_DETAILS.COMPRESSOR_SETUP.MICRO_CASCADE;
    if (!activeStageLocationSetup) {
      nextPAgeLink = PAGE_NAVIGATION.SYSTEM_DETAILS.PARAMETER_SETUP;
    }
    return nextPAgeLink;
  }, [activeStageLocationSetup, PAGE_NAVIGATION]);

  // Set table selection 
  const setCountValue = useCallback((modelId: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const isValid = REGEX_ITEMS?.NUMBER?.test(e?.target?.value);
    if (e?.target?.value?.length === 0 || isValid) {
      const inputTable = ARC_5?.[typeOfRack]?.compressorInputs || [];
      if (inputTable?.length > 0) {
        const modelIndex = inputTable?.map(input => input?.modelId)?.indexOf(modelId);
        if (modelIndex > -1 && inputTable[modelIndex]?.modelId === modelId) {
          const configItem = inputTable[modelIndex];
          const capacity = CompressorSetUpCalculations?.getCapacity(e?.target?.value, configItem?.enRating) || 0;
          inputTable[modelIndex]["count"] = e?.target?.value;
          inputTable[modelIndex]["capacity"] = capacity;
          const totalCapacity = CompressorSetUpCalculations?.getTotal(inputTable?.map(input => input?.capacity || 0)) || 0;
          const systemData = { [SYSTEM_ARCHS_KEYS.ARC_5]: { ...compressorSetup[SYSTEM_ARCHS_KEYS.ARC_5], [typeOfRack]: { ...compressorSetup[SYSTEM_ARCHS_KEYS.ARC_5]?.[typeOfRack], selectedCompressorLoad: totalCapacity, compressorInputs: inputTable } } };
          setCompressorSetup({ ...compressorSetup, ...systemData });
        }
      }
    }
  }, [compressorSetup, ARC_5, typeOfRack, SYSTEM_ARCHS_KEYS]);

  //reset  load setup values
  const setDefault = useCallback(() => {    
    const defaultType = COMPRESSOR_ARCHS_CONFIG?.[SYSTEM_ARCHS_KEYS.ARC_5]?.[TYPE_OF_RACK.MT]?.DEFAULT_TYPE;
    const defaultCompany = COMPRESSOR_ARCHS_CONFIG?.[SYSTEM_ARCHS_KEYS.ARC_5]?.[TYPE_OF_RACK.MT]?.DEFAULT_COMPANY;
    const defaultLTType = COMPRESSOR_ARCHS_CONFIG?.[SYSTEM_ARCHS_KEYS.ARC_5]?.[TYPE_OF_RACK.LT]?.DEFAULT_TYPE;
    const defaulLTCompany = COMPRESSOR_ARCHS_CONFIG?.[SYSTEM_ARCHS_KEYS.ARC_5]?.[TYPE_OF_RACK.LT]?.DEFAULT_COMPANY;
    const systemData = { [SYSTEM_ARCHS_KEYS.ARC_5]: { ...compressorSetup[SYSTEM_ARCHS_KEYS.ARC_5], [TYPE_OF_RACK.MT]: { ...compressorSetup?.[SYSTEM_ARCHS_KEYS.ARC_5]?.[TYPE_OF_RACK.MT], type: defaultType, company: defaultCompany, compressorInputs: [] }, [TYPE_OF_RACK.LT]: { ...compressorSetup?.[SYSTEM_ARCHS_KEYS.ARC_5]?.[TYPE_OF_RACK.LT], type: defaultLTType, company: defaulLTCompany, compressorInputs: [] } } };
    setCompressorSetup({ ...compressorSetup, ...systemData });
    setTypeOfRack(TYPE_OF_RACK.MT);
    setDefaultResetKey(Date.now());
  },[compressorSetup,SYSTEM_ARCHS_KEYS,TYPE_OF_RACK,COMPRESSOR_ARCHS_CONFIG]);

  //targetLoads
  const targetLoads = useMemo(() => {
    const slectedMTLoad = loadSetup?.selectedMTLoad || 0;
    const slectedLTLoad = loadSetup?.selectedLTLoad || 0;
    const targetLTLoad = slectedLTLoad;
    const targetMTLoad = CompressorSetUpCalculations?.getType2TargetMTLoad(slectedMTLoad, targetLTLoad, 2);
    return { targetMTLoad: CompressorSetUpCalculations?.getRoundValue(targetMTLoad, 1), targetLTLoad: CompressorSetUpCalculations?.getRoundValue(targetLTLoad, 1) };
  }, [loadSetup]);
  //ltLoadRanges warning
  const isLTLoadWarning = useMemo(() => {
    const isDeviated = CompressorSetUpCalculations.isDeviated(COMPRESSOR_ARCHS_CONFIG.LOAD_DEVIATION, compressorSetup?.[SYSTEM_ARCHS_KEYS.ARC_5]?.[TYPE_OF_RACK.LT]?.selectedCompressorLoad, targetLoads?.targetLTLoad);
    return isDeviated;
  }, [targetLoads, COMPRESSOR_ARCHS_CONFIG, TYPE_OF_RACK, compressorSetup, SYSTEM_ARCHS_KEYS]);
  //mtLoadRanges warning
  const isMTLoadRangeWarning = useMemo(() => {
    const isDeviated = CompressorSetUpCalculations.isDeviated(COMPRESSOR_ARCHS_CONFIG.LOAD_DEVIATION, compressorSetup?.[SYSTEM_ARCHS_KEYS.ARC_5]?.[TYPE_OF_RACK.MT]?.selectedCompressorLoad, targetLoads?.targetMTLoad);
    return isDeviated;
  }, [targetLoads, SYSTEM_ARCHS_KEYS, compressorSetup, TYPE_OF_RACK, COMPRESSOR_ARCHS_CONFIG]);
  //is continue button disabled
  const isContinueButtonDisabled = useMemo(() => {
    return isMTLoadRangeWarning || isLTLoadWarning || isCompressorDataLoading || isDefaultLTLoading;
  }, [isLTLoadWarning, isMTLoadRangeWarning, isCompressorDataLoading, isDefaultLTLoading]);

  return (
    <div>
      <ProgressStepper step={''} completed={PROGRESSBAR_STEPS_VALUE_COLL.EcoEfficiency[2]} />
      <Headline key={`hl-${SYSTEM_ARCHS_KEYS.ARC_5}_${updatedOn}`} calculationId={SYSTEM_ARCHS_PRE_CALCULATION_REACT_MS_MAPPING?.[SYSTEM_ARCHS_KEYS.ARC_5]} headline={t(LABELKEYS_COMPRESSOR_SETUP.HEADING)} activeStageLocationSetup={activeStageLocationSetup} backButton={PAGE_NAVIGATION.SYSTEM_DETAILS.COMPRESSOR_SETUP.DISTRIBUTED_SYSTEM_WATER} continueButton={continueNextPageLink} isDisable={isContinueButtonDisabled} />
      <TabStepper step={''} labelArray={PROGRESSBAR_STEPS_VALUE_COLL.EcoEfficiencyTabContainer} completed={PROGRESSBAR_STEPS_VALUE_COLL.EcoEfficiencyTabContainer[2]} childStep={PROGRESSBAR_STEPS_VALUE_COLL.EcoEfficiencyTabChildContainer[4]} calltoAction={t(LABELKEYS_SYSTEM_LOAD.RESET)} currentStep={true} onResetClick={setDefault} />
      <div className="row mt-3">
        <div className="col-md-3 col-sm-12">
          <SystemAnalysisPanel key={`sp-${SYSTEM_ARCHS_KEYS.ARC_5}_${updatedOn}`} isLTLoading={(typeOfRack == TYPE_OF_RACK.LT && isCompressorDataLoading) || isDefaultLTLoading} isMTLoading={typeOfRack == TYPE_OF_RACK.MT && isCompressorDataLoading} showSubCooler={false} showLTLoadWarning={isLTLoadWarning} showMTLoadWarning={isMTLoadRangeWarning} typeOfRack={typeOfRack} setRackType={setRackType} architecture={SYSTEM_ARCHS_KEYS.ARC_5} targetLoads={targetLoads} />
        </div>
        <div className="col-md-9 col-sm-12">
          {typeOfRack === TYPE_OF_RACK.MT ? <div className="select-temperature-title">{t(LABELKEYS_COMPRESSOR_SETUP.MEDIUM_TEMPERATURE_HEADING)}</div> : <div className="select-temperature-title">{t(LABELKEYS_COMPRESSOR_SETUP.LOW_TEMPERATURE_HEADING)}</div>}
          <div className="select-temperature">
            <div className="select-items row mx-0">
              <div className="col-md-6 col-xs-12">
                <div className="form-group">
                  <>
                    <label htmlFor="Type">{t(LABELKEYS_COMPRESSOR_SETUP.COMPRESSOR_TYPE)} <span className="mandatory"></span></label>
                    <select value={ARC_5?.[typeOfRack]?.type} onChange={onTypeChange} className="form-control" placeholder="" aria-label="Compressor Type">
                      {optionsTypeValues?.map((key, index) => {
                        return (
                          <option key={index} value={key}>{key}</option>
                        );
                      })}
                    </select>
                    <i className="angle-down-arrow"><BiChevronDown className="angle-down-icon" /></i>
                  </>
                </div>
              </div>
              <div className="col-md-6 col-xs-12">
                <div className="form-group">
                  <>
                    <label htmlFor="Company">{t(LABELKEYS_COMPRESSOR_SETUP.COMPRESSOR_MANUFACTURE)} <span className="mandatory"></span></label>
                    <select value={ARC_5?.[typeOfRack]?.company} onChange={onCompanyChange} className="form-control" placeholder="" aria-label="Compressor Manufacture">
                      {optionsCompanyValues?.map((key, index) => {
                        return (
                          <option key={index} value={key}>{key}</option>
                        );
                      })}
                    </select>
                    <i className="angle-down-arrow"><BiChevronDown className="angle-down-icon" /></i>
                  </>
                </div>
              </div>
            </div>
          </div>
          <div className="compressor-table-centralized">
            {isCompressorDataLoading ? <Loader /> : ARC_5?.[typeOfRack]?.compressorInputs?.length > 0 && <div className="panel-body">
              {ARC_5?.[typeOfRack]?.compressorInputs?.length > 0 && <div className="table-head">
                {COMPRESSOR_SETUP_CENTRALIZED_TABLE_HEADER?.map((e: any) => {
                  return (
                    <div key={`div${e?.fieldKey}`} className="table-items">
                      {t(e?.fieldKey)} {" "}
                    </div>
                  );
                })}
              </div>}
              {ARC_5?.[typeOfRack]?.compressorInputs?.map((val, key) => {
                return (
                  <div key={`div${val?.modelId}${key}`}>
                    <div className="table-data">{val?.compressor}</div>
                    <div className="table-data">
                      <input onChange={setCountValue(val?.modelId)} value={val?.count} type="text" aria-label="Count" /></div>
                    <div className="table-data">{CompressorSetUpCalculations.getRoundValue(val?.cop, 1)}</div>
                    <div className="table-data">{CompressorSetUpCalculations.getRoundValue(val?.enRating, 1)}</div>
                    <div className="table-data">{val?.capacity}</div>
                  </div>
                );
              })}
            </div>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cascade;