/****************************************************
App.tsx - Root Page of the application
*****************************************************/
import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { useGetConfigurationsQuery } from './services/configurations';
import ErrorFallback from "./components/masterpage/ErrorBoundary";
import { ErrorBoundary } from 'react-error-boundary';
import MainContent from "./components/masterpage/MainContent";
import './App.scss';
import { API_PATHS, ERROR_MESSAGES } from "./helper/constants";
import { setConfig } from "./store/actions";

const App = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [errorMessage, setErrorMessage] = useState("");
  const { data, error } = useGetConfigurationsQuery(API_PATHS.CONFIGURATIONS);
  //init store
  useEffect(() => {
    if (data?.isSuccess) {
      const resources = data?.result;
      setConfig(resources);      
      if (location.pathname !== "/contact-form") {
        navigate("/");
      }
    }
    else if (!data?.isSuccess && error) {
      setErrorMessage(ERROR_MESSAGES.CONFIG_NOT_FOUND);
    }
    // eslint-disable-next-line
  }, [data, error]);
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <div className="App main-wrapper">
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          {errorMessage?.length > 0 ? <>{errorMessage}</> : <MainContent />}
        </ErrorBoundary>
      </div></ErrorBoundary>
  );
};
export default App;
