import React, { useMemo, useCallback } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import "./LoadSetup.scss";
import { LABELKEYS_COMPRESSOR_SETUP, SYSTEM_LOAD_TABLE, REGEX_ITEMS } from "../../../../../helper/constants";
import { setLoadSetupValues } from "../../../../../store/actions";
import { LoadSetUpCalculations } from "../../../../../services/loadSetUpCalculations";
const TemperatureSide: React.FC<any> = (props) => {
  const { t } = useTranslation();
  const { LOAD_DATA_JSON, TYPE_OF_RACK } = useSelector((state: any) => state?.ecoEfficiency?.config || {});
  const { userInputs, tables } = useSelector((state: any) => state?.ecoEfficiency?.calculator || {});
  const { locationAndSize, loadSetup } = userInputs;

  //temperatureSide 
  const temperatureSideTable = useMemo(() => {
    return (tables?.cabinetBySizeMaster?.[`${locationAndSize?.storeSize}`]?.filter(value => value.cabinetType === props?.typeOfRack) || []);
  }, [props?.typeOfRack, locationAndSize?.storeSize, tables?.cabinetBySizeMaster]);

  // Set table selection 
  const setInputValue = useCallback((cabinetId: string, cabinetType: string, fieldKey: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const isValid = REGEX_ITEMS?.NUMBER?.test(e?.target?.value);
    if (e?.target?.value?.startsWith("-") || e?.target?.value?.length === 0 || isValid || e?.target?.value?.match(REGEX_ITEMS?.FLOAT_NUMBER)) {
      const tmpLoadSetup = { ...loadSetup };
      const inputTable = tmpLoadSetup?.loadInputs;
      if (inputTable?.length > 0) {
        const cabinetIndex = inputTable?.map(input => input?.cabinetId + "-" + input?.cabinetType)?.indexOf(cabinetId + "-" + cabinetType);
        if (cabinetIndex > -1 && inputTable[cabinetIndex]?.cabinetId === cabinetId && inputTable[cabinetIndex]?.cabinetType === cabinetType) {
          inputTable[cabinetIndex][fieldKey] = e?.target?.value;
          const capacityValue = LoadSetUpCalculations?.getCapacity(cabinetId, inputTable[cabinetIndex]?.temperature, inputTable[cabinetIndex]?.length) || 0;
          inputTable[cabinetIndex]["capacity"] = capacityValue;
          if (cabinetType === TYPE_OF_RACK.MT) {
            const selectedMTLoad = LoadSetUpCalculations?.getTotalWithPrecision(inputTable?.filter(input => input?.cabinetType === TYPE_OF_RACK.MT)?.map(input => input?.capacity)) || 0;
            setLoadSetupValues({ ...loadSetup, selectedMTLoad: selectedMTLoad, loadInputs: inputTable });
          }
          if (cabinetType === TYPE_OF_RACK.LT) {
            const selectedLTLoad = LoadSetUpCalculations?.getTotalWithPrecision(inputTable?.filter(input => input?.cabinetType === TYPE_OF_RACK.LT)?.map(input => input?.capacity)) || 0;
            setLoadSetupValues({ ...loadSetup, selectedLTLoad: selectedLTLoad, loadInputs: inputTable });
          }
        }
      }
    }
  }, [loadSetup, TYPE_OF_RACK]);

  const inRange = useCallback((num, a, b, threshold = 0) => {
    return (Math.min(a, b) - threshold <= num && num <= Math.max(a, b) + threshold);
  }, []);
  
  return (
    <div className="col-md-9 col-sm-12">
      {props?.typeOfRack === TYPE_OF_RACK.MT && <div className="title">{t(LABELKEYS_COMPRESSOR_SETUP.MEDIUM_TEMPERATURE_HEADING)}</div>}
      {props?.typeOfRack === TYPE_OF_RACK.LT && <div className="title">{t(LABELKEYS_COMPRESSOR_SETUP.LOW_TEMPERATURE_HEADING)}</div>}
      <div className="load-setup-table">
        <div className="panel-body">
          <div className="table-head-upper">
            {props?.typeOfRack === TYPE_OF_RACK.MT && <div className="table-items col-md-2">
              {t(SYSTEM_LOAD_TABLE.MTCASE)}{" "}
            </div>}
            {props?.typeOfRack === TYPE_OF_RACK.LT && <div className="table-items col-md-2">
              {t(SYSTEM_LOAD_TABLE.LTCASE)}{" "}
            </div>}
            <div className="table-items col-md-4">
              {t(SYSTEM_LOAD_TABLE.TEMPERATURE)}{" "}
            </div>
            <div className="table-items col-md-4">
              {t(SYSTEM_LOAD_TABLE.LENGTH)}<span className="table-items-info">(m/m<sup>3</sup>)</span>
            </div>
            <div className="table-items col-md-2">
              {t(SYSTEM_LOAD_TABLE.CAPACITY)}<span className="table-items-info">(kW)</span>
            </div>
          </div>
          <div className="table-head">
            <div className="table-items col-md-2">{" "}
            </div>
            <div className="table-items col-md-2 p-0">
              {t(SYSTEM_LOAD_TABLE.NEWRANGE)}{" "}
            </div>
            <div className="table-items col-md-2 p-0">
              {t(SYSTEM_LOAD_TABLE.TEMPRANGE)}{" "}
            </div>
            <div className="table-items col-md-2">
              {t(SYSTEM_LOAD_TABLE.NEWRANGE)}{" "}
            </div>
            <div className="table-items col-md-2">
              {t(SYSTEM_LOAD_TABLE.LENGTHRANGE)}{" "}
            </div>
            <div className="table-items col-md-2 p-0">{" "}
            </div>
          </div>
          {temperatureSideTable?.map((cabinetItem, key) => {
            const inputItem = loadSetup?.loadInputs?.filter(value => value.cabinetType === props?.typeOfRack && value.cabinetId == cabinetItem?.cabinetId)?.[0];
            return (
              <div className="row mx-0" key={`${props?.typeOfRack}_${key}`}>
                <div className="table-data col-md-2">{cabinetItem?.cabinetName?.charAt(0)?.toUpperCase() + cabinetItem?.cabinetName?.slice(1)}</div>
                <div className="table-data col-md-2"><input className={inRange(parseFloat(inputItem?.temperature), parseFloat(LOAD_DATA_JSON?.[`${cabinetItem?.cabinetId}`]?.tempFrom), parseFloat(LOAD_DATA_JSON?.[`${cabinetItem?.cabinetId}`]?.tempTo)) ? "" : "error"} type="text" aria-label="Product Temperature" onChange={setInputValue(cabinetItem?.cabinetId, cabinetItem?.cabinetType, "temperature")} value={inputItem?.temperature} /></div>
                <div className="table-data col-md-2">{LOAD_DATA_JSON?.[`${cabinetItem?.cabinetId}`]?.tempFrom} to {LOAD_DATA_JSON?.[`${cabinetItem?.cabinetId}`]?.tempTo} °C</div>
                <div className="table-data col-md-2"><input className={inRange(parseFloat(inputItem?.length),parseFloat(cabinetItem?.lengthFrom),parseFloat(cabinetItem?.lengthTo)) ?"":"error"} type="text" aria-label="Volume" onChange={setInputValue(cabinetItem?.cabinetId, cabinetItem?.cabinetType, "length")} value={inputItem?.length} /></div>
                <div className="table-data col-md-2">{cabinetItem?.lengthFrom} to {cabinetItem?.lengthTo} {LOAD_DATA_JSON?.[`${cabinetItem?.cabinetId}`]?.lengthUnit}{LOAD_DATA_JSON?.[`${cabinetItem?.cabinetId}`]?.lengthUnitSup && <sup>{LOAD_DATA_JSON?.[`${cabinetItem?.cabinetId}`]?.lengthUnitSup}</sup>}</div>
                <div className="table-data col-md-2"><span>{inputItem?.capacity || 0}</span></div>
              </div>
            );
          })}
          {!temperatureSideTable || temperatureSideTable?.length === 0 && <div>{t(SYSTEM_LOAD_TABLE.NO_DATA)}</div>}
          <div>
          </div>
        </div>
      </div>

    </div>
  );
};

export default TemperatureSide;