import React from 'react';
const TabStep = (props) => {
    return(
        <li>
        {props.currentStep === true ?
        <a className={(props.label === "3. Compressor Setup" ? "current-active" : (props.completed ? "active" : ''))} href="#">{props.label}</a>
        :<a className={(props.completed ? "active" : '')} href="#">{props.label}</a>
        }
        </li>
    );
};
export default TabStep;
