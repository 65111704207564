import React from "react";
import "./SystemArchitectures.scss";
import { Accordion, Form } from 'react-bootstrap';
import { LABELKEYS_STORE_SIZE } from "../../../../../helper/constants";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import DOMPurify from 'dompurify';

const SystemArchitectures = () => {  
  const { t } = useTranslation();
  const { SYSTEM_ARCHITECTURE_DATA_JSON, SYSTEM_ARCHITECTURE_TABLE_HEADER } = useSelector((state: any) => state?.ecoEfficiency?.config || {});
    
    return (
      <div className="system-arch-table">
      <div className="sub-head">{t(LABELKEYS_STORE_SIZE.HEADING_CONFIGURATION)}</div>
        <div className="panel-body">
          <div className="table-head">
          {SYSTEM_ARCHITECTURE_TABLE_HEADER?.map((e: any) => {
              return (
                <div key={`div${e?.fieldKey}`} className="table-items">
                  {t(e?.fieldKey)}{" "}
                </div>
              );
            })}
          </div>
          <div className="row_entries">
            <Accordion flush>
              <Form className="accordion_form_main">
                {SYSTEM_ARCHITECTURE_DATA_JSON?.map((val, key) => {
                  return (
                    <div key={key} className="accordion_div_main">
                      <Accordion.Item eventKey={String(key)} >
                        <Accordion.Header>
                          <div className="table-data">{val.SystemArchitectureName}</div>
                          <div className="table-data">{val.LowTemperature}</div>
                          <div className="table-data">{val.MediumTemperature}</div>
                          <div className="table-data">{val.HighStage}</div>
                          <div className="table-data">{val.OtherRefrigerant}</div>
                        </Accordion.Header>
                        {val.Description && <Accordion.Body>
                          <div className="row">
                            <div className="col-sm-8">
                              <div key={`div_synonym}`} className="arch-details">
                              <p dangerouslySetInnerHTML={{ __html:DOMPurify.sanitize(t(val.Description) || "") }} />
                              </div>
                            </div>
                            <div className="col-sm-4">
                              <div className="arch-diagram"><img src={val.DescriptionImage} alt="image" /></div>
                            </div>
                          </div>
                        </Accordion.Body>}
                      </Accordion.Item>
                    </div>
                  );
                })}
              </Form>
            </Accordion>
          </div>
        </div>
      </div>
    );
};

export default SystemArchitectures;