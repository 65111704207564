//calculations for results
import React from 'react';
export class ResultsCalculations extends React.Component {
    private static numberOr0 = num => isNaN(num) ? 0 : num;
  
    public static getTotalWithPrecision = (inputColl: any[]) => {
        let sumValue = 0;
        try {
            sumValue = inputColl.reduce((a, b) =>
            ResultsCalculations.numberOr0(a) + ResultsCalculations.numberOr0(b));

        } catch {
            sumValue = 0;

        }
        return ResultsCalculations.getRoundValue(sumValue,1);
    };
    public static getUpdatedPrice = (price, percentage) => {
        let updatedPrice = 0;
        try {
            updatedPrice = price + ((price * percentage) / 100);

        } catch {
            updatedPrice = 0;

        }
        return ResultsCalculations.getRoundValue(updatedPrice,1);
    };
    public static getRoundValue = (value, precision) => {
        const multiplier = Math.pow(10, precision || 0);
        return Math.round(value * multiplier) / multiplier;
    };
}