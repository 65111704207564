/**********************************************************************************************
StepNavigation.tsx - Progrss bar sub componenet
***********************************************************************************************/
import React from 'react';
import TabChildStep from './TabChildStep';
const TabStepChildNavigation: React.FC<{ activeChildIndex: any, childLabelArray:any }> = ({ activeChildIndex, childLabelArray }) => {
    return (
        <div>
            <div className="nav-setup-child">
                <ul>
                    {
                        childLabelArray?.map((item_label: any, index:number) => 
                            <TabChildStep
                            key={index}
                            index={index}
                            label={item_label.architectureName}
                            activeIndex={activeChildIndex || 0}
                            completed={index <= activeChildIndex}/>
                    )
                          
                    }
                    
                </ul>
            </div>
        </div>
    );
};
export default TabStepChildNavigation;
