import React from 'react';
const TabChildStep = (props) => {
    return(
        <li><a className={(props.completed ? "active" : '')} href="#">{props.label?.includes("R744 Transcritical")           
            ?  
            "R744 Transcritical" : props.label
            }</a></li>
    );
};
export default TabChildStep;
