import React, { useCallback, useEffect, useState, useMemo } from "react";
import "./SimulationSetup.scss";
import { REGEX_ITEMS, LABELKEYS_SYSTEM_LOAD, LABELKEYS_SIMULATION_SETUP } from "../../../../../helper/constants";
import { useTranslation } from "react-i18next";
import ProgressStepper from "../../../../common/ProgressBar/ProgressStepper";
import Headline from "../../../../common/Headline/Headline";
import { setSimulationSystem, setTables,setCalculator } from "../../../../../store/actions";
import TabStepper from "../../../../common/TabContainer/TabStepper";
import { dataApi } from "../../../../../services/data";
import { useSelector } from "react-redux";
import { BiChevronDown } from "react-icons/bi";
import Loader from "../../../../common/Loader/Loader";
import { store } from '../../../../../store/ecoEfficiencyStore';
const SimulationSetup = () => {
  const { t } = useTranslation();
  const [isDefaultSimulationLoading, setIsDefaultSimulationLoading] = useState(false);
  const { SIMULATION_ARCHITECTURE_MAPPING, RESTORE_SESSION_PAGE_IDS, SET_LEAKEAGE_OPTIONS, SERVICE_REQUEST_FETCH_DATA_TYPES, CONFIG_SIMULATION_SETUP_SELECT_FIELD, BASE_PRICE_REFRIGERANT_TABLE_HEADER, BASE_LEAKAGE_RATE_TABLE_HEADER, PROGRESSBAR_STEPS_VALUE_COLL, PAGE_NAVIGATION, SYSTEM_ARCHS_PRE_CALCULATION_REACT_MS_MAPPING, ELECTRICITY_PRICE_RANGES, REFRIGENT_PRICE_RANGES, SYSTEM_ARCHS_KEYS, EXCLUDE_ARC_TYPE } = useSelector((state: any) => state?.ecoEfficiency?.config || {});
  const { userInputs, tables, updatedOn, restoreTransactionId, restoredSessions, restoreSessionData } = useSelector((state: any) => state?.ecoEfficiency?.calculator || {});
  const { chooseSystems, simulationSetup } = userInputs;
  const containsAll = chooseSystems?.filteredSystems?.filter(value => EXCLUDE_ARC_TYPE.includes(value));
  let newRefrigernatData = {};
  let newRefrigerantIntensityData = {};
  let newRefrigerantPriceData = {};
  let newRefrigerantLifeSpanData = {};
  let newRefrigerantLeakageRateData = {};
  const loadDefaultLT = async () => {
    await setIsDefaultSimulationLoading(true);
    const tmpSimulationTableData = store.dispatch(dataApi.endpoints.getData.initiate({
      dataType: SERVICE_REQUEST_FETCH_DATA_TYPES.DEFAULT_VALUES,
      path: "simulationSetup",
      subPath: "refrigerantPrice"
    }));

    const tmpSimulationIntensityTableData = store.dispatch(dataApi.endpoints.getData.initiate({
      dataType: SERVICE_REQUEST_FETCH_DATA_TYPES.DEFAULT_VALUES,
      path: "simulationSetup",
      subPath: "electricityIntensity"
    }));

    const tmpSimulationPriceTableData = store.dispatch(dataApi.endpoints.getData.initiate({
      dataType: SERVICE_REQUEST_FETCH_DATA_TYPES.DEFAULT_VALUES,
      path: "simulationSetup",
      subPath: "electricityPrice"
    }));
    const tmpSimulationLifeSpanTableData = store.dispatch(dataApi.endpoints.getData.initiate({
      dataType: SERVICE_REQUEST_FETCH_DATA_TYPES.DEFAULT_VALUES,
      path: "simulationSetup",
      subPath: "lifeSpanOfSystem"
    }));
    const tmpSimulationLeakageRateTableData = await store.dispatch(dataApi.endpoints.getData.initiate({
      dataType: SERVICE_REQUEST_FETCH_DATA_TYPES.DEFAULT_VALUES,
      path: "simulationSetup",
      subPath: "leakageRate"
    }));
    const [SimulationTableData, SimulationIntensityTableData, SimulationPriceTableData, SimulationLifeSpanTableData, SimulationLeakageRateTableData] = await Promise.all([tmpSimulationTableData, tmpSimulationIntensityTableData, tmpSimulationPriceTableData, tmpSimulationLifeSpanTableData, tmpSimulationLeakageRateTableData]);

    if (SimulationTableData?.data?.isSuccess) {
      newRefrigernatData = SimulationTableData?.data.result.data;
    }
    if (SimulationIntensityTableData?.data?.isSuccess) {
      newRefrigerantIntensityData = SimulationIntensityTableData?.data.result.data;
    }
    if (SimulationPriceTableData?.data?.isSuccess) {
      newRefrigerantPriceData = SimulationPriceTableData?.data.result.data;
    }


    if (SimulationLifeSpanTableData?.data?.isSuccess) {
      newRefrigerantLifeSpanData = SimulationLifeSpanTableData?.data.result.data;
    }


    if (SimulationLeakageRateTableData?.data?.isSuccess) {
      newRefrigerantLeakageRateData = SimulationLeakageRateTableData?.data.result.data;
    }
    setTables({ simulationSetupMaster: { refrigerantPrice: newRefrigernatData, electricityIntencity: newRefrigerantIntensityData, electricityPrice: newRefrigerantPriceData, lifeSpan: newRefrigerantLifeSpanData, leakageRate: newRefrigerantLeakageRateData } });

    await setIsDefaultSimulationLoading(false);
  };
  useEffect(() => {
    if (!tables?.simulationSetupMaster) {
      loadDefaultLT();

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setValue = useCallback((storeKey: any) => (e: React.ChangeEvent<HTMLSelectElement>) => {
    const isValid = REGEX_ITEMS?.NUMBER?.test(e?.target?.value);
    if (e?.target?.value?.length === 0 || isValid || e?.target?.value?.match(REGEX_ITEMS?.FLOAT_NUMBER)) {
    setSimulationSystem({
      ...simulationSetup, [storeKey]: { value: e.target.value }
    });
    }
  }, [simulationSetup]);

  const setInputValue = useCallback((storeKey: any) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const isValid = REGEX_ITEMS?.NUMBER?.test(e?.target?.value);
    if (e?.target?.value?.length === 0 || isValid || e?.target?.value?.match(REGEX_ITEMS?.FLOAT_NUMBER)) {
    setSimulationSystem({
      ...simulationSetup, [storeKey]: { value: e.target.value }
    });
  }
  }, [simulationSetup]);

  const setLeakageValue = useCallback((leakageKey: any) => (e: React.ChangeEvent<HTMLSelectElement>) => {
   
    const isValid = REGEX_ITEMS?.NUMBER?.test(e?.target?.value);
    if (e?.target?.value?.length === 0 || isValid || e?.target?.value?.match(REGEX_ITEMS?.FLOAT_NUMBER)) {
    setSimulationSystem({
      ...simulationSetup, leakageRate: { ...simulationSetup?.leakageRate, [leakageKey]: e.target.value }
    });
  }
  }, [simulationSetup]);

  const setRefPriceValue = useCallback((priceKey: any) => (e: React.ChangeEvent<HTMLInputElement>) => {
    
    const isValid = REGEX_ITEMS?.NUMBER?.test(e?.target?.value);
    if (e?.target?.value?.length === 0 || isValid || e?.target?.value?.match(REGEX_ITEMS?.FLOAT_NUMBER)) {
    setSimulationSystem({
      ...simulationSetup, refrigerantPrice: { ...simulationSetup?.refrigerantPrice, [priceKey]: e.target.value }
    });
  }
  }, [simulationSetup]);

  //restore session
  useEffect(() => {
    if(restoreTransactionId && !restoredSessions?.includes(RESTORE_SESSION_PAGE_IDS?.SIMU_SETUP))
    {
        restoreSimulationSetupSessionValues();
    }
// eslint-disable-next-line react-hooks/exhaustive-deps
}, []);

const restoreSimulationSetupSessionValues = () => {
  const paramTable = restoreSessionData?.[restoreTransactionId]?.[RESTORE_SESSION_PAGE_IDS?.SIMU_SETUP] ? restoreSessionData?.[restoreTransactionId]?.[RESTORE_SESSION_PAGE_IDS?.SIMU_SETUP] : "";
  
  if(paramTable) {  
      let refrigerantrestorePriceArray: any = {};
      let leakageRestoreRateArray: any = {};
      Object.keys(paramTable?.refrigerantPrice)?.map((item : any) => {
        refrigerantrestorePriceArray = { ...refrigerantrestorePriceArray, [`${item}`]: paramTable?.refrigerantPrice?.[`${item}`] };
      });
      Object.keys(paramTable?.leakageRate)?.map((item : any) => {
        const arcID = (item == SYSTEM_ARCHS_KEYS.ARC_9)?SYSTEM_ARCHS_KEYS.ARC_8:item;
        leakageRestoreRateArray = { ...leakageRestoreRateArray, [`${arcID}`]: paramTable?.leakageRate?.[`${item}`] };
      });
      setSimulationSystem({
        ...simulationSetup, electricityPrice: { value: paramTable?.electricityPrice || undefined },
        lifeSpanOfSystem: { value: paramTable?.lifeSpan|| undefined },
        electricityIntencity: { value: paramTable?.electricityIntencity?.value || undefined },
        refrigerantPrice: refrigerantrestorePriceArray,
        leakageRate: leakageRestoreRateArray
      });
      restoredSessions.push(RESTORE_SESSION_PAGE_IDS?.SIMU_SETUP);
      setCalculator({ restoredSessions: restoredSessions });
  }
};


  useEffect(() => {
            
    if (tables?.simulationSetupMaster && simulationSetup?.lifeSpanOfSystem?.value === undefined && simulationSetup?.electricityIntencity?.value === undefined && simulationSetup?.electricityPrice?.value === undefined && ((restoredSessions.length == 0) || (!restoreTransactionId) || (restoreTransactionId && restoredSessions.length > 0 && restoredSessions?.includes(RESTORE_SESSION_PAGE_IDS?.PARAM_SETUP)))) {
      let refrigerantPriceArray: any = {};
      let leakageRateArray: any = {};
      tables?.simulationSetupMaster?.refrigerantPrice?.forEach((configItem: any) => {
        refrigerantPriceArray = { ...refrigerantPriceArray, [`${configItem?.property}`]: configItem?.defaultValue };
      });
      tables?.simulationSetupMaster?.leakageRate?.forEach((configItem: any) => {
        leakageRateArray = { ...leakageRateArray, [`${SIMULATION_ARCHITECTURE_MAPPING?.filter(value => value.architectureName === configItem.property)?.map((item: any) => item?.architectureId)}`]: parseFloat(configItem?.defaultValue) * 100 };
      });
      setSimulationSystem({
        ...simulationSetup, electricityPrice: { value: tables?.simulationSetupMaster?.electricityPrice?.[0]?.defaultValue || undefined },
        lifeSpanOfSystem: { value: tables?.simulationSetupMaster?.lifeSpan?.[0]?.defaultValue || undefined },
        electricityIntencity: { value: tables?.simulationSetupMaster?.electricityIntencity?.[0]?.defaultValue || undefined },
        refrigerantPrice: refrigerantPriceArray,
        leakageRate: leakageRateArray
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tables?.simulationSetupMaster]);

  //reset  load setup values
  const setDefault = () => {
    let refrigerantPriceArray: any = {};
    let leakageRateArray: any = {};
    tables?.simulationSetupMaster?.refrigerantPrice?.forEach((configItem: any) => {
      refrigerantPriceArray = { ...refrigerantPriceArray, [`${configItem?.property}`]: configItem?.defaultValue };
    });
    tables?.simulationSetupMaster?.leakageRate?.forEach((configItem: any) => {
       leakageRateArray = { ...leakageRateArray, [`${SIMULATION_ARCHITECTURE_MAPPING?.filter(value => value.architectureName === configItem.property)?.map((item: any) => item?.architectureId)}`]: parseFloat(configItem?.defaultValue) * 100 };
       leakageRateArray = { ...leakageRateArray, [`${Object.keys(SIMULATION_ARCHITECTURE_MAPPING)?.filter(value => value === configItem.property)?.map((item: any) => item)}`]: parseFloat(configItem?.defaultValue) * 100 };
    
    });
    setSimulationSystem({
      ...simulationSetup, electricityPrice: { value: tables?.simulationSetupMaster?.electricityPrice?.[0]?.defaultValue || null },
      lifeSpanOfSystem: { value: tables?.simulationSetupMaster?.lifeSpan?.[0]?.defaultValue || 0 },
      electricityIntencity: { value: tables?.simulationSetupMaster?.electricityIntencity?.[0]?.defaultValue || 0 },
      refrigerantPrice: refrigerantPriceArray,
      leakageRate: leakageRateArray
    });

  };
  // selected refrigents
  const selectedRefrigents = useMemo(() => {
    const refrigentsSelected: any = [];  
    chooseSystems?.selectedSystems?.map((val) => {
      if(val != SYSTEM_ARCHS_KEYS.ARC_5)
      {
        if (chooseSystems?.system?.[val]) {
          ["ltSide", "mtSide", "highStage"].map((key) => {
            const refrigenValue: any = chooseSystems?.system?.[val]?.[key] || undefined;
            if (refrigenValue && refrigenValue?.length > 0 && !refrigentsSelected.includes(refrigenValue)) {
              refrigentsSelected.push(refrigenValue);
            }
          });
        }
      }
      else
      {
        if (chooseSystems?.system?.[val]) {
          ["ltSide", "highStage"].map((key) => {
            const refrigenValue: any = chooseSystems?.system?.[val]?.[key] || undefined;
            if (refrigenValue && refrigenValue?.length > 0 && !refrigentsSelected.includes(refrigenValue)) {
              refrigentsSelected.push(refrigenValue);
            }
          });
        }
      }
      
    });
    return refrigentsSelected;
    // eslint-disable-next-line
  }, [chooseSystems?.selectedSystems, chooseSystems?.system]);

  // check range 
  const inRange = useCallback((num, a, b, threshold = 0) => {
    return (Math.min(a, b) - threshold <= num && num <= Math.max(a, b) + threshold);
  }, []);
  const isRefrigerantPriceValid = useMemo(() => {
    let isValid = true;
    selectedRefrigents?.map((refrigent: any) => {
      const refgPrice = simulationSetup?.refrigerantPrice?.[refrigent];
      if (refrigent && (refgPrice == "" || (!(inRange(parseFloat(refgPrice), REFRIGENT_PRICE_RANGES?.MIN, REFRIGENT_PRICE_RANGES?.MAX))))) {
        isValid = false;
      }
    });
    return isValid;
  }, [selectedRefrigents, simulationSetup?.refrigerantPrice, REFRIGENT_PRICE_RANGES, inRange]);

  // is elec price valid
  const isElectricityPriceValid = useMemo(() => {
    let isValid = true;
    const elecPrice = simulationSetup?.electricityPrice?.value;
    if (elecPrice == "" || (!(inRange(parseFloat(elecPrice), ELECTRICITY_PRICE_RANGES?.MIN, ELECTRICITY_PRICE_RANGES?.MAX)))) {
      isValid = false;
    }
    return isValid;
  }, [simulationSetup?.electricityPrice, ELECTRICITY_PRICE_RANGES, inRange]);
  const isButtonDisabled = useMemo(() => {    
    return isRefrigerantPriceValid && isElectricityPriceValid;
  }, [isRefrigerantPriceValid, isElectricityPriceValid]);

  return (
    <div>
      <ProgressStepper step={''} completed={PROGRESSBAR_STEPS_VALUE_COLL.EcoEfficiency[2]} />
      <Headline key={`simulation_${updatedOn}`} isCalculationStart calculationId={SYSTEM_ARCHS_PRE_CALCULATION_REACT_MS_MAPPING?.SIMULATION_SETUP} runCalButton headline={t(LABELKEYS_SIMULATION_SETUP.HEADING)} backButton={PAGE_NAVIGATION.SYSTEM_DETAILS.PARAMETER_SETUP} continueButton={PAGE_NAVIGATION.RESULTS_PAGE.RESULTS} isDisable={!isButtonDisabled} />
      <TabStepper step={''} completed={PROGRESSBAR_STEPS_VALUE_COLL.EcoEfficiencyTabContainer[4]} calltoAction={t(LABELKEYS_SYSTEM_LOAD.RESET)} labelArray={PROGRESSBAR_STEPS_VALUE_COLL.EcoEfficiencyTabContainer} onResetClick={setDefault} />
      {isDefaultSimulationLoading ? <Loader /> :
        <div className="row simulation-setup mt-3">

          <div className="col-md-4 col-xs-12">
            <div className="title">{t(LABELKEYS_SIMULATION_SETUP.PRICE)}</div>
            <div className="simulation-setup-table">
              <div className="panel-body">
                <div className="table-head">
                  {BASE_PRICE_REFRIGERANT_TABLE_HEADER?.map((e: any) => {
                    return (
                      (t(e?.fieldKey) === "Refrigerant") ?
                        <div key={`div${e?.fieldKey}`} className="table-items col-md-6">
                          {t(e?.fieldKey)}{" "}
                        </div>
                        :
                        <div key={`div${e?.fieldKey}`} className="table-items col-md-6">
                          {t(e?.fieldKey)}{" "} {!isRefrigerantPriceValid && <span className="warning">{t(LABELKEYS_SIMULATION_SETUP.REFRIGENT_PRICE_WARNING)}</span>}
                        </div>
                    );
                  })}
                </div>
                {selectedRefrigents && selectedRefrigents?.map((refrigent: any, i) => {
                  const refrigerantPricevalue = simulationSetup?.[`${'refrigerantPrice'}`]?.[`${refrigent}`];
                  return (
                    <div key={`div${refrigent}${i}`}>
                      {refrigent && refrigent != "" ?
                        <div className="d-flex">
                          <div className="table-data col-md-8">{refrigent}</div>
                          <div className="table-data col-md-2">
                            <input type="text" aria-label="Price"
                              className={(refrigerantPricevalue == "" || !(inRange(parseFloat(refrigerantPricevalue), REFRIGENT_PRICE_RANGES.MIN,REFRIGENT_PRICE_RANGES.MAX))) ? "error" : ""}
                              value={
                                refrigerantPricevalue || ""}
                              onChange={setRefPriceValue(refrigent)} /></div>
                        </div>
                        :
                        ""}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="col-md-4 col-xs-12">
            {
              CONFIG_SIMULATION_SETUP_SELECT_FIELD?.map((e: any, i: any) => {
                return (
                  <div key={`div${e?.fieldKey}${i}`}>
                    <div className="title"> {t(e?.fieldLabel)} </div>
                    {e?.fieldInput === "select" ?
                      <div className="form-group">
                        <select className="form-control" placeholder="" aria-label={t(e?.fieldLabel)} value={simulationSetup?.[`${e?.fieldKey}`]?.value || ""} onChange={setValue(e?.fieldKey)}>
                          <option value="undefined">Select</option>
                          {
                            e?.fieldvalueoptions?.map((option: any, key: number) => {
                              return (
                                <option key={key} value={option.value}>{option.label}</option>
                              );
                            })}
                        </select>
                        <i className="angle-down-arrow"><BiChevronDown className="angle-down-icon" /></i>
                      </div>

                      :
                      <div className="form-group">
                        <input type="text" aria-label={t(e?.fieldLabel)} className={(simulationSetup?.[`${e?.fieldKey}`]?.value == "" || (e?.fieldKey == "electricityPrice" && !isElectricityPriceValid)) ? "form-control error-message" : ""} value={simulationSetup?.[`${e?.fieldKey}`]?.value || ""} onChange={setInputValue(e?.fieldKey)} />
                        {e?.spanName && <span className="small-text">{e.spanName}</span>}
                      </div>
                    }
                    {e?.fieldDivider === true && <div className="divider"></div>}
                  </div>
                );
              })
            }
          </div>
          <div className="col-md-4 col-xs-12">
            <div className="title">{t(LABELKEYS_SIMULATION_SETUP.LEAKAGE)}</div>
            <div className="simulation-setup-table">
              <div className="panel-body">
                <div className="table-head">
                  {BASE_LEAKAGE_RATE_TABLE_HEADER?.map((e: any) => {
                    return (
                      (e?.fieldCount === 6) ?
                        <div key={`div${e?.fieldKey}`} className="table-items col-md-6">
                          {t(e?.fieldKey)}{" "}
                        </div>
                        :
                        <div key={`div${e?.fieldKey}`} className="table-items col-md-6">
                          {t(e?.fieldKey)}{" "}
                        </div>
                    );
                  })}
                </div>
                {tables?.systemArchMaster?.filter(value => chooseSystems?.selectedSystems?.filter(val => (containsAll.length == 2) ? val != SYSTEM_ARCHS_KEYS.ARC_9 : SYSTEM_ARCHS_KEYS.ARC_8)?.indexOf(value?.architectureId) > -1)?.map((val: any, key) => {
                  const arcID = (val?.architectureId == SYSTEM_ARCHS_KEYS.ARC_9) ? SYSTEM_ARCHS_KEYS.ARC_8 : val?.architectureId;
                  return (
                    <div key={key} className="d-flex">
                      <div className="table-data col-md-8">
                        {val.architectureName?.includes("R744 Transcritical")           
                        ?  
                        "R744 Transcritical" : val.architectureName
                        }
                        </div>
                      <div className="table-data col-md-2">
                        <div className="form-group value">
                          <>
                            <select className="form-control" placeholder="" aria-label="Value" value={simulationSetup?.[`${'leakageRate'}`]?.[`${arcID}`] || ""} onChange={setLeakageValue(arcID)} >
                              {SET_LEAKEAGE_OPTIONS?.map((item, key) =>
                                <option key={key} value={item.value}>{item.label}</option>
                              )}
                            </select>
                            <i className="angle-down-arrow"><BiChevronDown className="angle-down-icon" /></i>
                          </>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      }
    </div>
  );
};

export default SimulationSetup;