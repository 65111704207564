import React, { useCallback, useState, useMemo, useEffect } from "react";
// import React, { useState, useCallback } from "react";
import { CALL_TO_ACTION, LABELKEYS_SAVE_SESSION, LABEL_KEYS_LOADER, REGEX_VALIDATION } from "../../../../../helper/constants";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { BiDownload } from "react-icons/bi";
import { useSelector } from "react-redux";
import ResultsPdf from "../PDF/ResultsPdf";
import jsPDF from "jspdf";
import Modal from "react-bootstrap/Modal";
import { MdClose } from 'react-icons/md';
import { setCalculator } from "../.../../../../../../store/actions";
import { sessionsApi } from "../../../../../services/sessions";
import { store } from '../../../../../store/ecoEfficiencyStore';
import { resetAllQuery } from "../../../../../store/reducers";
import './Headline.scss';
import Loader from "../../../../common/Loader/Loader";
import { BiChevronDown } from "react-icons/bi";
const ResultsHeadline = (props) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [showSaveModal, setShowSaveModal] = useState(false);
    const [isSessionSaved, setIsSessionSaved] = useState(true);
    const [sessionName, setSessionName] = useState("");
    const [loader, showLoader] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [selectSessionOveride, setSessionOverride] = useState("");
    const [showExistingSessionWarning, setShowExistingSessionWarning] = useState(false);
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { resultsTab, numberOfSession, transactionName, transactionId, userInputs, isRestoredSessionUpdated, restoreTransactionId, deleteTransactionId } = useSelector((state: any) => state?.ecoEfficiency?.calculator || {});
    const { PAGE_NAVIGATION } = useSelector((state: any) => state?.ecoEfficiency?.config || {});
    const { locationAndSize } = userInputs;

    useEffect(() => {
        setSessionName(transactionName);
        // eslint-disable-next-line
    }, []);
    //isSessionNameChanged
    const sessionList: any = useMemo(() => {
        let sessionList: any = [];
        sessionList = numberOfSession?.filter(s => s?.sessionId !== deleteTransactionId) || [];
        return sessionList;
    }, [deleteTransactionId, numberOfSession]);
    //isSessionNameChanged
    const isSessionNameChanged = useMemo(() => {
        const isSaveEnabled = (!restoreTransactionId || (restoreTransactionId && isRestoredSessionUpdated)) && (sessionList?.length < 5 || (sessionList?.length >= 5 && selectSessionOveride?.length > 0));

        return sessionName?.length > 0 && isSaveEnabled;
    }, [restoreTransactionId, isRestoredSessionUpdated, sessionName, sessionList, selectSessionOveride]);
    //Modal Functionality:   
    const onSaveButtonClick = useCallback((e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.preventDefault();
        if (sessionName?.length < 1) {
            const sessionTitle = locationAndSize?.country + " (" + locationAndSize?.city + "), " + `${locationAndSize?.storeSize.charAt(0).toUpperCase() + locationAndSize?.storeSize.slice(1).toLowerCase()} (${locationAndSize?.storeSizeValue} m2)`;
            setSessionName(sessionTitle);
        }
        setShowExistingSessionWarning(false);
        setShowSaveModal(true);
        setSessionOverride("");
        setIsSessionSaved(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sessionName]);
    // onChangeSessionName 
    const onChangeSessionName = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setIsSessionSaved(false);
        setSessionName(e?.target?.value.replace(
            REGEX_VALIDATION.API_INPUT_FIELD_VALIDATION,
            ""
        ));
    }, []);

    //On back button Click - will move to prev stage if region and respirator filled  
    const onSaveSessionClick = useCallback(async () => {
        await setIsSaving(true);
        await setShowExistingSessionWarning(false);
        let requestBody: any = { sessionName: sessionName };
        if (selectSessionOveride?.length > 0) {
            requestBody = { ...requestBody, deleteSessionId: selectSessionOveride };
        }
        const requestBodySave = { transactionId: transactionId, pageData: requestBody };
        const savedData = await store.dispatch(sessionsApi.endpoints.saveSession.initiate(requestBodySave));
        if (savedData?.data?.isSuccess) {
            if (savedData?.data?.result === 'ExistingSession') {
                setShowExistingSessionWarning(true);
            }
            else {
                setIsSessionSaved(true);
                setShowExistingSessionWarning(false);
                if (selectSessionOveride && selectSessionOveride?.length > 0) {
                    await setCalculator({ transactionName: sessionName, deleteTransactionId: selectSessionOveride });
                    await setSessionOverride("");
                }
                else {
                    await setCalculator({ transactionName: sessionName });
                }

            }
        }
        await store.dispatch(sessionsApi?.util?.resetApiState());
        await setIsSaving(false);
    }, [sessionName, transactionId, selectSessionOveride]);

    //On back button Click - will move to prev stage if region and respirator filled  
    const onRestartClick = useCallback(() => {
        resetAllQuery();
        navigate(PAGE_NAVIGATION.OVERVIEW);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [navigate]);
    //On back button Click - will move to prev stage if region and respirator filled  
    const onContactHoneywellClick = useCallback(() => {
        window.open(PAGE_NAVIGATION?.CONTACT_FORM);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    //On back button Click - will move to prev stage if region and respirator filled  
    const onModalClose = useCallback(() => {
        setIsSessionSaved(false);
        setShowSaveModal(false);
    }, []);

    const { DOWNLOAD_RESULTS_FILENAME } = useSelector((state: any) => state?.ecoEfficiency?.config || {});
    // const [isDownloading, setIsDownloading] = useState(false);

    //On back button Click - will move to prev stage if region and respirator filled  
    const onBackButtonClick = useCallback((e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        window.scrollTo(0, 0);
        e.preventDefault();
        navigate(-1);
    }, [navigate]);

    //remove All ChildNodes
    const removeAllChildNodes = (parent) => {
        while (parent.firstChild) {
            parent.removeChild(parent.firstChild);
        }
    };

    // To Generate Result section PDF
    const generateResultHtml = (doc) => {
        const pdfResultPage: any = window.document.getElementsByClassName("results-table")[0]?.cloneNode(true);
        const pdfWrapper = window.document.getElementById("results-tab");
        removeAllChildNodes(pdfWrapper);
        pdfWrapper?.appendChild(pdfResultPage);
        //remove all can vas elements

        /* GROUP BAR GRAPH STARTS */
        const pdfGroupChartPage: any = window.document.getElementsByClassName("results-group-graph")[0]?.cloneNode(true);
        const pdfGroupChartWrapper = window.document.getElementById("group-chart-graph");
        removeAllChildNodes(pdfGroupChartWrapper);
        pdfGroupChartWrapper?.appendChild(pdfGroupChartPage);
        const element: any = pdfGroupChartWrapper?.getElementsByTagName("canvas");
        for (let index = element.length - 1; index >= 0; index--) {
            element[index].parentNode.removeChild(element[index]);
        }
        const canvas1: any = document.querySelector('#group-graph-results');
        const canvas1Img: any = canvas1.toDataURL("image/png", 1.0);
        const img1 = document.createElement("img");
        img1.id = "pic";
        img1.src = canvas1Img;
        img1.width = 600;
        const graph1: any = pdfGroupChartWrapper?.querySelector(".graph-group");
        removeAllChildNodes(graph1);
        graph1.appendChild(img1);
        doc.setFont("Helvetica");
        /* GROUP BAR GRAPH ENDS */

        /* BUBBLE BAR GRAPH STARTS */
        const pdfBubbleChartPage: any = window.document.getElementsByClassName("results-bubble-graph")[0]?.cloneNode(true);
        const pdfBubbleChartWrapper = window.document.getElementById("bubble-chart-graph");
        removeAllChildNodes(pdfBubbleChartWrapper);
        pdfBubbleChartWrapper?.appendChild(pdfBubbleChartPage);
        const elementBubble: any = pdfBubbleChartWrapper?.getElementsByTagName("canvas");
        for (let index = elementBubble.length - 1; index >= 0; index--) {
            elementBubble[index].parentNode.removeChild(elementBubble[index]);
        }
        const canvas2: any = document.querySelector('#bubble-graph-results');
        const canvas2Img: any = canvas2.toDataURL("image/png", 1.0);
        const img2 = document.createElement("img");
        img2.id = "pic";
        img2.src = canvas2Img;
        img2.width = 600;
        const arrowX: any = window.document.getElementsByClassName("x-axis-arrow")[0]?.cloneNode(true);
        const arrowY: any = window.document.getElementsByClassName("y-axis-arrow")[0]?.cloneNode(true);
        const graph2: any = pdfBubbleChartWrapper?.querySelector(".graph-bubble");
        removeAllChildNodes(graph2);
        graph2.appendChild(arrowX);
        graph2.appendChild(img2);
        graph2.appendChild(arrowY);
        doc.setFont("Helvetica");
        /* BUBBLE BAR GRAPH ENDS */

        /* STACKED BAR GRAPH STARTS */
        const pdfStackedChartPage: any = window.document.getElementsByClassName("results-stacked-graph")[0]?.cloneNode(true);
        const pdfStackedChartWrapper = window.document.getElementById("stacked-chart-graph");
        removeAllChildNodes(pdfStackedChartWrapper);
        pdfStackedChartWrapper?.appendChild(pdfStackedChartPage);
        const elementStacked: any = pdfStackedChartWrapper?.getElementsByTagName("canvas");
        for (let index = elementStacked.length - 1; index >= 0; index--) {
            elementStacked[index].parentNode.removeChild(elementStacked[index]);
        }
        const canvas3: any = document.querySelector('#stacked-graph-results');
        const canvas3Img: any = canvas3.toDataURL("image/png", 1.0);
        const img3 = document.createElement("img");
        img3.id = "pic";
        img3.src = canvas3Img;
        img3.width = 800;
        const graph3: any = pdfStackedChartWrapper?.querySelector(".graph-stacked");
        removeAllChildNodes(graph3);
        graph3.appendChild(img3);
        doc.setFont("Helvetica");
        /* STACKED BAR GRAPH ENDS */
    };

    // To Generate Sensititvity Analysis section PDF
    const generateSensitivityHtml = (doc) => {
        if (props?.selectedParameterTab) {
            const pdfSensitivityWrapper = window.document.getElementById("parameter-tab");
            removeAllChildNodes(pdfSensitivityWrapper);
            pdfSensitivityWrapper?.appendChild(props?.selectedParameterTab);
            pdfSensitivityWrapper?.querySelectorAll(".angle-down-arrow")?.forEach(downArrowElm => {
                if (downArrowElm) {
                    downArrowElm?.remove();
                }
            });
        }

        /* GROUP BAR GRAPH STARTS */
        const pdfGroupChart1Page: any = window.document.getElementsByClassName("results-group-graph-1")[0]?.cloneNode(true);
        const pdfGroupChart1Wrapper = window.document.getElementById("group-chart-graph-1");
        removeAllChildNodes(pdfGroupChart1Wrapper);
        pdfGroupChart1Wrapper?.appendChild(pdfGroupChart1Page);
        const element1: any = pdfGroupChart1Wrapper?.getElementsByTagName("canvas");
        for (let index = element1.length - 1; index >= 0; index--) {
            element1[index].parentNode.removeChild(element1[index]);
        }
        const canvasGroup1: any = document.querySelector('#group-graph-sensitivity-absolute');
        const canvasGroup1Img: any = canvasGroup1.toDataURL("image/png", 1.0);
        const imgGroup1 = document.createElement("img");
        imgGroup1.id = "pic";
        imgGroup1.src = canvasGroup1Img;
        imgGroup1.width = 600;
        const graphGroup1: any = pdfGroupChart1Wrapper?.querySelector(".graph-group-1");
        removeAllChildNodes(graphGroup1);
        graphGroup1.appendChild(imgGroup1);
        doc.setFont("Helvetica");
        /* GROUP BAR GRAPH ENDS */

        /* GROUP BAR GRAPH STARTS */
        const pdfGroupChart2Page: any = window.document.getElementsByClassName("results-group-graph-2")[0]?.cloneNode(true);
        const pdfGroupChart2Wrapper = window.document.getElementById("group-chart-graph-2");
        removeAllChildNodes(pdfGroupChart2Wrapper);
        pdfGroupChart2Wrapper?.appendChild(pdfGroupChart2Page);
        const elementGroup2: any = pdfGroupChart2Wrapper?.getElementsByTagName("canvas");
        for (let index = elementGroup2.length - 1; index >= 0; index--) {
            elementGroup2[index].parentNode.removeChild(elementGroup2[index]);
        }
        const canvasGroup2: any = document.querySelector('#group-graph-sensitivity-relative');
        const canvasGroup2Img: any = canvasGroup2.toDataURL("image/png", 1.0);
        const imgGroup2 = document.createElement("img");
        imgGroup2.id = "pic";
        imgGroup2.src = canvasGroup2Img;
        imgGroup2.width = 600;
        const graphGroup2: any = pdfGroupChart2Wrapper?.querySelector(".graph-group-2");
        removeAllChildNodes(graphGroup2);
        graphGroup2.appendChild(imgGroup2);
        doc.setFont("Helvetica");
        /* GROUP BAR GRAPH ENDS */

        /* BUBBLE BAR GRAPH STARTS */
        const pdfBubbleChart1Page: any = window.document.getElementsByClassName("results-bubble-graph-1")[0]?.cloneNode(true);
        const pdfBubbleChart1Wrapper = window.document.getElementById("bubble-chart-graph-1");
        removeAllChildNodes(pdfBubbleChart1Wrapper);
        pdfBubbleChart1Wrapper?.appendChild(pdfBubbleChart1Page);
        const elementBubble1: any = pdfBubbleChart1Wrapper?.getElementsByTagName("canvas");
        for (let index = elementBubble1.length - 1; index >= 0; index--) {
            elementBubble1[index].parentNode.removeChild(elementBubble1[index]);
        }
        const canvasBubble1: any = document.querySelector('#bubble-graph-sensitivity-absolute');
        const canvasBubble1Img: any = canvasBubble1.toDataURL("image/png", 1.0);
        const imgBubble1 = document.createElement("img");
        imgBubble1.id = "pic";
        imgBubble1.src = canvasBubble1Img;
        imgBubble1.width = 600;
        const arrowX1: any = window.document.getElementsByClassName("x-axis-arrow")[0]?.cloneNode(true);
        const arrowY1: any = window.document.getElementsByClassName("y-axis-arrow")[0]?.cloneNode(true);
        const graph2: any = pdfBubbleChart1Wrapper?.querySelector(".graph-bubble-1");
        removeAllChildNodes(graph2);
        graph2.appendChild(arrowX1);
        graph2.appendChild(imgBubble1);
        graph2.appendChild(arrowY1);
        doc.setFont("Helvetica");
        /* BUBBLE BAR GRAPH ENDS */

        /* BUBBLE BAR GRAPH STARTS */
        const pdfBubbleChart2Page: any = window.document.getElementsByClassName("results-bubble-graph-2")[0]?.cloneNode(true);
        const pdfBubbleChart2Wrapper = window.document.getElementById("bubble-chart-graph-2");
        removeAllChildNodes(pdfBubbleChart2Wrapper);
        pdfBubbleChart2Wrapper?.appendChild(pdfBubbleChart2Page);
        const elementBubble2: any = pdfBubbleChart2Wrapper?.getElementsByTagName("canvas");
        for (let index = elementBubble2.length - 1; index >= 0; index--) {
            elementBubble2[index].parentNode.removeChild(elementBubble2[index]);
        }
        const canvasBubble2: any = document.querySelector('#bubble-graph-sensitivity-relative');
        const canvasBubble2Img: any = canvasBubble2.toDataURL("image/png", 1.0);
        const imgBubble2 = document.createElement("img");
        imgBubble2.id = "pic";
        imgBubble2.src = canvasBubble2Img;
        imgBubble2.width = 600;
        const arrowX2: any = window.document.getElementsByClassName("x-axis-arrow")[0]?.cloneNode(true);
        const arrowY2: any = window.document.getElementsByClassName("y-axis-arrow")[0]?.cloneNode(true);
        const graphBubble2: any = pdfBubbleChart2Wrapper?.querySelector(".graph-bubble-2");
        removeAllChildNodes(graphBubble2);
        graphBubble2.appendChild(arrowX2);
        graphBubble2.appendChild(imgBubble2);
        graphBubble2.appendChild(arrowY2);
        doc.setFont("Helvetica");
        /* BUBBLE BAR GRAPH ENDS */

        /* STACKED BAR GRAPH STARTS */
        const pdfStackedChart1Page: any = window.document.getElementsByClassName("results-stacked-graph-1")[0]?.cloneNode(true);
        const pdfStackedChart1Wrapper = window.document.getElementById("stacked-chart-graph-1");
        removeAllChildNodes(pdfStackedChart1Wrapper);
        pdfStackedChart1Wrapper?.appendChild(pdfStackedChart1Page);
        const elementStacked1: any = pdfStackedChart1Wrapper?.getElementsByTagName("canvas");
        for (let index = elementStacked1.length - 1; index >= 0; index--) {
            elementStacked1[index].parentNode.removeChild(elementStacked1[index]);
        }
        const canvasStacked1: any = document.querySelector('#stacked-graph-sensitivity-absolute');
        const canvasStacked1Img: any = canvasStacked1.toDataURL("image/png", 1.0);
        const imgStacked1 = document.createElement("img");
        imgStacked1.id = "pic";
        imgStacked1.src = canvasStacked1Img;
        imgStacked1.width = 800;
        const graphStacked1: any = pdfStackedChart1Wrapper?.querySelector(".graph-stacked-1");
        removeAllChildNodes(graphStacked1);
        graphStacked1.appendChild(imgStacked1);
        doc.setFont("Helvetica");

        const pdfStackedChart2Page: any = window.document.getElementsByClassName("results-stacked-graph-2")[0]?.cloneNode(true);
        const pdfStackedChart2Wrapper = window.document.getElementById("stacked-chart-graph-2");
        removeAllChildNodes(pdfStackedChart2Wrapper);
        pdfStackedChart2Wrapper?.appendChild(pdfStackedChart2Page);
        const elementStacked2: any = pdfStackedChart2Wrapper?.getElementsByTagName("canvas");
        for (let index = elementStacked2.length - 1; index >= 0; index--) {
            elementStacked2[index].parentNode.removeChild(elementStacked2[index]);
        }
        const canvasStacked2: any = document.querySelector('#stacked-graph-sensitivity-relative');
        const canvasStacked2Img: any = canvasStacked2.toDataURL("image/png", 1.0);
        const imgStacked2 = document.createElement("img");
        imgStacked2.id = "pic";
        imgStacked2.src = canvasStacked2Img;
        imgStacked2.width = 800;
        const graphStacked2: any = pdfStackedChart2Wrapper?.querySelector(".graph-stacked-2");
        removeAllChildNodes(graphStacked2);
        graphStacked2.appendChild(imgStacked2);
        doc.setFont("Helvetica");
        /* STACKED BAR GRAPH ENDS */
    };

    const downloadPDF = () => {

        try {
            showLoader(true);
            const doc = new jsPDF("p", "pt", [950, 2600]);
            if (resultsTab === "results") {
                generateResultHtml(doc);
            } else {
                generateResultHtml(doc);
                generateSensitivityHtml(doc);
            }

            if (resultsTab === "results") {
                const finalPage: any = window.document.querySelector(".eco-resultsPdf-page1");
                doc.html(finalPage, {
                    callback: function (doc) {
                        doc.save(DOWNLOAD_RESULTS_FILENAME.Results);
                        showLoader(false);
                    }, html2canvas: { scale: 1 }
                }).catch(() => {
                    showLoader(false);
                });

            } else {
                const finalPage: any = window.document.querySelector(".eco-resultsPdf-page1");
                const finalPage2: any = window.document.querySelector(".eco-resultsPdf-page2");
                finalPage2.style.marginLeft = '-7%';
                doc.html(finalPage, {
                    callback: function (doc) {
                        doc.addPage([950, 3900], "p");
                        doc.html(finalPage2, {
                            callback: function (doc) {
                                doc.save(DOWNLOAD_RESULTS_FILENAME.Results);
                                showLoader(false);
                            }, y: 3900
                        }).catch(() => {
                            showLoader(false);
                        });
                    }, html2canvas: { scale: 1 }
                }).catch(() => {
                    showLoader(false);
                });
            }
        }
        catch
        {
            showLoader(false);
        }
    };
    const setOverrideTransaction = useCallback((e) => {
        e.preventDefault();
        setSessionOverride(e?.target?.value);
    }, []);
    return (
        <>
            <div className="eco-header-results">
                <div className="overview">
                    <div className="eco-title">{props.headline}</div>
                    {props?.sizelocation !== false &&
                        <div className="store-size">
                            <span>{locationAndSize?.country + " (" + locationAndSize?.city + "), "}
                                <span className="f-bold">{locationAndSize?.storeSize.charAt(0).toUpperCase() + locationAndSize?.storeSize.slice(1).toLowerCase()} ({locationAndSize?.storeSizeValue} m<sup>2</sup>)</span>
                            </span>
                        </div>}
                </div>
                <div className="buttons">
                    <div className="btn btn-primary" onClick={onBackButtonClick}>{t(CALL_TO_ACTION.BACK)}</div>
                    <div className="download-icon" id={"btn-download-pdf"} onClick={downloadPDF}><BiDownload /></div>
                    <div className={props?.isDisable ? "btn btn-secondary disabled" : "btn btn-secondary"} onClick={onSaveButtonClick}>{t(CALL_TO_ACTION.SAVE)}</div>
                </div>
            </div>
            <ResultsPdf />
            <div className="loader-save-wrapper">
                <Modal className="save-session" show={showSaveModal}>
                    <div className="save-session-content">
                        <div className="close-icon"><MdClose onClick={onModalClose} /></div>
                        <div className="warning-title">{isSessionSaved ? t(LABELKEYS_SAVE_SESSION.TITLE_SAVED) : t(LABELKEYS_SAVE_SESSION.TITLE_SAVE)}</div>


                        {isSaving ? <Loader message={t(LABEL_KEYS_LOADER.SAVING)} /> :
                            <>
                                <div className="form-group pt-2">
                                    <label htmlFor="Title">{t(LABELKEYS_SAVE_SESSION.LABEL_TITLE)} <span className="mandatory"></span></label>
                                    <input type="text" value={sessionName} onChange={onChangeSessionName} maxLength={49} className="form-control" aria-label="Title" />
                                </div>
                                {showExistingSessionWarning && <p className="warning-text pb-3">{t(LABELKEYS_SAVE_SESSION.LABEL_WARNING)}</p>}
                                {(restoreTransactionId && !isRestoredSessionUpdated) && <p className="warning-text pb-3">{t(LABELKEYS_SAVE_SESSION.LABEL_NO_CHANGE_WARNING)}</p>}
                                {sessionList?.length >= 5 && <div className="form-group pt-2">
                                    <label htmlFor="Select Session">{t(LABELKEYS_SAVE_SESSION.LABEL_SESSION_TITLE)} <span className="mandatory"></span></label>
                                    <select className="form-control" placeholder="" aria-label={t(LABELKEYS_SAVE_SESSION.LABEL_SESSION_TITLE)} value={selectSessionOveride} onChange={setOverrideTransaction}>
                                        <option value="">Select exisiting Sessions</option>
                                        {sessionList?.map((val: any) => {
                                            return (
                                                <option key={val.sessionId} value={val.sessionId}>{val.sessionName}</option>
                                            );
                                        })}
                                    </select>
                                    <i className="angle-down-arrow"><BiChevronDown className="angle-down-icon" /></i>
                                </div>}

                            </>

                        }
                        <p>{t(LABELKEYS_SAVE_SESSION.MESSAGE_CONTACT)}</p>
                        <div className="hyperlink mb-4" id={"btn-contact-honeywell"} onClick={onContactHoneywellClick}>{t(LABELKEYS_SAVE_SESSION.BUTTON_CONTACT)}</div>
                        <div className="btn-container mx-0 pt-3">
                            <div className={(!isSessionNameChanged || isSaving) ? "btn btn-primary disabled" : "btn btn-primary"} id={"btn-save"} onClick={isSessionNameChanged && !isSaving ? onSaveSessionClick : undefined}>{t(LABELKEYS_SAVE_SESSION.BUTTON_SAVE)}</div>
                            <div className="btn btn-secondary" onClick={onRestartClick}>{t(LABELKEYS_SAVE_SESSION.BUTTON_RESTART)}</div>
                        </div>
                    </div>
                </Modal>
            </div>
            <div className="loader-save-wrapper">
                <Modal className="modal-loader-save" show={loader}>
                    <div className="loader-save-content">
                        <Loader message={t(LABEL_KEYS_LOADER.DOWNLOADING)} />
                    </div>
                </Modal>
            </div>
        </>
    );
};

export default ResultsHeadline;