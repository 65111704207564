/****************************************************
Footer.tsx 
*****************************************************/

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { IoIosArrowUp } from "react-icons/io";
import './Footer.scss';
function Footer() {
  const [footer, setFooter] = useState("");
  const { HEADER_FOOTER_MARKUP } = useSelector((state: any) => state?.ecoEfficiency?.config || {});
  useEffect(() => {
    if (HEADER_FOOTER_MARKUP) {
      setTimeout(function () {
        //your code to be executed after 2 second
        setFooter(HEADER_FOOTER_MARKUP.footerMarkup);
      }, 300);
    }
  }, [HEADER_FOOTER_MARKUP]);

  return (
    <div className="cmp-experiencefragment cmp-experiencefragment--footer">
      <div className="scroll-to-top js-to-top">
          <IoIosArrowUp className="arrow-ip" />
      </div>
      <div dangerouslySetInnerHTML={{ __html: footer }} />
    </div>
  );
}
export default Footer;