import { configApi } from '../services/configurations';
import { dataApi } from '../services/data';
import { graphApi } from '../services/graph';
import { calculateApi } from '../services/calculate';
import { preCalculateApi } from '../services/preCalculate';
import { sensitivityAnalysisApi } from '../services/sensitivityAnalysis';
import { sessionsApi } from '../services/sessions';
import { store } from './ecoEfficiencyStore';
import { STORE_ACTION_TYPES } from "../helper/constants";
import { EcoEfficiencyAppState } from "../types/allTypes";
import { LOCATION_AND_SIZE, CHOOSE_SYSTEMS, LOAD_SETUP, COMPRESSOR_SETUP, PARAMETER_SETUP, SIMULATION_SETUP, SENSITIVITY_ANALYSIS } from "./initialStoreValues";
import { LoadSetUpCalculations } from "../services/loadSetUpCalculations";
export const initialState: EcoEfficiencyAppState = {
    calculator: {
        updatedOn: Date.now(),
        prevPagePath: "/",
        preCalculationInputs: {},
        transactionId: undefined,
        transactionName: "",
        userInputs: { locationAndSize: LOCATION_AND_SIZE, chooseSystems: CHOOSE_SYSTEMS, loadSetup: LOAD_SETUP, compressorSetup: { ...COMPRESSOR_SETUP }, parameterSetup: PARAMETER_SETUP, simulationSetup: SIMULATION_SETUP, sensitivityAnalysis: SENSITIVITY_ANALYSIS },
        tables: { countryMaster: undefined, systemArchMaster: undefined, cabinetBySizeMaster: { Small: null, Medium: null, Large: null, Hyper: null }, binTemperatureGraphMaster: {}, compressorMaster: {}, simulationSetupMaster: undefined, resultsMaster: {}, sensityvityResultsMaster: {} },
        typeOfRack: "Medium",
        tabContainerNavigation: [],
        typeOfTemperatureCondensingUnit: "Medium",
        resultsTab: 'results',
        storeSimulationSessionValue: {},
        simulationSessionFlag: false,
        restoredSessions: [],
        restoreSessionData: {},
        restoreTransactionId: undefined,
        numberOfSession: {},
        isRestoredSessionUpdated: false,
        currentUserID: "",
        deleteTransactionId: undefined
    },
    config: undefined,
    language: "en-us",
    error: { showError: false, errorMessage: undefined, errorInfo: undefined, endpointName: undefined, errorMetaInfo: undefined }
};

//get API for the end points
const getAPI = (endpointName) => {
    switch (endpointName) {
        case "getConfigurations":
            return configApi;
            break;
        case "getData":
            return dataApi;
            break;
        case "getGraph":
            return graphApi;
            break;
        case "getCalculate":
            return calculateApi;
            break;
        case "getPreCalculate":
            return preCalculateApi;
            break;
        case "getSensitivityAnalysis":
            return sensitivityAnalysisApi;
            break;
        case "getSessions":
            return sessionsApi;
            break;
        case "saveSession":
            return sessionsApi;
            break;
        default:
            return undefined;
            break;
    }

};
export const resetAllQuery = () => {
    if (store) {
        store.dispatch(dataApi?.util?.resetApiState());
        store.dispatch(preCalculateApi?.util?.resetApiState());
        store.dispatch(calculateApi?.util?.resetApiState());
        store.dispatch(sessionsApi?.util?.resetApiState());
        store.dispatch(sensitivityAnalysisApi?.util?.resetApiState());
        store.dispatch(graphApi?.util?.resetApiState());
    }
};
export const resetQuery = () => {
    const state = store.getState();
    const error = state?.ecoEfficiency?.error || {};
    const api = getAPI(error?.endpointName);

    if (api && store) {
        store.dispatch(api?.util?.resetApiState());
        store.dispatch({
            type: STORE_ACTION_TYPES.RESET_QUERY,
            payload: {},
        });

    }
};
//reducers
export const ecoEfficiencyReducer = (state = initialState, action: any) => {

    let { calculator, config, error } = state || {};
    const restoreTransactionId: any = calculator?.restoreTransactionId;
    const restoreSessionData: any = calculator?.restoreSessionData;
    const restoredSessions: any = [...calculator?.restoredSessions] || [];
    switch (action.type) {
        case STORE_ACTION_TYPES.CALCULATOR:
            calculator = { ...calculator, updatedOn: Date.now(), ...action.payload };
            const newState = { ...state, calculator: calculator };
            return newState as EcoEfficiencyAppState;
        case STORE_ACTION_TYPES.RESET_CALCULATOR:
            calculator = action.payload;
            const newResetState = { ...state, calculator: calculator };
            return newResetState as EcoEfficiencyAppState;
        case STORE_ACTION_TYPES.TABLES:
            const calcWithTables = { ...calculator, updatedOn: Date.now(), tables: { ...calculator?.tables, ...action.payload } };
            const storeSize = calcWithTables?.userInputs?.locationAndSize?.storeSize || "";
            if (Object.keys(action?.payload)?.includes("cabinetBySizeMaster") && action?.payload?.cabinetBySizeMaster?.[storeSize]) {
                const storeSizeTable = calcWithTables?.tables?.cabinetBySizeMaster?.[storeSize];
                const intialLoadInputsState: any = [];
                storeSizeTable?.forEach(cabinet => {
                    if (cabinet) {
                        const capacityValue = LoadSetUpCalculations?.getCapacity(cabinet?.cabinetId, cabinet?.tempDefault, cabinet?.lengthDefault) || 0;
                        intialLoadInputsState.push({ cabinetId: cabinet?.cabinetId, cabinetType: cabinet?.cabinetType, temperature: cabinet?.tempDefault || undefined, length: cabinet?.lengthDefault || undefined, capacity: capacityValue });
                    }
                });
                if (intialLoadInputsState?.length > 0) {
                    const selectedMTLoad = LoadSetUpCalculations?.getTotalWithPrecision(intialLoadInputsState?.filter(input => input?.cabinetType === "MT")?.map(input => input?.capacity)) || 0;
                    const selectedLTLoad = LoadSetUpCalculations?.getTotalWithPrecision(intialLoadInputsState?.filter(input => input?.cabinetType === "LT")?.map(input => input?.capacity)) || 0;
                    const loadSetUp = { selectedMTLoad: selectedMTLoad, selectedLTLoad: selectedLTLoad, loadInputs: intialLoadInputsState };
                    if (calcWithTables?.userInputs) {
                        calcWithTables.userInputs.loadSetup = { ...loadSetUp };
                    }
                }
            }
            const newTableState = { ...state, calculator: calcWithTables };
            return newTableState as EcoEfficiencyAppState;
        case STORE_ACTION_TYPES.LOCATION_AND_SIZE:
            const prevSize = { ...calculator }.userInputs?.locationAndSize?.storeSize;
            const prevClimate = { ...calculator }.userInputs?.locationAndSize?.climate;
            const calcWithLocationAndSizes = { ...calculator, updatedOn: Date.now() };
            if (calcWithLocationAndSizes?.userInputs?.locationAndSize) {
                calcWithLocationAndSizes.userInputs.locationAndSize = { ...calculator?.userInputs?.locationAndSize, ...action.payload };
            }
            if (Object.keys(action?.payload)?.includes("storeSize") && calculator?.tables?.cabinetBySizeMaster?.[action?.payload?.storeSize as any]) {
                const storeSizeTable = calculator?.tables?.cabinetBySizeMaster?.[action?.payload?.storeSize as any];
                const intialLoadInputsState: any = [];
                storeSizeTable?.forEach(cabinet => {
                    if (cabinet) {
                        const capacityValue = LoadSetUpCalculations?.getCapacity(cabinet?.cabinetId, cabinet?.tempDefault, cabinet?.lengthDefault) || 0;
                        intialLoadInputsState.push({ cabinetId: cabinet?.cabinetId, cabinetType: cabinet?.cabinetType, temperature: cabinet?.tempDefault || undefined, length: cabinet?.lengthDefault || undefined, capacity: capacityValue });
                    }
                });
                if (intialLoadInputsState?.length > 0) {
                    const selectedMTLoad = LoadSetUpCalculations?.getTotalWithPrecision(intialLoadInputsState?.filter(input => input?.cabinetType === "MT")?.map(input => input?.capacity)) || 0;
                    const selectedLTLoad = LoadSetUpCalculations?.getTotalWithPrecision(intialLoadInputsState?.filter(input => input?.cabinetType === "LT")?.map(input => input?.capacity));
                    const loadSetUp = { selectedMTLoad: selectedMTLoad, selectedLTLoad: selectedLTLoad, loadInputs: intialLoadInputsState };
                    if (calcWithLocationAndSizes?.userInputs) {
                        calcWithLocationAndSizes.userInputs.loadSetup = { ...loadSetUp };
                    }
                }
            }
            else {
                if (calcWithLocationAndSizes?.userInputs && Object.keys(action?.payload)?.includes("storeSize")) {
                    const loadSetUp = { selectedMTLoad: 0, selectedLTLoad: 0, loadInputs: [] };
                    calcWithLocationAndSizes.userInputs.loadSetup = { ...loadSetUp };
                }
            }
            if ((Object.keys(action?.payload)?.includes("storeSize") && prevSize !== action?.payload?.storeSize) || (Object.keys(action?.payload)?.includes("climate") && prevClimate !== action?.payload?.climate)) {

                if (calcWithLocationAndSizes?.userInputs) {
                    calcWithLocationAndSizes.userInputs.chooseSystems = { ...CHOOSE_SYSTEMS };
                    calcWithLocationAndSizes.userInputs.compressorSetup = { ...COMPRESSOR_SETUP };
                }

            }
            const newLocationState = { ...state, calculator: calcWithLocationAndSizes };
            return newLocationState as EcoEfficiencyAppState;
        case STORE_ACTION_TYPES.CHOOSE_SYSTEMS:
            const prevSelectedSystems = { ...calculator }.userInputs?.chooseSystems?.selectedSystems;
            const calcWithChooseSystem = { ...calculator, updatedOn: Date.now() };
            if (calcWithChooseSystem?.userInputs?.chooseSystems) {
                calcWithChooseSystem.userInputs.chooseSystems = { ...calculator?.userInputs?.chooseSystems, ...action.payload };
            }
            const newSelectedSystems = calcWithChooseSystem.userInputs?.chooseSystems?.selectedSystems?.filter(newArch => !prevSelectedSystems?.includes(newArch));
            newSelectedSystems?.forEach(arch => {
                if (calcWithChooseSystem?.userInputs?.compressorSetup?.[arch]) {
                    calcWithChooseSystem.userInputs.compressorSetup[arch] = { ...COMPRESSOR_SETUP[arch] };
                }
            });
            const newChooseSystemState = { ...state, calculator: calcWithChooseSystem };
            return newChooseSystemState as EcoEfficiencyAppState;
        case STORE_ACTION_TYPES.CHOOSE_SYSTEMS_VALUES:
            const calcWithChooseSystemValues = { ...calculator, updatedOn: Date.now() };
            if (calcWithChooseSystemValues?.userInputs?.chooseSystems?.system) {
                calcWithChooseSystemValues.userInputs.chooseSystems.system = { ...calculator?.userInputs?.chooseSystems?.system, ...action.payload };
            }
            Object.keys(action?.payload)?.forEach(arch => {
                if (calcWithChooseSystemValues?.userInputs?.compressorSetup?.[arch]) {
                    calcWithChooseSystemValues.userInputs.compressorSetup[arch] = { ...COMPRESSOR_SETUP[arch] };
                }
            });
            const newChooseSystemValuesState = { ...state, calculator: calcWithChooseSystemValues };
            return newChooseSystemValuesState as EcoEfficiencyAppState;
        case STORE_ACTION_TYPES.LOAD_SETUP_VALUES:
            const calcWithLoadValues = { ...calculator, updatedOn: Date.now() };
            if (calcWithLoadValues?.userInputs?.loadSetup) {
                calcWithLoadValues.userInputs.loadSetup = { ...action.payload };
            }
            const newLoadsetupValuesState = { ...state, calculator: calcWithLoadValues };
            return newLoadsetupValuesState as EcoEfficiencyAppState;
        case STORE_ACTION_TYPES.LOAD_SETUP_VALUES_RESET:
            const calcWithLoadResetValues = { ...calculator, updatedOn: Date.now() };
            if (calcWithLoadResetValues?.userInputs?.loadSetup) {
                const storeSizeTable = calculator?.tables?.cabinetBySizeMaster?.[calculator?.userInputs?.locationAndSize?.storeSize as any];
                const intialLoadInputsState: any = [];
                storeSizeTable?.forEach(cabinet => {
                    if (cabinet) {
                        const capacityValue = LoadSetUpCalculations?.getCapacity(cabinet?.cabinetId, cabinet?.tempDefault, cabinet?.lengthDefault) || 0;
                        intialLoadInputsState.push({ cabinetId: cabinet?.cabinetId, cabinetType: cabinet?.cabinetType, temperature: cabinet?.tempDefault || undefined, length: cabinet?.lengthDefault || undefined, capacity: capacityValue });
                    }
                });
                if (intialLoadInputsState?.length > 0) {
                    const selectedMTLoad = LoadSetUpCalculations?.getTotalWithPrecision(intialLoadInputsState?.filter(input => input?.cabinetType === "MT")?.map(input => input?.capacity)) || 0;
                    const selectedLTLoad = LoadSetUpCalculations?.getTotalWithPrecision(intialLoadInputsState?.filter(input => input?.cabinetType === "LT")?.map(input => input?.capacity));
                    const loadSetUp = { selectedMTLoad: selectedMTLoad, selectedLTLoad: selectedLTLoad, loadInputs: intialLoadInputsState };
                    if (calcWithLoadResetValues?.userInputs) {
                        calcWithLoadResetValues.userInputs.loadSetup = { ...loadSetUp };
                    }
                }
            }
            const newLoadsetupResetValuesState = { ...state, calculator: calcWithLoadResetValues };
            return newLoadsetupResetValuesState as EcoEfficiencyAppState;
        case STORE_ACTION_TYPES.LOAD_SETUP_VALUES_RESTORE_SESSION:
            const calcWithLoadRestoreValues = { ...calculator, updatedOn: Date.now() };
            if (calcWithLoadRestoreValues?.userInputs?.loadSetup) {
                const storeSizeTable = calculator?.tables?.cabinetBySizeMaster?.[calculator?.userInputs?.locationAndSize?.storeSize as any];
                const loadsetupSessionData = restoreSessionData?.[restoreTransactionId]?.["Load_setup"];
                const loadsetupSessionCabinetInfo = { ...loadsetupSessionData?.MT, ...loadsetupSessionData?.LT };
                const intialLoadInputsState: any = [];
                storeSizeTable?.forEach(cabinet => {
                    const sessionLoadItem = loadsetupSessionCabinetInfo?.[cabinet?.cabinetId] || undefined;
                    if (cabinet && sessionLoadItem) {
                        const capacityValue = LoadSetUpCalculations?.getCapacity(cabinet?.cabinetId, sessionLoadItem?.temperature, sessionLoadItem?.length) || 0;
                        intialLoadInputsState.push({ cabinetId: cabinet?.cabinetId, cabinetType: cabinet?.cabinetType, temperature: sessionLoadItem?.temperature, length: sessionLoadItem?.length, capacity: capacityValue });
                    }
                });
                if (intialLoadInputsState?.length > 0) {
                    const selectedMTLoad = LoadSetUpCalculations?.getTotalWithPrecision(intialLoadInputsState?.filter(input => input?.cabinetType === "MT")?.map(input => input?.capacity)) || 0;
                    const selectedLTLoad = LoadSetUpCalculations?.getTotalWithPrecision(intialLoadInputsState?.filter(input => input?.cabinetType === "LT")?.map(input => input?.capacity));
                    const loadSetUp = { selectedMTLoad: selectedMTLoad, selectedLTLoad: selectedLTLoad, loadInputs: intialLoadInputsState };
                    if (calcWithLoadRestoreValues?.userInputs) {
                        calcWithLoadRestoreValues.userInputs.loadSetup = { ...loadSetUp };
                        restoredSessions.push("Load_setup");
                        calcWithLoadRestoreValues.restoredSessions = restoredSessions;
                    }
                }
            }
            const newLoadsetupRestoreValuesState = { ...state, calculator: calcWithLoadRestoreValues };
            return newLoadsetupRestoreValuesState as EcoEfficiencyAppState;
        case STORE_ACTION_TYPES.SIMULATION_SETUP:
            const calcWithSimulationValues = { ...calculator, updatedOn: Date.now() };
            if (calcWithSimulationValues?.userInputs?.simulationSetup) {
                calcWithSimulationValues.userInputs.simulationSetup = { ...action.payload };
            }
            const newSimulationsetupValuesState = { ...state, calculator: calcWithSimulationValues };
            return newSimulationsetupValuesState as EcoEfficiencyAppState;
        case STORE_ACTION_TYPES.COMPRESSOR_SETUP:
            const calcWithCompressorValues = { ...calculator, updatedOn: Date.now() };
            if (calcWithCompressorValues?.userInputs?.compressorSetup) {
                calcWithCompressorValues.userInputs.compressorSetup = { ...action.payload };
            }
            const newCompressorSetupValuesState = { ...state, calculator: calcWithCompressorValues };
            return newCompressorSetupValuesState as EcoEfficiencyAppState;
        case STORE_ACTION_TYPES.PARAMETER_SETUP:
            const calcWithParameterValues = { ...calculator, updatedOn: Date.now() };
            if (calcWithParameterValues?.userInputs?.parameterSetup) {
                calcWithParameterValues.userInputs.parameterSetup = { ...action.payload };
            }
            const newParametersetupValuesState = { ...state, calculator: calcWithParameterValues };
            return newParametersetupValuesState as EcoEfficiencyAppState;
        case STORE_ACTION_TYPES.SENSITIVITY_ANALYSIS:
            const calcWithSensitivityValues = { ...calculator, updatedOn: Date.now() };
            if (calcWithSensitivityValues?.userInputs?.sensitivityAnalysis) {
                calcWithSensitivityValues.userInputs.sensitivityAnalysis = { ...action.payload };
            }
            const newSensitivityAnylysisValuesState = { ...state, calculator: calcWithSensitivityValues };
            return newSensitivityAnylysisValuesState as EcoEfficiencyAppState;
        case STORE_ACTION_TYPES.ERROR:
            error = action.payload;
            const newErrorState = { ...state, error: error };
            return newErrorState as EcoEfficiencyAppState;
        case STORE_ACTION_TYPES.RESET_QUERY:
            const preCalculator = { ...calculator, updatedOn: Date.now() };
            const newQueryErrorState = { ...state, calculator: preCalculator, error: { ...error, showError: false, errorMessage: undefined, errorInfo: undefined, endpointName: undefined, errorMetaInfo: undefined } };
            return newQueryErrorState as EcoEfficiencyAppState;
        case STORE_ACTION_TYPES.INIT:
            config = action.payload;
            const newConfiState = { ...state, config: config };
            return newConfiState as EcoEfficiencyAppState;
        case STORE_ACTION_TYPES.CONFIG_QUERY:
        case STORE_ACTION_TYPES.FETCH_QUERY:
        case STORE_ACTION_TYPES.GRAPH_QUERY:
        case STORE_ACTION_TYPES.CALCULATE_QUERY:
        case STORE_ACTION_TYPES.PRE_CALCULATE_QUERY:
        case STORE_ACTION_TYPES.SENSITYVITY_QUERY:
        case STORE_ACTION_TYPES.SESSIONS_QUERY:
            const { endpointName } = action?.meta?.arg;
            if (!action?.payload?.isSuccess) {
                const result = action?.payload?.result;
                if (result === 'InvalidToken') {
                    window.location.href = window.location.href;

                }
                else if (endpointName != 'saveSession') {
                    const newConfiState = { ...state, calculator: { ...calculator, updatedOn: Date.now() }, error: { showError: true, errorMessage: undefined, errorInfo: result, endpointName: endpointName, errorMetaInfo: { endpointName: action?.meta?.arg?.endpointName || undefined, url: action?.meta?.baseQueryMeta?.request?.url || "", originalArgs: JSON.stringify(action?.meta?.arg?.originalArgs || "") } || undefined } };
                    return newConfiState as EcoEfficiencyAppState;
                }

            }
            return state;
            break;
        default:
            return state;
    }
};

