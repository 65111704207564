import React from "react";
import { useSelector } from "react-redux";
import "./Home.scss";
import SystemArchitectures from "./architectures/SystemArchitectures";
import StoreSizeDefaultConfig from "./storesize/StoreSizeDefaultConfig";
import ProgressStepper from '../../../common/ProgressBar/ProgressStepper';
import { SYSTEM_ARCHITECTURE_TABLE } from "../../../../helper/constants";
import { useTranslation } from "react-i18next";
import Headline from "../../../common/Headline/Headline";
const Home = () => {
    const { t } = useTranslation();
    const { updatedOn } = useSelector((state: any) => state?.ecoEfficiency?.calculator || {});
    const { PROGRESSBAR_STEPS_VALUE_COLL, PAGE_NAVIGATION } = useSelector((state: any) => state?.ecoEfficiency?.config || {});
    
    return (
        <div>
            <ProgressStepper step={''} completed={PROGRESSBAR_STEPS_VALUE_COLL.EcoEfficiency[0]} />
            <Headline key={`hl-Home_${updatedOn}`} headline={t(SYSTEM_ARCHITECTURE_TABLE.HEADLINE)} backtoTerms={true} backButton={PAGE_NAVIGATION.TERMS_CONDITION} continueButton={PAGE_NAVIGATION.LOCATION_SIZE} startButton={true} sizelocation={false} />
            <StoreSizeDefaultConfig />
            <SystemArchitectures />
        </div>
    );
};

export default Home;