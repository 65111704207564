import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./TermsConditions.scss";
import { DISCLAIMER_INFO, TERMS_CONDITIONS_INFO } from "../../../../helper/constants";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Modal from "react-bootstrap/Modal";
import WarningModal from "../../../common/WarningModal/WarningModal";
import DOMPurify from 'dompurify';
const TermsConditions = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [showDisclaimer, setShowDisclaimer] = useState(false);
    const [isCheck, setIsCheck] = useState(false);
    const [isDisable, setIsDisable] = useState(true);
    const { PAGE_NAVIGATION, PARENT_SITE_UTL } = useSelector((state: any) => state?.ecoEfficiency?.config || {});

    const handleClick = useCallback(() => {
        setIsCheck(!isCheck);
        if (isCheck) {
            setIsDisable(true);
        } else {
            setIsDisable(false);
        }
    },[isCheck]);

    const onButton1Click = useCallback((e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        window.scrollTo(0, 0);
        e.preventDefault();
        setShowDisclaimer(false);
    }, []);

    const onButton2Click = useCallback((e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        window.scrollTo(0, 0);
        e.preventDefault();
        setShowDisclaimer(false);
        navigate(PAGE_NAVIGATION.OVERVIEW);
    }, [navigate, PAGE_NAVIGATION.OVERVIEW]);

    const onDisagreeClick= useCallback((e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        window.scrollTo(0, 0);
        e.preventDefault();
        window.location.replace(`${PARENT_SITE_UTL}`);
        return null;
    }, [PARENT_SITE_UTL]);

    return (
        <div className="terms-conditions">
            <div className="content">
                <div className="sub-heading">{t(TERMS_CONDITIONS_INFO.HEADING)}</div>
                <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(t(TERMS_CONDITIONS_INFO.TERMS_CONDITION_TEXT) || "") }} />
                <div className="checkbox">
                    <input id={`cbReadAndAccept`} type="checkbox" onChange={handleClick} checked={isCheck} />
                    <label htmlFor={`cbReadAndAccept`}><span>{t(TERMS_CONDITIONS_INFO.ACCEPT_TERMS_CONDITION_TEXT)}</span></label>
                </div>
                <div className="btn-container">
                    <div className="btn btn-primary" id={"btn-disagree"} onClick={onDisagreeClick}>{t(TERMS_CONDITIONS_INFO.DISAGREE)}</div>
                    <div id={"btn-agree"} className={isDisable ? "btn btn-secondary disabled" : "btn btn-secondary"} onClick={onButton2Click}>{t(TERMS_CONDITIONS_INFO.AGREE)}</div>
                </div>
            </div>
            <Modal show={showDisclaimer}>
                <WarningModal
                    isWarning={true}
                    isCloseVisible={false}
                    title={t(DISCLAIMER_INFO.TITLE)}
                    messgage={t(DISCLAIMER_INFO.DISCLAIMER_TEXT)}
                    button1Text={t(TERMS_CONDITIONS_INFO.AGREE)}
                    button2Text={t(TERMS_CONDITIONS_INFO.DISAGREE)}
                    onClickButton1={onButton1Click}
                    onClickButton2={onButton2Click}
                />
            </Modal>
        </div>
    );
};

export default TermsConditions;