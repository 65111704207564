/**********************************************************************************************
StepNavigation.tsx - Progrss bar sub componenet
***********************************************************************************************/
import React from 'react';
import TabStep from './TabStep';
const TabStepNavigation: React.FC<{ activeIndex: any, labelArray: any, currentStep:any , results:any}> = ({ activeIndex, labelArray, currentStep , results}) => {
    return (
        <div>
            <div className={results ? "nav-setup-results" : "nav-setup"}>
                <ul>
                    {labelArray?.map((item: any, index: number) =>
                        <TabStep
                            key={index}
                            index={index}
                            label={item}
                            activeIndex={activeIndex || 0}
                            completed={index <= activeIndex}
                            currentStep={currentStep}
                        />
                    )}
                </ul>
            </div>
        </div>
    );
};
export default TabStepNavigation;
