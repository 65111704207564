
import {store} from './ecoEfficiencyStore';
import { STORE_ACTION_TYPES } from "../helper/constants";
//Actions
export const setConfig = (resources) => {
    store.dispatch( { type: STORE_ACTION_TYPES.INIT, payload: resources });    
};

export const setStoreSize = (resources) => {
    store.dispatch( { type: STORE_ACTION_TYPES.CALCULATOR, payload: resources });    
};
export const setCalculator = (resources) => {
    store.dispatch( { type: STORE_ACTION_TYPES.CALCULATOR, payload: resources });    
};
export const setLocationAndStoreSizeValues = (keys) => {
    store.dispatch( { type: STORE_ACTION_TYPES.LOCATION_AND_SIZE, payload: keys });    
};
export const setTables = (keys) => {
    store.dispatch( { type: STORE_ACTION_TYPES.TABLES, payload: keys });    
};
export const setChooseSystem = (keys) => {
    store.dispatch( { type: STORE_ACTION_TYPES.CHOOSE_SYSTEMS, payload: keys });    
};
export const setChooseSystemValues = (keys) => {
    store.dispatch( { type: STORE_ACTION_TYPES.CHOOSE_SYSTEMS_VALUES, payload: keys });    
};
export const setLoadSetupValues = (keys) => {
    store.dispatch( { type: STORE_ACTION_TYPES.LOAD_SETUP_VALUES, payload: keys });    
};
export const reSetLoadSetupValues = () => {
    store.dispatch( { type: STORE_ACTION_TYPES.LOAD_SETUP_VALUES_RESET, payload: {}});    
};
export const restoreLoadSetupSessionValues = () => {
    store.dispatch( { type: STORE_ACTION_TYPES.LOAD_SETUP_VALUES_RESTORE_SESSION, payload: {}});    
};
export const setSimulationSystem = (keys) => {
    store.dispatch( { type: STORE_ACTION_TYPES.SIMULATION_SETUP, payload: keys });    
};
export const reSetSimulationSetupValues = () => {
    store.dispatch( { type: STORE_ACTION_TYPES.SIMULATION_SETUP_VALUES_RESET, payload: {}});    
};
export const setCompressorSetup = (keys) => {
    store.dispatch( { type: STORE_ACTION_TYPES.COMPRESSOR_SETUP, payload: keys });    
};
export const setParameterSetup = (keys) => {
    store.dispatch( { type: STORE_ACTION_TYPES.PARAMETER_SETUP, payload: keys });    
};
export const setSensitivityAnalysis = (keys) => {
    store.dispatch( { type: STORE_ACTION_TYPES.SENSITIVITY_ANALYSIS, payload: keys });    
};
export const setResetCalculator = (resources) => {
    store.dispatch( { type: STORE_ACTION_TYPES.RESET_CALCULATOR, payload: resources });    
};