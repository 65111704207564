import React, { useCallback, useMemo } from "react";
import "./SystemAnalysisPanel.scss";
import { ImgWarning } from "../../../../../../assets/images/archSelection";
import { useTranslation } from "react-i18next";
import { LABELKEYS_SYSTEM_ANALYSIS_PANEL } from "../../../../../../helper/constants";
import { LoadSetUpCalculations } from "../../../../../../services/loadSetUpCalculations";
import { useSelector } from "react-redux";
const SystemAnalysisPanel = (props) => {
  const { t } = useTranslation();
  //rack type selection
  // eslint-disable-next-line
  const onRackClick = useCallback((name) => () => {
    props?.setRackType(name);
  }, [props]);
  const { userInputs, tables } = useSelector((state: any) => state?.ecoEfficiency?.calculator || {});
  const { TYPE_OF_RACK,LOAD_SETUP_CONFIG } = useSelector((state: any) => state?.ecoEfficiency?.config || {});
  const { locationAndSize, loadSetup } = userInputs;
  
  //mtLoadRanges 
  const mtLoadRanges = useMemo(() => {
    return ({ from: LoadSetUpCalculations?.getTotal(tables?.cabinetBySizeMaster?.[`${locationAndSize?.storeSize}`]?.filter(value => value.cabinetType === TYPE_OF_RACK.MT)?.map(cabinet => cabinet?.capacityFrom && cabinet?.capacityFrom) || []) || 0, to: LoadSetUpCalculations?.getTotal(tables?.cabinetBySizeMaster?.[`${locationAndSize?.storeSize}`]?.filter(value => value.cabinetType === TYPE_OF_RACK.MT)?.map(cabinet => cabinet?.capacityTo && cabinet?.capacityTo) || []) || 0 });
  }, [locationAndSize?.storeSize, tables?.cabinetBySizeMaster,TYPE_OF_RACK.MT]);
  //ltLoadRanges
  const ltLoadRanges = useMemo(() => {
    return ({ from: LoadSetUpCalculations?.getTotal(tables?.cabinetBySizeMaster?.[`${locationAndSize?.storeSize}`]?.filter(value => value.cabinetType === TYPE_OF_RACK.LT)?.map(cabinet => cabinet?.capacityFrom && cabinet?.capacityFrom) || []) || 0, to: LoadSetUpCalculations?.getTotal(tables?.cabinetBySizeMaster?.[`${locationAndSize?.storeSize}`]?.filter(value => value.cabinetType === TYPE_OF_RACK.LT)?.map(cabinet => cabinet?.capacityTo && cabinet?.capacityTo) || []) || 0 });
  }, [locationAndSize?.storeSize, tables?.cabinetBySizeMaster,TYPE_OF_RACK]);

  //ltLoadRanges warning
  const isLTLoadRangeWarning = useMemo(() => {
    return ((parseFloat(loadSetup?.selectedLTLoad) < (ltLoadRanges?.from-(ltLoadRanges?.from*(LOAD_SETUP_CONFIG.DEVIATION/100)))) || (parseFloat(loadSetup?.selectedLTLoad) > (ltLoadRanges.to+(ltLoadRanges?.to*(LOAD_SETUP_CONFIG.DEVIATION/100)))));
  }, [ltLoadRanges, loadSetup?.selectedLTLoad,LOAD_SETUP_CONFIG]);
  //mtLoadRanges warning
  const isMTLoadRangeWarning = useMemo(() => {
    return ((parseFloat(loadSetup?.selectedMTLoad) < (mtLoadRanges.from-(mtLoadRanges?.from*(LOAD_SETUP_CONFIG.DEVIATION/100)))) || (parseFloat(loadSetup?.selectedMTLoad) > (mtLoadRanges.to+(mtLoadRanges?.to*(LOAD_SETUP_CONFIG.DEVIATION/100)))));
  }, [mtLoadRanges, loadSetup?.selectedMTLoad,LOAD_SETUP_CONFIG]);
  return (
    <div className="system-analysis-panel">
      <div className="content">
        <div className="title">{t(LABELKEYS_SYSTEM_ANALYSIS_PANEL.MEDIUM_TEMPERATURE)}</div>
        <div className="selected-item">
          <div className="info">{t(LABELKEYS_SYSTEM_ANALYSIS_PANEL.SELECTED_LOAD_MT)}</div>
          <div className="info"> {loadSetup?.selectedMTLoad} </div>
        </div>
        <div className="load-range">
          <div className="info">{t(LABELKEYS_SYSTEM_ANALYSIS_PANEL.LOAD_MT)}</div>
          <div className="info">{mtLoadRanges?.from}-{mtLoadRanges?.to}</div>
        </div>
        {isMTLoadRangeWarning && <div className="warning-message">
          <div className="mesg">
            <div className="warning-icon"><img src={ImgWarning} alt="Warning" width="18px" /></div>
            <span>{t(LABELKEYS_SYSTEM_ANALYSIS_PANEL.WARNING)}</span>
          </div>
            <span> {t(LABELKEYS_SYSTEM_ANALYSIS_PANEL.WARNING_MSG_MT_LOAD)}</span>
        </div>}
        <div className={props?.typeOfRack === TYPE_OF_RACK.MT ? "btn btn-black" : "btn btn-white"} onClick={onRackClick(TYPE_OF_RACK.MT)}>{t(LABELKEYS_SYSTEM_ANALYSIS_PANEL.SELECT)}</div>
      </div>
      <div className="content">
        <div className="title">{t(LABELKEYS_SYSTEM_ANALYSIS_PANEL.LOW_TEMPERATURE)}</div>
        <div className="selected-item">
          <div className="info">{t(LABELKEYS_SYSTEM_ANALYSIS_PANEL.SELECTED_LOAD_LT)}</div>
          <div className="info"> {loadSetup?.selectedLTLoad}</div>
        </div>
        <div className="load-range">
          <div className="info">{t(LABELKEYS_SYSTEM_ANALYSIS_PANEL.LOAD_LT)}</div>
          <div className="info">{ltLoadRanges?.from}-{ltLoadRanges?.to} </div>
        </div>
        {isLTLoadRangeWarning && <div className="warning-message">
          <div className="mesg">
            <div className="warning-icon"><img src={ImgWarning} alt="Warning" width="18px" /></div>
            <span>{t(LABELKEYS_SYSTEM_ANALYSIS_PANEL.WARNING)}</span>
          </div>
            <span> {t(LABELKEYS_SYSTEM_ANALYSIS_PANEL.WARNING_MSG_LT_LOAD)}</span>
        </div>}
        <div className={props?.typeOfRack === TYPE_OF_RACK.LT? "btn btn-black" : "btn btn-white"} onClick={onRackClick(TYPE_OF_RACK.LT)}>{t(LABELKEYS_SYSTEM_ANALYSIS_PANEL.SELECT)}</div>
      </div>
    </div>
  );
};

export default SystemAnalysisPanel;