//location and size initial values
export const LOCATION_AND_SIZE = {
    country: "",
    city: "",
    storeSize: "",
    storeSizeValue: "",
    climate:"",
};
//choose systems values
export const CHOOSE_SYSTEMS = {
    selectedSystems: [],
    baseline: "",
    system:
    {
        ARC_1: {
            ltSide: "",
            mtSide: "",
            highStage: ""
        },
        ARC_2: {
            ltSide: "",
            mtSide: "",
            highStage: ""
        },
        ARC_3: {
            ltSide: "",
            mtSide: "",
            highStage: ""
        },
        ARC_4: {
            ltSide: "",
            mtSide: "",
            highStage: ""
        },
        ARC_5: {
            ltSide: "",
            mtSide: "",
            highStage: ""
        },
        ARC_6: {
            ltSide: "",
            mtSide: "",
            highStage: ""
        },
        ARC_7: {
            ltSide: "",
            mtSide: "",
            highStage: ""
        },
        ARC_8: {
            ltSide: "",
            mtSide: "",
            highStage: ""
        },
        ARC_9: {
            ltSide: "",
            mtSide: "",
            highStage: ""
        }

    },
    filteredSystems: [],
};

//load setup system values
export const LOAD_SETUP = {
    selectedMTLoad: null,
    selectedLTLoad: null,
    loadInputs: []
};

//comressor setup system values
export const COMPRESSOR_SETUP = {
    ARC_1: {
        subCooler: "0",
        MT: {},
        LT: {}
    },
    ARC_2: {
        subCooler: "15",
        configuration: "Rack",
        MT: {},
        LT: {}
    },
    ARC_3: {
        MT: {},
        LT: {}
    },
    ARC_4: {
        MT: {},
        LT: {}
    },
    ARC_5: {
        MT: {},
        LT: {}
    },
    ARC_6: {
        MT: {},
        LT: {}
    },
    ARC_7: {
        MT: {},
        LT: {}
    },
    ARC_8: {
        MT: {},
        LT: {}
    },
    ARC_9: {
        MT: {},
        LT: {}
    }
};

//parameter setup system values
export const PARAMETER_SETUP = {
    /*approachTemperature:
    {
        evaporator: undefined,
        condenser: undefined,
        gasCoolerCo2TransCritical: undefined,
        secondaryHeatExchangers: undefined,
    },*/
    saturationTemperatureSuction:
    {
        tdEvap: undefined,
        tdCond: undefined,
        delpSuc: undefined,
        delpLiq: undefined
    },
    saturationTemperatureLiquid:
    {
        tdEvap: undefined,
        tdCond: undefined,
        delpSuc: undefined,
        delpLiq: undefined
    },
    minimumCondensingTemperature:
    {
        minimumCondensingTemperature: undefined,
        expValue: undefined
    },
    percentageLoadInDayAndNight:
    {
        dayLoad: '90',
        nightLoad: '70'
    }
};

//simulation setup systems value
export const SIMULATION_SETUP =
{
    refrigerantPrice:
    {
        R404A: undefined,
        R448A: undefined,
        R455A: undefined,
        R290: undefined,
        R744: undefined,
        R515B: undefined,
        R513A: undefined,
        R134a: undefined,
        R471A: undefined,
    },
    electricityIntencity:
    {
        name: "",
        value: undefined,
    },
    electricityPrice:
    {
        value: undefined
    },
    lifeSpanOfSystem:
    {
        value: undefined
    },
    leakageRate:
    {
        ARC_1: undefined,
        ARC_2: undefined,
        ARC_3: undefined,
        ARC_4: undefined,
        ARC_5: undefined,
        ARC_6: undefined,
        ARC_7: undefined,
        ARC_8: undefined,
    }
};

//sensitivity analysis value
export const SENSITIVITY_ANALYSIS =
{
    refrigerantPrice:
    {
        R404A: undefined,
        R448A: undefined,
        R455A: undefined,
        R290: undefined,
        R744: undefined,
        R515B: undefined,
        R513A: undefined,
        R134a: undefined,
        R471A: undefined,
    },
    electricityIntencity:
    {
        name: "",
        value: undefined,
    },
    electricityPrice:
    {
        value: undefined
    },
    lifeSpanOfSystem:
    {
        value: undefined
    },
    leakageRate:
    {
        ARC_1: undefined,
        ARC_2: undefined,
        ARC_3: undefined,
        ARC_4: undefined,
        ARC_5: undefined,
        ARC_6: undefined,
        ARC_7: undefined,
        ARC_8: undefined,
    },
    refrigerantChangePricePercentage:
    {
        R404A: "0",
        R448A: "0",
        R455A: "0",
        R290: "0",
        R744: "0",
        R515B: "0",
        R513A: "0",
        R134a: "0",
        R471A: "0",
    },
    leakageRatePercentage:
    {
        ARC_1: "0",
        ARC_2: "0",
        ARC_3: "0",
        ARC_4: "0",
        ARC_5: "0",
        ARC_6: "0",
        ARC_7: "0",
        ARC_8: "0",
    }
};