import React, { useCallback, useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { skipToken } from "@reduxjs/toolkit/query";
import "./SemiDistributedSystem.scss";
import { LABELKEYS_COMPRESSOR_SETUP, LABELKEYS_SYSTEM_LOAD, REGEX_ITEMS } from "../../../../../../helper/constants";
import { useTranslation } from "react-i18next";
import TabStepper from "../../../../../common/TabContainer/TabStepper";
import ProgressStepper from '../../../../../common/ProgressBar/ProgressStepper';
import Headline from '../../../../../common/Headline/Headline';
import SystemAnalysisPanel from "../systemanalysispanel/SystemAnalysisPanel";
import { BiChevronDown } from "react-icons/bi";
import { useGetDataQuery, dataApi } from "../../../../../../services/data";
import { setTables, setCompressorSetup, setCalculator } from "../../../../../../store/actions";
import { CompressorSetUpCalculations } from "../../../../../../services/compressorSetUpCalculations";
import Loader from "../../../../../common/Loader/Loader";
import { store } from '../../../../.../../../../store/ecoEfficiencyStore';
const SemiDistributedSystem = () => {
  const { t } = useTranslation();
  const { config } = useSelector((state: any) => state?.ecoEfficiency || {});
  const { userInputs, tables, updatedOn, restoreTransactionId, restoredSessions, restoreSessionData } = useSelector((state: any) => state?.ecoEfficiency?.calculator || {});
  const { locationAndSize, chooseSystems, compressorSetup, loadSetup } = userInputs;
  const [climate] = useState(tables?.countryMaster?.[locationAndSize?.country]?.[locationAndSize?.city]);
  const { ARC_2 } = compressorSetup;
  const { SYSTEM_ARCHS_PRE_CALCULATION_REACT_MS_MAPPING, TYPE_OF_RACK, COMPRESSOR_ARCHS_CONFIG, SYSTEM_ARCHS_KEYS, SERVICE_REQUEST_FETCH_DATA_TYPES, COMPRESSOR_SETUP_CENTRALIZED_TABLE_HEADER, PROGRESSBAR_STEPS_VALUE_COLL, PAGE_NAVIGATION, SYSTEM_ARCHS_REACT_MS_MAPPING, RESTORE_SESSION_PAGE_IDS } = useSelector((state: any) => state?.ecoEfficiency?.config || {});
  const [typeOfRack, setTypeOfRack] = useState(TYPE_OF_RACK?.MT);
  const [selectedRack, setSelectedRack] = useState(COMPRESSOR_ARCHS_CONFIG?.RACK_VALUES?.RACK_1);
  const [defaultResetKey, setDefaultResetKey] = useState(Date.now());
  const [isDefaultLTLoading, setIsDefaultLTLoading] = useState(false);
  const type = ARC_2?.[typeOfRack]?.type;
  const company = ARC_2?.[typeOfRack]?.company;

  //type restoreSessionData
  const restoreSessionArcData = useMemo(() => {
    if (!restoredSessions?.includes(RESTORE_SESSION_PAGE_IDS?.ARC_2) && restoreSessionData?.[restoreTransactionId]?.[RESTORE_SESSION_PAGE_IDS?.ARC_2]) {
      return restoreSessionData?.[restoreTransactionId]?.[RESTORE_SESSION_PAGE_IDS?.ARC_2] || undefined;
    }
    else {
      return undefined;
    }
  }, [RESTORE_SESSION_PAGE_IDS, restoreTransactionId, restoredSessions, restoreSessionData]);

  //On rack type size
  const setRackType = useCallback((rackType) => {
    setTypeOfRack(rackType);
  }, [setTypeOfRack]);

  //On rack change
  const setRack = useCallback((rackType, rack) => {
    setSelectedRack(rack);
    setTypeOfRack(rackType);

  }, [setTypeOfRack]);

  //rack collection
  const activeRacks = useMemo(() => {
    return COMPRESSOR_ARCHS_CONFIG?.[SYSTEM_ARCHS_KEYS.ARC_2]?.[locationAndSize?.storeSize]?.[typeOfRack]?.Racks || [];
  }, [COMPRESSOR_ARCHS_CONFIG, SYSTEM_ARCHS_KEYS, typeOfRack, locationAndSize?.storeSize]);

  //refrigerant
  const refrigerant = useMemo(() => {
    if (typeOfRack == TYPE_OF_RACK.MT) {
      return chooseSystems?.system?.[SYSTEM_ARCHS_KEYS.ARC_2]?.mtSide;
    }
    else if (typeOfRack == TYPE_OF_RACK.LT) {
      return chooseSystems?.system?.[SYSTEM_ARCHS_KEYS.ARC_2]?.ltSide;
    }
    else {
      return null;
    }
  }, [chooseSystems, typeOfRack, SYSTEM_ARCHS_KEYS, TYPE_OF_RACK]);
  //type options
  const optionsTypeValues = useMemo(() => {
    return COMPRESSOR_ARCHS_CONFIG?.[SYSTEM_ARCHS_KEYS.ARC_2]?.[typeOfRack]?.[refrigerant]?.[locationAndSize?.storeSize]?.TYPES || [];
  }, [COMPRESSOR_ARCHS_CONFIG, SYSTEM_ARCHS_KEYS, typeOfRack, refrigerant,locationAndSize?.storeSize]);
  //tableKey
  const tableKey = useMemo(() => {
    return `${locationAndSize?.storeSize}-${SYSTEM_ARCHS_KEYS.ARC_2}-${typeOfRack}-${refrigerant}-${type}-${company}-${climate}`;
  }, [typeOfRack, locationAndSize?.storeSize, refrigerant, SYSTEM_ARCHS_KEYS, type, company, climate]);

  /*------Data Query -----*/
  //country master
  const { data: compressorData, isLoading: isCompressorDataLoading } = useGetDataQuery(!type || !company || !refrigerant || tables?.compressorMaster?.[tableKey] ? skipToken : {
    tableKey: tableKey,
    dataType: SERVICE_REQUEST_FETCH_DATA_TYPES.COMPRESSOR,
    storeSize: locationAndSize?.storeSize,
    architectureId: SYSTEM_ARCHS_KEYS.ARC_2,
    cabinetType: typeOfRack,
    type: type,
    company: company,
    refrigerant: refrigerant,
    climate: climate
  });
  useEffect(() => {
    if (compressorData?.result?.data?.compressorData && !tables?.compressorMaster?.[tableKey] && tableKey) {
      setTables({ compressorMaster: { ...tables?.compressorMaster, [tableKey]: compressorData?.result?.data?.compressorData } });
    }
    else {
      if (tableKey && !tables?.compressorMaster?.[tableKey]) {
        const systemData = { [SYSTEM_ARCHS_KEYS.ARC_2]: { ...compressorSetup[SYSTEM_ARCHS_KEYS.ARC_2], [typeOfRack]: { ...compressorSetup[SYSTEM_ARCHS_KEYS.ARC_2]?.[typeOfRack], selectedCompressorLoad: undefined, compressorInputs: [] } } };
        setCompressorSetup({ ...compressorSetup, ...systemData });
      }
    }
    // eslint-disable-next-line 
  }, [compressorData?.result?.data]);

  //Reset values if table empty
  useEffect(() => {
    if (tableKey && !tables?.compressorMaster?.[tableKey]) {
      const systemData = { [SYSTEM_ARCHS_KEYS.ARC_2]: { ...compressorSetup[SYSTEM_ARCHS_KEYS.ARC_2], [typeOfRack]: { ...compressorSetup[SYSTEM_ARCHS_KEYS.ARC_2]?.[typeOfRack], selectedCompressorLoad: undefined, compressorInputs: [] } } };
      setCompressorSetup({ ...compressorSetup, ...systemData });
    }
    // eslint-disable-next-line 
  }, [tables?.compressorMaster?.[tableKey]]);
  //set default type & company
  useEffect(() => {
    if (!type && !company) {
      const defaultType = COMPRESSOR_ARCHS_CONFIG?.[SYSTEM_ARCHS_KEYS.ARC_2]?.[typeOfRack]?.DEFAULT_TYPE;
      const defaultCompany = COMPRESSOR_ARCHS_CONFIG?.[SYSTEM_ARCHS_KEYS.ARC_2]?.[typeOfRack]?.DEFAULT_COMPANY;
      const defaultLTType = COMPRESSOR_ARCHS_CONFIG?.[SYSTEM_ARCHS_KEYS.ARC_2]?.[TYPE_OF_RACK.LT]?.DEFAULT_TYPE;
      const defaulLTCompany = COMPRESSOR_ARCHS_CONFIG?.[SYSTEM_ARCHS_KEYS.ARC_2]?.[TYPE_OF_RACK.LT]?.DEFAULT_COMPANY;
      const systemData = { [SYSTEM_ARCHS_KEYS.ARC_2]: { ...compressorSetup[SYSTEM_ARCHS_KEYS.ARC_2], [TYPE_OF_RACK.MT]: { ...compressorSetup?.[SYSTEM_ARCHS_KEYS.ARC_2][TYPE_OF_RACK.MT], type: defaultType, company: defaultCompany }, [TYPE_OF_RACK.LT]: { ...compressorSetup?.[SYSTEM_ARCHS_KEYS.ARC_2][TYPE_OF_RACK.LT], type: defaultLTType, company: defaulLTCompany } } };
      setCompressorSetup({ ...compressorSetup, ...systemData });
    }
    // eslint-disable-next-line 
  }, [typeOfRack]);

  useEffect(() => {
    if (tableKey && tables?.compressorMaster?.[tableKey]) {
      if (!(ARC_2?.[typeOfRack]?.compressorInputs?.length > 0)) {
        const inputTable: any = [];
        tables?.compressorMaster?.[tableKey]?.filter(comp => activeRacks?.indexOf(comp?.rackType) > -1)?.forEach((configItem, i) => {
          if (configItem) {
            let defaultCount = configItem?.defaultCount || 0;
            if (restoreSessionArcData) {
              defaultCount = restoreSessionArcData?.[typeOfRack]?.[configItem?.rackType]?.compressors?.[configItem?.modelId]?.count || 0;
            }

            const capacity = CompressorSetUpCalculations?.getCapacity(defaultCount, configItem?.enRating) || 0;
            inputTable.push({ modelId: configItem?.modelId, compressor: `Compressor #` + (i + 1), cop: configItem?.cop, enRating: configItem?.enRating, count: defaultCount, capacity: capacity, rackType: configItem?.rackType });
          }
        });
        if (inputTable?.length > 0) {
          const totalCapacity = CompressorSetUpCalculations?.getTotal(inputTable?.map(input => input?.capacity || 0)) || 0;
          const systemData = { [SYSTEM_ARCHS_KEYS.ARC_2]: { ...compressorSetup[SYSTEM_ARCHS_KEYS.ARC_2], [typeOfRack]: { ...compressorSetup[SYSTEM_ARCHS_KEYS.ARC_2]?.[typeOfRack], selectedCompressorLoad: totalCapacity, compressorInputs: inputTable } } };
          setCompressorSetup({ ...compressorSetup, ...systemData });
        }
      }

    }
    // eslint-disable-next-line 
  }, [tableKey, tables?.compressorMaster?.[tableKey], defaultResetKey]);

  const setInputTable = useCallback((key, table, rack) => {
    if (key && table) {
      if (!(ARC_2?.[rack]?.compressorInputs?.length > 0)) {
        const inputTable: any = [];
        table?.filter(comp => activeRacks?.indexOf(comp?.rackType) > -1)?.forEach((configItem, i) => {
          if (configItem) {
            let defaultCount = configItem?.defaultCount || 0;
            if (restoreSessionArcData) {
              defaultCount = restoreSessionArcData?.[rack]?.[configItem?.rackType]?.compressors?.[configItem?.modelId]?.count || 0;
            }
            const capacity = CompressorSetUpCalculations?.getCapacity(defaultCount, configItem?.enRating) || 0;
            inputTable.push({ modelId: configItem?.modelId, compressor: `Compressor #` + (i + 1), cop: configItem?.cop, enRating: configItem?.enRating, count: defaultCount, capacity: capacity, rackType: configItem?.rackType });
          }
        });
        if (inputTable?.length > 0) {
          const totalCapacity = CompressorSetUpCalculations?.getTotal(inputTable?.map(input => input?.capacity || 0)) || 0;
          const systemData = { [SYSTEM_ARCHS_KEYS.ARC_2]: { ...compressorSetup[SYSTEM_ARCHS_KEYS.ARC_2], [rack]: { ...compressorSetup[SYSTEM_ARCHS_KEYS.ARC_2]?.[rack], selectedCompressorLoad: totalCapacity, compressorInputs: inputTable } } };
          setCompressorSetup({ ...compressorSetup, ...systemData });
        }
        if (restoredSessions && restoreTransactionId) {
          setCalculator({ restoredSessions: [...restoredSessions, RESTORE_SESSION_PAGE_IDS?.ARC_2] });
        }
      }
    }

  }, [compressorSetup, SYSTEM_ARCHS_KEYS, ARC_2, activeRacks, restoredSessions, restoreSessionArcData, restoreTransactionId, RESTORE_SESSION_PAGE_IDS]);

  //loadDeafultLT
  const loadDefaultLT = async () => {
    const defaultType = await ARC_2?.[TYPE_OF_RACK.LT]?.type || COMPRESSOR_ARCHS_CONFIG?.[SYSTEM_ARCHS_KEYS.ARC_2]?.[TYPE_OF_RACK.LT]?.DEFAULT_TYPE;
    const defaultCompany = await ARC_2?.[TYPE_OF_RACK.LT]?.company || COMPRESSOR_ARCHS_CONFIG?.[SYSTEM_ARCHS_KEYS.ARC_2]?.[TYPE_OF_RACK.LT]?.DEFAULT_COMPANY;
    const ltRefrigerant = chooseSystems?.system?.[SYSTEM_ARCHS_KEYS.ARC_2]?.ltSide;
    const ltTablekey = `${locationAndSize?.storeSize}-${SYSTEM_ARCHS_KEYS.ARC_2}-${TYPE_OF_RACK.LT}-${ltRefrigerant}-${defaultType}-${defaultCompany}-${climate}`;

    await setIsDefaultLTLoading(true);
    const ltTableData = await store.dispatch(dataApi.endpoints.getData.initiate({
      tableKey: ltTablekey,
      dataType: SERVICE_REQUEST_FETCH_DATA_TYPES.COMPRESSOR,
      storeSize: locationAndSize?.storeSize,
      architectureId: SYSTEM_ARCHS_KEYS.ARC_2,
      cabinetType: TYPE_OF_RACK.LT,
      type: defaultType,
      company: defaultCompany,
      refrigerant: ltRefrigerant,
      climate: climate
    }));
    if (ltTableData?.data?.isSuccess && ltTableData?.data.result?.data?.compressorData) {
      setTables({ compressorMaster: { ...tables?.compressorMaster, [ltTableData?.originalArgs?.tableKey || ltTablekey]: ltTableData?.data.result?.data?.compressorData } });
      setInputTable(ltTableData?.originalArgs?.tableKey || ltTablekey, ltTableData?.data?.result?.data?.compressorData, TYPE_OF_RACK.LT);
    }
    await setIsDefaultLTLoading(false);
  };
  //check if LT table exists
  const isTableReloadForRack = (rackType) => {
    const selctedType = ARC_2?.[rackType]?.type;
    const selectectCompany = ARC_2?.[rackType]?.company;
    let selctedRefrigerant = "";
    if (rackType == TYPE_OF_RACK.MT) {
      selctedRefrigerant = chooseSystems?.system?.[SYSTEM_ARCHS_KEYS.ARC_2]?.mtSide;
    }
    else if (typeOfRack == TYPE_OF_RACK.LT) {
      selctedRefrigerant = chooseSystems?.system?.[SYSTEM_ARCHS_KEYS.ARC_2]?.ltSide;
    }
    if (selctedType?.length > 0 && selectectCompany?.length > 0) {
      const key = `${locationAndSize?.storeSize}-${SYSTEM_ARCHS_KEYS.ARC_2}-${rackType}-${selctedRefrigerant}-${selctedType}-${selectectCompany}-${climate}`;
      if (!tables?.compressorMaster[key]) {
        return true;
      }
      else {
        return false;
      }
    }
    return false;
  };
  useEffect(() => {
    if (typeOfRack == TYPE_OF_RACK.MT && !isCompressorDataLoading && !isDefaultLTLoading && (Object.keys(ARC_2?.[TYPE_OF_RACK.MT]?.compressorInputs || [])?.length > 0 && Object.keys(ARC_2?.[TYPE_OF_RACK.LT] || [])?.length < 1 || isTableReloadForRack(TYPE_OF_RACK.LT))) {
      loadDefaultLT();
    }
    // eslint-disable-next-line 
  }, [ARC_2]);
  //On type change
  const onTypeChange = useCallback((e) => {
    const defaultCompany = COMPRESSOR_ARCHS_CONFIG?.[SYSTEM_ARCHS_KEYS.ARC_2]?.[typeOfRack]?.COMPANIES?.filter(c => c?.type == (e?.target?.value))?.map(c => c?.company)?.[0] || "";
    const systemData = { [SYSTEM_ARCHS_KEYS.ARC_2]: { ...compressorSetup[SYSTEM_ARCHS_KEYS.ARC_2], [typeOfRack]: { ...compressorSetup?.[SYSTEM_ARCHS_KEYS.ARC_2]?.[typeOfRack], type: e?.target?.value, company: defaultCompany, compressorInputs: [] } } };
    setCompressorSetup({ ...compressorSetup, ...systemData });
  }, [compressorSetup, SYSTEM_ARCHS_KEYS, typeOfRack, COMPRESSOR_ARCHS_CONFIG]);

  //On Comapny chnage
  const onCompanyChange = useCallback((e) => {
    const systemData = { [SYSTEM_ARCHS_KEYS.ARC_2]: { ...compressorSetup[SYSTEM_ARCHS_KEYS.ARC_2], [typeOfRack]: { ...compressorSetup?.[SYSTEM_ARCHS_KEYS.ARC_2]?.[typeOfRack], company: e?.target?.value, compressorInputs: [] } } };
    setCompressorSetup({ ...compressorSetup, ...systemData });
  }, [compressorSetup, SYSTEM_ARCHS_KEYS, typeOfRack]);

  //On sub cooler chnage
  const onSubCoolerChange = useCallback((e) => {
    const systemData = { [SYSTEM_ARCHS_KEYS.ARC_2]: { ...compressorSetup[SYSTEM_ARCHS_KEYS.ARC_2], subCooler: e?.target?.value } };
    setCompressorSetup({ ...compressorSetup, ...systemData });
  }, [compressorSetup, SYSTEM_ARCHS_KEYS]);
  //On sub cooler chnage
  const onConfigurationChange = useCallback((configValue) => () => {
    const systemData = { [SYSTEM_ARCHS_KEYS.ARC_2]: { ...compressorSetup[SYSTEM_ARCHS_KEYS.ARC_2], configuration: configValue } };
    setCompressorSetup({ ...compressorSetup, ...systemData });
  }, [compressorSetup, SYSTEM_ARCHS_KEYS]);
  //company options
  const optionsCompanyValues = useMemo(() => {
    return COMPRESSOR_ARCHS_CONFIG?.[SYSTEM_ARCHS_KEYS.ARC_2]?.[typeOfRack]?.[refrigerant]?.COMPANIES?.filter(c => c?.type == (type || COMPRESSOR_ARCHS_CONFIG?.[SYSTEM_ARCHS_KEYS.ARC_2]?.[typeOfRack]?.DEFAULT_TYPE || ""))?.map(c => c?.company) || [];
  }, [COMPRESSOR_ARCHS_CONFIG, SYSTEM_ARCHS_KEYS, typeOfRack, type, refrigerant]);

  // Set table selection 
  const setCountValue = useCallback((modelId: string, rackTypeKey) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const isValid = REGEX_ITEMS?.NUMBER?.test(e?.target?.value);
    if (e?.target?.value?.length === 0 || isValid) {
      const inputTable = ARC_2?.[typeOfRack]?.compressorInputs || [];
      if (inputTable?.length > 0) {
        const modelIndex = inputTable?.map(input => `${input?.modelId}-${input?.rackType}`)?.indexOf(`${modelId}-${rackTypeKey}`);
        if (modelIndex > -1 && inputTable[modelIndex]?.modelId === modelId) {
          const configItem = inputTable[modelIndex];
          const capacity = CompressorSetUpCalculations?.getCapacity(e?.target?.value, configItem?.enRating) || 0;
          inputTable[modelIndex]["count"] = e?.target?.value;
          inputTable[modelIndex]["capacity"] = capacity;
          const totalCapacity = CompressorSetUpCalculations?.getTotal(inputTable?.map(input => input?.capacity || 0)) || 0;
          const systemData = { [SYSTEM_ARCHS_KEYS.ARC_2]: { ...compressorSetup[SYSTEM_ARCHS_KEYS.ARC_2], [typeOfRack]: { ...compressorSetup[SYSTEM_ARCHS_KEYS.ARC_2]?.[typeOfRack], selectedCompressorLoad: totalCapacity, compressorInputs: inputTable } } };
          setCompressorSetup({ ...compressorSetup, ...systemData });
        }
      }
    }
  }, [compressorSetup, ARC_2, typeOfRack, SYSTEM_ARCHS_KEYS]);

  //reset  load setup values
  const setDefault = useCallback(() => {
    const defaultType = COMPRESSOR_ARCHS_CONFIG?.[SYSTEM_ARCHS_KEYS.ARC_2]?.[TYPE_OF_RACK.MT]?.DEFAULT_TYPE;
    const defaultCompany = COMPRESSOR_ARCHS_CONFIG?.[SYSTEM_ARCHS_KEYS.ARC_2]?.[TYPE_OF_RACK.MT]?.DEFAULT_COMPANY;
    const defaultLTType = COMPRESSOR_ARCHS_CONFIG?.[SYSTEM_ARCHS_KEYS.ARC_2]?.[TYPE_OF_RACK.LT]?.DEFAULT_TYPE;
    const defaulLTCompany = COMPRESSOR_ARCHS_CONFIG?.[SYSTEM_ARCHS_KEYS.ARC_2]?.[TYPE_OF_RACK.LT]?.DEFAULT_COMPANY;
    const systemData = { [SYSTEM_ARCHS_KEYS.ARC_2]: { ...compressorSetup[SYSTEM_ARCHS_KEYS.ARC_2], subCooler: "15", configuration: "Rack", [TYPE_OF_RACK.MT]: { ...compressorSetup?.[SYSTEM_ARCHS_KEYS.ARC_2]?.[TYPE_OF_RACK.MT], type: defaultType, company: defaultCompany, compressorInputs: [] }, [TYPE_OF_RACK.LT]: { ...compressorSetup?.[SYSTEM_ARCHS_KEYS.ARC_2]?.[TYPE_OF_RACK.LT], type: defaultLTType, company: defaulLTCompany, compressorInputs: [] } } };
    setCompressorSetup({ ...compressorSetup, ...systemData });
    setTypeOfRack(TYPE_OF_RACK.MT);
    setSelectedRack(COMPRESSOR_ARCHS_CONFIG?.RACK_VALUES?.RACK_1);
    setDefaultResetKey(Date.now());
  }, [compressorSetup, SYSTEM_ARCHS_KEYS, TYPE_OF_RACK, COMPRESSOR_ARCHS_CONFIG]);

  //selectedRackLoad
  const selectedRackLoad = useMemo(() => {
    let totalCapacity = 0;
    const table = ARC_2?.[typeOfRack]?.compressorInputs || [];
    if (table?.length > 0) {
      const selctedRackItems = table?.filter(comp => comp?.rackType === selectedRack) || [];
      totalCapacity = CompressorSetUpCalculations?.getTotal(selctedRackItems?.map(input => input?.capacity || 0)) || 0;
    }
    return totalCapacity;
  }, [typeOfRack, selectedRack, ARC_2]);

  //targetLTLoadWithSubCooler
  const getLTLoadWithSubCooler = useCallback((rackType, rackName, inputTable, arc) => {
    return CompressorSetUpCalculations.getArc2LTLoadWithSubCooler(rackName, inputTable, arc, config);
  }, [config]);
  const getMTRackLoad = useCallback((rackType, rackName, inputTable) => {
    return CompressorSetUpCalculations.getArc2MTRackLoad(rackName, inputTable, config);
  }, [config]);
  //targetLTLoadWithSubCooler
  const targetLTLoadWithSubCooler = useMemo(() => {
    let targetLoad = 0;
    const inputTable = loadSetup?.loadInputs;
    targetLoad = getLTLoadWithSubCooler(TYPE_OF_RACK.LT, selectedRack, inputTable, ARC_2);
    return targetLoad;
  }, [loadSetup, selectedRack, ARC_2, TYPE_OF_RACK, getLTLoadWithSubCooler]);
  //targetLTLoadWithSubCooler
  const targetMTLoadWithShift = useMemo(() => {
    let targetLoad = 0;
    const targetLoadLT: any = [];
    const inputTable = loadSetup?.loadInputs;
    const slectedMTLoad = loadSetup?.selectedMTLoad || 0;
    const slectedLTLoad = loadSetup?.selectedLTLoad || 0;
    const currentRackLoad = getMTRackLoad(TYPE_OF_RACK.MT, selectedRack, inputTable);
    const allLTRackNames = COMPRESSOR_ARCHS_CONFIG?.["ARC_2"]?.[locationAndSize?.storeSize]?.[TYPE_OF_RACK.LT]?.Racks || [];
    allLTRackNames?.forEach(rack => {
      targetLoadLT.push(getLTLoadWithSubCooler(TYPE_OF_RACK.LT, rack, inputTable, ARC_2));
    });
    targetLoad = ((slectedMTLoad + (slectedLTLoad - CompressorSetUpCalculations?.getTotal(targetLoadLT))) * (currentRackLoad / slectedMTLoad));
    return CompressorSetUpCalculations.getRoundValue?.(targetLoad, 1) || 0;
  }, [loadSetup, selectedRack, TYPE_OF_RACK, ARC_2, locationAndSize, COMPRESSOR_ARCHS_CONFIG, , getLTLoadWithSubCooler, getMTRackLoad]);

  //targetLTLoadWithSubCooler
  const targetLoads = useMemo(() => {
    const targetLoadLT: any = [];
    const targetLoadMT: any = [];
    const inputTable = loadSetup?.loadInputs;
    const slectedMTLoad = loadSetup?.selectedMTLoad || 0;
    const slectedLTLoad = loadSetup?.selectedLTLoad || 0;
    const allLTRackNames = COMPRESSOR_ARCHS_CONFIG?.["ARC_2"]?.[locationAndSize?.storeSize]?.[TYPE_OF_RACK.LT]?.Racks || [];
    allLTRackNames?.forEach(rack => {
      targetLoadLT.push(getLTLoadWithSubCooler(TYPE_OF_RACK.LT, rack, inputTable, ARC_2));
    });
    const allMTRackNames = COMPRESSOR_ARCHS_CONFIG?.["ARC_2"]?.[locationAndSize?.storeSize]?.[TYPE_OF_RACK.MT]?.Racks || [];
    allMTRackNames?.forEach(rack => {
      const currentRackLoad = getMTRackLoad(TYPE_OF_RACK.MT, rack, inputTable);
      targetLoadMT.push(((slectedMTLoad + (slectedLTLoad - CompressorSetUpCalculations?.getTotal(targetLoadLT))) * (currentRackLoad / slectedMTLoad)));
    });
    const totalLTLoad = CompressorSetUpCalculations?.getTotal(targetLoadLT);
    const totalMTLoad = CompressorSetUpCalculations?.getTotal(targetLoadMT);
    return { targetMTLoad: totalMTLoad, targetLTLoad: totalLTLoad };
  }, [loadSetup, TYPE_OF_RACK, ARC_2, locationAndSize, COMPRESSOR_ARCHS_CONFIG, getLTLoadWithSubCooler, getMTRackLoad]);
  //ltLoadRanges warning
  const isLTLoadWarning = useMemo(() => {
    const isDeviated = CompressorSetUpCalculations.isDeviated(COMPRESSOR_ARCHS_CONFIG.LOAD_DEVIATION, compressorSetup?.[SYSTEM_ARCHS_KEYS.ARC_2]?.[TYPE_OF_RACK.LT]?.selectedCompressorLoad, targetLoads?.targetLTLoad);
    return isDeviated;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadSetup?.selectedLTLoad, COMPRESSOR_ARCHS_CONFIG, TYPE_OF_RACK, compressorSetup, SYSTEM_ARCHS_KEYS, targetLoads]);
  //mtLoadRanges warning
  const isMTLoadRangeWarning = useMemo(() => {
    const isDeviated = CompressorSetUpCalculations.isDeviated(COMPRESSOR_ARCHS_CONFIG.LOAD_DEVIATION, compressorSetup?.[SYSTEM_ARCHS_KEYS.ARC_2]?.[TYPE_OF_RACK.MT]?.selectedCompressorLoad, targetLoads?.targetMTLoad);
    return isDeviated;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadSetup?.selectedMTLoad, SYSTEM_ARCHS_KEYS, compressorSetup, TYPE_OF_RACK, COMPRESSOR_ARCHS_CONFIG, targetLoads]);
  //is continue button disabled
  const isContinueButtonDisabled = useMemo(() => {
    return isMTLoadRangeWarning || isLTLoadWarning || isCompressorDataLoading || isDefaultLTLoading;
  }, [isLTLoadWarning, isMTLoadRangeWarning, isCompressorDataLoading, isDefaultLTLoading]);
  // for active stage
  const activeStageLocationSetup = useMemo(() => {
    const selectedSystemArr = tables?.systemArchMaster?.filter(value => chooseSystems?.selectedSystems?.indexOf(value?.architectureId) > -1)?.map((item: any) => item.architectureId);
    const nextCompressorStage = tables?.systemArchMaster?.filter(value => selectedSystemArr?.indexOf(value.architectureId) > selectedSystemArr?.indexOf(Object.keys(SYSTEM_ARCHS_REACT_MS_MAPPING)[1]))[0]?.architectureId || "";
    return nextCompressorStage;
  }, [chooseSystems, SYSTEM_ARCHS_REACT_MS_MAPPING, tables?.systemArchMaster]);

  // for next page link
  const continueNextPageLink = useMemo(() => {
    let nextPAgeLink = PAGE_NAVIGATION.SYSTEM_DETAILS.COMPRESSOR_SETUP.DISTRIBUTED_SYSTEM_AIR;
    if (!activeStageLocationSetup) {
      nextPAgeLink = PAGE_NAVIGATION.SYSTEM_DETAILS.PARAMETER_SETUP;
    }
    return nextPAgeLink;
  }, [activeStageLocationSetup, PAGE_NAVIGATION]);


  return (
    <div>
      <ProgressStepper step={''} completed={PROGRESSBAR_STEPS_VALUE_COLL.EcoEfficiency[2]} />
      <Headline key={`hl-${SYSTEM_ARCHS_KEYS.ARC_2}_${updatedOn}`} calculationId={SYSTEM_ARCHS_PRE_CALCULATION_REACT_MS_MAPPING?.[SYSTEM_ARCHS_KEYS.ARC_2]} headline={t(LABELKEYS_COMPRESSOR_SETUP.HEADING)} activeStageLocationSetup={activeStageLocationSetup} backButton={PAGE_NAVIGATION.SYSTEM_DETAILS.COMPRESSOR_SETUP.CENTRALIZED_SYSTEM} continueButton={continueNextPageLink} isDisable={isContinueButtonDisabled} />
      <TabStepper step={''} labelArray={PROGRESSBAR_STEPS_VALUE_COLL.EcoEfficiencyTabContainer} completed={PROGRESSBAR_STEPS_VALUE_COLL.EcoEfficiencyTabContainer[2]} childStep={PROGRESSBAR_STEPS_VALUE_COLL.EcoEfficiencyTabChildContainer[1]} calltoAction={t(LABELKEYS_SYSTEM_LOAD.RESET)} currentStep={true} onResetClick={setDefault} />
      <div className="row mt-3">
        <div className="col-md-3 col-sm-12">
          <SystemAnalysisPanel key={`sp-${SYSTEM_ARCHS_KEYS.ARC_2}_${updatedOn}`} isLTLoading={(typeOfRack == TYPE_OF_RACK.LT && isCompressorDataLoading) || isDefaultLTLoading} isMTLoading={typeOfRack == TYPE_OF_RACK.MT && isCompressorDataLoading} racks={true} showconfiguraton={true} showLTLoadWarning={isLTLoadWarning} showMTLoadWarning={isMTLoadRangeWarning} typeOfRack={typeOfRack} setRackType={setRackType} setRack={setRack} architecture={SYSTEM_ARCHS_KEYS.ARC_2} onSubCoolerChange={onSubCoolerChange} onConfigurationChange={onConfigurationChange} rack={selectedRack} targetLoads={targetLoads} />
        </div>
        <div className="col-md-9 col-sm-12">
          {typeOfRack === TYPE_OF_RACK.MT ? <div className="select-temperature-title">{t(LABELKEYS_COMPRESSOR_SETUP.MEDIUM_TEMPERATURE_HEADING)}{": "}{t(COMPRESSOR_ARCHS_CONFIG?.RACKS_LABELS?.[compressorSetup?.[SYSTEM_ARCHS_KEYS.ARC_2]?.configuration]?.[selectedRack])}</div> : <div className="select-temperature-title">{t(LABELKEYS_COMPRESSOR_SETUP.LOW_TEMPERATURE_HEADING)}{": "}{t(COMPRESSOR_ARCHS_CONFIG?.RACKS_LABELS?.[compressorSetup?.[SYSTEM_ARCHS_KEYS.ARC_2]?.configuration]?.[selectedRack])}</div>}
          <div className="select-temperature">
            <div className="select-items row mx-0">
              <div className="col-md-6 col-xs-12">
                <div className="form-group">
                  <>
                    <label htmlFor="Type">{t(LABELKEYS_COMPRESSOR_SETUP.COMPRESSOR_TYPE)} <span className="mandatory"></span></label>
                    <select value={ARC_2?.[typeOfRack]?.type} onChange={onTypeChange} className="form-control" placeholder="" aria-label="Compressor Type">
                      {optionsTypeValues?.map((key, index) => {
                        return (
                          <option key={index} value={key}>{key}</option>
                        );
                      })}
                    </select>
                    <i className="angle-down-arrow"><BiChevronDown className="angle-down-icon" /></i>
                  </>
                </div>
                <div className="selected-mt">{typeOfRack === TYPE_OF_RACK.MT ? t(LABELKEYS_COMPRESSOR_SETUP.SELECTED_MT_COMPRESSOR) : t(LABELKEYS_COMPRESSOR_SETUP.SELECTED_LT_COMPRESSOR)}</div>
                <div className="selected-mt-value">{selectedRackLoad}</div>
              </div>
              <div className="col-md-6 col-xs-12">
                <div className="form-group">
                  <>
                    <label htmlFor="Company">{t(LABELKEYS_COMPRESSOR_SETUP.COMPRESSOR_MANUFACTURE)} <span className="mandatory"></span></label>
                    <select value={ARC_2?.[typeOfRack]?.company} onChange={onCompanyChange} className="form-control" placeholder="" aria-label="Compressor Manufacture">
                      {optionsCompanyValues?.map((key, index) => {
                        return (
                          <option key={index} value={key}>{key}</option>
                        );
                      })}
                    </select>
                    <i className="angle-down-arrow"><BiChevronDown className="angle-down-icon" /></i>
                  </>
                </div>
                {typeOfRack === TYPE_OF_RACK.MT && <div className="target-mt">{t(LABELKEYS_COMPRESSOR_SETUP.TARGET_MT_COMPRESSOR_WITH_SUBCOOLER)}</div>}
                {typeOfRack === TYPE_OF_RACK.LT && <div className="target-mt">{t(LABELKEYS_COMPRESSOR_SETUP.TARGET_LT_COMPRESSOR_WITH_SUBCOOLER)}</div>}
                <div className="target-mt-value">{typeOfRack === TYPE_OF_RACK.MT ? targetMTLoadWithShift : targetLTLoadWithSubCooler}</div>

              </div>
            </div>
          </div>
          {isCompressorDataLoading ? <Loader /> :
            <div className="compressor-table">
              <div className="panel-body">
                {ARC_2?.[typeOfRack]?.compressorInputs?.filter(comp => comp?.rackType === selectedRack)?.length > 0 && <div className="table-head">
                  {COMPRESSOR_SETUP_CENTRALIZED_TABLE_HEADER?.map((e: any) => {
                    return (
                      <div key={`div${e?.fieldKey}`} className="table-items">
                        {t(e?.fieldKey)}{" "}
                      </div>
                    );
                  })}
                </div>}
                {ARC_2?.[typeOfRack]?.compressorInputs?.filter(comp => comp?.rackType === selectedRack)?.map((val, key) => {
                  return (
                    <div key={`div${val?.modelId}${key}`}>
                      <div className="table-data">{`Compressor #` + (key + 1)}</div>
                      <div className="table-data">
                        <input onChange={setCountValue(val?.modelId, val?.rackType)} value={val?.count} type="text" aria-label="Count" /></div>
                      <div className="table-data">{CompressorSetUpCalculations.getRoundValue(val?.cop, 1)}</div>
                      <div className="table-data">{CompressorSetUpCalculations.getRoundValue(val?.enRating, 1)}</div>
                      <div className="table-data">{val?.capacity}</div>
                    </div>
                  );
                })}
              </div>
            </div>}
        </div>
      </div>
    </div>
  );
};

export default SemiDistributedSystem;