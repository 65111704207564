import React from 'react';
import './AmbientGraph.scss';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
ChartJS.defaults.font.size = 20;
ChartJS.defaults.font.weight = 800;
ChartJS.defaults.color = 'black';
ChartJS.defaults.font.family = 'honeywell_sansregular';
const AmbientGraph= (props) => {
const options = {
  type: 'line', 
  elements: {
    bar: {
      borderWidth: 0,
    }
  },
  maintainAspectRatio: true,
 /*  responsive: true, */
  align:'center',
  plugins: {
    title: {
      display: true,
    },
    tooltip :
    {
      intersect : false
    },
    legend: {
      display: false,
      labels: {
        font: {
          size: 24,
        },
      },
    },
  },
    scales: {
        x: {
          stacked: true,
          grid: {
            color: '#fff',
            borderColor: "#000"
          },
          title: {
            display: true,
            text: 'Ambient Temperature (°C)',
            fontWidth:800,
            color:'#000'
          },
          beginAtZero:false,
        },
        y: {
          stacked: true,
          grid: {
            color: '#fff',
            borderColor: "#000"
          },
          title: {
            display: true,
            text: 'Number Of Hours Per Year',
            fontWidth:800,
            color:'#000'
          },
        },
        
    },
};

const labels = props.label;
const data = {
  labels,
  datasets: [
    {
      label:'',
      data: props.data,
      backgroundColor: '#37cafc',
      stack:'Stack 0',
      barThickness: 40,
      datalabels: {
        display: false,
        color: "",
        anchor: "horizontal",
        offset: -40,
        align: "horizontal",
        rotation:0
      }
    },
  ],
  
  hoverRadius: 0
};
  return (<div><Bar id={`ambGraph${props?.idInfo}`} className="ambient-graph" options={options} data={data} aria-label="Ambient" /></div>);
 
};
export default AmbientGraph;
