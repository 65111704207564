import React, { useEffect, useCallback, useMemo } from "react";
import { useSelector } from 'react-redux';
import { skipToken } from "@reduxjs/toolkit/query";
import "./LocationSize.scss";
import ProgressStepper from '../../../common/ProgressBar/ProgressStepper';
import { LOCATION_SIZE_LABEL, LABEL_KEYS_LOADER, MEDIUM_TEMP_STORE_SIZE_TABLE } from "../../../../helper/constants";
import { useTranslation } from "react-i18next";
import { BiChevronDown } from "react-icons/bi";
import Headline from "../../../common/Headline/Headline";
import { setLocationAndStoreSizeValues, setTables } from "../../../../store/actions";
import AmbientGraph from "../../../common/Graph/AmbientGraph/AmbientGraph";
import { useGetDataQuery } from "../../../../services/data";
import { useGetGraphQuery } from "../../../../services/graph";
import TemperatureSide from "./temperatureSide/TemperatureSide";
import Loader from "../../../common/Loader/Loader";
const LocationSize = () => {
    const { userInputs, tables } = useSelector((state: any) => state?.ecoEfficiency?.calculator || {});

    const { locationAndSize } = userInputs;
    const { SIZE_DATA_JSON, SERVICE_REQUEST_FETCH_DATA_TYPES, SERVICE_REQUEST_GRAPH_DATA_TYPES, SERVICE_REQUEST_GRAPH_TYPES } = useSelector((state: any) => state?.ecoEfficiency?.config || {});
    const { t } = useTranslation();
    const { TEMPERATURE_HEADING_CONFIG_LOCATION_MT, TEMPERATURE_HEADING_CONFIG_LOCATION_LT, PROGRESSBAR_STEPS_VALUE_COLL, PAGE_NAVIGATION } = useSelector((state: any) => state?.ecoEfficiency?.config || {});

    /*------Data Query -----*/
    //country master
    const { data: countryData, isLoading: isCountryDataLoading } = useGetDataQuery(tables?.countryMaster ? skipToken : {
        dataType: SERVICE_REQUEST_FETCH_DATA_TYPES.LOCATION_ALL
    });
    useEffect(() => {
        if (countryData?.result?.data && !tables?.countryMaster) {
            setTables({ countryMaster: countryData?.result?.data });
        }
        // eslint-disable-next-line 
    }, [countryData?.result?.data]);
    //cabinet by size master 
    const { data: cabinetBySizeData, isLoading: isCabinetBySizeDataLoading } = useGetDataQuery(!locationAndSize?.storeSize || locationAndSize?.storeSize?.length == 0 || tables?.cabinetBySizeMaster?.[locationAndSize?.storeSize] ? skipToken : {
        dataType: SERVICE_REQUEST_FETCH_DATA_TYPES.CABINETS_BY_SIZE,
        storeSize: locationAndSize?.storeSize
    });
    useEffect(() => {
        if (cabinetBySizeData?.result?.data && locationAndSize?.storeSize?.length > 0 && !tables?.cabinetBySizeMaster[locationAndSize?.storeSize]) {
            setTables({ cabinetBySizeMaster: { ...tables?.cabinetBySizeMaster, [locationAndSize?.storeSize]: cabinetBySizeData?.result?.data } });
        }
        // eslint-disable-next-line   
    }, [cabinetBySizeData?.result?.data]);
    //bin temp master 
    const { data: binTempGraphData, isLoading: isBinTempGraphDataLoading } = useGetGraphQuery(locationAndSize?.country?.length > 0 && locationAndSize?.city?.length > 0 && !tables?.binTemperatureGraphMaster?.[`${locationAndSize?.country}-${locationAndSize?.city}`] ? {
        dataType: SERVICE_REQUEST_GRAPH_DATA_TYPES.BIN_TEMPERATURE,
        graphType: SERVICE_REQUEST_GRAPH_TYPES.BAR_CHART,
        country: locationAndSize?.country,
        city: locationAndSize?.city
    } : skipToken);
    useEffect(() => {
        if (binTempGraphData?.result?.data && locationAndSize?.country?.length > 0 && locationAndSize?.city?.length > 0 && !tables?.binTemperatureGraphMaster?.[`${locationAndSize?.country}-${locationAndSize?.city}`]) {
            setTables({ binTemperatureGraphMaster: { ...tables?.binTemperatureGraphMaster, [`${locationAndSize?.country}-${locationAndSize?.city}`]: binTempGraphData?.result?.data } });
        }
        // eslint-disable-next-line   
    }, [binTempGraphData?.result?.data]);
    //Enable continue button 
    const isButtonDisabled = useMemo(() => {
        return (!(locationAndSize?.country?.length > 0 && locationAndSize?.city?.length > 0 && tables?.cabinetBySizeMaster?.[`${locationAndSize?.storeSize}`]?.length > 0 && locationAndSize?.storeSize?.length > 0) || isCabinetBySizeDataLoading || isBinTempGraphDataLoading || isCountryDataLoading);
    }, [locationAndSize, isCabinetBySizeDataLoading, isBinTempGraphDataLoading, isCountryDataLoading,tables?.cabinetBySizeMaster]);
    //On store size change
    const onSizeChange = useCallback((e) => {
        setLocationAndStoreSizeValues({ storeSize: e?.target?.value, storeSizeValue: SIZE_DATA_JSON?.[e?.target?.value] });
    }, [SIZE_DATA_JSON]);
    //On Country Change
    const onCountryChange = useCallback((e) => {
        setLocationAndStoreSizeValues({ country: e?.target?.value, city: "", climate: "" });
    }, []);
    //On city Change
    const onCityChange = useCallback((e) => {
        const climate = tables?.countryMaster?.[locationAndSize?.country]?.[e?.target?.value] || "";
        setLocationAndStoreSizeValues({ city: e?.target?.value, climate: climate });
    }, [locationAndSize, tables?.countryMaster]);
    return (
        <>
            <ProgressStepper step={''} completed={PROGRESSBAR_STEPS_VALUE_COLL.EcoEfficiency[1]} />
            <Headline headline={t(LOCATION_SIZE_LABEL.HEADLINE)} backButton={PAGE_NAVIGATION.OVERVIEW} continueButton={PAGE_NAVIGATION.SYSTEM_DETAILS.CHOOSE_SYSTEM} sizelocation={false} isDisable={isButtonDisabled} />
            {isCountryDataLoading ? <Loader message={t(LABEL_KEYS_LOADER.LOADING)} /> : <div className="row store-form">
                <div className="col-sm-3 form-bg">
                    <div className="form-group">
                        <label htmlFor="Country">{t(LOCATION_SIZE_LABEL.COUNTRY)} <span className="mandatory"></span></label>
                        <select className="form-control" aria-label={t(LOCATION_SIZE_LABEL.COUNTRY)} placeholder="" value={locationAndSize?.country} onChange={onCountryChange}>
                            <option value="">{t(LOCATION_SIZE_LABEL.SELECT_COUNTRY)}</option>
                            {tables?.countryMaster && Object.keys(tables?.countryMaster).map((key, index) => {
                                return (
                                    <option key={index} value={key}>{key}</option>
                                );
                            })}
                        </select>
                        <i className="angle-down-arrow"><BiChevronDown className="angle-down-icon" /></i>
                    </div>
                    <div className="form-group">
                        <label htmlFor="City">{t(LOCATION_SIZE_LABEL.CITY)} <span className="mandatory"></span></label>
                        <select className="form-control" aria-label={t(LOCATION_SIZE_LABEL.SELECT_CITY)} placeholder="" value={locationAndSize?.city} onChange={onCityChange}>
                            <option value="">{t(LOCATION_SIZE_LABEL.SELECT_CITY)}</option>
                            {tables?.countryMaster && locationAndSize?.country && tables?.countryMaster[locationAndSize?.country] && Object.keys(tables?.countryMaster[locationAndSize?.country])?.map((e, key) => {
                                return (
                                    <option key={key} value={e}>{e}</option>
                                );
                            })}
                        </select>
                        <i className="angle-down-arrow"><BiChevronDown className="angle-down-icon" /></i>
                    </div>

                    <div className="form-group">
                        <label htmlFor="Size">{t(LOCATION_SIZE_LABEL.SIZE)} <span className="mandatory"></span></label>
                        <select className="form-control" aria-label="Size" placeholder="" onChange={onSizeChange} value={locationAndSize?.storeSize}>
                            <option value="">{t(LOCATION_SIZE_LABEL.SELECT_SIZE)}</option>
                            {Object.keys(SIZE_DATA_JSON).map((key) => {
                                return (
                                    <option key={key} value={key}>{key}</option>
                                );
                            })}
                        </select>
                        <i className="angle-down-arrow"><BiChevronDown className="angle-down-icon" /></i>
                    </div>
                </div>
                <div className="col-md-9 col-sm-12">
                    {locationAndSize?.country !== "" && locationAndSize?.city !== "" && tables?.binTemperatureGraphMaster?.[`${locationAndSize?.country}-${locationAndSize?.city}`]?.bardata[1].ydata &&
                        <>
                            <div className="sub-head">{t(LOCATION_SIZE_LABEL.GRAPH_HEADING)}</div>
                            <div className="graph-head">{t(LOCATION_SIZE_LABEL.BIN_TEMPERATURE_HEADING)}</div>
                            {isBinTempGraphDataLoading ? <Loader message={t(LABEL_KEYS_LOADER.LOADING)} /> : <AmbientGraph key={`${locationAndSize?.country}-${locationAndSize?.city}`} idInfo={`${locationAndSize?.country}-${locationAndSize?.city}`} data={tables?.binTemperatureGraphMaster?.[`${locationAndSize?.country}-${locationAndSize?.city}`]?.bardata[1].ydata} label={tables?.binTemperatureGraphMaster?.[`${locationAndSize?.country}-${locationAndSize?.city}`]?.bardata[0].xdata} />}
                        </>
                    }
                </div>
            </div>}
            {isCabinetBySizeDataLoading ? <Loader message={t(LABEL_KEYS_LOADER.LOADING)} /> : <>
                {(locationAndSize?.storeSize != "" && tables?.cabinetBySizeMaster?.[`${locationAndSize?.storeSize}`]) && <div className="store-location row mx-0">
                    <div className="col-md-6 col-sm-12 pl-0">
                        <TemperatureSide headerType={TEMPERATURE_HEADING_CONFIG_LOCATION_MT} temperatureType={t(MEDIUM_TEMP_STORE_SIZE_TABLE.MT_HEADING_HOME)} storeType={locationAndSize?.storeSize} TempTable={tables?.cabinetBySizeMaster?.[`${locationAndSize?.storeSize}`]} cabinetType={"MT"} />
                    </div>
                    <div className="col-md-6 col-sm-12 pr-0">
                        <TemperatureSide headerType={TEMPERATURE_HEADING_CONFIG_LOCATION_LT} temperatureType={t(MEDIUM_TEMP_STORE_SIZE_TABLE.LT_HEADING_HOME)} storeType={locationAndSize?.storeSize} TempTable={tables?.cabinetBySizeMaster?.[`${locationAndSize?.storeSize}`]} cabinetType={"LT"} />
                    </div>
                </div>}</>}
        </>
    );
};

export default LocationSize;