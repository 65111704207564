// Calculator selction (home) labels keys
export const LABELKEYS_DASHBOARD = {
    HEADING: "DASHBOARD.MAIN_HEADING"
};
//API Paths
export const API_PATHS = {
    CONFIGURATIONS: "configurations",
    LABEL_CONFIG_LABELS: "labels"
};
//Store action type 
export const STORE_ACTION_TYPES = {
    RESET_CALCULATOR: "calculator",
    CALCULATOR: "calculator",
    TABLES: "tables",
    LOCATION_AND_SIZE: "locationAndSize",
    CHOOSE_SYSTEMS: "chooseSystems",
    CHOOSE_SYSTEMS_VALUES: "chooseSystemsValue",
    PARAMETER_SETUP: "parameterSetup",
    SIMULATION_SETUP: "simulationSetup",
    COMPRESSOR_SETUP: "compressorSetup",
    LOAD_SETUP_VALUES: "loadSetupValues",
    LOAD_SETUP_VALUES_RESET: "loadSetupValuesReset",
    LOAD_SETUP_VALUES_RESTORE_SESSION: "loadSetupValuesRestoreSession",
    SENSITIVITY_ANALYSIS: "sensitivityAnalysis",
    SIMULATION_SETUP_VALUES_RESET: "simulationSetupValuesReset",
    INIT: "init",
    CONFIG_QUERY: "configApi/executeQuery/fulfilled",
    FETCH_QUERY: "dataApi/executeQuery/fulfilled",
    GRAPH_QUERY: "graphApi/executeQuery/fulfilled",
    CALCULATE_QUERY: "calculateApi/executeQuery/fulfilled",
    PRE_CALCULATE_QUERY: "preCalculateApi/executeQuery/fulfilled",
    SENSITYVITY_QUERY: "sensitivityAnalysisApi/executeQuery/fulfilled",
    SESSIONS_QUERY: "sessionsApi/executeQuery/fulfilled",
    ERROR: "error",
    RESET_QUERY: "resetQuery"
};
//Error Messages
export const ERROR_MESSAGES = {
    CONFIG_NOT_FOUND: "Error: Cannot access configuration files for this app",
    LANG_FILE_NOT_FOUND: "Error: Cannot access language files for this app",
    ERROR_HANDLING_MESSAGE: "ERROR_HANDLING.ERROR_HANDLING_MESSAGE",
    ERROR_HANDLING_SUB_HEADLINE: "ERROR_HANDLING.ERROR_HANDLING_SUB_HEADLINE",
    ERROR_HANDLING_HEADLINE: "ERROR_HANDLING.ERROR_HANDLING_HEADLINE"
};
//Error Messages
export const ERROR_HANDLER_LABELS_WARNINGS = {
    TITLE: "ERROR_HANDLER_LABELS_WARNINGS.TITLE",
    MESSAGE: "ERROR_HANDLER_LABELS_WARNINGS.MESSAGE",
    RESTART: "ERROR_HANDLER_LABELS_WARNINGS.RESTART",
    BACK: "ERROR_HANDLER_LABELS_WARNINGS.BACK"
};
//Error Messages
export const LABEL_KEYS_LOADER = {
    LOADING: "LABEL_KEYS_LOADER.LOADING",
    SAVING: "LABEL_KEYS_LOADER.SAVING",
    CALCULATING: "LABEL_KEYS_LOADER.CALCULATING",
    DOWNLOADING: "LABEL_KEYS_LOADER.DOWNLOADING"
};
export const LANG_API_PATHS = (lang: string) => {
    let apiPath = "";
    if (lang) {
        apiPath = API_PATHS.LABEL_CONFIG_LABELS + "?lang=" + lang;
    }
    return apiPath;
};
export const BRANDING_JS_PATHS = (key: any) => {
    let apiPath = "";
    switch (key) {
        case "230":
            apiPath = "/js/headerfooter-adm-dev.js";
            break;
        case "266":
            apiPath = "/js/headerfooter-adm-qa.js";
            break;
        case "240":
            apiPath = "/js/headerfooter-adm-stage.js";
            break;
        case "79":
            apiPath = "/js/headerfooter-adm.js";
            break;
        default:
            apiPath = "/js/headerfooter-adm-dev.js";
            break;
    }
    return apiPath;
};
//HOME
export const LABELKEYS_HOME =
{
    HEADING: "HOME.HEADING"
};
//ProgressBar Mobile
export const PROGRESS_BAR = {
    SHOW: "PROGRESSBAR.SHOW",
    OF: "PROGRESSBAR.OF"
};
//SYSTEM ARCHITECTURE PRODUCT LIST
export const SYSTEM_ARCHITECTURE_TABLE = {
    HEADLINE: "SYSTEM_ARCHITECTURE_TABLE_LISTS.HEADLINE"
};

//ARIA-LABLES
export const ARIA_LABEL = {
    LINK: "ARIA_LABELS.LINK"
};

// Store Size selction  labels
export const LABELKEYS_STORE_SIZE = {
    HEADING: "STORE_SIZE.HEADING",
    HEADING_CONFIGURATION: "STORE_SIZE.HEADING_CONFIGURATION",
    SYSTEMS: "STORE_SIZE.SYSTEMS"
};

//Temperature Table Store Size
export const MEDIUM_TEMP_STORE_SIZE_TABLE =
{
    LT_HEADING: "MEDIUM_TEMP_STORE_SIZE_TABLE_LISTS.LT_HEADING",
    MT_HEADING: "MEDIUM_TEMP_STORE_SIZE_TABLE_LISTS.MT_HEADING",
    LT_HEADING_HOME: "MEDIUM_TEMP_STORE_SIZE_TABLE_LISTS.LT_HEADING_HOME",
    MT_HEADING_HOME: "MEDIUM_TEMP_STORE_SIZE_TABLE_LISTS.MT_HEADING_HOME",
    VIEW_SCHEMATICS: "MEDIUM_TEMP_STORE_SIZE_TABLE_LISTS.VIEW_SCHEMATICS"
};

//System Choice Table
export const LABELKEYS_SYSTEM_CHOICE =
{
    HEADING: "LABEL_SYSTEM_CHOICE.HEADING",
    DEFAULT: "LABEL_SYSTEM_CHOICE.DEFAULT"
};
export const SYSTEM_CHOICE_TABLE =
{
    SYSTEMS: "SYSTEM_CHOICE_TABLE_LISTS.SYSTEMS",
    BASELINE: "SYSTEM_CHOICE_TABLE_LISTS.BASELINE",
    LTSIDE: "SYSTEM_CHOICE_TABLE_LISTS.LTSIDE",
    MTSIDE: "SYSTEM_CHOICE_TABLE_LISTS.MTSIDE",
    HIGH_STAGE: "SYSTEM_CHOICE_TABLE_LISTS.HIGH_STAGE"
};
//System Load Setup
export const LABELKEYS_SYSTEM_LOAD =
{
    HEADING: "LABEL_SYSTEM_LOAD.HEADING",
    RESET: "LABEL_SYSTEM_LOAD.RESET",
    WARNING: "LABEL_SYSTEM_LOAD.WARNING",
};
export const SYSTEM_LOAD_TABLE =
{
    MTCASE: "SYSTEM_LOAD_TABLE_LISTS.MTCASE",
    LTCASE: "SYSTEM_LOAD_TABLE_LISTS.LTCASE",
    TEMPERATURE: "SYSTEM_LOAD_TABLE_LISTS.TEMPERATURE",
    NEWRANGE: "SYSTEM_LOAD_TABLE_LISTS.NEWRANGE",
    TEMPRANGE: "SYSTEM_LOAD_TABLE_LISTS.TEMPRANGE",
    LENGTH: "SYSTEM_LOAD_TABLE_LISTS.LENGTH",
    LENGTHRANGE: "SYSTEM_LOAD_TABLE_LISTS.LENGTHRANGE",
    CAPACITY: "SYSTEM_LOAD_TABLE_LISTS.CAPACITY",
    NO_DATA: "SYSTEM_LOAD_TABLE_LISTS.NO_DATA"
};
// Location and Size Label
export const LOCATION_SIZE_LABEL =
{
    HEADLINE: "LABLE_LOCATION_SIZE.HEADLINE",
    COUNTRY: "LABLE_LOCATION_SIZE.COUNTRY",
    SELECT_COUNTRY: "LABLE_LOCATION_SIZE.SELECT_COUNTRY",
    CITY: "LABLE_LOCATION_SIZE.CITY",
    SELECT_CITY: "LABLE_LOCATION_SIZE.SELECT_CITY",
    SIZE: "LABLE_LOCATION_SIZE.SIZE",
    SELECT_SIZE: "LABLE_LOCATION_SIZE.SELECT_SIZE",
    GRAPH_HEADING: "LABLE_LOCATION_SIZE.GRAPH_HEADING",
    BIN_TEMPERATURE_HEADING: "LABLE_LOCATION_SIZE.BIN_TEMPERATURE_HEADING"
};

export const STORE_SIZE_TEMP_SIDE = [
    {
        mt: [
            {
                case: "Multideck, open",
                temprature: "0 to +7 °C",
                capacity: [
                    {
                        Small: "11.3 to 13.8 m ",
                        Medium: "21.3 to 22.5 m",
                        Large: "38 to 40.5 m",
                        Hyper: "62.5 to 71.9 m",
                    }
                ],
                viewSchematic: "View Schematics",
                schematicGraph: '/images/ecoEfficiency/StoreSizeTable/case_MULTIDECK_OPEN.jpg'
            },
            {
                case: "Multideck, closed",
                temprature: "-2 to +4 °C",
                capacity: [
                    {
                        Small: "3.8 to 5 m",
                        Medium: "8.8 to 11.3 m",
                        Large: "25 to 26 m",
                        Hyper: "125 to 128.1 m",
                    }
                ],
                viewSchematic: "View Schematics",
                schematicGraph: '/images/ecoEfficiency/StoreSizeTable/case_MULTIDECK_CLOSED.jpg'

            },
            {
                case: "Multideck, semi-vertical closed",
                temprature: "+2 to +6 °C",
                capacity: [
                    {
                        Small: "",
                        Medium: "",
                        Large: "15 to 16.3 m",
                        Hyper: "12.5 to 13.8 m",
                    }
                ],
                viewSchematic: "View Schematics",
                schematicGraph: '/images/ecoEfficiency/StoreSizeTable/case_SEMI_VERTICAL_CLOSED.png'
            },
            {
                case: "Multideck, roll-in open",
                temprature: "+2 to +8 °C",
                capacity: [
                    {
                        Small: "",
                        Medium: "",
                        Large: "11.3 to 12.5 m",
                        Hyper: "11.3 to 12.5 m",
                    }
                ],
                viewSchematic: "View Schematics",
                schematicGraph: '/images/ecoEfficiency/StoreSizeTable/case_ROLL_IN.png'
            },
            {
                case: "Multideck, Ultra-freshness",
                temprature: "-1 to +3 °C",
                capacity: [
                    {
                        Small: "",
                        Medium: "3.8 to 5 m",
                        Large: "11.3 to 12.5 m",
                        Hyper: "11.3 to 12.5 m",
                    }
                ],
                viewSchematic: "View Schematics",
                schematicGraph: '/images/ecoEfficiency/StoreSizeTable/case_ULTRA_FRESH.jpg'
            },
            {
                case: "Counter",
                temprature: "0 to +6 °C",
                capacity: [
                    {
                        Small: "3.8 to 5 m",
                        Medium: "5 to 6.3 m",
                        Large: "25 to 26 m",
                        Hyper: "43.8 to 45 m",
                    }
                ],
                viewSchematic: "View Schematics",
                schematicGraph: '/images/ecoEfficiency/StoreSizeTable/case_COUNTER.jpg'
            },
            {
                case: "Multideck, vertical with glass doors",
                temprature: "0 to +4 °C",
                capacity: [
                    {
                        Small: "1.6 to 2.4 m ",
                        Medium: "2.4 to 3.2 m",
                        Large: "9.5 to 11.1 m",
                        Hyper: "6.4 to 8 m",
                    }
                ],
                viewSchematic: "View Schematics",
                schematicGraph: '/images/ecoEfficiency/StoreSizeTable/case_GLASS_DOOR.jpg'
            },
            {
                case: "Storage Room MT - 1",
                temprature: "-1 to +2 °C",
                capacity: [
                    {
                        Small: "",
                        Medium: "",
                        Large: "",
                        Hyper: "40 to 45 m³",
                    }
                ],
            },
            {
                case: "Storage Room MT - 2",
                temprature: "0 to +2 °C",
                capacity: [
                    {
                        Small: "",
                        Medium: "",
                        Large: "",
                        Hyper: "70 to 75 m³",
                    }
                ],
            },
            {
                case: "Storage Room MT - 1",
                temprature: "-1 to +2 °C",
                capacity: [
                    {
                        Small: "10 to 15 m³",
                        Medium: "15 to 20 m³",
                        Large: "30 to 35 m³",
                        Hyper: "",
                    }
                ],
            },
            {
                case: "Storage Room MT - 2",
                temprature: "0 to +2 °C",
                capacity: [
                    {
                        Small: "",
                        Medium: "15 to 20 m³",
                        Large: "30 to 35 m³",
                        Hyper: "",
                    }
                ],
            },
            {
                case: "Storage Room MT - 3",
                temprature: "+6 to +8 °C",
                capacity: [
                    {
                        Small: "",
                        Medium: "15 to 20 m³",
                        Large: "30 to 35 m³",
                        Hyper: "",
                    }
                ],
            },
            {
                case: "Storage Room MT - 2",
                temprature: "0 to +2 °C",
                capacity: [
                    {
                        Small: "10 to 15 m³ ",
                        Medium: "",
                        Large: "",
                        Hyper: "",
                    }
                ],
            },
            {
                case: "Storage Room MT - 3",
                temprature: "+6 to +8 °C",
                capacity: [
                    {
                        Small: "",
                        Medium: "",
                        Large: "",
                        Hyper: "250 to 255 m³",
                    }
                ],
            },
            {
                case: "Laboratory Dock MT",
                temprature: "+8 to +12 °C",
                capacity: [
                    {
                        Small: "",
                        Medium: "",
                        Large: "100 to 120 m³",
                        Hyper: "",
                    }
                ],
            },
            {
                case: "Laboratory Dock MT",
                temprature: "+8 to +12 °C",
                capacity: [
                    {
                        Small: "",
                        Medium: "",
                        Large: "",
                        Hyper: "440 to 450 m³",
                    }
                ],
            }
        ],
        lt: [
            {
                case: "Island Single Row with Lids",
                temprature: "-18 to -24 °C",
                capacity: [
                    {
                        Small: "7.5 to 13.8 m",
                        Medium: "21.3 to 22.5 m",
                        Large: "21.8 to 31.3 m",
                        Hyper: "17.5 to 25 m",
                    }
                ],
                viewSchematic: "View Schematics",
                schematicGraph: '/images/ecoEfficiency/StoreSizeTable/case_ISLAND_SINGLE_CLOSED.png'
            },
            {
                case: "Island Double Row with Lids",
                temprature: "-18 to -24 °C",
                capacity: [
                    {
                        Small: "",
                        Medium: "8.8 to 10 m",
                        Large: "28.1 to 37.5 m",
                        Hyper: "103.1 to 112.5 m",
                    }
                ],
                viewSchematic: "View Schematics",
                schematicGraph: '/images/ecoEfficiency/StoreSizeTable/case_ISLAND_DOUBLE_CLOSED.png'
            },
            {
                case: "Combo",
                temprature: "-18 to -24 °C",
                capacity: [
                    {
                        Small: "2.5 to 3.8 m",
                        Medium: "8.8 to 11.3 m",
                        Large: "12.5 to 15.6 m",
                        Hyper: "21.9 to 23.8 m",
                    }
                ],
                viewSchematic: "View Schematics",
                schematicGraph: '/images/ecoEfficiency/StoreSizeTable/case_COMBI.jpg'
            },
            {
                case: "Multideck, vertical with glass doors",
                temprature: "-18 to -24 °C",
                capacity: [
                    {
                        Small: "",
                        Medium: "8 to 9.5 m",
                        Large: "11.2 to 12.5 m",
                        Hyper: "14.3 to 23.9 m",
                    }
                ],
                viewSchematic: "View Schematics",
                schematicGraph: '/images/ecoEfficiency/StoreSizeTable/case_GLASS_DOOR.jpg'
            },
            {
                case: "Storage Room LT",
                temprature: "-24 to -28 °C",
                capacity: [
                    {
                        Small: "10 to 12 m³",
                        Medium: "",
                        Large: "",
                        Hyper: "",
                    }
                ],
            },
            {
                case: "Storage Room LT",
                temprature: "-24 to -28 °C",
                capacity: [
                    {
                        Small: "",
                        Medium: "15 to 20 m³",
                        Large: "29 to 30 m³",
                        Hyper: "145 to 150 m³",
                    }
                ],
            }
        ]
    }
];

export const CALL_TO_ACTION = {
    BACK: "CALL_TO_ACTION_LABEL.BACK",
    CONTINUE: "CALL_TO_ACTION_LABEL.CONTINUE",
    START: "CALL_TO_ACTION_LABEL.START",
    SAVE: "CALL_TO_ACTION_LABEL.SAVE",
    RUN_CALC: "CALL_TO_ACTION_LABEL.RUN_CALC"
};

export const LABELKEYS_COMPRESSOR_SETUP = {
    HEADING: "LABEL_COMPRESSOR_SETUP.HEADING",
    DAYLOAD: "LABEL_COMPRESSOR_SETUP.DAYLOAD",
    LOW_TEMPERATURE_HEADING: "LABEL_COMPRESSOR_SETUP.LOW_TEMPERATURE_HEADING",
    SELECT: "LABEL_COMPRESSOR_SETUP.SELECT",
    COMPANY: "LABEL_COMPRESSOR_SETUP.COMPANY",
    TYPE: "LABEL_COMPRESSOR_SETUP.TYPE",
    COMPRESSOR_TYPE: "LABEL_COMPRESSOR_SETUP.COMPRESSOR_TYPE",
    CONFIGURATION: "LABEL_COMPRESSOR_SETUP.CONFIGURATION",
    MEDIUM_TEMPERATURE_HEADING: "LABEL_COMPRESSOR_SETUP.MEDIUM_TEMPERATURE_HEADING",
    REFRIGERANT: "LABEL_COMPRESSOR_SETUP.REFRIGERANT",
    SELECTED_MT_COMPRESSOR: "LABEL_COMPRESSOR_SETUP.SELECTED_MT_COMPRESSOR",
    TARGET_MT_COMPRESSOR: "LABEL_COMPRESSOR_SETUP.TARGET_MT_COMPRESSOR",
    SELECTED_LT_COMPRESSOR: "LABEL_COMPRESSOR_SETUP.SELECTED_LT_COMPRESSOR",
    TARGET_LT_COMPRESSOR: "LABEL_COMPRESSOR_SETUP.TARGET_LT_COMPRESSOR",
    MEDIUM_TEMPERATURE: "LABEL_COMPRESSOR_SETUP.MEDIUM_TEMPERATURE",
    COMPRESSOR_MANUFACTURE: "LABEL_COMPRESSOR_SETUP.COMPRESSOR_MANUFACTURE",
    LOW_TEMPERATURE: "LABEL_COMPRESSOR_SETUP.LOW_TEMPERATURE",
    TARGET_MT_COMPRESSOR_WITH_SUBCOOLER: "LABEL_COMPRESSOR_SETUP.TARGET_MT_COMPRESSOR_WITH_SUBCOOLER",
    TARGET_LT_COMPRESSOR_WITH_SUBCOOLER: "LABEL_COMPRESSOR_SETUP.TARGET_LT_COMPRESSOR_WITH_SUBCOOLER",
    LOW_TEMPERATURE_MICRO_CAS: "LABEL_COMPRESSOR_SETUP.LOW_TEMPERATURE_MICRO_CAS",
    MEDIUM_TEMPERATURE_MICRO_CAS: "LABEL_COMPRESSOR_SETUP.MEDIUM_TEMPERATURE_MICRO_CAS"
};

export const LABELKEYS_PARAMETER_SETUP = {
    HEADING: "LABEL_PARAMETER_SETUP.HEADING",
    WARNING: "LABEL_PARAMETER_SETUP.WARNING",
    DAYLOAD: "LABEL_PARAMETER_SETUP.DAYLOAD",
};


export const LABELKEYS_SIMULATION_SETUP = {
    HEADING: "LABEL_SIMULATION_SETUP.HEADING",
    PRICE: "LABEL_SIMULATION_SETUP.PRICE",
    ELECTRICITY_INTENSITY: "LABEL_SIMULATION_SETUP.ELECTRICITY_INTENSITY",
    MID_CARBON: "LABEL_SIMULATION_SETUP.MID_CARBON",
    ELECTRICITY_PRICE: "LABEL_SIMULATION_SETUP.ELECTRICITY_PRICE",
    ELECTRICITY_VALUE: "LABEL_SIMULATION_SETUP.ELECTRICITY_VALUE",
    LIFE_SPAN: "LABEL_SIMULATION_SETUP.LIFE_SPAN",
    YEARS: "LABEL_SIMULATION_SETUP.YEARS",
    LEAKAGE: "LABEL_SIMULATION_SETUP.LEAKAGE",
    REFRIGENT_PRICE_WARNING: "LABEL_SIMULATION_SETUP.REFRIGENT_PRICE_WARNING",

};

export const LABELKEYS_SYSTEM_ANALYSIS_PANEL =
{
    SUB_COOLER: "LABELS_SYSTEM_ANALYSIS_PANEL.SUB_COOLER",
    SUB_COOLER_OPTION_1: "LABELS_SYSTEM_ANALYSIS_PANEL.SUB_COOLER_OPTION_1",
    SUB_COOLER_OPTION_2: "LABELS_SYSTEM_ANALYSIS_PANEL.SUB_COOLER_OPTION_2",
    PERCENTAGE_SHIFT: "LABELS_SYSTEM_ANALYSIS_PANEL.PERCENTAGE_SHIFT",
    CONFIGURATION: "LABELS_SYSTEM_ANALYSIS_PANEL.CONFIGURATION",
    RACKS: "LABELS_SYSTEM_ANALYSIS_PANEL.RACKS",
    SELECT: "LABELS_SYSTEM_ANALYSIS_PANEL.SELECT",
    CONDENSING: "LABELS_SYSTEM_ANALYSIS_PANEL.CONDENSING",
    MEDIUM_TEMPERATURE: "LABELS_SYSTEM_ANALYSIS_PANEL.MEDIUM_TEMPERATURE",
    REFRIGERANT: "LABELS_SYSTEM_ANALYSIS_PANEL.REFRIGERANT",
    SELECTED_MT: "LABELS_SYSTEM_ANALYSIS_PANEL.SELECTED_MT",
    LOAD_MT: "LABELS_SYSTEM_ANALYSIS_PANEL.LOAD_MT",
    TARGET_MT: "LABELS_SYSTEM_ANALYSIS_PANEL.TARGET_MT",
    RACK_1: "LABELS_SYSTEM_ANALYSIS_PANEL.RACK_1",
    RACK_2: "LABELS_SYSTEM_ANALYSIS_PANEL.RACK_2",
    RACK_3: "LABELS_SYSTEM_ANALYSIS_PANEL.RACK_3",
    RACK_4: "LABELS_SYSTEM_ANALYSIS_PANEL.RACK_4",
    CDU_1: "LABELS_SYSTEM_ANALYSIS_PANEL.CDU_1",
    CDU_2: "LABELS_SYSTEM_ANALYSIS_PANEL.CDU_2",
    CDU_3: "LABELS_SYSTEM_ANALYSIS_PANEL.CDU_3",
    CDU_4: "LABELS_SYSTEM_ANALYSIS_PANEL.CDU_4",
    LOW_TEMPERATURE: "LABELS_SYSTEM_ANALYSIS_PANEL.LOW_TEMPERATURE",
    SELECTED_LT: "LABELS_SYSTEM_ANALYSIS_PANEL.SELECTED_LT",
    LOAD_LT: "LABELS_SYSTEM_ANALYSIS_PANEL.LOAD_LT",
    TARGET_LT: "LABELS_SYSTEM_ANALYSIS_PANEL.TARGET_LT",
    RACK: "LABELS_SYSTEM_ANALYSIS_PANEL.RACK",
    WARNING: "LABELS_SYSTEM_ANALYSIS_PANEL.WARNING",
    WARNING_MSG: "LABELS_SYSTEM_ANALYSIS_PANEL.WARNING_MSG",
    WARNING_MSG_MT_LOAD: "LABELS_SYSTEM_ANALYSIS_PANEL.WARNING_MSG_MT_LOAD",
    WARNING_MSG_LT_LOAD: "LABELS_SYSTEM_ANALYSIS_PANEL.WARNING_MSG_LT_LOAD",
    WARNING_MSG_COMP_MT_LOAD: "LABELS_SYSTEM_ANALYSIS_PANEL.WARNING_MSG_COMP_MT_LOAD",
    WARNING_MSG_COMP_LT_LOAD: "LABELS_SYSTEM_ANALYSIS_PANEL.WARNING_MSG_COMP_LT_LOAD",
    CENTRALIZED_ID: "LABELS_SYSTEM_ANALYSIS_PANEL.CENTRALIZED_ID",
    SELECTED_MT_VALUE: "LABELS_SYSTEM_ANALYSIS_PANEL.SELECTED_MT_VALUE",
    LOAD_MT_VALUE: "LABELS_SYSTEM_ANALYSIS_PANEL.LOAD_MT_VALUE",
    LOAD_LT_VALUE: "LABELS_SYSTEM_ANALYSIS_PANEL.LOAD_LT_VALUE",
    SELECTED_LOAD_LT: "LABELS_SYSTEM_ANALYSIS_PANEL.SELECTED_LOAD_LT",
    SELECTED_LOAD_MT: "LABELS_SYSTEM_ANALYSIS_PANEL.SELECTED_LOAD_MT"
};

export const LABELKEYS_WARNING = {
    START: "LABELKEY_WARNING.START",
    TEXT: "LABELKEY_WARNING.TEXT",
    NEW: "LABELKEY_WARNING.NEW",
    CONTINUE: "LABELKEY_WARNING.CONTINUE"
};
export const ERROR_WARNINGS = {
    RESTART: "ERROR_WARNINGS.RESTART",
    BACK: "ERROR_WARNINGS.BACK",
    ERROR: "ERROR_WARNINGS.ERROR"
};
export const RESULTS_LABEL =
{
    HEADLINE: "RESULT_LABEL.HEADLINE",
    RESULTS: "RESULT_LABEL.RESULTS",
    SENSITIVITY_ANALYSIS: "RESULT_LABEL.SENSITIVITY_ANALYSIS",
    STORE_LOCATION: "RESULT_LABEL.STORE_LOCATION",
    ABSOLUTE_GRAPH: "RESULT_LABEL.ABSOLUTE_GRAPH",
    RELATIVE_GRAPH: "RESULT_LABEL.RELATIVE_GRAPH",
    SENSITIVITY_PARAMETER: "RESULT_LABEL.SENSITIVITY_PARAMETER",
    RUN: "RESULT_LABEL.RUN",
    PERCENTAGE_WRT_BASE: "RESULT_LABEL.PERCENTAGE_WRT_BASE",
    BASE_PARAMETER: "RESULT_LABEL.BASE_PARAMETER",
    NEW_PARAMETER: "RESULT_LABEL.NEW_PARAMETER",
    SELECT_PARAMETER: "RESULT_LABEL.SELECT_PARAMETER",
    SENSITIVITY_HEADLINE: "RESULT_LABEL.SENSITIVITY_HEADLINE"
};
export const REGEX_ITEMS =
{
    NUMBER: /^[0-9\b]+$/,
    FLOAT_NUMBER: /^([0-9]{1,})?(\.)?([0-9]{1,})?$/
};
// Terms & Condition Label
export const TERMS_CONDITIONS_INFO = {
    HEADING: "TERMS_CONDITIONS_INFO.HEADING",
    TERMS_CONDITION_TEXT: "TERMS_CONDITIONS_INFO.TERMS_CONDITION_TEXT",
    ACCEPT_TERMS_CONDITION_TEXT: "TERMS_CONDITIONS_INFO.ACCEPT_TERMS_CONDITION_TEXT",
    AGREE: "TERMS_CONDITIONS_INFO.AGREE",
    DISAGREE: "TERMS_CONDITIONS_INFO.DISAGREE"
};
// Disclaimer Text/Label
export const DISCLAIMER_INFO = {
    TITLE: "DISCLAIMER_INFO.TITLE",
    DISCLAIMER_TEXT: "DISCLAIMER_INFO.DISCLAIMER_TEXT"
};
// Calculation Types 
export const CALCULATION_TYPES = {
    SAVE_RESULTS: "Results",
    SAVE_SENSITIVITY: "Sensitivity Analysis"
};
// PDF Labels
export const LABELKEYS_PDF = {
    HEADLINE_SAVE_RESULTS_PDF: "LABELS_PDF.HEADLINE_SAVE_RESULTS_PDF",
    HEADLINE_SAVE_SENSITIVITY_PDF: "LABELS_PDF.HEADLINE_SAVE_SENSITIVITY_PDF",
    EMAIL: "LABELS_PDF.EMAIL",
    TECHNICAL_SUPPORT: "LABELS_PDF.TECHNICAL_SUPPORT",
    WEB_URL: "LABELS_PDF.WEB_URL"
};
// Sensitivity Parameter Text/Label
export const SENSITIVITY_PARAMETER_LABEL = {
    KWH: "SENSITIVITY_PARAMETER_LABEL.KWH",
    YEARS: "SENSITIVITY_PARAMETER_LABEL.YEARS",
    SPAN : "SENSITIVITY_PARAMETER_LABEL.SPAN"
};
export const LABELKEYS_SAVE_SESSION = {
    BUTTON_SAVE: "LABELKEYS_SAVE_SESSION.SAVE",
    BUTTON_RESTART: "LABELKEYS_SAVE_SESSION.RESTART",
    BUTTON_CONTACT: "LABELKEYS_SAVE_SESSION.CONTACT",
    TITLE_SAVE: "LABELKEYS_SAVE_SESSION.TITLE_SAVE",
    TITLE_SAVED: "LABELKEYS_SAVE_SESSION.TITLE_SAVED",
    MESSAGE_CONTACT: "LABELKEYS_SAVE_SESSION.MESSAGE_CONTACT",
    LABEL_TITLE: "LABELKEYS_SAVE_SESSION.LABEL_TITLE",
    LABEL_SESSION_TITLE: "LABELKEYS_SAVE_SESSION.LABEL_SESSION_TITLE",
    LABEL_WARNING: "LABELKEYS_SAVE_SESSION.LABEL_WARNING",
    LABEL_NO_CHANGE_WARNING: "LABELKEYS_SAVE_SESSION.LABEL_NO_CHANGE_WARNING"
};
export const LABEL_SESSION_TABLE = {
    HEADING : "LABELKEYS_SESSION_TABLE.HEADING",
    NO_RESULT : "LABELKEYS_SESSION_TABLE.NO_RESULT",
};
// get timezone abbr
export const tzAbbr = (dateInput) => {
    const dateObject = dateInput ?new Date(dateInput):new Date();
        const dateString = dateObject + "";
        let tzAbbrValue: any = (
            // Works for the majority of modern browsers
            dateString.match(/\(([^\)]+)\)$/) ||
            // IE outputs date strings in a different format:
            dateString.match(/([A-Z]+) [\d]{4}$/)
        );
    if (tzAbbrValue) {       
        tzAbbrValue = tzAbbrValue[1].match(/[A-Z]/g).join("");
    }
    return tzAbbrValue;
};
export const REGEX_VALIDATION = {
    API_INPUT_FIELD_VALIDATION: /[!@#%&*~`^$_+\=\[\]{};':"\\|.<>\/?]+/gi,
    ELECTRICITY_PRICE_INPUT_FIELD_VALIDATION : /[a-zA-Z!@#%&*~`^$_+\=\[\]{-};':"\\|,()<>\/?]+/gi
  };