/**********************************************************************************************
ErrorHandling.tsx - Error modal with back and restart options
***********************************************************************************************/
import "./ErrorHandling.scss";
import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import WarningModal from '../WarningModal/WarningModal';
import { ERROR_HANDLER_LABELS_WARNINGS } from "../../../helper/constants";
import { useTranslation } from "react-i18next";
import { resetQuery } from "../../../store/reducers";
import Analytics from "../../../services/analytics";
const ErrorHandling = () => {
   
    const navigate = useNavigate();
    
    const { t } = useTranslation();
    const { error } = useSelector((state: any) => state?.ecoEfficiency || {});  
    const { ecoEfficiency } = useSelector((state: any) => state || {});  
    const { PAGE_NAVIGATION,ERROR_ACTION_TYPES } = useSelector((state: any) => state?.ecoEfficiency?.config || {});
    const {prevPagePath} = useSelector((state: any) => state?.ecoEfficiency?.calculator || {});
    //Modal Functionality:  
    const [show, setShow] = useState(error?.showError);
    const onBackButtonClick = useCallback((e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.preventDefault();
        Analytics.sendAnalyticsDataForError(ecoEfficiency, error?.errorInfo || "", error?.errorMetaInfo || undefined, true, ERROR_ACTION_TYPES?.BACK || "back");
        setShow(false);
        resetQuery();
        navigate(prevPagePath || -1);
    }, [navigate,prevPagePath,ERROR_ACTION_TYPES,ecoEfficiency,error]);
    const onResetButtonClick = useCallback((e) => {
        e.preventDefault();
        Analytics.sendAnalyticsDataForError(ecoEfficiency, error?.errorInfo || "", error?.errorMetaInfo || undefined, true, ERROR_ACTION_TYPES?.RESET || "reset");
        setShow(false);
        resetQuery();
        navigate(PAGE_NAVIGATION.OVERVIEW);
    }, [PAGE_NAVIGATION, navigate,ERROR_ACTION_TYPES,ecoEfficiency,error]);

    //Modal Functionality Ends
    return (
        <div className="errorhandling">
            <Modal show={show}>
                <WarningModal
                    isErrorPopup
                    isWarning={true}
                    isCloseVisible={false}
                    title={t(ERROR_HANDLER_LABELS_WARNINGS.TITLE)}
                    messgage={t(ERROR_HANDLER_LABELS_WARNINGS.MESSAGE)}
                    button1Text={t(ERROR_HANDLER_LABELS_WARNINGS.BACK)}
                    button2Text={t(ERROR_HANDLER_LABELS_WARNINGS.RESTART)}
                    onClickButton1={onBackButtonClick}
                    onClickButton2={onResetButtonClick}
                    onClickClose={null}
                />
            </Modal>
        </div>
    );
};

export default ErrorHandling;