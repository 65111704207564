import React, {useRef} from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  BubbleController,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BubbleController,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

const GroupBarGraph= (props) => {
    const ref = useRef();
    const options = {
        plugins: {
          legend: {
            filter:null,
            onClick: function (e) {
              e.stopPropagation();
          },
          position:'right',
          labels: {
            usePointStyle:true,
              font: {
                size: [`${props?.label?.length >= 5 ? 12 : 14}`],
                weight: 900
              }
          }
          },
          title: {
            display: true
          },
          datalabels: {
            display: true,
            color: "black"
          },
          
          tooltip: {
            enabled: true,
            usePointStyle : true,
            callbacks: {
              label: function(context) {
                  let label = context.dataset.label || '';
    
                  if (label) {
                      label += ': ';
                  }
                  if (context.parsed.y !== null) {
                      label += context.parsed.y.toLocaleString();
                  }
                  return label;
              }
          }
          },
        },
        responsive: true,
        scales: {
          x: { 
            stacked: true,
            grid: {
              color: 'transparent',
              borderColor: 'black'
            },
            ticks: {
              autoSkip: false,
              maxRotation: [`${props?.label?.length >= 5 ? 20 : 0}`],
              minRotation: [`${props?.label?.length >= 5 ? 20 : 0}`],
              font: {
                size: [`${props?.label?.length >= 5 ? 12 : 14}`],
                weight: 900
              }
          },
          },
          A: {
            grid: {
            color: 'transparent',
            borderColor: 'black'
            },
              type: 'linear',
              position: 'left',
              ticks: { beginAtZero: true, color: 'black', font: {
                size: [`${props?.label?.length >= 5 ? 12 : 14}`],
                weight: 900
              } },
              title: {
                display: true,
                text: 'CapEx & OpEx (Euro)',
                padding:10,
                 font: {
                  size: [`${props?.label?.length >= 5 ? 12 : 14}`],
                  weight: 900
                },
                color:'#000', 
              }
            },
            B: {
              grid: {
                color: 'transparent',
                borderColor: 'black'
              },
              type: 'linear',
              position: 'right',
              ticks: { beginAtZero: true, color: 'black', font: {
                size: [`${props?.label?.length >= 5 ? 12 : 14}`],
                weight: 900
              } },
              title: {
                display: true,
                text: 'Emissions (TCO2)',
                padding:10,
                color:'#000',
                font: {
                  size: [`${props?.label?.length >= 5 ? 12 : 14}`]
                }
              },
            },
        },
      };
      
      const labels = props.label;
      const data = {
        labels,
        datasets: [
          {
            type : 'bubble',
            label: 'Emissions',
            borderColor: '#717272',
            fill: true,
            data: props.emission,
            backgroundColor: "#717272",
            borderWidth: 2,
            yAxisID: 'B',
            datalabels: {
              display: false,
              color: "black",
              anchor: "horizontal",
              align: "horizontal",
              rotation:0
            },  
            pointStyle:'circle'
        },
          {
            label: 'CAPEX',
            data: props.capex,
            yAxisID: 'A',
            backgroundColor: '#CE5D55',
            stack: 'Stack 0',
            pointStyle:'rect',
            datalabels: {
              display: true,
              formatter: function(value) {
                value = Math.round(value);
                return  value.toLocaleString();
              },
              padding:-5,
              offset: -55,
              anchor: "end",
              align: "start",
              rotation:270,
              font:{
                size:'12px',
                weight: 800
              },
            },
          },
          {
            label: 'OpEx - Electricity',
            data: props.opexElectricity,
            yAxisID: 'A',
            backgroundColor: '#01AFF1',
            stack: 'Stack 1',
            pointStyle:'rect',
            datalabels: {
              display: true,
              formatter: function(value) {
                value = Math.round(value);
                return  value.toLocaleString();
              },
              padding:-5,
              offset: -55,
              anchor: "end",
              align: "start",
              rotation:270,
              
              font:{
                size:'12px',
                weight: 800
              },
            }
          },
          {
            label: 'OpEx - Service',
            data: props.opexService,
            yAxisID: 'A',
            backgroundColor: '#FBC400',
            stack: 'Stack 2',
            pointStyle:'rect',
            datalabels: {
              display: true,
              formatter: function(value) {
                value = Math.round(value);
                return  value.toLocaleString();
              },
              padding:-5,
              offset: -55,
              anchor: "end",
              align: "start",
              rotation:270,
              font:{
                size:'12px',
                weight: 800
              },
            }
          },
          {
            label: 'OpEx - Topping Off',
            data: props.opexToppingUp,
            yAxisID: 'A',
            backgroundColor: '#9EC66A',
            stack: 'Stack 3',
            pointStyle:'rect',
            datalabels: {
              display: true,
              formatter:function(value) {
                value = Math.round(value);
                return  value.toLocaleString();
              }, 
              padding:-5,
              offset: -55,
              anchor: "end",
              align: "start",
              rotation:270,
              font:{
                size:'12px',
                weight: 800
              },
            },
          }
        ],
      };
      return( <Bar id={props?.source} ref={ref} options={options} data={data} aria-label="Group Bar Graph" /> );
};
export default GroupBarGraph;
