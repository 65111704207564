/**********************************************************************************************
Headline.tsx - Back ,Continue and Run Calculation button on the top 
***********************************************************************************************/
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { CALL_TO_ACTION, LABELKEYS_WARNING, LABEL_KEYS_LOADER, LABEL_SESSION_TABLE, REGEX_VALIDATION } from "../../../helper/constants";
import { useTranslation } from "react-i18next";
import _ from 'lodash';
import { useNavigate, useLocation } from "react-router-dom";
import './Headline.scss';
import moment from "moment";
import { useSelector } from "react-redux";
import Modal from "react-bootstrap/Modal";
import WarningModal from "../../common/WarningModal/WarningModal";
import { preCalculateApi } from "../../../services/preCalculate";
import { calculateApi } from "../../../services/calculate";
import { store } from '../.../../../../store/ecoEfficiencyStore';
import { PreCalculationInputs } from "../../../services/preCalculationInputs";
import Loader from "../Loader/Loader";
import ErrorHandling from "../ErrorHandling/ErrorHandling";
import { setCalculator, setTables, setResetCalculator, setChooseSystem } from "../.../../../../store/actions";
import Analytics from "../../../services/analytics";
import { MdClose } from "react-icons/md";
import { VscSearch } from "react-icons/vsc";
import { initialState } from "../../../store/reducers";
import { sessionsApi } from "../../../services/sessions";
import { dataApi } from "../../../services/data";
import { LOCATION_AND_SIZE, CHOOSE_SYSTEMS, LOAD_SETUP, COMPRESSOR_SETUP, PARAMETER_SETUP, SIMULATION_SETUP, SENSITIVITY_ANALYSIS } from "../.../../../../store/initialStoreValues";
const Headline = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [showWarning, setShowWarning] = useState(false);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [showChooseSimulation, setShowChooseSimulation] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [isCalculationInprogress, setCalculationInprogress] = useState(false);
    const [sessionList, setSessionList] = useState<any>([]);
    const [numberofSession, setNumberofSession] = useState<any>([]);
    const [resetSearchSessionList, resetSessionList] = useState<any>([]);
    const [filteredSearchSessionList, filteredSessionList] = useState<any>([]);
    const [chooseSimulationEnable, setChooseSystemEnable] = useState(false);
    const [isSessionAvailable, setSessionAvailable] = useState(0);
    const [setSession, onSetSession] = useState<any>([]);
    const [currentUser, setCurrentUserId] = useState("");
    const { t } = useTranslation();
    const { config, error } = useSelector((state: any) => state?.ecoEfficiency || {});
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { showError } = error;
    const { userInputs, transactionId, tables, preCalculationInputs, restoreTransactionId, restoredSessions, restoreSessionData, storeSimulationSessionValue } = useSelector((state: any) => state?.ecoEfficiency?.calculator || {});
    const { chooseSystems, compressorSetup, locationAndSize } = userInputs;
    const { TABPROGRESSSTEPPER, PAGE_NAVIGATION_ORDER, PAGE_NAVIGATION, SYSTEM_ARCHS_REACT_MS_MAPPING, SYSTEM_ARCHS_PRE_CALCULATION_REACT_MS_MAPPING, SYSTEM_ARCHS_KEYS, SIMULATION_TABLE_LISTS, RESTORE_SESSION_PAGE_ID_MAPPING_OTHERS, RESTORE_SESSION_PAGE_ID_MAPPING_COMPRESSORS, SERVICE_REQUEST_FETCH_DATA_TYPES, RESTORE_SESSION_ARC_PAGE_IDS, RESTORE_SESSION_PAGE_IDS } = useSelector((state: any) => state?.ecoEfficiency?.config || {});
    const activeStage = props?.activeStage || "";
    const activeStageLocationSetup = props?.activeStageLocationSetup || "";
    const [isCheck30, setIsCheck30] = useState(false);
    const [isCheck90, setIsCheck90] = useState(false);
    const onContinueButtonClick = useCallback(async (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        window.scrollTo(0, 0);
        e.preventDefault();
        let isValid = true;
        let preCalculationData: any = undefined;
        if (props.calculationId) {
            await setIsSaving(true);
            const preCalculation = await PreCalculationInputs.getInputJSON(props.calculationId, userInputs, config);
            preCalculationData = { ...preCalculation };
            const tmpRefershKey = Date.now();
            const requestBody = { pageData: preCalculation, refreshKey: tmpRefershKey, transactionId: transactionId, pageName: props.calculationId === SYSTEM_ARCHS_PRE_CALCULATION_REACT_MS_MAPPING.LOAD_SETUP ? "loadSetup" : props.calculationId };
            let calculationData: any = {};
            if (preCalculation && props.calculationId !== SYSTEM_ARCHS_PRE_CALCULATION_REACT_MS_MAPPING.ARC_8) {
                calculationData = await store.dispatch(preCalculateApi.endpoints.getPreCalculate.initiate(requestBody));
            } else if (preCalculation && props.calculationId === SYSTEM_ARCHS_PRE_CALCULATION_REACT_MS_MAPPING.ARC_8 && chooseSystems?.selectedSystems?.indexOf(SYSTEM_ARCHS_KEYS.ARC_8) > -1 && chooseSystems?.selectedSystems?.indexOf(SYSTEM_ARCHS_KEYS.ARC_9) < 0) {
                calculationData = await store.dispatch(preCalculateApi.endpoints.getPreCalculate.initiate(requestBody));
            }
            else if (preCalculation && props.calculationId === SYSTEM_ARCHS_PRE_CALCULATION_REACT_MS_MAPPING.ARC_8 && chooseSystems?.selectedSystems?.indexOf(SYSTEM_ARCHS_KEYS.ARC_8) < 0 && chooseSystems?.selectedSystems?.indexOf(SYSTEM_ARCHS_KEYS.ARC_9) > -1) {
                const preCalculationArc9: any = { ...preCalculation };
                preCalculationArc9.calculationId = SYSTEM_ARCHS_PRE_CALCULATION_REACT_MS_MAPPING?.[SYSTEM_ARCHS_KEYS.ARC_9];
                if (preCalculationArc9?.data?.refrigerant?.toString()?.trim()?.length == 0) {
                    preCalculationArc9.data.refrigerant = chooseSystems?.system?.[SYSTEM_ARCHS_KEYS.ARC_9]?.mtSide;
                }
                const requestBodyArc9 = { pageData: preCalculationArc9, refreshKey: tmpRefershKey, transactionId: transactionId, pageName: preCalculationArc9.calculationId };
                calculationData = await store.dispatch(preCalculateApi.endpoints.getPreCalculate.initiate(requestBodyArc9));
            }
            else if (preCalculation && props.calculationId === SYSTEM_ARCHS_PRE_CALCULATION_REACT_MS_MAPPING.ARC_8 && chooseSystems?.selectedSystems?.indexOf(SYSTEM_ARCHS_KEYS.ARC_8) > -1 && chooseSystems?.selectedSystems?.indexOf(SYSTEM_ARCHS_KEYS.ARC_9) > -1) {
                calculationData = await store.dispatch(preCalculateApi.endpoints.getPreCalculate.initiate(requestBody));
                if (calculationData?.data?.isSuccess) {
                    const preCalculationArc9: any = { ...preCalculation };
                    preCalculationArc9.calculationId = SYSTEM_ARCHS_PRE_CALCULATION_REACT_MS_MAPPING?.[SYSTEM_ARCHS_KEYS.ARC_9];
                    if (preCalculationArc9?.data?.refrigerant?.toString()?.trim()?.length == 0) {
                        preCalculationArc9.data.refrigerant = chooseSystems?.system?.[SYSTEM_ARCHS_KEYS.ARC_9]?.mtSide;
                    }

                    const requestBodyArc9 = { pageData: preCalculationArc9, refreshKey: tmpRefershKey, transactionId: transactionId, pageName: preCalculationArc9.calculationId };
                    calculationData = await store.dispatch(preCalculateApi.endpoints.getPreCalculate.initiate(requestBodyArc9));
                }
            }
            await setIsSaving(false);
            if (calculationData?.data?.isSuccess) {
                if (calculationData?.data?.result?.transactionId && !transactionId) {
                    await setCalculator({ transactionId: calculationData?.data?.result?.transactionId, preCalculationInputs: { ...preCalculationInputs, [calculationData?.data?.result?.transactionId]: { ...[calculationData?.data?.result?.transactionId], [props.calculationId]: preCalculation } } });
                    isValid = true;
                }
                else if (transactionId && transactionId?.length > 0) {

                    if (props.calculationId === SYSTEM_ARCHS_PRE_CALCULATION_REACT_MS_MAPPING.SIMULATION_SETUP) {
                        const baselineArcName: string = tables?.systemArchMaster?.filter(value => value?.architectureId == chooseSystems?.baseline)?.[0]?.architectureName || "";
                        const selectedSystemNames: any = tables?.systemArchMaster?.filter(value => chooseSystems?.selectedSystems?.includes(value?.architectureId))?.map(sys => sys?.architectureName) || [];
                        await Analytics.sendAnalyticsData(null, "systemDetails", { selectedSystems: chooseSystems?.selectedSystems?.length || 0, selectedSystemNames: selectedSystemNames?.join(","), baseline: baselineArcName });
                        await setCalculationInprogress(true);
                        const requestBodyForcalculation = { pageData: { architectures: chooseSystems?.selectedSystems, baseline: chooseSystems?.baseline, LocationAndSystemDetails: { locationAndSize: { ...locationAndSize }, chooseSystems: { ...chooseSystems } } }, refreshKey: tmpRefershKey, transactionId: transactionId };
                        const calculationResultData = await store.dispatch(calculateApi.endpoints.getCalculate.initiate(requestBodyForcalculation));
                        if (calculationResultData?.data?.isSuccess) {
                            if (calculationResultData?.data?.result) {
                                setTables({ resultsMaster: { ...tables?.resultsMaster, [transactionId]: calculationResultData?.data?.result }, sensityvityResultsMaster: { ...tables?.sensityvityResultsMaster, [transactionId]: calculationResultData?.data?.result } });
                            }
                            else {
                                setTables({ resultsMaster: { ...tables?.resultsMaster, [transactionId]: {} }, sensityvityResultsMaster: { ...tables?.sensityvityResultsMaster, [transactionId]: {} } });
                            }
                            isValid = true;
                        }
                        else {
                            isValid = false;
                        }
                        await setCalculationInprogress(false);

                    }
                    else {
                        isValid = true;
                    }
                    await setCalculator({ preCalculationInputs: { ...preCalculationInputs, [transactionId]: { ...[transactionId], [transactionId]: preCalculation } } });

                }
            }
            else {
                isValid = false;
            }
        }
        if (isValid) {
            await setIsSaving(true);
            await retrieveSessionData(preCalculationData);
            await Analytics.sendAnalyticsData(location.pathname);
            if (props.activeStage) {
                setCalculator({ prevPagePath: location.pathname });
                navigate(TABPROGRESSSTEPPER?.filter(value => value.fieldKey === chooseSystems?.selectedSystems?.[activeStage + 1])?.[0]?.fieldURL);
            }
            if (props.activeStageLocationSetup) {
                setCalculator({ prevPagePath: location.pathname });
                navigate(TABPROGRESSSTEPPER?.filter(value => value.fieldKey === activeStageLocationSetup)?.[0]?.fieldURL);
            }
            else {
                setCalculator({ prevPagePath: location.pathname });
                navigate(props.continueButton);
            }
            await setIsSaving(false);

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [navigate, props, activeStageLocationSetup, TABPROGRESSSTEPPER, activeStage, chooseSystems?.selectedSystems, userInputs, config, SYSTEM_ARCHS_KEYS, SYSTEM_ARCHS_PRE_CALCULATION_REACT_MS_MAPPING, transactionId]);
    //get pageid
    const getPageID = (path) => {
        let pageId = undefined;
        if (path && RESTORE_SESSION_PAGE_ID_MAPPING_OTHERS) {
            pageId = RESTORE_SESSION_PAGE_ID_MAPPING_OTHERS?.[path] || undefined;
        }
        if (!pageId) {
            const selectedArchs = storeSimulationSessionValue?.inputJson?.LocationAndSystemDetails?.chooseSystems?.selectedSystems || [];
            const selectedArchPaths = storeSimulationSessionValue?.inputJson?.LocationAndSystemDetails?.chooseSystems?.selectedSystems?.map(arch => PAGE_NAVIGATION_ORDER?.[arch]) || [];
            if (selectedArchPaths?.includes(path)) {
                if (path === PAGE_NAVIGATION_ORDER.ARC_8) {
                    if (selectedArchs?.includes(SYSTEM_ARCHS_KEYS.ARC_8)) {
                        pageId = RESTORE_SESSION_PAGE_ID_MAPPING_COMPRESSORS?.[SYSTEM_ARCHS_KEYS.ARC_8] || undefined;
                    }
                    else if (selectedArchs?.includes(SYSTEM_ARCHS_KEYS.ARC_9)) {
                        pageId = RESTORE_SESSION_PAGE_ID_MAPPING_COMPRESSORS?.[SYSTEM_ARCHS_KEYS.ARC_9] || undefined;
                    }
                }
                else {
                    pageId = RESTORE_SESSION_PAGE_ID_MAPPING_COMPRESSORS?.[path] || undefined;

                }
            }

        }
        return pageId;
    };
    const isRetrievedSessionDataUpdated = async (preCalculation) => {
        let isSessionDataUpdated = false;
        const pageId = await getPageID(location.pathname);
        if (restoreTransactionId) {
            if (RESTORE_SESSION_PAGE_IDS?.LOAD_SETUP === pageId && !_.isEqual(locationAndSize, storeSimulationSessionValue?.inputJson?.LocationAndSystemDetails?.locationAndSize)) {
                isSessionDataUpdated = true;
            }
            else if (RESTORE_SESSION_PAGE_IDS?.LOAD_SETUP === pageId && !_.isEqual(chooseSystems, storeSimulationSessionValue?.inputJson?.LocationAndSystemDetails?.chooseSystems)) {
                isSessionDataUpdated = true;
            }
            if (!isSessionDataUpdated && pageId && pageId === preCalculation.calculationId && preCalculation.data && restoreSessionData?.[restoreTransactionId]?.[pageId]) {
                const sessionData = restoreSessionData?.[restoreTransactionId]?.[pageId] || {};
                if (!(_.isEqual(preCalculation?.data, sessionData))) {
                    isSessionDataUpdated = true;
                }
            }
            if (isSessionDataUpdated) {
                await setCalculator({ isRestoredSessionUpdated: true });
            }
        }
    };
    const retrieveSessionData = async (preCalculationData) => {
        await setIsSaving(true);
        await isRetrievedSessionDataUpdated(preCalculationData);
        const currPathIndex = activeNavigationPaths.indexOf(location.pathname);
        const newPath = activeNavigationPaths?.[currPathIndex + 1] || undefined;
        if (restoreTransactionId && newPath) {
            const pageId = getPageID(newPath);

            if (pageId && !restoredSessions?.includes(pageId) && !restoreSessionData?.[restoreTransactionId]?.[pageId]) {
                const requestBody = { pageName: pageId, sessionId: restoreTransactionId, dataType: SERVICE_REQUEST_FETCH_DATA_TYPES.SAVED_SESSION_DATA };
                const sessionData = await store.dispatch(dataApi.endpoints.getData.initiate(requestBody));
                if (sessionData?.data?.isSuccess) {
                    if (sessionData?.data?.result?.data?.data) {
                        const newRestoreSessionData = { ...restoreSessionData, [restoreTransactionId]: { ...restoreSessionData[restoreTransactionId], [pageId]: sessionData?.data?.result?.data?.data } };

                        const newUserInputs = { ...userInputs };
                        if (RESTORE_SESSION_ARC_PAGE_IDS?.ALL?.includes(pageId)) {
                            try {
                                const compressorSessionData = sessionData?.data?.result?.data?.data;
                                let arcId = RESTORE_SESSION_ARC_PAGE_IDS?.[pageId];
                                if (compressorSessionData?.loadShift && newUserInputs?.compressorSetup?.[arcId]?.["subCooler"]) {

                                    newUserInputs.compressorSetup[arcId]["subCooler"] = parseFloat(compressorSessionData?.loadShift) * 100;
                                }
                                if (compressorSessionData?.configuration && newUserInputs?.compressorSetup?.[arcId]?.["configuration"]) {

                                    newUserInputs.compressorSetup[arcId]["configuration"] = compressorSessionData?.configuration || "Rack";
                                }
                                if (!compressorSetup?.[arcId]?.MT?.company) {

                                    arcId = arcId === SYSTEM_ARCHS_KEYS.ARC_8 || arcId === SYSTEM_ARCHS_KEYS.ARC_9 ? SYSTEM_ARCHS_KEYS.ARC_8 : arcId;
                                    if (arcId === SYSTEM_ARCHS_KEYS.ARC_2) {
                                        newUserInputs.compressorSetup[arcId]["MT"]["company"] = compressorSessionData?.MT?.Rack_1?.company || "";
                                        newUserInputs.compressorSetup[arcId]["LT"]["company"] = compressorSessionData?.LT?.Rack_1?.company || "";
                                        newUserInputs.compressorSetup[arcId]["MT"]["type"] = compressorSessionData?.MT?.Rack_1?.type || "";
                                        newUserInputs.compressorSetup[arcId]["LT"]["type"] = compressorSessionData?.LT?.Rack_1?.type || "";
                                    }
                                    else {
                                        newUserInputs.compressorSetup[arcId]["MT"]["company"] = compressorSessionData?.MT.company || "";
                                        newUserInputs.compressorSetup[arcId]["LT"]["company"] = compressorSessionData?.LT.company || "";
                                        newUserInputs.compressorSetup[arcId]["MT"]["type"] = compressorSessionData?.MT.type || "";
                                        newUserInputs.compressorSetup[arcId]["LT"]["type"] = compressorSessionData?.LT.type || "";
                                    }

                                }
                            }
                            catch
                            { }

                        }
                        await setCalculator({ restoreSessionData: newRestoreSessionData, userInputs: newUserInputs });
                    }
                }
            }
            await setIsSaving(false);
        }

    };
    const onStartButtonClick = useCallback(async (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        Analytics.sendAnalyticsData(location.pathname);
        setIsLoading(true);
        setShowWarning(true);
        window.scrollTo(0, 0);
        e.preventDefault();
        const tmpRefershKey = Date.now();
        const requestBody = { refreshKey: tmpRefershKey };
        let calculationData: any = {};
        calculationData = await store.dispatch(sessionsApi.endpoints.getSessions.initiate(requestBody));
        setSessionAvailable((calculationData?.data?.result?.savedList || [])?.length);
        const sortedList = [...(calculationData?.data?.result?.savedList || [])].sort((a: any, b: any) => new Date(a.updatedDate).getTime() - new Date(b.updatedDate).getTime());
        setSessionList(sortedList);
        resetSessionList(sortedList);
        setNumberofSession(sortedList);
        setCurrentUserId(calculationData.data.result.userId);
        setIsLoading(false);

    }, [location.pathname]);
    const activeNavigationPaths = useMemo(() => {
        const activePaths: any[] = [];
        Object.keys(PAGE_NAVIGATION_ORDER).forEach((key) => {
            const pathUrl = PAGE_NAVIGATION_ORDER?.[key];
            if (pathUrl && !(activePaths?.includes(pathUrl))) {
                if (!(Object.keys(SYSTEM_ARCHS_REACT_MS_MAPPING)?.includes(key))) {
                    activePaths.push(pathUrl);
                }
                else if (chooseSystems?.selectedSystems?.indexOf(key) > -1) {
                    activePaths.push(pathUrl);
                }
            }
        });
        return activePaths;

    }, [PAGE_NAVIGATION_ORDER, SYSTEM_ARCHS_REACT_MS_MAPPING, chooseSystems?.selectedSystems]);

    //On back button Click - will move to prev stage if region and respirator filled  
    const onBackButtonClick = useCallback((e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        window.scrollTo(0, 0);
        e.preventDefault();
        if (props?.backtoTerms === true) {
            navigate(props?.backButton);
        }
        else {
            const currPathIndex = activeNavigationPaths.indexOf(location.pathname);
            if (currPathIndex > 0) {
                const prevPath = activeNavigationPaths?.[currPathIndex - 1] || PAGE_NAVIGATION.TERMS_CONDITION;
                navigate(prevPath);
            }
            else {
                navigate(-1);
            }
        }
    }, [navigate, location, props?.backButton, props?.backtoTerms, activeNavigationPaths, PAGE_NAVIGATION]);

    const onModalNewButtonClick = useCallback((e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        window.scrollTo(0, 0);
        setCalculator({
            numberOfSession: numberofSession,
            currentUserID: currentUser
        });
        setResetCalculator({
            ...initialState.calculator,
            updatedOn: Date.now(),
            prevPagePath: "/",
            preCalculationInputs: {},
            transactionId: undefined,
            transactionName: "",
            userInputs: { locationAndSize: LOCATION_AND_SIZE, chooseSystems: CHOOSE_SYSTEMS, loadSetup: LOAD_SETUP, compressorSetup: { ...COMPRESSOR_SETUP }, parameterSetup: PARAMETER_SETUP, simulationSetup: SIMULATION_SETUP, sensitivityAnalysis: SENSITIVITY_ANALYSIS },
            tables: { countryMaster: undefined, systemArchMaster: undefined, cabinetBySizeMaster: { Small: null, Medium: null, Large: null, Hyper: null }, binTemperatureGraphMaster: {}, compressorMaster: {}, simulationSetupMaster: undefined, resultsMaster: {}, sensityvityResultsMaster: {} },
            tabContainerNavigation: [],
            storeSimulationSessionValue: undefined,
            restoreTransactionId: undefined,
            numberOfSession: numberofSession,
            restoredSessions: [],
            restoreSessionData: {},
            isRestoredSessionUpdated: false,
            deleteTransactionId: undefined,
            currentUserID: currentUser
        });
        e.preventDefault();
        setShowWarning(false);
        navigate(props.continueButton);
    }, [currentUser, navigate, numberofSession, props.continueButton]);

    const onModalContinueButtonClick = useCallback(async (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        window.scrollTo(0, 0);
        setShowWarning(false);
        setShowChooseSimulation(true);
        e.preventDefault();
    }, []);

    const onCloseButtonClick = useCallback((e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        window.scrollTo(0, 0);
        e.preventDefault();
        setShowWarning(false);
    }, []);

    const onSimulationBackButtonClick = useCallback((e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        window.scrollTo(0, 0);
        e.preventDefault();
        setShowWarning(false);
        setShowChooseSimulation(false);
    }, []);

    const onSimulationChooseButtonClick = useCallback((e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        window.scrollTo(0, 0);
        e.preventDefault();
        setShowWarning(false);
        setShowChooseSimulation(false);
        if (setSession?.sessionId) {
            setCalculator({
                ...initialState.calculator,
                updatedOn: Date.now(),
                prevPagePath: "/",
                preCalculationInputs: {},
                transactionId: undefined,
                transactionName: "",
                restoreTransactionId: setSession?.sessionId,
                userInputs: {
                    locationAndSize: setSession?.inputJson?.LocationAndSystemDetails?.locationAndSize || LOCATION_AND_SIZE,
                    chooseSystems: CHOOSE_SYSTEMS, loadSetup: LOAD_SETUP, compressorSetup: { ...COMPRESSOR_SETUP }, parameterSetup: PARAMETER_SETUP, simulationSetup: SIMULATION_SETUP, sensitivityAnalysis: SENSITIVITY_ANALYSIS
                },
                tables: { countryMaster: undefined, systemArchMaster: undefined, cabinetBySizeMaster: { Small: null, Medium: null, Large: null, Hyper: null }, binTemperatureGraphMaster: {}, compressorMaster: {}, simulationSetupMaster: undefined, resultsMaster: {}, sensityvityResultsMaster: {} },
                tabContainerNavigation: [],
                storeSimulationSessionValue: setSession,
                numberOfSession: numberofSession,
                restoredSessions: [],
                restoreSessionData: {},
                isRestoredSessionUpdated: false,
                deleteTransactionId: undefined,
                currentUserID: currentUser
            });
        }
        setChooseSystem((setSession?.inputJson?.LocationAndSystemDetails?.chooseSystems || CHOOSE_SYSTEMS));
        navigate(props.continueButton);
    }, [setSession, navigate, props.continueButton, numberofSession, currentUser]);

    const onSimualtionCloseButton = useCallback((e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        window.scrollTo(0, 0);
        e.preventDefault();
        setShowWarning(false);
        setShowChooseSimulation(false);
    }, []);

    const GenerateDate = useCallback((updatedDate: any) => {
        //const dateFormatter = Intl.DateTimeFormat('sv-SE');
        const formattedRandomDate = moment(new Date(updatedDate)).format('DD MMM YYYY');
        //dateFormatter.format(updatedDate);
        return (formattedRandomDate);
    }, []);

    const GenerateTime = useCallback((updatedDate: any) => {
        const formattedTime = moment(new Date(updatedDate)).format("hh:mm A");
        const dateObject = updatedDate ? new Date(updatedDate) : new Date();
        const dateString = dateObject + "";
        let tzAbbrValue: any = (
            // Works for the majority of modern browsers
            dateString.match(/\(([^\)]+)\)$/) ||
            // IE outputs date strings in a different format:
            dateString.match(/([A-Z]+) [\d]{4}$/)
        );
        if (tzAbbrValue) {
            tzAbbrValue = tzAbbrValue[1].match(/[A-Z]/g).join("");
        }
        return (formattedTime + " " + tzAbbrValue);
    }, []);


    //Search Functionality Click : Triggering the Search Button will display product based on search terms 
    const [searchTermValue, setSearchValue] = useState("");

    useEffect(() => {
        if (searchTermValue?.length === 0) {
            if(isCheck30 === true || isCheck90 === true)
            {
                const productlistfilter = [...filteredSearchSessionList]; 
                setSessionList(productlistfilter);
            }
            else
            {
                const productlistfilter = [...resetSearchSessionList];
                setSessionList(productlistfilter);
            }
        }
        else if (searchTermValue?.length > 0) {          
            if(isCheck30 === true || isCheck90 === true)
            {
                const productlistfilter = [...filteredSearchSessionList]?.filter(item => (item?.sessionName?.toLowerCase()?.includes(searchTermValue.toLowerCase()) || item?.userId?.toLowerCase()?.includes(searchTermValue.toLowerCase()))) || [];
                setSessionList(productlistfilter);
            }
            else
            {
                const productlistfilter = [...resetSearchSessionList]?.filter(item => (item?.sessionName?.toLowerCase()?.includes(searchTermValue.toLowerCase()) || item?.userId?.toLowerCase()?.includes(searchTermValue.toLowerCase()))) || [];
                setSessionList(productlistfilter);    
            }
            }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchTermValue, isCheck90, isCheck30]);

    const findSearchTerm = () => {       
        if (searchTermValue?.length > 0) {
            if(isCheck30 === true || isCheck90 === true)
            {
                const productlistfilter = [...filteredSearchSessionList]?.filter(item => (item?.sessionName?.toLowerCase()?.includes(searchTermValue.toLowerCase()) || item?.userId?.toLowerCase()?.includes(searchTermValue.toLowerCase()))) || [];
                setSessionList(productlistfilter);
            }
            else
            {
            const productlistfilter = [...resetSearchSessionList]?.filter(item => (item?.sessionName?.toLowerCase()?.includes(searchTermValue.toLowerCase()) || item?.userId?.toLowerCase()?.includes(searchTermValue.toLowerCase()))) || [];
            setSessionList(productlistfilter);
            }
        }
        else {
            if(isCheck30 === true || isCheck90 === true)
            {
                const productlistfilter = [...filteredSearchSessionList]; 
                setSessionList(productlistfilter);
            }
            else
            {
            const productlistfilter = [...resetSearchSessionList];
            setSessionList(productlistfilter);
            }
        }
    };

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    const storeSessionId = useCallback((storeSession: string) => (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.preventDefault();
        setChooseSystemEnable(true);
        onSetSession(storeSession);
    }, []);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const FilterDate = useCallback((numberOfdays: number) => (e: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
        if(numberOfdays === 30)
        {
            setIsCheck30(!isCheck30);
            setIsCheck90(false);
            if (!isCheck30) {
                const datelistfilter =
                resetSearchSessionList?.filter((val) => {
                        const then = new Date(GenerateDate(val.updatedDate));
                        const now = new Date();
                        const msBetweenDates = Math.abs(then.getTime() - now.getTime());
                        const daysBetweenDates = msBetweenDates / (24 * 60 * 60 * 1000);
                        if (daysBetweenDates <= numberOfdays) {
                            return true;
                        }
                    });
                    setSessionList(datelistfilter);
                    filteredSessionList(datelistfilter);
                }
                else
                {
                    setSessionList(resetSearchSessionList);  
                    filteredSessionList(resetSearchSessionList);
                }
        }
        else if(numberOfdays === 90){
            setIsCheck90(!isCheck90);  
            setIsCheck30(false);
            if (!isCheck90) { 
                const datelistfilter =
                resetSearchSessionList?.filter((val) => {
                        const then = new Date(GenerateDate(val.updatedDate));
                        const now = new Date();
                        const msBetweenDates = Math.abs(then.getTime() - now.getTime());
                        const daysBetweenDates = msBetweenDates / (24 * 60 * 60 * 1000);
                        if (daysBetweenDates <= numberOfdays) {
                            return true;
                        }
                    });
                    setSessionList(datelistfilter);
                    filteredSessionList(datelistfilter);
                }
                else
                {
                    setSessionList(resetSearchSessionList); 
                    filteredSessionList(resetSearchSessionList);
                }
        }

    }, [GenerateDate, isCheck30, isCheck90, resetSearchSessionList]);

    //Modal Functionality Ends
    return (
        <>
            <div className={props?.runCalButton ? "simulation-header" : "eco-header"}>
                <div className="overview">
                    <div className="eco-title">{props.headline}</div>
                    {props?.sizelocation !== false &&
                        <div className="store-size">
                            <span>{locationAndSize?.country + " (" + locationAndSize?.city + "), "}
                                <span className="f-bold">{locationAndSize?.storeSize.charAt(0).toUpperCase() + locationAndSize?.storeSize.slice(1).toLowerCase()} ({locationAndSize?.storeSizeValue} m<sup>2</sup>)</span>
                            </span>
                        </div>}
                </div>
                <div className="buttons">
                    <div className="btn btn-primary" onClick={onBackButtonClick}>{t(CALL_TO_ACTION.BACK)}</div>
                    {props?.startButton &&
                        <div id={'btn-start'} className={props?.isDisable ? "btn btn-secondary disabled" : "btn btn-secondary"} onClick={onStartButtonClick}>{t(CALL_TO_ACTION.START)}</div>}

                    {!props?.startButton && !props?.runCalButton && <div className={props?.isDisable ? "btn btn-secondary disabled" : "btn btn-secondary"} onClick={onContinueButtonClick}>{t(CALL_TO_ACTION.CONTINUE)}</div>}
                    {props?.runCalButton &&
                        <div className={props?.isDisable ? "btn btn-secondary disabled" : "btn btn-secondary"} onClick={onContinueButtonClick}>{t(CALL_TO_ACTION.RUN_CALC)}</div>}
                </div>
                <Modal show={showWarning}>
                    <WarningModal
                        isWarning={false}
                        isCloseVisible={true}
                        title={t(LABELKEYS_WARNING.START)}
                        messgage={t(LABELKEYS_WARNING.TEXT)}
                        button1Text={t(LABELKEYS_WARNING.NEW)}
                        button2Text={t(LABELKEYS_WARNING.CONTINUE)}
                        onClickButton1={onModalNewButtonClick}
                        onClickButton2={onModalContinueButtonClick}
                        continueButtonDisable={isSessionAvailable}
                        onClickClose={onCloseButtonClick}
                        navigateLink={props.continueButton}
                        closeLink={props.backButton}
                        isLoading={isLoading}
                        loaderMessage={t(LABEL_KEYS_LOADER.LOADING)}
                    />
                </Modal>
                <div className="loader-save-wrapper">
                    <Modal className="modal-loader-save" show={isSaving}>
                        <div className="loader-save-content">
                            <Loader message={t((isCalculationInprogress || props.calculationId === SYSTEM_ARCHS_PRE_CALCULATION_REACT_MS_MAPPING.SIMULATION_SETUP) ? LABEL_KEYS_LOADER.CALCULATING : LABEL_KEYS_LOADER.SAVING)} />
                        </div>
                    </Modal>
                </div>
                <ErrorHandling key={props.key || "errorMsg"} />
                {showError && <ErrorHandling key={props.key || "errorMsg"} />}
            </div>

            {showChooseSimulation === true &&
                <div className="warning-overlay choose-simulation">
                    <div className="warning-container">
                        <div className="modal-heading pb-2">
                            <div className="warning-title">{t(LABEL_SESSION_TABLE.HEADING)}</div>
                            <div className="close-icon" onClick={onSimualtionCloseButton}><MdClose /></div>
                        </div>
                        <div className="choose-simulation">
                            <div className="filter-block">
                                <div className="list-items">
                                    <div className="form-check-inline"><input name="group1" aria-label="Past 30 Days" type="checkbox" className="form-check-input" onClick={FilterDate(30)} checked={isCheck30}/> Past 30 Days</div>
                                    <div className="form-check-inline"><input name="group1" aria-label="Past 90 Days" type="checkbox" className="form-check-input" onClick={FilterDate(90)} checked={isCheck90}/> Past 90 Days</div>
                                </div>
                                <div className="search-bar">
                                    <div className="form-group has-search">
                                        <input type="text" className="form-control" placeholder="" aria-label="Search" onChange={event => { setSearchValue(event.target.value.replace(
            REGEX_VALIDATION.API_INPUT_FIELD_VALIDATION,
            ""
          )); }} />
                                    </div>
                                    <span className="form-control-feedback"><VscSearch onClick={findSearchTerm} /></span>
                                </div>
                            </div>

                            <div className="simulation-table">
                                <div className="panel-body">
                                    <div className="table-head">
                                        {SIMULATION_TABLE_LISTS?.map((e: any) => {
                                            return (
                                                <div key={`div${e?.fieldKey}`} className="table-items col-md-3">
                                                    {t(e?.fieldKey)}{" "}
                                                </div>
                                            );
                                        })}
                                    </div>
                                    <div className="filter-items">
                                        {sessionList?.length > 0 ? sessionList?.map((val, key) => {
                                            return (
                                                <div key={key} className={setSession.sessionId === val.sessionId && setSession.sessionName === val.sessionName ? "d-flex table-row selected-row" : "d-flex table-row"} onClick={storeSessionId(val)}>
                                                    <div className="table-data col-md-3">{val.sessionName}</div>
                                                    <div className="table-data col-md-3">{val.userId}</div>
                                                    <div className="table-data col-md-3">{GenerateDate(val.updatedDate)}</div>
                                                    <div className="table-data col-md-3 px-0">{GenerateTime(val.updatedDate)}</div>
                                                </div>
                                            );
                                        }) :
                                            <div className="d-flex table-row">
                                                <div className="table-data col-md-12 text-center no-results">{t(LABEL_SESSION_TABLE.NO_RESULT)}</div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>

                            <div className="btn-container">
                                <div className="btn btn-primary" onClick={onSimulationBackButtonClick}>BACK</div>
                                <div className={chooseSimulationEnable === true ? "btn btn-secondary" : "btn btn btn-secondary disabled"} onClick={onSimulationChooseButtonClick}>Choose Simulation</div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>

    );
};

export default Headline;
