//graph api calls 
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
export const graphApi = createApi({
    reducerPath: 'graphApi',
    baseQuery: fetchBaseQuery({
        baseUrl: '/api/graph',
    }),
    endpoints: (builder) => ({
        getGraph: builder.query<{ response?: any, isSuccess: any, result: any }, any>({
            query: params => ({
                url: '',
                method: 'POST',
                body: params
            }),
            transformResponse: (response: { message: any }) => {
                return response.message;
            }
            ,
        }),
    }),
});
export const { useGetGraphQuery } = graphApi;