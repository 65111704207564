import React, { useState, useCallback, useMemo,useEffect } from "react";
import { useSelector } from "react-redux";
import "./LoadSetup.scss";
import { LABELKEYS_SYSTEM_LOAD } from "../../../../../helper/constants";
import { useTranslation } from "react-i18next";
import ProgressStepper from "../../../../common/ProgressBar/ProgressStepper";
import Headline from "../../../../common/Headline/Headline";
import TabStepper from "../../../../common/TabContainer/TabStepper";
import SystemAnalysisPanel from "./systemAnalysisPanel/SystemAnalysisPanel";
import TemperatureSide from "./TemperatureSide";
import { reSetLoadSetupValues,restoreLoadSetupSessionValues } from "../../../../../store/actions";
import { LoadSetUpCalculations } from "../../../../../services/loadSetUpCalculations";
const LoadSetup = () => {
  const { t } = useTranslation();
  const { userInputs, tables, updatedOn,restoreTransactionId,restoredSessions } = useSelector((state: any) => state?.ecoEfficiency?.calculator || {});
  const { loadSetup, locationAndSize, chooseSystems } = userInputs;

  const { LOAD_SETUP_CONFIG, PROGRESSBAR_STEPS_VALUE_COLL, PAGE_NAVIGATION, TYPE_OF_RACK, SYSTEM_ARCHS_PRE_CALCULATION_REACT_MS_MAPPING,RESTORE_SESSION_PAGE_IDS } = useSelector((state: any) => state?.ecoEfficiency?.config || {});
  const [typeOfRack, setTypeOfRack] = useState(TYPE_OF_RACK.MT);
  //restore session
  useEffect(() => {
   if(restoreTransactionId && !restoredSessions?.includes(RESTORE_SESSION_PAGE_IDS?.LOAD_SETUP))
   {
    restoreLoadSetupSessionValues();
   }
   // eslint-disable-next-line
  }, []);
  //On rack type size
  const setRackType = useCallback((rackType) => {
    setTypeOfRack(rackType);
  }, [setTypeOfRack]);
  //reset  load setup values
  const setDefault = useCallback(() => {
    reSetLoadSetupValues();
  }, []);
  //mtLoadRanges 
  const mtLoadRanges = useMemo(() => {
    return ({ from: LoadSetUpCalculations?.getTotal(tables?.cabinetBySizeMaster?.[`${locationAndSize?.storeSize}`]?.filter(value => value.cabinetType === TYPE_OF_RACK.MT)?.map(cabinet => cabinet?.capacityFrom && cabinet?.capacityFrom) || []) || 0, to: LoadSetUpCalculations?.getTotal(tables?.cabinetBySizeMaster?.[`${locationAndSize?.storeSize}`]?.filter(value => value.cabinetType === TYPE_OF_RACK.MT)?.map(cabinet => cabinet?.capacityTo && cabinet?.capacityTo) || []) || 0 });
  }, [locationAndSize?.storeSize, tables?.cabinetBySizeMaster, TYPE_OF_RACK]);
  //ltLoadRanges
  const ltLoadRanges = useMemo(() => {
    return ({ from: LoadSetUpCalculations?.getTotal(tables?.cabinetBySizeMaster?.[`${locationAndSize?.storeSize}`]?.filter(value => value.cabinetType === TYPE_OF_RACK.LT)?.map(cabinet => cabinet?.capacityFrom && cabinet?.capacityFrom) || []) || 0, to: LoadSetUpCalculations?.getTotal(tables?.cabinetBySizeMaster?.[`${locationAndSize?.storeSize}`]?.filter(value => value.cabinetType === TYPE_OF_RACK.LT)?.map(cabinet => cabinet?.capacityTo && cabinet?.capacityTo) || []) || 0 });
  }, [locationAndSize?.storeSize, tables?.cabinetBySizeMaster, TYPE_OF_RACK]);

  //is continue button disabled
  const isContinueButtonDisabled = useMemo(() => {
    const isLTLoadRangeWarning = ((parseFloat(loadSetup?.selectedLTLoad) < (ltLoadRanges?.from - (ltLoadRanges?.from * (LOAD_SETUP_CONFIG.DEVIATION / 100)))) || (parseFloat(loadSetup?.selectedLTLoad) > (ltLoadRanges.to + (ltLoadRanges?.to * (LOAD_SETUP_CONFIG.DEVIATION / 100)))));
    const isMTLoadRangeWarning = ((parseFloat(loadSetup?.selectedMTLoad) < (mtLoadRanges.from - (mtLoadRanges?.from * (LOAD_SETUP_CONFIG.DEVIATION / 100)))) || (parseFloat(loadSetup?.selectedMTLoad) > (mtLoadRanges.to + (mtLoadRanges?.to * (LOAD_SETUP_CONFIG.DEVIATION / 100)))));
    return isMTLoadRangeWarning || isLTLoadRangeWarning;
  }, [loadSetup, ltLoadRanges, mtLoadRanges, LOAD_SETUP_CONFIG]);

  return (
    <div>
      <ProgressStepper step={''} completed={PROGRESSBAR_STEPS_VALUE_COLL.EcoEfficiency[2]} />
      <Headline key={`load_${updatedOn}`} isCalculationStart calculationId={SYSTEM_ARCHS_PRE_CALCULATION_REACT_MS_MAPPING?.LOAD_SETUP} isDisable={isContinueButtonDisabled}
        activeStageLocationSetup={tables?.systemArchMaster?.filter(value => chooseSystems?.selectedSystems?.indexOf(value?.architectureId) > -1)[0]?.architectureId}
        headline={t(LABELKEYS_SYSTEM_LOAD.HEADING)} backButton={PAGE_NAVIGATION.SYSTEM_DETAILS.CHOOSE_SYSTEM}
      />
      <TabStepper step={''} labelArray={PROGRESSBAR_STEPS_VALUE_COLL.EcoEfficiencyTabContainer} completed={PROGRESSBAR_STEPS_VALUE_COLL.EcoEfficiencyTabContainer[1]} calltoAction={t(LABELKEYS_SYSTEM_LOAD.RESET)} onResetClick={setDefault} />
      <div className="row load-setup mt-3">
        <div className="col-md-3 col-sm-12">
          <SystemAnalysisPanel key={`ls-${updatedOn}`} typeOfRack={typeOfRack} setRackType={setRackType} />
        </div>
        <TemperatureSide typeOfRack={typeOfRack} />
      </div>
    </div>
  );
};

export default LoadSetup;