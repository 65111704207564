import React from 'react';
const Loader = (props) => {
    return (
        <>            
            <div className="spinner-border text-secondary" role="status"><span className="sr-only"></span></div>
            <div className="loading-text">{props?.message || ""}</div>
        </>
    );
};
export default Loader;
