//Sensitivity Analysis 
import React from 'react';
export class SensitivityAnalysisInputs extends React.Component {
    // Get Input JSON for Sensitivity Analysis
    public static getInputJSON = async (parameter, userInputs, config) => {
        const { CONFIG_SIMULATION_SETUP_SELECT_FIELD, SYSTEM_ARCHS_KEYS } = config;
        const { chooseSystems, sensitivityAnalysis } = userInputs;
        let JSONOutput = {};   
        try {
            switch (parameter) {
                case "0":
                    const refrigentsSelected: any = [];
                    const refrigerantPrices = {};                    
                    await chooseSystems?.selectedSystems?.map((val) => {
                        if(val != "ARC_5")
                        {
                            if (chooseSystems?.system?.[val]) {
                            ["ltSide", "mtSide", "highStage"].map((key) => {
                                const refrigenValue: any = chooseSystems?.system?.[val]?.[key] || undefined;
                                if (refrigenValue && refrigenValue?.length > 0 && !refrigentsSelected.includes(refrigenValue)) {
                                refrigentsSelected.push(refrigenValue);
                                }
                            });
                            }
                        }
                        else
                        {
                            if (chooseSystems?.system?.[val]) {
                            ["ltSide", "highStage"].map((key) => {
                                const refrigenValue: any = chooseSystems?.system?.[val]?.[key] || undefined;
                                if (refrigenValue && refrigenValue?.length > 0 && !refrigentsSelected.includes(refrigenValue)) {
                                refrigentsSelected.push(refrigenValue);
                                }
                            });
                            }
                        }
                    });
                    await refrigentsSelected?.map((val) => {
                        const checkEligibility = typeof sensitivityAnalysis?.refrigerantPrice?.[val] == 'string' ? sensitivityAnalysis?.refrigerantPrice?.[val]?.length > 0: sensitivityAnalysis?.refrigerantPrice?.[val];
                        if (checkEligibility) {
                            refrigerantPrices[val] = sensitivityAnalysis?.refrigerantPrice?.[val];
                        }
                    });
                    const refrigerantPriceJSON= { "calculationId" : "sensitivity-refrigerantPrice", "refrigerantPrice": refrigerantPrices };
                    JSONOutput = refrigerantPriceJSON;
                    break;
                case "1":
                    const fieldConfig = await CONFIG_SIMULATION_SETUP_SELECT_FIELD?.filter(config => config?.fieldKey == "electricityIntencity")[0];
                    const electricityIntencityLabel = await fieldConfig?.fieldvalueoptions?.filter(f => f.value == sensitivityAnalysis?.electricityIntencity?.value)[0]?.label || "";
                    const electricityIntensityJSON= { "calculationId" : "sensitivity-electricityIntencity", "electricityIntencity": { "category": electricityIntencityLabel, "value": sensitivityAnalysis?.electricityIntencity?.value } };
                    JSONOutput = electricityIntensityJSON;
                    break;
                case "2":
                    const electricityPriceJSON= { "calculationId" : "sensitivity-electricityPrice", "electricityPrice": sensitivityAnalysis?.electricityPrice?.value };
                    JSONOutput = electricityPriceJSON;
                    break;
                case "3":
                    const lifeSpanJSON= { "calculationId" : "sensitivity-lifeSpan", "lifeSpan": sensitivityAnalysis?.lifeSpanOfSystem?.value };
                    JSONOutput = lifeSpanJSON;
                    break;
                case "4":
                    const leakageRates = {};
                    await chooseSystems?.selectedSystems?.map((key) => {
                       const val = (key == SYSTEM_ARCHS_KEYS.ARC_9) ? SYSTEM_ARCHS_KEYS.ARC_8 : key;
                        leakageRates[key] = sensitivityAnalysis?.leakageRate[val] || '0';
                    });
                    const leakageRateJSON= { "calculationId" : "sensitivity-leakageRate", "leakageRate": leakageRates };
                    JSONOutput = leakageRateJSON;
                    break;
                default:
                    return undefined;
                    break;
            }

        } catch (err) {
            return undefined;
        }
        return await JSONOutput;
    };



}