
import { configureStore } from '@reduxjs/toolkit';
import { reducer as formReducer } from "redux-form";
import { setupListeners } from '@reduxjs/toolkit/query';
import { configApi } from '../services/configurations';
import { dataApi} from '../services/data';
import { graphApi} from '../services/graph';
import { calculateApi } from '../services/calculate';
import { preCalculateApi } from '../services/preCalculate';
import { ecoEfficiencyReducer } from "./reducers";
import { sensitivityAnalysisApi } from '../services/sensitivityAnalysis';
import { sessionsApi } from '../services/sessions';
export const store = configureStore({
    reducer: {
        form: formReducer,        
        ecoEfficiency: ecoEfficiencyReducer,
        [configApi.reducerPath]: configApi.reducer,
        [dataApi.reducerPath]: dataApi.reducer,
        [graphApi.reducerPath]: graphApi.reducer,
        [calculateApi.reducerPath]: calculateApi.reducer,
        [preCalculateApi.reducerPath]: preCalculateApi.reducer,
        [sensitivityAnalysisApi.reducerPath]: sensitivityAnalysisApi.reducer,
        [sessionsApi.reducerPath]: sessionsApi.reducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({ immutableCheck: false}).concat(configApi.middleware).concat(dataApi.middleware).concat(graphApi.middleware).concat(calculateApi.middleware).concat(preCalculateApi.middleware).concat(sensitivityAnalysisApi.middleware).concat(sessionsApi.middleware),
});
setupListeners(store.dispatch);