import React, { useCallback, useMemo } from "react";
import "./SystemAnalysisPanel.scss";
import { BiChevronDown } from "react-icons/bi";
import { ImgWarning } from "../../../../../../assets/images/archSelection";
import { useTranslation } from "react-i18next";
import { LABELKEYS_SYSTEM_ANALYSIS_PANEL } from "../../../../../../helper/constants";
import { useSelector } from "react-redux";
import Loader from "../../../../../common/Loader/Loader";
const SystemAnalysisPanel = (props) => {
  const { t } = useTranslation();
  const { userInputs } = useSelector((state: any) => state?.ecoEfficiency?.calculator || {});
  const { chooseSystems, compressorSetup, loadSetup, locationAndSize } = userInputs;
  // eslint-disable-next-line   
  const { TYPE_OF_RACK, COMPRESSOR_ARCHS_CONFIG,SYSTEM_ARCHS_KEYS } = useSelector((state: any) => state?.ecoEfficiency?.config || {});
  const onRackTypeClick = useCallback((name) => () => {
    props?.setRackType(name);
  }, [props]);
  const onRackClick = useCallback((reackType, name) => () => {
    props?.setRack(reackType, name);
  }, [props]);
  //sub cooler options
  const optionValuesSubCooler = useMemo(() => {
    const valueColl: string[] = [];
    const configItem = COMPRESSOR_ARCHS_CONFIG?.SUB_COOLER_OPTIONS || {};
    for (let i = parseInt(configItem?.START || "0"); i <= parseInt(configItem?.END || "25"); i = i + parseInt(configItem?.DIFF || "1")) {
      valueColl.push(`${i}`);
    }
    return valueColl;
  }, [COMPRESSOR_ARCHS_CONFIG]);

  return (
    <div className="system-analysis-panel">
      <div className="content">
        {props?.showSubCooler !== false &&
          <>
            <div className="form-group">
              <label htmlFor="Sub Cooler">{t(LABELKEYS_SYSTEM_ANALYSIS_PANEL.SUB_COOLER)} <span className="mandatory"></span></label>
              <select value={compressorSetup?.[props?.architecture]?.subCooler} onChange={props?.onSubCoolerChange} className="form-control" placeholder="" aria-label="Sub Cooler">
                {optionValuesSubCooler.map((key, index) => {
                  return (
                    <option key={index} value={key}>{key}%</option>
                  );
                })}
              </select>
              <i className="angle-down-arrow"><BiChevronDown className="angle-down-icon" /></i>
            </div>
            <div className="small">{t(LABELKEYS_SYSTEM_ANALYSIS_PANEL.PERCENTAGE_SHIFT)}</div>
            {props?.showconfiguraton === true && <div className="form-group">
              <label htmlFor="Sub Cooler">{t(LABELKEYS_SYSTEM_ANALYSIS_PANEL.CONFIGURATION)}</label>
              <div className="radio-system">
                <div className="radio-system-analysis"><input checked={compressorSetup?.[props?.architecture]?.configuration === COMPRESSOR_ARCHS_CONFIG?.[props?.architecture]?.CONFIGURATIONS?.RACK} onChange={props?.onConfigurationChange(COMPRESSOR_ARCHS_CONFIG?.[props?.architecture]?.CONFIGURATIONS?.RACK)} type="radio" aria-label="Racks" /><span>{t(LABELKEYS_SYSTEM_ANALYSIS_PANEL.RACKS)}</span></div>
                <div className="radio-system-analysis"><input checked={compressorSetup?.[props?.architecture]?.configuration === COMPRESSOR_ARCHS_CONFIG?.[props?.architecture]?.CONFIGURATIONS?.CDU} onChange={props?.onConfigurationChange(COMPRESSOR_ARCHS_CONFIG?.[props?.architecture]?.CONFIGURATIONS?.CDU)} type="radio" aria-label="Condensing Units" /><span>{t(LABELKEYS_SYSTEM_ANALYSIS_PANEL.CONDENSING)}</span></div>
              </div>
            </div>}
          </>
        }
      </div>
      <div className="content">
        <div className="title">{t(LABELKEYS_SYSTEM_ANALYSIS_PANEL.MEDIUM_TEMPERATURE)}</div>
        {<div className="selected-item">
          <div className="info">{t(LABELKEYS_SYSTEM_ANALYSIS_PANEL.REFRIGERANT)}
          <span className="refrigerant">{props?.architecture === SYSTEM_ARCHS_KEYS.ARC_5? chooseSystems?.system?.[props?.architecture]?.highStage : chooseSystems?.system?.[props?.architecture]?.mtSide}</span></div>
        </div>}
        <div className="selected-item">
          <div className="info">{t(LABELKEYS_SYSTEM_ANALYSIS_PANEL.SELECTED_MT)}</div>
          <div className="info">{props.isMTLoading ? <Loader /> : compressorSetup?.[props?.architecture]?.[TYPE_OF_RACK.MT]?.selectedCompressorLoad || "-"}</div>
        </div>
        {props?.showMTLoadWarning && <div className="warning-message">
          <div className="mesg">
            <div className="warning-icon"><img src={ImgWarning} alt="Warning" width="18px" /></div>
            <span>{t(LABELKEYS_SYSTEM_ANALYSIS_PANEL.WARNING)}</span>
          </div>
          <span> {t(LABELKEYS_SYSTEM_ANALYSIS_PANEL.WARNING_MSG_COMP_MT_LOAD)}</span>
        </div>}
        <div className="load-range">
          <div className="info">{t(LABELKEYS_SYSTEM_ANALYSIS_PANEL.TARGET_MT)}</div>
          <div className="info"> {props?.targetLoads ? props?.targetLoads?.targetMTLoad : loadSetup?.selectedMTLoad || "-"} </div>
        </div>
        {props.racks ?
          <>
            {compressorSetup?.[props?.architecture]?.configuration === COMPRESSOR_ARCHS_CONFIG?.[props?.architecture]?.CONFIGURATIONS?.RACK && <>
              {COMPRESSOR_ARCHS_CONFIG?.[props?.architecture]?.[locationAndSize?.storeSize]?.[TYPE_OF_RACK.MT]?.Racks?.indexOf(COMPRESSOR_ARCHS_CONFIG?.RACK_VALUES?.RACK_1) > -1 && <div onClick={onRackClick(TYPE_OF_RACK.MT, COMPRESSOR_ARCHS_CONFIG?.RACK_VALUES?.RACK_1)} className={props?.typeOfRack === TYPE_OF_RACK.MT && props?.rack === COMPRESSOR_ARCHS_CONFIG?.RACK_VALUES?.RACK_1 ? "btn btn-black" : "btn btn-white"}>{t(LABELKEYS_SYSTEM_ANALYSIS_PANEL.RACK_1)}</div>}
              {COMPRESSOR_ARCHS_CONFIG?.[props?.architecture]?.[locationAndSize?.storeSize]?.[TYPE_OF_RACK.MT]?.Racks?.indexOf(COMPRESSOR_ARCHS_CONFIG?.RACK_VALUES?.RACK_2) > -1 && <div onClick={onRackClick(TYPE_OF_RACK.MT, COMPRESSOR_ARCHS_CONFIG?.RACK_VALUES?.RACK_2)} className={props?.typeOfRack === TYPE_OF_RACK.MT && props?.rack === COMPRESSOR_ARCHS_CONFIG?.RACK_VALUES?.RACK_2 ? "btn btn-black" : "btn btn-white"}>{t(LABELKEYS_SYSTEM_ANALYSIS_PANEL.RACK_2)}</div>}
              {COMPRESSOR_ARCHS_CONFIG?.[props?.architecture]?.[locationAndSize?.storeSize]?.[TYPE_OF_RACK.MT]?.Racks?.indexOf(COMPRESSOR_ARCHS_CONFIG?.RACK_VALUES?.RACK_3) > -1 && <div onClick={onRackClick(TYPE_OF_RACK.MT, COMPRESSOR_ARCHS_CONFIG?.RACK_VALUES?.RACK_3)} className={props?.typeOfRack === TYPE_OF_RACK.MT && props?.rack === COMPRESSOR_ARCHS_CONFIG?.RACK_VALUES?.RACK_3 ? "btn btn-black" : "btn btn-white"}>{t(LABELKEYS_SYSTEM_ANALYSIS_PANEL.RACK_3)}</div>}
              {COMPRESSOR_ARCHS_CONFIG?.[props?.architecture]?.[locationAndSize?.storeSize]?.[TYPE_OF_RACK.MT]?.Racks?.indexOf(COMPRESSOR_ARCHS_CONFIG?.RACK_VALUES?.RACK_4) > -1 && <div onClick={onRackClick(TYPE_OF_RACK.MT, COMPRESSOR_ARCHS_CONFIG?.RACK_VALUES?.RACK_4)} className={props?.typeOfRack === TYPE_OF_RACK.MT && props?.rack === COMPRESSOR_ARCHS_CONFIG?.RACK_VALUES?.RACK_4 ? "btn btn-black" : "btn btn-white"}>{t(LABELKEYS_SYSTEM_ANALYSIS_PANEL.RACK_4)}</div>}
            </>
            }
            {compressorSetup?.[props?.architecture]?.configuration === COMPRESSOR_ARCHS_CONFIG?.[props?.architecture]?.CONFIGURATIONS?.CDU && <>
              {COMPRESSOR_ARCHS_CONFIG?.[props?.architecture]?.[locationAndSize?.storeSize]?.[TYPE_OF_RACK.MT]?.Racks?.indexOf(COMPRESSOR_ARCHS_CONFIG?.RACK_VALUES?.RACK_1) > -1 && <div onClick={onRackClick(TYPE_OF_RACK.MT, COMPRESSOR_ARCHS_CONFIG?.RACK_VALUES?.RACK_1)} className={props?.typeOfRack === TYPE_OF_RACK.MT && props?.rack === COMPRESSOR_ARCHS_CONFIG?.RACK_VALUES?.RACK_1 ? "btn btn-black" : "btn btn-white"}>{t(LABELKEYS_SYSTEM_ANALYSIS_PANEL.CDU_1)}</div>}
              {COMPRESSOR_ARCHS_CONFIG?.[props?.architecture]?.[locationAndSize?.storeSize]?.[TYPE_OF_RACK.MT]?.Racks?.indexOf(COMPRESSOR_ARCHS_CONFIG?.RACK_VALUES?.RACK_2) > -1 && <div onClick={onRackClick(TYPE_OF_RACK.MT, COMPRESSOR_ARCHS_CONFIG?.RACK_VALUES?.RACK_2)} className={props?.typeOfRack === TYPE_OF_RACK.MT && props?.rack === COMPRESSOR_ARCHS_CONFIG?.RACK_VALUES?.RACK_2 ? "btn btn-black" : "btn btn-white"}>{t(LABELKEYS_SYSTEM_ANALYSIS_PANEL.CDU_2)}</div>}
              {COMPRESSOR_ARCHS_CONFIG?.[props?.architecture]?.[locationAndSize?.storeSize]?.[TYPE_OF_RACK.MT]?.Racks?.indexOf(COMPRESSOR_ARCHS_CONFIG?.RACK_VALUES?.RACK_3) > -1 && <div onClick={onRackClick(TYPE_OF_RACK.MT, COMPRESSOR_ARCHS_CONFIG?.RACK_VALUES?.RACK_3)} className={props?.typeOfRack === TYPE_OF_RACK.MT && props?.rack === COMPRESSOR_ARCHS_CONFIG?.RACK_VALUES?.RACK_3 ? "btn btn-black" : "btn btn-white"}>{t(LABELKEYS_SYSTEM_ANALYSIS_PANEL.CDU_3)}</div>}
              {COMPRESSOR_ARCHS_CONFIG?.[props?.architecture]?.[locationAndSize?.storeSize]?.[TYPE_OF_RACK.MT]?.Racks?.indexOf(COMPRESSOR_ARCHS_CONFIG?.RACK_VALUES?.RACK_4) > -1 && <div onClick={onRackClick(TYPE_OF_RACK.MT, COMPRESSOR_ARCHS_CONFIG?.RACK_VALUES?.RACK_4)} className={props?.typeOfRack === TYPE_OF_RACK.MT && props?.rack === COMPRESSOR_ARCHS_CONFIG?.RACK_VALUES?.RACK_4 ? "btn btn-black" : "btn btn-white"}>{t(LABELKEYS_SYSTEM_ANALYSIS_PANEL.CDU_4)}</div>}
            </>
            }
          </>
          :
          <div className={props?.typeOfRack === TYPE_OF_RACK.MT ? "btn btn-black" : "btn btn-white"} onClick={onRackTypeClick(TYPE_OF_RACK.MT)}>{t(LABELKEYS_SYSTEM_ANALYSIS_PANEL.RACK)}</div>}
      </div>
      <div className="content">
        <div className="title">{t(LABELKEYS_SYSTEM_ANALYSIS_PANEL.LOW_TEMPERATURE)}</div>
        {
          <div className="selected-item">
            <div className="info">{t(LABELKEYS_SYSTEM_ANALYSIS_PANEL.REFRIGERANT)}<span className="refrigerant">{chooseSystems?.system?.[props?.architecture]?.ltSide}</span></div>
          </div>
        }
        <div className="selected-item">
          <div className="info">{t(LABELKEYS_SYSTEM_ANALYSIS_PANEL.SELECTED_LT)}</div>
          <div className="info"> {props.isLTLoading ? <Loader /> : compressorSetup?.[props?.architecture]?.[TYPE_OF_RACK.LT]?.selectedCompressorLoad || "-"}</div>
        </div>
        {props?.showLTLoadWarning && <div className="warning-message">
          <div className="mesg">
            <div className="warning-icon"><img src={ImgWarning} alt="Warning" width="18px" /></div>
            <span>{t(LABELKEYS_SYSTEM_ANALYSIS_PANEL.WARNING)}</span>
          </div>
          <span> {t(LABELKEYS_SYSTEM_ANALYSIS_PANEL.WARNING_MSG_COMP_LT_LOAD)}</span>
        </div>}
        <div className="load-range">
          <div className="info">{t(LABELKEYS_SYSTEM_ANALYSIS_PANEL.TARGET_LT)}</div>
          <div className="info"> {props?.targetLoads ? props?.targetLoads?.targetLTLoad : loadSetup?.selectedLTLoad || "-"} </div>
        </div>
        {props.racks ? <>
          {compressorSetup?.[props?.architecture]?.configuration === COMPRESSOR_ARCHS_CONFIG?.[props?.architecture]?.CONFIGURATIONS?.RACK && <>
            {COMPRESSOR_ARCHS_CONFIG?.[props?.architecture]?.[locationAndSize?.storeSize]?.[TYPE_OF_RACK.LT]?.Racks?.indexOf(COMPRESSOR_ARCHS_CONFIG?.RACK_VALUES?.RACK_1) > -1 && <div onClick={onRackClick(TYPE_OF_RACK.LT, COMPRESSOR_ARCHS_CONFIG?.RACK_VALUES?.RACK_1)} className={props?.typeOfRack === TYPE_OF_RACK.LT && props?.rack === COMPRESSOR_ARCHS_CONFIG?.RACK_VALUES?.RACK_1 ? "btn btn-black" : "btn btn-white"}>{t(LABELKEYS_SYSTEM_ANALYSIS_PANEL.RACK_1)}</div>}
            {COMPRESSOR_ARCHS_CONFIG?.[props?.architecture]?.[locationAndSize?.storeSize]?.[TYPE_OF_RACK.LT]?.Racks?.indexOf(COMPRESSOR_ARCHS_CONFIG?.RACK_VALUES?.RACK_2) > -1 && <div onClick={onRackClick(TYPE_OF_RACK.LT, COMPRESSOR_ARCHS_CONFIG?.RACK_VALUES?.RACK_2)} className={props?.typeOfRack === TYPE_OF_RACK.LT && props?.rack === COMPRESSOR_ARCHS_CONFIG?.RACK_VALUES?.RACK_2 ? "btn btn-black" : "btn btn-white"}>{t(LABELKEYS_SYSTEM_ANALYSIS_PANEL.RACK_2)}</div>}
          </>
          }
          {compressorSetup?.[props?.architecture]?.configuration === COMPRESSOR_ARCHS_CONFIG?.[props?.architecture]?.CONFIGURATIONS?.CDU && <>
            {COMPRESSOR_ARCHS_CONFIG?.[props?.architecture]?.[locationAndSize?.storeSize]?.[TYPE_OF_RACK.LT]?.Racks?.indexOf(COMPRESSOR_ARCHS_CONFIG?.RACK_VALUES?.RACK_1) > -1 && <div onClick={onRackClick(TYPE_OF_RACK.LT, COMPRESSOR_ARCHS_CONFIG?.RACK_VALUES?.RACK_1)} className={props?.typeOfRack === TYPE_OF_RACK.LT && props?.rack === COMPRESSOR_ARCHS_CONFIG?.RACK_VALUES?.RACK_1 ? "btn btn-black" : "btn btn-white"}>{t(LABELKEYS_SYSTEM_ANALYSIS_PANEL.CDU_1)}</div>}
            {COMPRESSOR_ARCHS_CONFIG?.[props?.architecture]?.[locationAndSize?.storeSize]?.[TYPE_OF_RACK.LT]?.Racks?.indexOf(COMPRESSOR_ARCHS_CONFIG?.RACK_VALUES?.RACK_2) > -1 && <div onClick={onRackClick(TYPE_OF_RACK.LT, COMPRESSOR_ARCHS_CONFIG?.RACK_VALUES?.RACK_2)} className={props?.typeOfRack === TYPE_OF_RACK.LT && props?.rack === COMPRESSOR_ARCHS_CONFIG?.RACK_VALUES?.RACK_2 ? "btn btn-black" : "btn btn-white"}>{t(LABELKEYS_SYSTEM_ANALYSIS_PANEL.CDU_2)}</div>}
          </>
          }
        </>
          :
          <div className={props?.typeOfRack === TYPE_OF_RACK.LT ? "btn btn-black" : "btn btn-white"} onClick={onRackTypeClick(TYPE_OF_RACK.LT)}>{t(LABELKEYS_SYSTEM_ANALYSIS_PANEL.RACK)}</div>}
      </div>
    </div>
  );
};

export default SystemAnalysisPanel;